import React, { useContext, useState } from "react";
import { ProfileContext } from "../../context/ProfileContext";
import {
  List,
  Show,
  SimpleShowLayout,
  TextField,
  Create,
  Edit,
  SimpleForm,
  FunctionField,
  TextInput,
  useRedirect,
  useNotify,
  ReferenceField,
  AutocompleteInput,
  useQueryWithStore,
  Loading,
  Error,
  useShowController,
  DeleteButton,
  EditButton,
  TopToolbar,
} from "react-admin";
import { validateName, validateRequiredEmail } from "../../utils/validators";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { HiddenTextInput } from "../../components/hiddenTextInput";
import EmptyListPage from "../../components/EmptyListPage";
import AdminIcon from "@material-ui/icons/SupervisorAccount";
import PhoneNumberField from "../../layout/TwPhoneNumberInput";
import WithRecord from "../../components/WithRecord";
import { db } from "../../config/firebaseConfig";
import exporter from "../../components/customDataExporter";
import useFetchCurrentUserPermissions from "../../components/useFetchCurrentUserPermissions";
import getResourceLink from "../../routes/resource-names";
import InviteStatus from "../../components/InviteStatus";
import ResendInviteButton from "../../components/ResendInviteButton";
import createUser from "../../utils/createUser";
import StyledTableHeader from "../../layout/TableHeader";
import BioField from "./Agents/components/AgentBioField";
import PageHeader from "../../components/PageHeader";
import {TeamName} from "./teams"
import {
  CardContent,
  CardActions,
  Card, 
  Avatar,
  Divider
} 
from "@material-ui/core";
import {useStyles} from "./Agents/views/AgentsShow"
import { useMediaQuery} from "@material-ui/core";
import AdminCard from "../../components/userComponent/AdminCard";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import IndeterminateLoading from "../../components/IndeterminateLoading";


export const AdminList = (props) => {
  const { profile } = useContext(ProfileContext);
  const { formattedPermissions } = useFetchCurrentUserPermissions();
  const companyId = profile.company.id;
  const resourceName = getResourceLink(companyId);
  let cachedProfile = JSON.parse(localStorage.getItem("cachedProfile"));
  const isSmall = useMediaQuery(`(max-width:950px)`);
  const compType = profile.admin
    ? profile.admin.companyType
    : cachedProfile.admin.companyType;

  const adminsExporter = (admins) =>
    exporter({
      resourceArr: admins,
      companyId: companyId,
      resourceType: "admins ",
    });

  return companyId ? (
    <List
      {...props}
      title="Admins"
      empty={
        <EmptyListPage
          icon={AdminIcon}
          sort={{ field: "createdAt", order: "DESC" }}
        />
      }
      exporter={
        formattedPermissions[
          compType === "serviceProvider" ? resourceName.admins:resourceName.businessAdmins
        ] &&
        formattedPermissions[
          compType === "serviceProvider" ? resourceName.admins:resourceName.businessAdmins
        ].list
          ? adminsExporter
          : false
      }
    >
      {isSmall ? (
        <AdminCard/>
        ) : (
      <StyledTableHeader rowClick="show">
        <BioField/>
        <ReferenceField
          label="Team"
          reference={
            compType === "serviceProvider" ? `${resourceName.teams}` : compType === "business" ? `${resourceName.businessTeams}` :null
            }
          source="team.id"
          link="show"
        >
         <FunctionField
          label="Teams"
          render={(record) => (
            <TeamName name={record.name} />
          )}
        />
        </ReferenceField>
         {/* <EditButton /> */}
        {/* <DeleteButton undoable={false} /> */}
      </StyledTableHeader>
       )}
    </List>
  ) : null;
};

export const AdminShow = (props) => {
  const { profile } = useContext(ProfileContext);
  const companyId = profile.company.id;
  const adminUserId = profile.admin.userId;
  const resourceName = getResourceLink(companyId);
  const classes = useStyles();
  let cachedProfile = JSON.parse(localStorage.getItem("cachedProfile"));

  const compType = profile.admin
    ? profile.admin.companyType
    : cachedProfile.admin.companyType;

  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props);

  const ShowViewActions = ({ basePath, data }) => (
    <TopToolbar className={classes.toolbar}>
      <EditButton
        label="All Admins"
        icon={<ArrowBackIosIcon />}
        color="primary"
        to={ compType === "serviceProvider" ? resourceName.admins:resourceName.businessAdmins}
        size="medium"
      />
      {/* <EditButton basePath={basePath} record={data} /> */}
    </TopToolbar>
  );

  return companyId ? (
    <Show {...props} title="Admin/Details" actions={<ShowViewActions />}>
      <SimpleShowLayout>
      {record !== undefined && (
      <Card style={{margin:"0.5rem",borderRadius:"10px"}}>
        <div style={{display:"grid", justifyContent:"center",marginTop:"0.5rem",gap:"0.5rem"}}>
         <Avatar alt={record.firstName} className={classes.image} />
         <FunctionField
         style={{display:"grid", justifyContent:"center"}}
                className={classes.text}
                  render={(record) =>
                   record.name ? record.name : `${record.firstName} ${record.lastName}`
                  }
                />
                <React.Fragment>
                  <div>
                  <ReferenceField
                      label="Team"
                      reference={
                        compType === "serviceProvider" ? 
                        `${resourceName.teams}` 
                        : `${resourceName.businessTeams}`}
                      source="team.id"
                      link="show"
                    >
                      <FunctionField
                      style={{display:"grid", justifyContent:"center"}}
                                render={(record) =>
                                  `${record.name}`
                                }
                              />
                    </ReferenceField>
                  </div>
                  <div className={classes.text}>
                        <FunctionField
                        style={{display:"grid", justifyContent:"center"}}
                          label="Invite status"
                          render={(record) => (
                            <InviteStatus
                              label="Invite Status"
                              inviteConfirmed={record.inviteConfirmed}
                            />
                          )}
                        />
                      </div>
                </React.Fragment>
                </div>
                <Divider style={{margin:"0.4rem 3rem 0"}}/>
            <CardContent >
                <Grid container spacing={2} style={{display:"grid", justifyContent:"center"}}>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      component="p"
                      style={{display:"grid", justifyContent:"center"}}
                    >
                      Contact:{" "}
                    </Typography>
                    <div>
                        <TextField className={classes.text}  style={{display:"flex", justifyContent:"center"}} source="phoneNumber" />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      component="p"
                      style={{display:"grid", justifyContent:"center"}}
                    >
                      Hub:{" "}
                    </Typography>
                    <div className={classes.text} style={{display:"flex", justifyContent:"center"}}>
                        {record.hub || "N/A"}
                      </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      component="p"
                      style={{display:"grid", justifyContent:"center"}}
                    >
                      Title:{" "}
                    </Typography>
                    <div className={classes.text} style={{display:"flex", justifyContent:"center"}}>
                        {record.title  || "N/A"}
                      </div>
                  </Grid>
                    <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      component="p"
                      style={{display:"grid", justifyContent:"center"}}
                    >
                      Email:{" "}
                    </Typography>
                    <div>
                        <TextField style={{display:"flex", justifyContent:"center"}} className={classes.text}source="emailAddress" />
                      </div>
                  </Grid> 
                </Grid>
            </CardContent>
            <CardActions style={{display:"grid", justifyContent:"center"}}>
            {adminUserId !== record.userId 
               ? (
            <ResendInviteButton
                path={props.resource}
                docId={props.id}
                inviteConfirmed={record.inviteConfirmed}
              />
               ): null}
            </CardActions>
          </Card>
        )}
      </SimpleShowLayout>
    </Show>
  ) : null;
};

const AdminForm = (props) => (
  <div style={{ width: "100%" }}>
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
      >
        <PageHeader title="Enter Admin information"/>
      </Grid>
      <Grid container spacing={2} item xs={12} sm={6}>
        <Grid item xs={12} sm={6}>
          <TextInput source="firstName" validate={validateName} fullWidth variant="outlined" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput source="lastName" validate={validateName} fullWidth variant="outlined" />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInput
            source="emailAddress"
            fullWidth
            validate={validateRequiredEmail}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <PhoneNumberField defaultValue={props.record.phoneNumber} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <AutocompleteInput
            source="team.id"
            label="Team"
            choices={props.choices}
            optionText={"name"}
            optionValue={"id"}
            validate={validateName}
            options={{ autoComplete: "none" }}
            fullWidth
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextInput source="title" fullWidth variant="outlined"/>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInput source="hub" fullWidth variant="outlined"/>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInput multiline source="bio" fullWidth variant="outlined"/>
        </Grid>
      </Grid>
    </Grid>
  </div>
);

export const AdminCreate = (props) => {
  const { profile } = useContext(ProfileContext);
  const companyId = profile.company.id;
  const adminId = profile.admin.id;
  const resourceName = getResourceLink(companyId);
  const redirect = useRedirect();
  const notify = useNotify();
  const [showLoader, setShowLoader] = useState(false);

  let cachedProfile = JSON.parse(localStorage.getItem("cachedProfile"));

  const compType = profile.admin
    ? profile.admin.companyType
    : cachedProfile.admin.companyType;

  // load teams belonging to current company
  const { loaded, error, data } = useQueryWithStore({
    type: "getList",
    resource: compType === "serviceProvider" ? resourceName.teams : compType === "business" ? resourceName.businessTeams :null,
    payload: {
      pagination: { page: 1, perPage: 25 },
      sort: { field: "name", order: "ASC" },
      filter: {},
    },
  });
  if (!loaded) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  const teams = data;

  const onSuccess = () => {
    notify("The admin user was created successfully","success");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify(error, "warning");
    console.log(error);
  };

  const saveAdmin = async (record) => {
    const { firstName, lastName, emailAddress, phoneNumber, bio } =
      record;
    const adminUserData = {
      firstName,
      lastName,
      emailAddress,
      phoneNumber,
      bio,
    };
    const adminData = { 
      companyId, 
      companyType: compType === "serviceProvider" ? "serviceProvider":"businesses" ,
      name:firstName.concat(" ",lastName),
      ...record
    };
    /**
     * 1. check if an admin with the given details exists in the current sp.
     * 2. if so, show an alert notifying the user that such an admin already exists
     * context: we can't have more than one admin with the same email address for each sp
     */
    try {
      //Trigger the loader the moment the save button is clicked
      setShowLoader(true);
      const adminsSnap = await db
        .collection(
          compType === "serviceProvider" ? 
          `companies/${companyId}/admins`
          : `businesses/${companyId}/admins`
          )
        .where("emailAddress", "==", emailAddress)
        .get();
      if (adminsSnap.size > 0) {
        // an admin exists
        notify(
          "An admin with that email address already exists, please use a different email address",
          "warning"
        );
      } else {
        // admin doesn't exists; proceed with the creation
        // Save admin data to the users collection and the rest in admins
        try {
          // save Admin's data
          createUser(
            adminUserData,
            adminData,
            props.resource,
            onSuccess,
            onFailure
          );
        } catch (error) {
          onFailure(error);
          console.log(error);
        }
      }
    } catch (err) {
      //Disable the loader the moment something goes wrong
      setShowLoader(false);
      console.error("******* err: fetching admin *******", err);
    }
  };

  return (
    <>
    {showLoader && (
          <IndeterminateLoading
            showDialog={true}
            message="Creating admin. Do not close this tab nor exit your browser."
          />
        )}
    <Create
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Admin/Create"
    >
      <SimpleForm save={saveAdmin} warnWhenUnsavedChanges>
        <AdminForm choices={teams} />
        <HiddenTextInput source="inviterId" initialValue={adminId} />
      </SimpleForm>
    </Create>
    </>
  );
};

export const AdminEdit = (props) => {
  const { profile } = useContext(ProfileContext);
  const companyId = profile.company.id;
  const redirect = useRedirect();
  const notify = useNotify();
  const [state, setState] = React.useState({
    teams: [],
  });

  let cachedProfile = JSON.parse(localStorage.getItem("cachedProfile"));

  const compType = profile.admin
    ? profile.admin.companyType
    : cachedProfile.admin.companyType;

  React.useEffect(() => {
    const fetchTeams = async () => {
      const teamsData = await (
        await db.collection(
          compType === "serviceProvider" ? 
          `companies/${companyId}/teams`
          : `businesses/${companyId}/teams`
          ).get()
      ).docs;
      teamsData.forEach((teamData) => {
        const team = teamData.data();
        team.id = teamData.id;
        setState((state) => ({
          ...state,
          teams: [...state.teams, team],
        }));
      });
    };

    fetchTeams();
  }, [companyId]);

  const onSuccess = () => {
    notify("The admin user was updated successfully","success");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The admin user was not updated!", "warning");
    console.log(error);
  };
  return (
    <Edit
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Admin/Edit"
    >
      <WithRecord>
        {({ record, ...props }) => (
          <SimpleForm record={record} {...props} warnWhenUnsavedChanges>
            <AdminForm record={record} choices={state.teams} />
          </SimpleForm>
        )}
      </WithRecord>
    </Edit>
  );
};
