import React, { useState } from "react";
import AuthSidebar from "../../components/authComponents/Sidebar";
import {
  EmailInputField,
  PasswordInputField,
} from "../../components/authComponents/InputFields";
import Divider from "../../components/authComponents/layout/Divider";
import { AuthBtn, AuthLinkBtn } from "../../components/authComponents/Buttons";
import {
  AuthContentWrapper,
  AuthPageWrapper,
} from "../../components/authComponents/layout/Wrappers";
import PasswordReset from "./PasswordReset";

const SignInPage = () => {
  const [formErrors, setFormErrors] = useState({
    email: null,
    password: null,
  });

  return (
    <AuthPageWrapper>
      <AuthSidebar login />
      <AuthContentWrapper>
        <h1 className="auth_title" style={{ marginBottom: "3rem" }}>Sign in</h1>
        <EmailInputField formErrors={formErrors} />
        <PasswordInputField formErrors={formErrors} />
        <AuthBtn emailAndPswd signin />
        <Divider />
        <AuthBtn google signin />
        <AuthLinkBtn register />
        <PasswordReset />
      </AuthContentWrapper>
    </AuthPageWrapper>
  );
};

export default SignInPage;
