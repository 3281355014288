import React, { useState } from "react";
import AuthSidebar from "../../components/authComponents/Sidebar";
import {
  EmailInputField,
  PasswordInputField,
} from "../../components/authComponents/InputFields";
import Divider from "../../components/authComponents/layout/Divider";
import { AuthBtn, AuthLinkBtn } from "../../components/authComponents/Buttons";
import {
  AuthContentWrapper,
  AuthPageWrapper,
} from "../../components/authComponents/layout/Wrappers";
import { auth, googleProvider } from "../../config/firebaseConfig";
import { navigate } from "@reach/router";
import { getUser, isExistingTwiftUser } from "../auth/authHelpers";
import StickyAnnouncementBanner from "../../components/StickyAnnouncementBanner";

const SignUpPage = () => {
  const [state, setState] = useState({
    email: "",
    password: "",
    formErrors: {
      email: null,
      password: null,
    },
  });

  // destructure state
  const { formErrors, email, password } = state;

  const handleTextFieldChange = (e) => {
    // get the user input
    const {
      target: { name, value },
    } = e;

    // set input values to state
    setState((state) => ({
      ...state,
      [name]: value,
    }));
    // console.log("**** e.target.value *****", name, value);
  };

  const handleEmailAndPasswordSignUp = async () => {
    // form validation
    const reqFieldText = "* This field is required";
    if (!email && !password) {
      setState((state) => ({
        ...state,
        formErrors: {
          email: reqFieldText,
          password: reqFieldText,
        },
      }));
    } else if (!email) {
      setState((state) => ({
        ...state,
        formErrors: {
          email: reqFieldText,
        },
      }));
    } else if (!password) {
      setState((state) => ({
        ...state,
        formErrors: {
          password: reqFieldText,
        },
      }));
    } else {
      // signup with email and password
      try {
        // get the auth result
        const authResult = await auth.createUserWithEmailAndPassword(
          email,
          password
        );
        // redirect to '/about-you' on success
        navigate("/about-you", {
          state: {
            displayName: authResult.user.displayName,
            email: authResult.user.email,
            phoneNumber: authResult.user.phoneNumber,
          },
        });
      } catch (err) {
        /**
         * handle errors:
         * @see https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#createuserwithemailandpassword for the error references
         *   1. auth/email-already-in-use
         *   2. auth/invalid-email
         *   3. everything else
         */
        const { code } = err;
        switch (code) {
          case "auth/email-already-in-use": // There exists a firebase auth user with the given email.
            {
              /**
               * There exists a firebase auth user with the given email. Get the current user
               */
              try {
                const authResult = await auth.signInWithEmailAndPassword(
                  email,
                  password
                );
                // Present the workspace chooser for the user to choose between creating a new workspace or continuing with the existing one
                const isTwiftUser = await isExistingTwiftUser(
                  authResult.user.email,
                  authResult.user.phoneNumber
                );
                if (isTwiftUser) {
                  const twiftUser = await getUser(authResult.user.email);
                  navigate("/workspace-picker", {
                    state: {
                      emailAddress: authResult.user.email,
                      firstName: twiftUser.firstName,
                      userId: twiftUser.id,
                    },
                  });
                } else {
                  // something is wrong here. There exists a firebase auth user with the given email but there doesn't exist a corresponding twift user.
                }
              } catch (error) {
                console.error(
                  "**** signup err: err while signing in existing auth user ****",
                  error
                );
              }
            }
            break;
          case "auth/invalid-email":
            {
              // this is thrown if the email address is not valid
              // set 'invalid email' as an error in the email form field
              setState((state) => ({
                ...state,
                formErrors: {
                  email: "invalid email",
                },
              }));
            }
            break;
          default:
            console.log("**** err: err signing up with email + pswd ***", err);
            break;
        }
      }
    }
  };

  const handleGoogleSignUp = async () => {
    try {
      // use the google auth provider to create a firebase auth user
      const authResult = await auth.signInWithPopup(googleProvider);
      // determine if there exists a matching twift user
      const isTwiftUser = await isExistingTwiftUser(
        authResult.user.email,
        authResult.user.phoneNumber
      );
      if (isTwiftUser) {
        const twiftUser = await getUser(authResult.user.email);
        navigate("/workspace-picker", {
          state: {
            emailAddress: authResult.user.email,
            firstName: twiftUser.firstName,
            userId: twiftUser.id,
          },
        });
      } else {
        // redirect to '/about-you'
        navigate("/about-you", {
          state: {
            displayName: authResult.user.displayName,
            email: authResult.user.email,
            phoneNumber: authResult.user.phoneNumber,
          },
        });
      }
    } catch (err) {
      // handle errs
      // @see https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithpopup for error references
      const { code } = err;
      switch (code) {
        case "auth/popup-closed-by-user":
          {
            // @todo: show custom alert to the user
            alert(
              "You've closed the google account chooser popup before choosing an account, please try again"
            );
          }
          break;
        default:
          // @todo: show custom alert to the user
          alert(
            "Something went wrong during signup, please contact us if this persists"
          );
          break;
      }
    }
  };
  return (
    <AuthPageWrapper>
      <AuthSidebar signup />
      <AuthContentWrapper>
      <StickyAnnouncementBanner />
        <h1 className="auth_title" style={{ marginBottom: "3rem" }}>
          Sign up for free
        </h1>
        {/* sign up with google cta button */}
        <AuthBtn google signup handleClick={handleGoogleSignUp} />
        <Divider />
        {/* email input */}
        <EmailInputField
          onChangeHandler={handleTextFieldChange}
          formErrors={formErrors}
        />
        {/* password input */}
        <PasswordInputField
          onChangeHandler={handleTextFieldChange}
          formErrors={formErrors}
        />
        {/* sign up with email + password cta button */}
        <AuthBtn
          emailAndPswd
          handleClick={handleEmailAndPasswordSignUp}
          signup
        />  
        {/* login cta text link */}
        <AuthLinkBtn login />
      </AuthContentWrapper>
    </AuthPageWrapper>
  );
};

export default SignUpPage;
