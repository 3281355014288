import React from "react";
import { auth } from "../../config/firebaseConfig";
import { navigate } from "@reach/router";
import { Button, Box } from '@material-ui/core';

const SignOut = () => {
  const handleLogout = async (e) => {
    e.preventDefault();
    // clear localStorage
    localStorage.clear();

    try {
      // signout from firebase
      await auth.signOut();

      // redirect to the signin page
      navigate("/");
    } catch (err) {
      console.error("**** an error occurred while signing out *****", err);

      //@todo - notify users of the failure
    }
  };

  return (
    <Box sx={{ p: 2, pt: 1.5 }}>
    <Button fullWidth color="secondary" variant="outlined" onClick={handleLogout}>
      Logout
    </Button>
  </Box>
  );
};

export default SignOut;
