import React, { useState, useEffect, useContext } from "react";
import { ProfileContext } from "../../context/ProfileContext";
import { Card, CardContent, CardMedia } from "@material-ui/core";
import { db } from "../../config/firebaseConfig";
import { Typography } from "@material-ui/core";
import Link from "../StyledLink";
import styles from "./styles.js"
import revenue from "../../assets/cash.svg";

const RevenueStatus = () => {
  const { profile } = useContext(ProfileContext);
  const [state, setState] = useState({
    totalRevenue: 0.0,
  });

  useEffect(() => {
    const getPayments = async () => {
      try {
        // fetch the value of the company's summary (main) account balance
        // it is assumed the backend is responsible for continually updating the
        // wallet totals and thus all we need to do is to just read and display it
        const companyId = profile.company.id;
        const unsubscribe = db.collection(`companies/${companyId}/accounts`)
        .where("type", "==", "summary")
        .onSnapshot((snapshot) => {
          // extract the balance from the fetched account (we assume there's only 1)
          snapshot.forEach((doc) => {
            const acct = doc.data();
            setState((state) => ({ ...state, totalRevenue: acct.balance }));
          });
        });

        return unsubscribe;
      } catch (error) {
        console.log("##### error fetching: ", error)
      }
    };

    getPayments();
  }, [profile.company.id]);

  return (
    <div>
      <Link to="/payments">
        <Card style={styles.card}>
          <CardMedia
            style={styles.logo}
            image={revenue}
            title="Revenue"
          />
          <CardContent>
          <div style={styles.content}>
              <Typography variant="h4" gutterBottom style={styles.CardContent}>
                Total Revenue
              </Typography>
              <Typography variant="h1" gutterBottom style={styles.h1}>
                {state.totalRevenue.toLocaleString()} /=
            </Typography>
            </div>
          </CardContent>
        </Card>
      </Link>
    </div>
  );
};

export default RevenueStatus;
