import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  CardHeader,
  Avatar,
  Typography,
  Grid,
  Divider,
} from "@material-ui/core";
import moment from "moment";
import { useListContext } from "react-admin";
import { useStyles } from "./AgentCard";
import InviteStatus from "../InviteStatus";

const InviteCard = (props) => {
  const classes = useStyles();
  const { ids, data, basePath } = useListContext(props);
  if (!ids || !data) {
    return null;
  }

  return (
    <div className={classes.root}>
      {ids.map((id) => (
        <div
          onClick={() => window.location.assign(`/home#/invites/${id}/show`)}
          key={data[id]}
        >
          <Card key={id} className={classes.card}>
            <CardHeader
              action={<InviteStatus vehicleType={data[id].inviteConfirmed} />}
              title={
                <p className={classes.text}>
                  {` ${data[id].businessName}`}
                </p>
              }
              avatar={<Avatar alt={`${data[id].firstName}`} />}
              subheader={` ${data[id].businessEmailAddress}`}
            />
            <CardContent className={classes.cardContent}>
              <Divider className={classes.divider} />
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <div className={classes.wrapper}>
                    <Typography variant="caption" display="block" gutterBottom>
                      Phone Number: &nbsp;
                    </Typography>

                    <b>{data[id].businessPhoneNumber}</b>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.wrapper}>
                    <Typography variant="caption" display="block" gutterBottom>
                      Invited  On : &nbsp;
                    </Typography>
                    <b>{moment(data[id].createdAt).format("DD-MM-YYYY")}</b>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default InviteCard;