import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { syncCompanyData, syncTeamData, syncCustomClaims } from "../../../syncGlobalProfile";
import { ProfileContext } from "../../../../../context/ProfileContext";
import { navigate } from "@reach/router";
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AccountCard from "./AccountCard"
import { db } from "../../../../../config/firebaseConfig";
import { hasPendingInvite, sendAccountVerificationLink } from "../../../authHelpers";

/**
 * MultipleAccountsSelector - will be used to choose an account to continue with in one of the following cases:
 * 1. registered as an admin in more than one company
 * 2. registered as an agent in more than one company
 * 3. registered as both an admin and an agent
 *
 * @example
 * We found multiple accounts with your details, please choose one to continue
 * A. - ABCD LOGISTICS (service Provider)
 *    - role: Agent
 *
 * B. - XYZ DELIVERIES (service Provider)
 *    - role: Admin
 *    - team: Members
 */
const AccountSelector = (props) => {
  const { authUser, updateProfile } = useContext(ProfileContext);
  const [accountsArray, setAccountsArray] = useState([]);
  const { fullScreen, open, onClose, ariaLabel, sendMultipleAccsDataToLogin } =
    props;

  useEffect(() => {
    /**
     * setMultipleAccounts - retrieves the cached profile object, unpacks
     * the embedded multiple accounts and sets the appropriate component state
     * @returns - void
     */
    const setMultipleAccounts = async () => {
      if (localStorage.getItem("multipleAccounts")) {
        const cachedSyncedProfile = JSON.parse(
          localStorage.getItem("multipleAccounts")
        );

        let accsArray = [];

        if (cachedSyncedProfile.agent && !cachedSyncedProfile.agent.errors) {
          // user is registered as an agent in just one company
          accsArray.push({
            ...cachedSyncedProfile.agent,
            role: "agent",
          });
        } else if (
          cachedSyncedProfile.agent &&
          cachedSyncedProfile.agent.errors
        ) {
          // user is registered as an agent in more than one company
          const cachedAgentsArr = cachedSyncedProfile.agent.agents;
          cachedAgentsArr.forEach(async (cachedAgent) => {
            cachedAgent.role = "agent";
          });
          accsArray = [...accsArray, ...cachedAgentsArr];
        }

        // user is rgistered as an admin
        if (
          cachedSyncedProfile.admin &&
          !cachedSyncedProfile.admin.hasMultipleAdmins
        ) {
          accsArray.push({
            ...cachedSyncedProfile.admin,
            role: "admin",
          });
        } else if (
          cachedSyncedProfile.admin &&
          cachedSyncedProfile.admin.hasMultipleAdmins
        ) {
          // user has multiple admin accounts
          const cachedAdminsArr = cachedSyncedProfile.admin.admins;
          cachedAdminsArr.forEach(async (cachedAdmin) => {
            cachedAdmin.role = "admin";
          });

          accsArray = [...accsArray, ...cachedAdminsArr];
        }

        setAccountsArray(accsArray);
      }
    };

    setMultipleAccounts();
  }, [open]);

  /**
   * handleSelect - defines what happens when a user clicks on the account card
   * @param {*} e - React click event
   */
  const handleNext = async (selectedAccount) => {
    const timerStart = Date.now();
    console.log(">>> MAC handleNext. starts at ", timerStart)
    const profile = JSON.parse(selectedAccount);

    // upddate profile context
    const spId =
      profile.role === "agent"
        ? profile.serviceProviderId
        : profile.companyId;

    // sync the company data
    profile.company = await syncCompanyData(spId, profile.companyType);

    // for admin accounts
    if (profile.role === "admin" && profile.team) {
      // determine whether to show the account verification alert or not
      if (
        !authUser.emailVerified ||
        (!profile.inviteConfirmed && profile.team?.name !== "Owners")
      ){
         // call the http function 'verifyAccount'
        const inviteeId = profile.id;
        const inviterId = profile.inviterId;
        const companyId = profile.companyId;
        const inviteeEmailAddress = profile.emailAddress;
        const collName =
          profile.companyType === "serviceProvider"
            ? "companies"
            : "businesses";
        // tell the login component to show the alert
        sendMultipleAccsDataToLogin({
          isModalOpen: false,
          isAdminInvitePending: true,
        });
        // send the verification link
        sendAccountVerificationLink(
          inviteeId,
          inviterId,
          companyId,
          collName,
          inviteeEmailAddress
        );
      } else {
        // set the custom claims of the auth user based on (using) the chosen company account
        // access the persisted firebase auth user to feed it into the custom claims syncer
        try {
          await syncCustomClaims(authUser, { companyDocId: profile.companyId });

          // extract the admin
          const { company, team, ...rest } = profile;
          profile.admin = {
            ...rest,
          };

          profile.team = await syncTeamData(
            profile.companyId,
            profile.team.id,
            profile.companyType === "serviceProvider" ? "companies" : "businesses"
          );

          // update profile context
          updateProfile({ ...profile, authUser });
          // add to localStorage
          localStorage.setItem(
            "cachedProfile",
            JSON.stringify({ ...profile, authUser })
          );

          const timeNow = Date.now();
          const timeDiff = timeNow - timerStart;
          console.log(`>>>>> MAC started at: ${timerStart} to: ${timeNow}. it took ${timeDiff} to complete chooser`)

          // redirect to '/home'
          navigate("home");
        } catch (error) {
            console.error("******** err setting custom claims *******", error);
        }
      }
    } else if (profile.role === "agent") {
      // populate the profile object
      /**
       * - destructure profile
       * NB: agents don't belong to a team and that's why unlike for admins, 'team' is not included while destructuring
       */
      const { company, ...rest } = profile;
      profile.agent = {
        ...rest,
      };

      // update profile context
      updateProfile({ ...profile, authUser });
      // add to localStorage
      localStorage.setItem(
        "cachedProfile",
        JSON.stringify({ ...profile, authUser })
      );

      // redirect to '/agents'
      navigate("agents");
    }
  };


  return (
    <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
        aria-labelledby={ariaLabel}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <DialogTitle id={ariaLabel}>{"Where would you like to go to?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            We found multiple accounts with your details.<br/> Please choose one to continue.
          </DialogContentText>
          <Grid container justify="center" spacing={2}
            aria-label="multipleAccountsChooser"
            name="multipleAccountsChooser"
          >
            {accountsArray.length
              ? accountsArray.map((account, index) => {
                const selectedAccount = JSON.stringify(account)
                  return (
                    <Grid button onClick={() => handleNext(selectedAccount)} key={index}
                      style={{ minWidth: 200 }} item xs={6} sm={4}>
                      <AccountCard account={account} />
                    </Grid>
                  );
                })
              : null}
          </Grid>
        </DialogContent>
      </Dialog>
  );
};

AccountSelector.propTypes = {
  fullScreen: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  ariaLabel: PropTypes.string,
};

export default AccountSelector;
