import React, { useState, useContext } from "react";
import { ProfileContext } from "../../../../context/ProfileContext";
import {
  Button,
  SaveButton,
  useCreate,
  useNotify,
  FormWithRedirect,
} from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconCancel from "@material-ui/icons/Cancel";
import PropTypes from "prop-types";
import { AgentForm } from "../views/AgentsCreate";
import getResourceLink from "../../../../routes/resource-names";
import createAgent from "../../../../utils/createAgent";


// AgentQuickCreate - allows one to quickly create agents.
// It's rendered as a Dialog, depending on the showDialog: bool value
const AgentQuickCreate = ({ defaultState, onDialogClosed, serviceProviderId }) => {
  // get the agents resource to be used with useCreate()

  // resourcesObject gives you = {serviceProviderId: value, _resourceNameObj: { resource1: value, resource2: value, ... }}
  const resourcesObject = getResourceLink(serviceProviderId);
  const agentsResource = resourcesObject.agents;
  const { profile } = useContext(ProfileContext);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showDialog, setShowDialog] = useState(defaultState);
  const [{ loading }] = useCreate(agentsResource);
  const notify = useNotify();

  const handleCloseClick = () => {
    setIsButtonDisabled(true)
    setShowDialog(false);
    try {
      // if function was passed as prop call it
      onDialogClosed();
    } catch (error) {
      // if it wasn't, hence an exception, do nothing
    }
  };
  
    const onSuccess= () => {
      setIsButtonDisabled(true)
      notify("Agent created successfully!", "success");
      handleCloseClick();
    }
  
    const onFailure = (error) => {
      notify(error.message, "error");
    }
  

  return (
    <>
      {/* The create agent Dialog */}
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create Agent"
      >

        <FormWithRedirect
          resource="payments"
          save={(record) => createAgent(record, profile, notify, onSuccess, onFailure, agentsResource)}
          // initialValues={initialValues}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <AgentForm />
              </DialogContent>
              <DialogActions style={{ display: "flex", justifyContent: "space-between",marginTop: "1rem" }}>
                <Button
                  variant="outlined"
                  label="ra.action.cancel"
                  size="medium"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={saving || isButtonDisabled}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

AgentQuickCreate.propTypes = {
  defaultState: PropTypes.bool.isRequired,
  onDialogClosed: PropTypes.func.isRequired,
  serviceProviderId: PropTypes.string.isRequired,
};

export default AgentQuickCreate;
