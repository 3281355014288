import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";

const AuthSidebar = ({
  login,
  signup,
  data,
  identity,
  congrats,
  workspace,
}) => {
  return (
    <Wrapper>
      <img
        id="logo"
        src="https://ik.imagekit.io/twendemobility/logo_rdg9vkc7W.svg?updatedAt=1630574915829"
        alt="Twende Mobility Logo"
      />
      {data && (
        <img
          className="auth_images"
          src="https://ik.imagekit.io/twendemobility/Auth/data_1JsxSWeqn.svg?updatedAt=1632224738523"
          alt="data privacy"
        />
      )}
      {identity && (
        <img
          className="auth_images"
          src="https://ik.imagekit.io/twendemobility/Auth/identity_mylv35ySc4p.svg?updatedAt=1632224739558"
          alt="identity"
        />
      )}
      {congrats && (
        <img
          className="auth_images"
          id="congrats_img"
          src="https://ik.imagekit.io/twendemobility/Auth/congrats_l19tcarDfZ.svg?updatedAt=1632224738329"
          alt="congratulations"
        />
      )}
      {login && (
        <Typography component="p">
          Optimise your operations with Twift
        </Typography>
      )}
      {workspace && <Typography component="p">Workspace</Typography>}
      {signup && (
        <Typography component="p">
          Free trial for 14 days,
          <br />
          Free starter plan,
          <br />
          Forever
        </Typography>
      )}
      {workspace && (
        <p className="additional-text">
          Select the type of company you want to create
        </p>
      )}
      {workspace && (
        <p className="additional_info">
          Each company type has a different set of functionality. Selecting one
          or the other will change your experience.
        </p>
      )}
      {data && (
        <Typography component="p">You can trust us with your data</Typography>
      )}
      {data && (
        <p className="additional-text">
          Your sensitive data remains private to you & cannot be accessed by
          anyone, not even us!
        </p>
      )}
      {identity && <Typography component="p">Confirm your Identity</Typography>}
      {identity && (
        <p className="additional-text">
          We need to verify your identity before you can start using your
          account
        </p>
      )}
      {congrats && <Typography component="p">CONGRATULATIONS!</Typography>}
      {congrats && (
        <p className="additional-text">
          Your account was created successfully! You can now start using Twift!
        </p>
      )}
      {login && (
        <ul>
          <li>Track & manage your fleet</li>
          <li>Manage orders efficiently</li>
          <li>Easily handle payments</li>
        </ul>
      )}
      {signup && (
        <ul>
          <li>No credit card needed</li>
          <li>Unlimited orders</li>
          <li>All premium features</li>
        </ul>
      )}
      {login && (
        <p className="additional_info">
          Twift connects logistics service providers with their customers who
          need to send items from one place to another
        </p>
      )}
      {signup && (
        <p className="additional_info">
          After the free trial, select the plan that best meets your needs
        </p>
      )}
    </Wrapper>
  );
};

export default AuthSidebar;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  padding: 3rem;
  width: 45vw;
  height: 100vh;
  max-height: 100vh;
  background-color: #fafafa;
  background-image: url("https://ik.imagekit.io/twendemobility/Auth/background_kz3JKycc4O.svg?updatedAt=1632140695357");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: auto;
  #logo {
    height: 4rem;
  }
  .additional-text {
    font-size: 1rem;
    font-family: "Fira Sans", sans-serif;
    font-weight: 600;
    color: #000;
  }
  .auth_images {
    height: 30%;
  }
  p {
    color: #00acc1;
    font-size: 1.5rem;
    font-weight: 600;
    font-family: "Ubuntu", sans-serif;
    margin: 3rem 0;
    line-height: 1.8;
  }
  ul {
    margin-top: 3rem;
    list-style: none;
  }
  ul li:before {
    content: "✓   ";
    color: #005ff4;
  }
  ul li {
    font-size: 1.2rem;
    font-weight: 600;
    font-family: "Fira Sans", sans-serif;
    color: #000;
    line-height: 1.8;
    margin: 0.5rem;
  }
  .additional_info {
    margin-top: 4rem;
    font-size: 0.9rem;
    font-family: "Fira Sans", sans-serif;
    font-weight: 500;
    color: #707070;
  }
  @media (max-width: 768px) {
    background-image: url("https://ik.imagekit.io/twendemobility/background_KewmxNr-L.svg?updatedAt=1630576549482");
    padding: 1rem;
    width: 100vw;
    height: auto;
    max-height: auto;
    background-attachment: initial;
    align-items: center;
    .auth_images {
      height: auto;
      width: 35%;
    }
    #congrats_img {
      height: 10rem;
      width: auto;
    }
    #logo {
      height: 2.5rem;
    }
    .additional-text {
      font-size: 0.6rem;
    }
    p {
      font-size: 1.2rem;
      margin: 0.5rem 0;
      text-align: center;
    }
    ul {
      margin: 0.5rem 0;
    }
    ul li {
      font-size: 0.9rem;
    }
    .additional_info {
      margin: 0.5rem 0;
      font-size: 0.9rem;
    }
    .additional-text {
      font-size: 0.95rem;
    }
  }
  @media (max-width: 540px) {
    padding: 2rem;
    #logo {
      height: 3rem;
    }
    .auth_images {
      display: none;
    }
    p {
      font-size: 1.3rem;
      margin: 0.3rem 0;
      text-align: center;
    }
    .additional_info {
      margin: 0.3rem 0;
      font-size: 1rem;
    }
    .additional-text {
      font-size: 1rem;
    }
    ul {
      margin: 0.3rem 0;
    }
    ul li {
      font-size: 0.8rem;
    }
  }
`;
