import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button, CircularProgress } from "@material-ui/core";
import googleIcon from "../../assets/google.svg";
import {
  ArrowBack,
  ArrowForward,
  EmailRounded,
  MeetingRoomRounded,
  Replay,
} from "@material-ui/icons";
import { AuthLink } from "../../pages/auth/AuthStyles";
import { TwToolTip } from "../../layout/TwToolTip/styles";

export const AuthBtn = ({
  emailAndPswd,
  google,
  handleClick,
  signin,
  signup,
}) => {
  return emailAndPswd ? (
    <EmailAndPswdBtnWrapper
      type="submit"
      fullWidth
      color="primary"
      variant="contained"
      disableElevation
      onClick={handleClick}
    >
      <EmailRounded />
      {signin && <span>Sign in with your E-mail</span>}
      {signup && <span>Sign up with your E-mail</span>}
    </EmailAndPswdBtnWrapper>
  ) : google ? (
    <GoogleAuthBtnWrapper
      fullwidth
      disableElevation
      variant="outlined"
      onClick={handleClick}
    >
      <img src={googleIcon} alt="google sign up" width="20rem" />
      {signin && <span>Sign in with Google</span>}
      {signup && <span>Sign up with Google</span>}
    </GoogleAuthBtnWrapper>
  ) : null;
};

export const AuthLinkBtn = ({ register, resetPwd, login, to }) => {
  return (
    <AuthLink
      to={
        register ? "/signup" : resetPwd ? "/password-reset" : login ? "/" : to
      }
    >
      {register && (
        <span
          style={{
            color: "#707070",
            fontFamily: "Fira Sans",
            fontSize: "0.9rem",
          }}
        >
          Do not have an account yet?{" "}
        </span>
      )}
      {register && (
        <span
          style={{
            color: "#00acc1",
            fontFamily: "Fira Sans",
            fontSize: "0.9rem",
          }}
        >
          Register here
        </span>
      )}
      {resetPwd && (
        <span
          style={{
            color: "#00acc1",
            fontFamily: "Fira Sans",
            fontSize: "0.9rem",
          }}
        >
          Forgot password?
        </span>
      )}
      {login && (
        <span
          style={{
            color: "#707070",
            fontFamily: "Fira Sans",
            fontSize: "0.9rem",
          }}
        >
          Already have an account?{" "}
        </span>
      )}
      {login && (
        <span
          style={{
            color: "#00acc1",
            fontFamily: "Fira Sans",
            fontSize: "0.9rem",
          }}
        >
          Log in here
        </span>
      )}
    </AuthLink>
  );
};
export const TwButton = ({
  handleClick,
  id,
  style,
  text,
  contained,
  flat,
  backBtn,
  logoutBtn,
  nextBtn,
  disabled,
  isLoading,
}) => {
  return contained ? (
    <ContainedBtnWrapper
      type="submit"
      color="primary"
      variant="contained"
      disableElevation
      onClick={handleClick}
      style={style}
      disabled={disabled}
      id={id}
    >
      <span>{isLoading ? <CircularProgress color="inherit" /> : text}</span>
    </ContainedBtnWrapper>
  ) : flat ? (
    <FlatBtnWrapper onClick={handleClick} style={style} id={id}>
      {backBtn && (
        <ArrowBack
          style={{ color: "#707070", fontSize: "0.8rem", fontWeight: "500" }}
        />
      )}
      {backBtn && <span>Back</span>}
      {logoutBtn && (
        <MeetingRoomRounded
          style={{ color: "#707070", fontSize: "0.9rem", fontWeight: "500" }}
        />
      )}
      {logoutBtn && <span>Logout</span>}
      {nextBtn && <span style={{ color: "#00acc1" }}>Next</span>}
      {nextBtn && <ArrowForward style={{ color: "#00acc1" }} />}
      <span>{text}</span>
    </FlatBtnWrapper>
  ) : null;
};

// TwButton prop-types
TwButton.propTypes = {
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export const ResendOTPBtn = ({ handleClick }) => {
  return (
    <TwToolTip title="Resend Code" aria-label="Resend Code">
      <ResendOTPBtnWrapper onClick={handleClick}>
        <Replay style={{ color: "#00acc1" }} />
      </ResendOTPBtnWrapper>
    </TwToolTip>
  );
};

const EmailAndPswdBtnWrapper = styled(Button)`
  && {
    width: 100%;
    margin-top: 2rem;
  span {
    font-family: "Ubuntu", sans-serif;
    font-weight: 600;
    font-size: 1rem;
    text-transform: none;
    margin: 0 0.8rem;
    color: #fff;
  }
  @media (max-width: 768px) {
    span {
      font-size: 1rem;
      margin: 0.3rem;
    }
  }
`;

const GoogleAuthBtnWrapper = styled(Button)`
  && {
  width: 100%;
  margin: 1rem 0;
  background-color: #fff;
  border: 2px solid #00acc1;
  span {
    font-family: "Ubuntu", sans-serif;
    font-weight: 600;
    font-size: 1rem;
    text-transform: none;
    margin: 0 0.8rem;
    color: #00acc1;
  }
  @media (max-width: 768px) {
    span {
      font-size: 1rem;
      margin: 0.3rem;
    }
  }
`;

const ResendOTPBtnWrapper = styled(Button)`
  && {
    margin-right: 1rem;
    margin-left: -5rem;
    @media (max-width: 768px) {
      margin-left: -2rem;
    }
    @media (max-width: 320px) {
      margin: 0 -0.3rem;
    }
    @media (max-width: 320px) {
      margin: 0;
      margin-left: -0.8rem;
    }
  }
`;

const ContainedBtnWrapper = styled(Button)`
  && {
    margin-top: 2rem;
    width: 100%;
    span {
      font-family: "Ubuntu", sans-serif;
      font-weight: 600;
      font-size: 1rem;
      margin: 0 0.8rem;
      text-transform: none;
      color: #fff;
    }
    @media (max-width: 768px) {
      span {
        font-size: 1rem;
        margin: 0.3rem;
      }
    }
    @media (max-width: 540px) {
      padding: 0;
      margin-top: 1rem;
      span {
        font-size: 0.8rem;
        margin: 0.3rem;
      }
    }
  }
`;

const FlatBtnWrapper = styled(Button)`
  && {
    margin-top: 2rem;
    span {
      font-family: "Ubuntu", sans-serif;
      font-weight: 500;
      font-size: 0.8rem;
      margin: 0 0.8rem;
      color: #707070;
      text-transform: none;
    }
    @media (max-width: 768px) {
      span {
        font-size: 1rem;
        margin: 0.3rem;
      }
    }
    @media (max-width: 540px) {
      padding: 0;
      margin-top: 0.5rem;
      span {
        font-size: 0.8rem;
        margin: 0.3rem;
      }
    }
  }
`;
