/* eslint-disable */
import * as React from "react";
import { AppBar, UserMenu } from "react-admin";
import ProfilePage from "../pages/auth/ProfilePage";
import MenuItem from "@material-ui/core/MenuItem";
import { ProfileContext } from "../context/ProfileContext";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";

const TwCustomIconStyle = {
  avatar: {
    height: 40,
    width: 40,
  },
};

const TwCustomIcon = withStyles(TwCustomIconStyle)(({ classes, user }) => (
  <Avatar className={classes.avatar} src={user.photoURL} />
));

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    color:"#000000"
  },
}));

const TwUserMenu = (props) => (
  <UserMenu {...props} icon={<TwCustomIcon user={props.user} />}>
    <MenuItem>
      {" "}
      <ProfilePage />{" "}
    </MenuItem>
  </UserMenu>
);

const TwAppBar = (props) => {
  const { profile } = React.useContext(ProfileContext);
  let cachedProfile = JSON.parse(localStorage.getItem("cachedProfile"));
  const userData =  profile ? profile.authUser : cachedProfile.authUser;
  const [state, setState] = useState({
    user: {},
  });
  
  useEffect(() => {
    if (userData) {
      setState((state) => ({
        ...state,
        user: userData,
      }));
    }
  }, [userData]);

  const { photoURL, displayName, email } = state.user;
  const classes = useStyles();

  return (
    <AppBar
    {...props}
    userMenu={<TwUserMenu user={state.user} />}
    className={classes.root}
    elevation={1}
  />
  );
};

export default TwAppBar;
