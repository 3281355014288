import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: bold;
`;

export const SubHeader = styled.div``;
