import React, { useContext, useState, useEffect } from "react";
//material ui imports
import {Grid } from "@material-ui/core";
//RA imports
import PageHeader from "../PageHeader";
import { ProfileContext } from "../../context/ProfileContext";
import TextField from "@material-ui/core/TextField";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import { db } from "../../config/firebaseConfig";
import {Row,Main,Wrapper,ContentWrapper} from "./CustomerForm";
import PhoneInput from "react-phone-number-input";
import MUIPhoneNumberInput from "../../layout/TwPhoneNumberInput/MUIPhoneInput";
import InputAdornment from '@material-ui/core/InputAdornment';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Typography from "@material-ui/core/Typography";
import {
  useStyles,
} from "./OrderStyles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

//sender form
const RecipientForm = (props) => {
  const classes = useStyles();
  const { onChangeHandler, formName,updateAutocompletedCustomerData,validateForm ,handlePhoneNumberChange,updateGeoAddress} = props;
  const { profile } = useContext(ProfileContext);
  const serviceProviderId = profile.company.id;
  const [customers, setCustomers] = useState([]);
  const { name, address, phoneNumber, role } = props.values;
  const [defaultValue, setDefaultValues] = React.useState({});
  const { autocompletedName, autocompletedAddress, autocompletedPhoneNumber, id } = defaultValue;
  const [value, setValue] = React.useState();
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  useEffect(() => {
    const fetchCustomers = async (serviceProviderId) => {
      let custObjectsList = [];
      const custList = (
        await db
          .collection("customers")
          .where("serviceProviders", "==", serviceProviderId)
          .get()
      ).docs;

      custList.forEach((cust) => {
        const custData = {
          id: cust.id,
          ...cust.data(),
        };
        // filter out deleted customers from the suggestion list
        /*eslint-disable eqeqeq*/
        return !custData.deleted || custData.deleted != true
          ? (custObjectsList = [...custObjectsList, custData])
          : null;
      });

      setCustomers(custObjectsList);
    };

    fetchCustomers(serviceProviderId);
  }, [serviceProviderId]);

  const handleAutocomplete = (event, selectedCustomer) => {
    if(selectedCustomer ) {
    const {id,name,address, phoneNumber} = selectedCustomer;
    setDefaultValues({
      autocompletedName:name,
      autocompletedPhoneNumber:phoneNumber,
      autocompletedAddress:address,
      id:id,
    })
    updateAutocompletedCustomerData({id,name,address, phoneNumber})
  }
  else {
    setDefaultValues({
      autocompletedName:"",
      autocompletedPhoneNumber:"",
      autocompletedAddress:"",
      id:"",
    })
    updateAutocompletedCustomerData({autocompletedName, autocompletedAddress, autocompletedPhoneNumber})
  }
  }

  return (
    <form ref={validateForm} >
      <Main>
        <Wrapper container spacing={2} >
          <Grid item lg={10} sm={12}  xs={12}>
          <PageHeader title="Recipient information" />
          </Grid>
          <Grid item lg={5} sm={10}  xs={12}>
          <Autocomplete
            options={customers}
            getOptionLabel={(option) => `${option.name}`}
            fullWidth
            onChange={handleAutocomplete}
            renderOption={(option) => (
              <ListItem>
                <ListItemAvatar>
                  <Avatar />
                </ListItemAvatar>
                <ListItemText
                  primary={`${option.name}`}
                  secondary={option.phoneNumber}
                />
              </ListItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search existing customers..."
                variant="outlined"
                fullWidth
              />
            )}
          />
          </Grid>
          </Wrapper>
        <Row>
          <hr /> <span> OR ADD A NEW CUSTOMER </span>
          <hr />
        </Row>
        <ContentWrapper container spacing={3} >
          <Grid item lg={8} sm={10}  xs={12}>
            <TextField
              variant="outlined"
              required
              name="name"
              value={name ? name:autocompletedName === null ? "" : autocompletedName }
              onChange={(event) => onChangeHandler(event)}
              label="Name"
              placeholder="Recipient's Name."
              InputProps={{
                startAdornment: <InputAdornment position="start"><PersonIcon className={classes.icon}/></InputAdornment>,
              }}
              fullWidth
              autoComplete="name"
            />
          </Grid>
          <Grid item lg={5} sm={10}  xs={12}>
          <PhoneInput
            required
            defaultCountry="KE"
            placeholder="Enter phone number"
            value={autocompletedPhoneNumber ? autocompletedPhoneNumber:phoneNumber}
            onChange={handlePhoneNumberChange}
            InputProps={{
              startAdornment: <InputAdornment position="start"><PhoneIcon className={classes.icon}/></InputAdornment>,
            }}
            inputComponent={MUIPhoneNumberInput}
            fullWidth
          />
          </Grid>
          <Grid item lg={5} sm={10} xs={12}>
          <Autocomplete
              id="google-map-demo"
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.description
              }
              filterOptions={(x) => x}
              options={options}
              autoComplete
              includeInputInList
              filterSelectedOptions
              defaultValue={address}
              value={autocompletedAddress? autocompletedAddress:address}
              onChange={(event, value) => {
                setOptions(value ? [value, ...options] : options);
                setValue(value);
                updateGeoAddress(value?.description)
              }}
              onInputChange={(event, inputValue) => {
                setInputValue(inputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Add a location"
                  variant="outlined"
                  fullWidth
                  required
                />
              )}
              renderOption={(option) => {
                const matches =
                  option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                  option.structured_formatting.main_text,
                  matches.map((match) => [match.offset, match.offset + match.length])
                );

                return (
                  <Grid container alignItems="center">
                    <Grid item>
                      <LocationOnIcon className={classes.icon} />
                    </Grid>
                    <Grid item xs>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{ fontWeight: part.highlight ? 700 : 400 }}
                        >
                          {part.text}
                        </span>
                      ))}

                      <Typography variant="body2" color="textSecondary">
                        {option.structured_formatting.secondary_text}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }}
            />
          </Grid>
          <TextField
            style={{ display: "none" }}
            variant="outlined"
            value={role}
            onChange={(event) => onChangeHandler(event)}
            name="role"
            fullWidth
            defaultValue={formName}
          />
            {id && 
           <TextField
            style={{ display: "none" }}
            variant="outlined"
            value={id}
            name="id"
            fullWidth
          />  }
        </ContentWrapper>
      </Main>
    </form>
  );
};
RecipientForm.propTypes = {
  formName: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  updateAutocompletedCustomerData: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  handlePhoneNumberChange:PropTypes.func.isRequired,
  updateGeoAddress:PropTypes.func.isRequired,
};
export default RecipientForm;
