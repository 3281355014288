/* eslint-disable */
import firebase from "firebase";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig, {useFetchStreams: false});

export const auth = firebase.auth();
export const db = firebase.firestore();
// select the configured location of HTTP cloud functions (primarily for syncCustomClaims callable)
// https://firebase.google.com/docs/functions/locations#client-side_location_selection_for_callable_functions
export const functions = firebaseApp.functions(process.env.REACT_APP_FUNCTIONS_REGION);

// for local instances, use the emulator to connect the frontend to auth, firestore & functions
// adapted from https://fireship.io/lessons/firebase-emulator-advanced/
if (location.hostname === "localhost") {
  // https://firebase.google.com/docs/emulator-suite/connect_auth#web
  auth.useEmulator("http://localhost:9099");
  firebase.firestore().settings({
    host: "localhost:8880",
    ssl: false,
  });

  functions.useFunctionsEmulator("http://localhost:5001");
}

firebase.analytics();

export const googleProvider = new firebase.auth.GoogleAuthProvider();

// offline data persistence
const cachedProfile = localStorage.getItem("profile");
const profile = JSON.parse(cachedProfile);
if (profile?.company?.id) {
  // synchronize tabs when enabling persistence to avoid warning:
  // "Failed to obtain exclusive access to the persistence layer..."
  // https://firebase.google.com/docs/reference/js/firebase.firestore.PersistenceSettings
  db.enablePersistence({ synchronizeTabs: true }).catch(function (err) {
    if (err.code === "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      console.log("error enabling persistence: ", err);
    } else if (err.code === "unimplemented") {
      // The current browser does not support all of the
      // features required to enable persistence
      alert("Your current browser does not allow offline data sync");
    }
  console.log('Offline persistence is enabled.');
  });
}

// extract the redirect URL based on the host we're running in. the deployed instance values should already have 'https' schem in them (doppler)
const redirectUrl = location.hostname === "localhost" ? `http://${location.host}` : process.env.REACT_APP_FIREBASE_FE_HOST_NAME;
export const actionCodeSettings = {
  // URL you want to redirect back to. The domain for this
  // URL must be in the authorized domains list in the Firebase Console.
  // without the http/s scheme, the following error occurs:
  // "auth/invalid-continue-uri", message: "Missing domain in continue url"
  url: redirectUrl,
  // This must be true.
  handleCodeInApp: true,
  // there needs to be a firebase DL domain set up on the corresponding project,
  // even a dummy/test link domain is OK: https://stackoverflow.com/a/55579719/1145905
  dynamicLinkDomain: process.env.REACT_APP_FIREBASE_DL_DOMAIN
};

/**
 * send a sign in link to an email to allow passwordless authentication
 * called either at signup or signin. based on https://firebase.google.com/docs/auth/web/email-link-auth
 * callers can perform any additional setup they need before or after this function is run
 * @param {string} emailAddress the email address to send the sign in link to
 */
export const sendSignInLinkToEmail = async (emailAddress) => {
  return auth.sendSignInLinkToEmail(emailAddress, actionCodeSettings)
  .then((result) => {
    console.log("sign in with email result: ", result);
    // The link was successfully sent. Inform the user.
    alert(`We sent a message to your email address ${emailAddress}, please check it and follow the link we sent you to complete the process`);
    // Save the email locally so we don't need to ask the user for it again
    // if they open the link on the same device.
    window.localStorage.setItem('emailForSignIn', emailAddress);
  });
};

export { firebaseConfig, firebaseApp };
