import React from "react";
import Accordion from "./TwAccordion";
import FAQItem from "./FAQsbodyItem";

const FAQsAccordion = () => {
  const panelsArray = [
    {
      id: "1",
      title: "Data security",
      body: (
        <div>
          <FAQItem
            title="How can I guarantee my safety on the platform?"
            content="Twift runs on top of the most secure cloud platform providers, including Google’s industry-leading services. The integrity of your business-critical data is central to our own business.. "
          />
        </div>
      ),
    },
    {
      id: "2",
      title: "Access & permissions",
      body: (
        <div>
          <FAQItem
            title="Can anyone access my Twift data?"
            content="Only admins who you invite to your account can access the data that you allow them to. Set fine-grained permissions based on the roles of your team members."
          />
        </div>
      ),
    },
    {
      id: "3",
      title: "Customizations",
      body: (
        <div>
          <FAQItem
            title="Can I modify the look and feel of my Twift app?"
            content="Absolutely. We understand the importance of brand to your business. Your Company Settings tab already features plenty of configuration options, but feel free to reach out to us if you have more ideas on how to further enhance your branding. 
."
          />
        </div>
      ),
    },
    {
      id: "4",
      title: "Support",
      body: (
        <div>
          <FAQItem
            title="How can I reach out to customer support?"
            content="Your subscription entitles you to call, email, live-chat or text us to attend to your queries. Pro and Enterprise customers enjoy dedicated support from cross-functional teams led by an account manager. "
          />
        </div>
      ),
    },
    {
      id: "5",
      title: "About Twende Mobility",
      body: (
        <div>
          <FAQItem
            title="Who is Twende?"
            content="Twende is a A unified way for planning travel, rich travel experiences and fulfilling logistics needs."
          />
          <FAQItem
            title="What is Twift?"
            content="Twift connects logistics service providers with their customers: businesses and individuals that need to send items with speed, precision and affordability in mind. Our cloud-based “operating system” makes this possible."
          />
          <FAQItem
            title="How do I join the Twende Team"
            content="We are a team of highly motivated Kenyans with a shared vision of improving travel experiences for any Kenyan who travels long-distance. Connect with us on LinkedIn and we’ll be sure to share any openings we are hiring for."
          />
        </div>
      ),
    },
  ];
  return (
    <div>
        <Accordion panelsArray={panelsArray} style={{ alignItem: "center" }} />
    </div>
  );
};

export default FAQsAccordion;
