import React, { useState, useRef, useEffect } from "react";
import { auth } from "../../../config/firebaseConfig";
import firebase from "firebase";
import "firebase/auth";
import AuthSidebar from "../../../components/authComponents/Sidebar";
import {
  TwButton,
  ResendOTPBtn,
} from "../../../components/authComponents/Buttons";
import styled from "styled-components";
import {
  AuthContentWrapper,
  AuthPageWrapper,
} from "../../../components/authComponents/layout/Wrappers";
import { getToken } from "../../../config/fcm-notifications";
import { createTwiftUser } from "../../auth/authHelpers";
import { navigate } from "@reach/router";
import OtpInput from "react-otp-input";
import IndeterminateLoading from "../../../components/IndeterminateLoading";

const OTPPage = ({ location }) => {
  const [state, setState] = useState({
    otp: "",
    OTPConfResult: null,
    isSendingCode: false,
    isConfirmingCode: false,
  });
  const [fcmToken, setFcmToken] = useState("");
  const captchaRef = useRef(null);

  const { otp, OTPConfResult, isSendingCode, isConfirmingCode } = state;

  const { firstName, lastName, phoneNumber } = location.state;

  const [showLoader, setShowLoader] = useState(false);

  const handleOTPInputChange = (val) => {
    setState((state) => ({
      ...state,
      otp: val,
    }));
  };

  const handlePhoneNumberSignIn = async (phoneNumber) => {
    // update the language of the auth instance before rendering the reCAPTCHA.
    // use the user's device language so that we don't have to set a specific language such as english or chinese
    auth.useDeviceLanguage();

    // setup a reCAPTCHA verifier
    const reCAPTCHAVerifier = new firebase.auth.RecaptchaVerifier(
      captchaRef.current,
      {
        size: "invisible",
        callback: (response) => {
          console.info("Invisible Captcha", response);
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log(
            "******** success: recaptch resolved ************",
            response
          );
        },
        "expired-callback": () => {
          console.error(
            "*** err: response expired, solve the recaptcha again ****"
          );
        },
      }
    );

    try {
      // setIsSendingCode === true
      setState({
        ...state,
        isSendingCode: true,
      });
      // signIn with phoneNumber
      const confirmationResult = await auth.currentUser.linkWithPhoneNumber(
        phoneNumber,
        reCAPTCHAVerifier
      );

      console.log(
        "**** a/c link success: linkWithPhoneNumber was successfull, here's the confirmationResult ****",
        confirmationResult
      );
      // set the confirmationResult to state
      // we'll use it later on to verify the code sent to the user, also,
      // setIsSendingCode === false
      setState({
        ...state,
        isSendingCode: false,
        OTPConfResult: confirmationResult,
      });
    } catch (err) {
      // handle errors
      const { code } = err;
      switch (code) {
        case "auth/provider-already-linked":
          {
            // accounts already linked signInWithPhoneNumber instead?
            try {
              // setIsSendingCode === true
              setState({
                ...state,
                isSendingCode: true,
              });
              const confirmationResult = await auth.signInWithPhoneNumber(
                phoneNumber,
                reCAPTCHAVerifier
              );
              console.log(
                "**** sign in success: signInWithPhoneNumber was successfull, here's the confirmationResult ****",
                confirmationResult
              );
              // set the confirmationResult to state
              // we'll use it later on to verify the code sent to the user
              setState({
                ...state,
                isSendingCode: false,
                OTPConfResult: confirmationResult,
              });
            } catch (err) {
              console.error(
                "**** auth/provider-already-linked err: err signing in with phone number ****",
                err
              );
            }
          }
          break;
        default:
          console.error("**** err: err signing in with phone number ****", err);
        // show a generic alert with an option for them to contact us
      }
    }
  };

  useEffect(() => {
    // handle phone number sign-in
    handlePhoneNumberSignIn(phoneNumber).catch((err) => console.error(err));

    // get fcm token
    getToken(setFcmToken);
  }, []);

  const confirmOTP = async (e) => {
    // prevent default btn behaviour
    e.preventDefault();
    
    //Trigger the loader the moment the verify identity button is clicked
    setShowLoader(true);

    // construct the code entered by the user
    const code = otp;
    console.log("***** OTP code from user ***", code);
    // confirm code
    if (OTPConfResult) {
      try {
        // set isConfirmingCode === true
        setState({
          ...state,
          isConfirmingCode: true,
        });
        const res = await OTPConfResult.confirm(code);
        // User signed in successfully.
        // this is the already-linked auth user account
        // i.e, by the time the user is getting the code, the auth accounts
        // have already been linked ( we do that in the useEffect above)
        const user = res.user;
        console.log("**** success: code confirmed ****", user);
        // create the twift user record
        const twiftUser = await createTwiftUser(
          user,
          firstName,
          lastName,
          phoneNumber,
          fcmToken
        );
        console.log("**** success @confirmOTP: twiftUser ****", twiftUser);
        // set isConfirmingCode === false
        setState({
          ...state,
          isConfirmingCode: false,
        });
        if (twiftUser) {
          // redirect to the auth home
          // add twiftUserId as location.state so that it's available in the auth home
          navigate("/auth-home", {
            state: { twiftUserId: twiftUser.id },
          });
        } else {
          /**
           * @todo - err: handle multiple user records, i.e twiftUser === undefined
           */
        }
      } catch (err) {
        const { code, credential } = err;
        switch (code) {
          case "auth/credential-already-in-use":
            {
              console.log(
                "**** err: handling 'auth/credential-already-in-use', i.e, signin with credential *****",
                credential
              );

              try {
                // sign in with credential
                // adapted from @link - https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithcredential
                const userCred = await auth.signInWithCredential(credential);
                // @note that we can get { user, additionalUserInfo, credential, operationType } = await auth.signInWithCredential(credential)
                console.log("***** success: signInWithCredential success ****");

                // delete auth user (this is the auth user that had been created via phone auth provider) to ensure that we don't get 'auth/credential-already-in-use'
                // @see https://firebase.google.com/docs/auth/web/manage-users#delete_a_user for more info
                const user = userCred.user;
                user
                  .delete()
                  .then(() => {
                    console.log("#### successs: user delete success #####");

                    // ideally, we should retry linking with phone number but we can't call
                    // handlePhoneNumberSignIn - which does a/c linking, here because we'll get an
                    // error since deleting a user sets auth.currentUser to null and we
                    // use auth.currentUser to do account linking. Therefore, ask the
                    // user to try again and take them back to '/signup'
                    alert("something went wrong, please try again");
                    // @todo - use a custom alert component
                    navigate("/signup");
                  })
                  .catch((deleteErr) =>
                    console.log(
                      "### deleteErr: err deleting user ####",
                      deleteErr
                    )
                  );
              } catch (signInWithCredErr) {
                const { code, message } = signInWithCredErr;
                // we can also get { credential, email } = signInWithCredErr

                console.log(
                  "***** err: signInWithCredential err code *****",
                  code,
                  message
                );
              }
            }
            break;
          default: {
            //make the button clickable again once the otp is false
            setState({
              ...state,
              isConfirmingCode: false,
            });
            //dispose of the loader when the otp is false
            setShowLoader(false);
            //Notify users what happened
            alert("You entered an invalid code. A new one has been sent to your device. Please try again");
            //Resend the OTP immediately
            handleResendOTP(e);
            // User couldn't sign in (bad verification code?)
            console.error(
              "**** err: couldn't sign in (bad verification code?) ****",
              err
            );
            // @todo - handle error
          }
        }
      }
    } else {
      // this is null
      console.log("**** OTPConfResult is null ****", OTPConfResult);
    }
  };

  const handleResendOTP = (e) => {
    e.preventDefault();
    // handle phone number sign-in
    handlePhoneNumberSignIn(phoneNumber).catch((err) => console.error(err));
  };

  const handleBack = () => navigate("/about-you");
  

  const OTPInputStyle = {
    width: "3rem",
    height: "5rem",
    margin: "0 0.5rem",
    fontSize: "2rem",
    textAlign: "center",
    borderRadius: "0.3rem",
    boxShadow: "0px 0px 5px 0 rgba(0, 0, 0, 0.15)",
    border: "none",
    backgroundColor: "#fafafa",
  };

  return (
    <AuthPageWrapper>
      {showLoader && (
          <IndeterminateLoading
            showDialog={true}
            message="Verifying the code. Do not close this tab nor exit your browser."
          />
        )}
      <AuthSidebar identity />
      <AuthContentWrapper>
        <img
          id="otp_img"
          height="150rem"
          src="https://ik.imagekit.io/twendemobility/Auth/otp_image_wa7W6OA4aFe.svg?updatedAt=1632476132672"
          alt="otp"
        />
        <p
          style={{
            lineHeight: "1.8",
            fontSize: "0.8rem",
            fontFamily: "Fira Sans",
            color: "#707070",
            textAlign: "center",
            margin: "2rem 0",
          }}
        >
          Enter the code sent to{" "}
          <span style={{ color: "#000", fontWeight: "bold" }}>
            {phoneNumber}
          </span>{" "}
          to access your account. This process will only happen once.
        </p>
        <div id="recaptcha-container" ref={captchaRef}></div>
        <OTPFrom>
          <OTPContainer>
            <OtpInput
              value={otp}
              onChange={handleOTPInputChange}
              numInputs={6}
              inputStyle={OTPInputStyle}
            />
            <ResendOTPBtn handleClick={handleResendOTP} />
          </OTPContainer>
          <TwButton
            contained
            text="Verify identity"
            // disable btn until the code is sent to the user and the user has entered the 6-digit code
            disabled={isSendingCode || otp.length < 6}
            handleClick={confirmOTP}
            isLoading={isConfirmingCode}
          />
          <TwButton flat backBtn handleClick={handleBack}/>
        </OTPFrom>
      </AuthContentWrapper>
    </AuthPageWrapper>
  );
};

export default OTPPage;

const OTPContainer = styled.div`
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
`;

const OTPFrom = styled.form`
  width: auto !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
