import { TextField } from "@material-ui/core";
import styled from "styled-components";

const TwTextField = styled(TextField)`
  && {
    margin: 0.5rem 1rem;
  }
`;

export default TwTextField;
