import React, { useEffect, useState } from "react";
import AuthSidebar from "../../../components/authComponents/Sidebar";
import { TwButton } from "../../../components/authComponents/Buttons";
import PhoneInput from "react-phone-number-input";
import styled from "styled-components";
import { StyledTextField } from "../../auth/AuthStyles";
import MUIPhoneNumberInput from "../../../layout/TwPhoneNumberInput/MUIPhoneInput";
import {
  AuthContentWrapper,
  AuthPageWrapper,
} from "../../../components/authComponents/layout/Wrappers";
import { navigate } from "@reach/router";
import useAuthUser from "../../auth/useAuthUser";
import { getAdminsForUser, getUserByPhoneNumber } from "../../auth/authHelpers";

const AboutYouPage = ({ location }) => {
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    formErrors: {
      firstName: null,
      lastName: null,
      phoneNumber: null,
    },
  });
  const authUser = useAuthUser();

  const { firstName, lastName, phoneNumber, formErrors } = state;

  useEffect(() => {
    // load the data from "/signup" that is stored in props.location.state
    if (location.state) {
      const nameArr = location.state.displayName
        ? location.state.displayName.split(" ")
        : [];
      const cachedFirstName = nameArr.length ? nameArr[0] : "";
      const cachedLastName =
        nameArr.length && nameArr.length > 1 ? nameArr[1] : "";
      setState((state) => ({
        ...state,
        firstName: cachedFirstName,
        lastName: cachedLastName,
        phoneNumber: location.state.phoneNumber
          ? location.state.phoneNumber
          : "",
      }));
    }
  }, []);

  const handleTextFieldChange = (e) => {
    const { name, value } = e.currentTarget;
    setState((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handlePhoneNumberChange = (value) => {
    setState((state) => ({
      ...state,
      phoneNumber: value,
      formErrors: {
        ...state.formErrors,
        phoneNumber: null,
      },
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // update authUser.displayName to match what the user entered in the form
    await authUser.updateProfile({
      displayName: `${firstName} ${lastName}`,
    });
    const userWithPhoneNumber = await getUserByPhoneNumber(phoneNumber);
    if (userWithPhoneNumber) {
      const adminsForUser = await getAdminsForUser(userWithPhoneNumber.id);
      if (adminsForUser) {
        // the user has existing admins

        console.log(`***** user has existing admins: ${adminsForUser} *****`);
      } else {
        // the user doesn't have any existing admins; continue with sign up.

        // redirect to '/otp'
        navigate("/otp", {
          state: {
            firstName,
            lastName,
            phoneNumber,
          },
        });
      }
    } else {
      // redirect to '/otp'
      navigate("/otp", {
        state: {
          firstName,
          lastName,
          phoneNumber,
        },
      });
    }
  };

  const handleBack = () => navigate("/signup");

  return (
    <AuthPageWrapper>
      <AuthSidebar data />
      <AuthContentWrapper>
        <h1 style={{ marginBottom: "3rem" }}>About you</h1>
        <p
          style={{
            lineHeight: "1.8",
            fontSize: "1rem",
            fontFamily: "Fira Sans",
            color: "#707070",
            textAlign: "center",
          }}
        >
          This data is only meant to improve your experience on Twift. We will
          not Communicate nor share it with any third party without your
          consent.
        </p>
        <form onSubmit={handleFormSubmit}>
          <div className="row">
            <LoginTextFields
              variant="outlined"
              margin="normal"
              label="First name"
              name="firstName"
              value={firstName}
              placeholder="John"
              id="firstName"
              onChange={handleTextFieldChange}
              autoComplete="first name"
              error={formErrors.firstName}
              helperText={formErrors?.firstName}
              required
              fullWidth
            />
            <LoginTextFields
              variant="outlined"
              margin="normal"
              label="Last name"
              name="lastName"
              value={lastName}
              placeholder="Doe"
              id="lastName"
              onChange={handleTextFieldChange}
              autoComplete="last name"
              error={formErrors.lastName}
              helperText={formErrors?.lastName}
              required
              fullWidth
            />
          </div>
          <PhoneNumberField>
            <PhoneInput
              defaultCountry="KE"
              placeholder="701 234 567"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              inputComponent={MUIPhoneNumberInput}
              error={formErrors.phoneNumber}
              helperText={formErrors?.phoneNumber}
            />
          </PhoneNumberField>
          <Row>
            <TwButton flat backBtn handleClick={handleBack} />{" "}
            <TwButton
              id="complete_signup_btn"
              contained
              text="Complete signup"
              style={{ width: "50%" }}
            />
          </Row>
        </form>
      </AuthContentWrapper>
    </AuthPageWrapper>
  );
};

export default AboutYouPage;

const LoginTextFields = styled(StyledTextField)`
  && {
    width: 100%;
    margin: 1rem;
    @media (max-width: 414px) {
      margin: 0.5rem 0;
    }
  }
`;

const PhoneNumberField = styled.div`
  width: 95%;
  margin: 1rem;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 414px) {
    margin: 0.5rem 0;
  }
`;

const Row = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    #complete_signup_btn {
      width: 100% !important;
    }
  }
`;
