import React from "react";
import {
	IconButton,
	Popover,
	ListItem,
	List,
	Divider,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useNotify } from "react-admin";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { makeStyles } from "@material-ui/core/styles";
import { EditButton, ShowButton } from "react-admin";
import { db } from "../../../../config/firebaseConfig";
import styled from "styled-components";

const useStyles = makeStyles(() => ({
	button: {
		border: "2px solid #f2f2f2",
		borderRadius: "10px",
		padding: "2px",
	},
	icon: {
		fill: "black",
	},
}));

/**
 *
 * These are "true" enabled by default.
 * @param {bool} show if false, hide the show button
 * @param {bool} edit if false, hide the edit button
 * @param {bool} deletable if false, hide the delete button
 * using "deletable" because delete is a JS keyword
 */

export default function ActionsPopover({
	record,
	basePath,
	show,
	edit,
	deletable,
}) {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const profile = JSON.parse(localStorage.getItem("cachedProfile"));
	const companyId = profile.company.id;
	const notify = useNotify();

	const handleClick = (event) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (event) => {
		event.stopPropagation();
		setAnchorEl(null);
	};

	const handleDelete = async (e, payload) => {
		e.stopPropagation();
		console.log("<<<<<< payload", payload);
		await db
			.collection(`companies/${companyId}/agents`)
			.doc(payload)
			.update({ deleted: true })
			.then(() => {
				notify("Agent successfully deleted", "success");
				setTimeout(() => window.location.reload(), 1000);
				clearTimeout(setTimeout(() => window.location.reload(), 1000));
			});
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	return (
		<div>
			<IconButton onClick={handleClick} className={classes.button}>
				<MoreVertIcon className={classes.icon} />
			</IconButton>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<div>
					{/* show agent cta */}
					{show === false ? null : (
						<List component="nav">
							<ListItem button>
								<ShowButton basePath={basePath} label="View" record={record} />
							</ListItem>
						</List>
					)}
					<Divider />
					{/* edit agent cta */}
					{edit === false ? null : (
						<List component="nav">
							<ListItem button>
								<EditButton basePath={basePath} label="Edit" record={record} />
							</ListItem>
						</List>
					)}
					<Divider />
					{/* delete agent cta */}
					{deletable === false ? null : (
						<List component="nav">
							<ListItem button>
								{/* <DeleteButton
									label="Deletesssssss"
									record={record}
								/> */}
								<DeleteButtonWrapper
									onClick={(e) => handleDelete(e, record.id)}
								>
									<DeleteIcon />
									<span> delete</span>
								</DeleteButtonWrapper>
							</ListItem>
						</List>
					)}
				</div>
			</Popover>
		</div>
	);
}

const DeleteButtonWrapper = styled.button`
	border: none;
	background-color: transparent;
	font-size: 0.8125rem;
	color: #c1004b;
	text-transform: uppercase;
	text-align: center;
	margin: 0.2rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	span {
		margin-left: 0.2rem;
	}
`;
