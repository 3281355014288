//styles the related to dashboard elements
const styles = {
  card: {
    backgroundColor: "#FFFF",
    width: "auto",
    borderRadius: "8px",
    height: "100px",
    display: "flex",
    padding: "10px"
  },
  icon: {
    color: "#00acc1",
    height: "40px",
  },
  CardContent: {
    fontSize: "1.3rem",
    color: "#707070",
  },
  h1: {
    fontWeight: "bold",
    fontSize: "1.3rem",
  },
  content: {
    flex: '1 0 auto',
    alignItems: "center",
    gap: "1rem",
  },
  avatar: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    height: "5rem",
    width: "5rem",
    backgroundSize: "contain",
  },
  wrapper: {
    padding: "1rem",
    borderRadius: "8px",
  },
  title: {
    color: "#707070",
  },
  paper: {
    color: "#000039",
  },
  cta: {
    padding: "0.5rem",
    display: "flex",
    justifyContent: "center",
    textDecoration: "none",
    textAlign: "center",
  },
  button: {
    textTransform: "capitalize",
    color: "#FFFF",
    borderRadius: "25px",
  },
  divider: {
    margin: "0.3rem",
  },
  messages: {
    textOverflow: "ellipsis",
  },
  data: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    padding: "0.5rem",
  },
  order: {
    fontWeight: "bold",
    color: "#000000",
  },
};
export default styles;
