import React from "react";
import FAQs from "../../components/Business/Help/FAQsAccordion";
import Fab from "../../components/Business/Help/ChatFab";
import ContactInfo from "../../components/Business/Help/ContactInfo";
import styled from "styled-components";
import PageHeader from "../../components/PageHeader"

const BusinessHelp = () => {
  return (
    <div>
      <Wrapper>
      <PageHeader title="Frequently Asked Questions"/>
        <FAQs />
        <ContactInfo />
        <Fab />
      </Wrapper>
    </div>
  );
};

export default BusinessHelp;

const Wrapper = styled.main`
  min-height: 100vh;
  position: absolute;
  right: 0;
  justify-content: center;
  padding: 2rem;
  background-color: #fafafa;
  @media (min-width: 600px) {
    padding: 5rem;
    width: 83vw;
  }
`;
