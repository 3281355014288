import * as React from "react";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  ListItemSecondaryAction
}from "@material-ui/core";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {
  linkToRecord,
  useListContext,
} from "react-admin";

const useStyles = makeStyles({
  root: {
    width: "100vw",
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  inline: {
    display: "inline",
  },
});

const MessageListMobile = () => {
  const classes = useStyles();
  const { basePath, data, ids, loaded, total } = useListContext();

  return loaded || Number(total) > 0 ? (
    <List className={classes.root}>
      {ids.map((id) => {
        const item = data[id];
        if (!item) return null;

        return (
          <>
          <Link
            to={linkToRecord(basePath, id)}
            className={classes.link}
            key={id}
          >
            <ListItem button>
            <ListItemAvatar>
              <Avatar alt={ item.recipient}/>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <span style={{fontWeight:"bold"}}>{item.recipient}</span>
                }
                secondary={item.body}
                secondaryTypographyProps={{ noWrap: true }}
              />
              {/* <ListItemSecondaryAction style={{top: "30%"}}>
                 <span style={{fontSize:"0.75rem"}}>{moment(item.updatedAt).format('DD-MM-YYYY')}</span>
              </ListItemSecondaryAction> */}
            </ListItem>
          </Link>
          <Divider/>
          </>
        );
      })}
    </List>
  ) : null;
};

MessageListMobile.propTypes = {
  basePath: PropTypes.string,
  data: PropTypes.any,
  hasBulkActions: PropTypes.bool.isRequired,
  ids: PropTypes.array,
  onToggleItem: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

MessageListMobile.defaultProps = {
  hasBulkActions: false,
  selectedIds: [],
};

export default MessageListMobile;
