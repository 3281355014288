import React, { useContext, useState } from "react";
import Heading from "../../components/Business/Settings/Heading";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import { ProfileContext } from "../../context/ProfileContext";
import { db } from "../../config/firebaseConfig";
import { useNotify } from "react-admin";
import SPModal from "../../components/Business/Settings/ChooseSPModal";
import PageHeader from "../../components/PageHeader";
import throttle from "lodash/throttle";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import parse from "autosuggest-highlight/parse";
import { Grid, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

//renders the location option while typing
export function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

export const autocompleteService = { current: null };

const SettingsPage = () => {
  const { profile, updateProfile } = useContext(ProfileContext);

  const currentEmail = profile.authUser.email;
  const currentPhoneNumber = profile.admin.phoneNumber;
  const currentUserName = profile.authUser.displayName;
  const currentCompanyName = profile.company.name;
  const companyId = profile.company.id;
  let cachedProfile = JSON.parse(localStorage.getItem("cachedProfile"));
  const compType = profile.admin
    ? profile.admin.companyType
    : cachedProfile.admin.companyType;

  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  const [value, setValue] = React.useState("");
  //fetch current company address
  React.useEffect(() => {
    const getAddress = async () => {
      try {
        const unsubscribe = db
          .collection(
            compType === "serviceProvider" ? "companies" : "businesses"
          )
          .onSnapshot((snapshot) => {
            snapshot.forEach((doc) => {
              const address = doc.data().address;
              setValue(address);
            });
          });

        return unsubscribe;
      } catch (error) {
        console.log(" error fetching address ", error);
      }
    };
    getAddress();
  }, [companyId]);

  // uses google api keys to autosuggest the current location
  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  //makes request to google places library for each location
  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  //updates the location based on the input values
  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const notify = useNotify();

  const userDocId = profile.admin.userId;

  const onUserNameChangeHandler = (e) => {
    setUserName(e.target.value);
  };
  const onEmailChangeHandler = (e) => {
    setEmail(e.target.value);
  };
  const onPhoneNumberChangeHandler = (e) => {
    setPhoneNumber(e.target.value);
  };
  const onCompanyNameChangeHandler = (e) => {
    setCompanyName(e.target.value);
  };

  const onErrorOccured = (item) => {
    notify(
      `An error occured when trying to update the ${item}. Please try again`,
      "warning"
    );
  };

  const onSuccess = (item) => {
    notify(`${item} updated successfully`, "success");
  };

  //update user full name
  const updateUserName = () => {
    let userFullName;
    if (userName.includes(" ")) {
      userFullName = userName.split(" ");
      userFullName.length > 0
        ? db
            .collection("users")
            .doc(userDocId)
            .update({
              firstName: userFullName[0],
              lastName: userFullName[1],
            })
            .then(() => {
              onSuccess("Full name");
              updateProfile({
                ...profile,
                admin: {
                  ...profile.admin,
                  firstName: userFullName[0],
                  lastName: userFullName[1],
                },
                authUser: {
                  ...profile.authUser,
                  displayName: userName,
                },
              });
              // Update profile object in localstorage
            localStorage.setItem(
              "cachedProfile",
              JSON.stringify({
                ...profile,
                admin: {
                  ...profile.admin,
                  firstName: userFullName[0],
                  lastName: userFullName[1],
                },
                authUser: {
                  ...profile.authUser,
                  displayName: userName,
                },
              }));
            })
        : console.log('error');
    } else {
      console.log('error')
    }
  };

  //update phone number
  const updatePhoneNumber = () => {
    phoneNumber.length > 0
      ? db
          .collection("users")
          .doc(userDocId)
          .update({ phoneNumber: phoneNumber })
          .then(() => {
            onSuccess("Phone number");
            updateProfile({
              ...profile,
              admin: {
                ...profile.admin,
                phoneNumber: phoneNumber,
              },
            });
            // Update profile object in localstorage
            localStorage.setItem(
              "cachedProfile",
              JSON.stringify({
                ...profile,
                admin: {
                  ...profile.admin,
                  phoneNumber: phoneNumber,
                },
              }));
          })
      : console.log('error');
  };

  //update email address
  const updateEmail = () => {
    email.length > 0
      ? db
          .collection("users")
          .doc(userDocId)
          .update({ emailAddress: email })
          .then(() => {
            onSuccess("Email");
            // updateProfileObject({ emailFlag: true, email: email });
            updateProfile({
              ...profile,
              authUser: {
                ...profile.authUser,
                email: email,
              },
              admin: {
                ...profile.admin,
                emailAddress: email,
              }
            });
            // Update profile object in localstorage
            localStorage.setItem(
              "cachedProfile",
              JSON.stringify({
                ...profile,
                authUser: {
                  ...profile.authUser,
                  email: email,
                },
                admin: {
                  ...profile.admin,
                  emailAddress: email,
                }
              }));
          })
      : console.log('error');
  };

  //update company name
  const updateCompanyName = () => {
    companyName.length > 0
      ? db
          .collection(
            compType === "serviceProvider" ? "companies" : "businesses"
          )
          .doc(companyId)
          .update({ name: companyName })
          .then(() => {
            onSuccess("Company name");
            updateProfile({
              ...profile,
              company: {
                ...profile.company,
                name: companyName,
              },
            });
            // Update profile object in localstorage
            localStorage.setItem(
            "cachedProfile",
            JSON.stringify({
              ...profile,
              company: {
                ...profile.company,
                name: companyName,
              },
            }));
          })
      : console.log('error');
  };

  //update company address
  const updateCompanyAddress = () => {
    try {
      db.collection(compType === "serviceProvider" ? "companies" : "businesses")
        .doc(companyId)
        .update({ address: value.structured_formatting.main_text })
        .then(() => {
          onSuccess("Address");
          setValue(value.description);
        });
    } catch (error) {
      console.log('error');
    }
  };

  return (
    <Wrapper>
      <PageHeader title="Brand" />
      <form noValidate autoComplete="on">
        <FieldsWrapper>
          <div className="txt-field-wrap">
            <TextField
              id="outlined-helperText"
              label="Company name"
              variant="outlined"
              fullWidth
              // onChange={onCompanyNameChangeHandler}
              // onBlur={updateCompanyName}
              value={currentCompanyName}
            />
          </div>
          <div className="txt-field-wrap">
            <Autocomplete
              id="google-map-demo"
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.description
              }
              filterOptions={(x) => x}
              options={options}
              autoComplete
              includeInputInList
              filterSelectedOptions
              onBlur={updateCompanyAddress}
              defaultValue={value}
              value={value}
              onChange={(event, value) => {
                setOptions(value ? [value, ...options] : options);
                setValue(value);
              }}
              onInputChange={(event, inputValue) => {
                setInputValue(inputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Address"
                  placeHolder="Residence"
                  variant="outlined"
                  fullWidth
                  required
                />
              )}
              renderOption={(option) => {
                const matches =
                  option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                  option.structured_formatting.main_text,
                  matches.map((match) => [
                    match.offset,
                    match.offset + match.length,
                  ])
                );

                return (
                  <Grid container alignItems="center">
                    <Grid item>
                      <LocationOnIcon />
                    </Grid>
                    <Grid item xs>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                          }}
                        >
                          {part.text}
                        </span>
                      ))}

                      <Typography variant="body2" color="textSecondary">
                        {option.structured_formatting.secondary_text}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }}
            />
          </div>
        </FieldsWrapper>
        <PageHeader title="Contact information" />
        <FieldsWrapper>
          <div className="txt-field-wrap">
            <TextField
              id="outlined-helperText"
              label="Full name"
              variant="outlined"
              fullWidth
              // onBlur={updateUserName}
              // onChange={onUserNameChangeHandler}
              value={currentUserName}
            />
          </div>
          <div className="txt-field-wrap">
            <TextField
              id="outlined-helperText"
              label="Email"
              variant="outlined"
              type="email"
              fullWidth
              // onBlur={updateEmail}
              // onChange={onEmailChangeHandler}
              value={currentEmail}
            />
          </div>
          <div className="txt-field-wrap">
            <TextField
              id="outlined-helperText"
              label="Phone number"
              variant="outlined"
              type="tel"
              fullWidth
              // onBlur={updatePhoneNumber}
              // onChange={onPhoneNumberChangeHandler}
              value={currentPhoneNumber}
            />
          </div>
        </FieldsWrapper>
      </form>
      {compType === "business" ? (
        <>
          <PageHeader title="Service provider(s)" />
          <SPModal />
        </>
      ) : null}
    </Wrapper>
  );
};

export default SettingsPage;

const Wrapper = styled.main`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 83vw;
  position: absolute;
  right: 0;
  padding: 2rem 5rem 5rem;
  background-color: #fafafa;
  @media (max-width: 1024px) {
    width: 100vw;
    padding: 2rem;
  }
`;

const FieldsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 2rem;
  margin-bottom: 2rem;
  .txt-field-wrap {
    width: 95%;
  }
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
