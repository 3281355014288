import { Link } from "@reach/router";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

export const SignUpWrapper = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80vw;
`;

export const SignUpHeading = styled.h1`
  text-align: center;
  margin: 0.5rem 0;
  font-size: 1.875rem;
  font-weight: 700;
`;

export const HelperText = styled.p`
  text-align: center;
  margin: 0.5rem 0;
`;

export const LinkText = styled.span`
  color: #00acc1;
`;

export const AuthLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paper: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(2),
    },
    backgroundColor: "#4caf50",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "white",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  googleBtn: {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  link: {
    color: theme.palette.primary.main,
  },
  helperText: {
    margin: "0.5rem 0",
  },
  label: {
    margin: "0.5rem 0",
  },
}));

export const StyledTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#00acc1",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#00acc1",
      },
    },
  },
})(TextField);

export const BtnGroup = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const SignUpPreview = styled.div`
  text-align: center;
  margin-top: 1.5rem;
`;

export const SignUpPreviewText = styled.p`
  margin: 0 0 0.5rem 0;
`;

export const AuthPreviewBtn = styled(Button)`
  margin: 0;
  padding: 0;
  text-transform: capitalize;
`;

export const SectionDividerText = styled(Typography)`
  margin: 0.5rem 0;
  margin-bottom: -0.5rem;
`;

export const LogoutBtn = styled(Button)`
  margin-left: 0.8rem;
  text-transform: capitalize;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  display: flex;
  align-items: center;
`;
