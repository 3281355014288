import React, { useContext, cloneElement } from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ChipField,
  ReferenceField,
  FunctionField,
  Filter,
  TextInput,
  TopToolbar,
  CreateButton,
  ExportButton,
} from "react-admin";
import { ProfileContext } from "../../context/ProfileContext";
import EmptyListPage from "../EmptyListPage";
import ActionsPopOver from "../../pages/admin/Agents/components/ActionsPopOver";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@material-ui/icons/Search';
import { useMediaQuery } from "@material-ui/core";
import StyledTableHeader from "../../layout/TableHeader";
import MobileGrid from "./MobileGrid";

const OrdersFilter = (props) => (
  <Filter {...props}>
    <TextInput  label="Order#" placeholder="Search ..." source="trackingCode" alwaysOn variant="outlined" InputProps={{
              startAdornment: (
                <InputAdornment position="start"><SearchIcon style={{fill:"grey"}}/></InputAdornment>
              ),
            }} />
    <TextInput label="Status" source="status" />
  </Filter>
);

const ListActions = (props) => (
  <TopToolbar>
    {cloneElement(props.filters, { context: "button" })}
    {/* <CreateButton
      label="Create an Order"
      variant="contained"
      color="primary"
      to={"/consignments/create"}
    /> */}
    <ExportButton />
  </TopToolbar>
);

const BizOrdersList = (props) => {
  const { profile } = useContext(ProfileContext);
  const businessCustomerId = profile.company.customerId;
  const isSmall = useMediaQuery(`(max-width:950px)`);

  return businessCustomerId ? (
    <List
      {...props}
      sort={{ field: "createdAt", order: "DESC" }}
      actions={<ListActions />}
      title="Orders"
      filters={<OrdersFilter />}
      filter={{ "sender.id": businessCustomerId }}
      empty={<EmptyListPage />}
    >
       {isSmall ? (
        <MobileGrid />
      ) : (
        <StyledTableHeader rowClick="show">
        <TextField source="trackingCode" label="Order #" />
        <DateField label="Delivery Date" source="date" />
        <FunctionField
          label="Sender"
          render={(record) => record.sender.firstName ? `${record.sender.firstName} ${record.sender.lastName}`:`${record.sender.name} `}
        />
        <TextField label="Origin" source="origin.name" />
        <FunctionField
          label="Recipient"
          render={(record) => record.recipient.firstName ? `${record.recipient.firstName} ${record.recipient.lastName}`:`${record.recipient.name} `}
        />
        <TextField label="Destination" source="destination.name" />
        <ChipField label="Status" source="status.value" />
        <ActionsPopOver   show={false}/>
      </StyledTableHeader>
       )}
    </List>
  ) : null;
};
export default BizOrdersList;
