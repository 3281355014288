import React from "react";
import Fab from "@material-ui/core/Fab";
import ChatIcon from "@material-ui/icons/Chat";
import styled from "styled-components";

const StyledFab = styled(Fab)`
  && {
    background-color: #00acc1;
    float: right;
  }
`;

const Wrapper = styled.div`
  position: relative;
  bottom: -0.8rem;
  right: 0;
  @media (min-width: 600px) {
    bottom: -5rem;
  }
`;

export default function ChatFab() {
  return (
    <Wrapper>
      <StyledFab color="primary" aria-label="add">
        <ChatIcon style={{ color: "#fff" }} />
      </StyledFab>
    </Wrapper>
  );
}
