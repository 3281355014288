import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  table: {
    width: "100%",
  },
});

const Transaction = ({ transactions }) => {
  const classes = useStyles();

  return (
    <div  className={classes.table}>
      {transactions.length ? (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Account Name</TableCell>
                <TableCell>Account Number</TableCell>
                <TableCell align="center">Mode</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="right">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((trans) => (
                <TableRow key={trans.id}>
                  <TableCell>
                    {trans.data().accountName}
                  </TableCell>
                  <TableCell>
                    {trans.data().accountNumber}
                  </TableCell>
                  <TableCell align="center">{trans.data().mode}</TableCell>
                  <TableCell align="center">{trans.data().status}</TableCell>
                  <TableCell align="right">{trans.data().amount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </div>
  );
};

Transaction.propTypes = {
  transactions: PropTypes.array.isRequired,
};

export default Transaction;
