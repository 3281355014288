import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { StyledTextField, useStyles } from "../../../AuthStyles";
import Button from "@material-ui/core/Button";
import { db } from "../../../../../config/firebaseConfig";
import styled from "styled-components";
import { Typography, CssBaseline } from "@material-ui/core";
import AuthContentWrapper from "../../../../../components/Auth/ContentWrapper";
import firebase from "firebase/app";
import { getToken } from "../../../../../config/fcm-notifications";
import PhoneInput from "react-phone-number-input";
import MUIPhoneNumberInput from "../../../../../layout/TwPhoneNumberInput/MUIPhoneInput";

const PersonalInfo = ({ user, toggleShowPersonalInfo, sendAlertInfoToParent }) => {
  const classes = useStyles();
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    formErrors: {
      firstName: null,
      lastName: null,
      phoneNumber: null,
    },
  });

  const { firstName, lastName, phoneNumber, formErrors } = state;
  const [fcmToken, setFcmToken] = useState("");

  useEffect(() => {
    // prefill form with authUser details

    if (user.displayName) {
      const splitName = user.displayName.split(" ");
      setState((state) => ({
        ...state,
        firstName: splitName[0],
        lastName: splitName[1],
      }));
    }

    if (user.phoneNumber) {
      setState((state) => ({
        ...state,
        phoneNumber: user.phoneNumber,
      }));
    }

    getToken(setFcmToken);
  }, [user]);

  const handleTextFieldChange = (e) => {
    const { name, value } = e.currentTarget;
    setState((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handlePhoneNumberChange = (value) => {
    setState((state) => ({
      ...state,
      phoneNumber: value,
    }));
  };

  //deteremines whether the form inputs have been filled with data
  const formIsValid = () => {
    const isValid =
      !firstName.length ||
      !lastName.length ||
      !phoneNumber ||
      (phoneNumber && phoneNumber.length < 13);

    return isValid;
  };

  const handleSubmit = () => {
    const minLength = (value) => {
      /***
       * Str.replace(/ /g, "") - removes whitespace from a string
       */
      const valueLen = value !== undefined && value.replace(/ /g, "").length;
      if (valueLen < 12) {
        return "Phone Number must be greater than 13 in length";
      } else {
        return undefined;
      }
    };
    const formFieldIsRequiredText = "* This field is required";
    //applies  error messages to the helperText when a form is empty
    if (!firstName.length || !lastName.length) {
      setState((state) => ({
        ...state,
        formErrors: {
          firstName: formFieldIsRequiredText,
          lastName: formFieldIsRequiredText,
        },
      }));
    } else if (!phoneNumber.length) {
      // phone number is required
      setState((state) => ({
        ...state,
        formErrors: {
          ...formErrors,
          phoneNumber: formFieldIsRequiredText,
        },
      }));
    } else if (minLength(phoneNumber)) {
      // phone number must be greater thatn 13
      setState((state) => ({
        ...state,
        formErrors: {
          ...formErrors,
          phoneNumber: minLength(phoneNumber),
        },
      }));
    } else {
      user
        .updateProfile({
          displayName: `${firstName} ${lastName}`,
        })
        .then(async function () {
          // Update successful.

          // check whether there exists a user before creating one
          const existingUserSnap = await db
            .collection("users")
            .where("emailAddress", "==", user.email)
            .get();
          if (existingUserSnap.empty) {
            // there doesn't exist a user with the given email address, thus create new
            const userPayload = {
              uid: user.uid,
              bio: "",
              firstName: firstName,
              lastName: lastName,
              gender: "unknown",
              emailAddress: user.email,
              emailAdressVerified: user.emailVerified,
              phoneNumber: phoneNumber,
              phoneNumberVerified: false, // will by dynamic once we start verifying phone numbers
              profilePicUrl: user.photoURL,
              fcmToken: firebase.firestore.FieldValue.arrayUnion(fcmToken),
            };

            db.collection("users")
            .add(userPayload)
            .then((userRef) => {
              console.log("**** success: user has been created ", userRef.id);
              toggleShowPersonalInfo({
                userId: userRef.id,
                showSelectAcType:
                  localStorage.getItem("accountType") === "business"
                    ? false
                    : true,
              });
            })
            .catch((err) => {
              console.error("**** err when creating user ******", err);
              // @todo - handle error
            });
          } else {
            if (existingUserSnap.size === 1) {
              // we have just one user
              existingUserSnap.forEach((userDoc) => {
                // get the userDocId and pass it to the parent component
                toggleShowPersonalInfo({
                  userId: userDoc.id,
                  showSelectAcType:
                    localStorage.getItem("accountType") === "business"
                      ? false
                      : true,
                });
              });
            } else if (existingUserSnap.size > 1) {
              // we have a problem as we shouldn't more than one user record with the same email address
              console.warn(`*** warning: duplicate records for ${user.email} ***`);

              // send alert info to the SignUp component
              sendAlertInfoToParent({
                isOpen: true,
                message: `Something went wrong during the signup process. Please contact us if this persists.`,
                info: `${user.email} has duplicate user records`
              })
            }
          }
        })
        .catch((err) => {
          console.error("**** err when updating firebase user ******", err);
          // @todo - handle error
        });
    }
  };

  return (
    <AuthContentWrapper>
      <Typography component="h1">
        We need you to key in some additional details
      </Typography>
      <CssBaseline />
      <hr />
      <form>
        <div className="row">
          <LoginTextFields
            variant="outlined"
            margin="normal"
            label="First Name"
            name="firstName"
            value={firstName}
            placeholder="E.g: John"
            id="firstName"
            onChange={handleTextFieldChange}
            autoComplete="first name"
            error={formErrors.firstName}
            helperText={formErrors?.firstName}
            required
            fullWidth
          />
          <LoginTextFields
            variant="outlined"
            margin="normal"
            label="Last Name"
            name="lastName"
            value={lastName}
            placeholder="E.g: Doe"
            id="lastName"
            onChange={handleTextFieldChange}
            autoComplete="last name"
            error={formErrors.lastName}
            helperText={formErrors?.lastName}
            required
            fullWidth
          />
        </div>
        <PhoneNumberField>
          <PhoneInput
            defaultCountry="KE"
            placeholder="Enter phone number"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            inputComponent={MUIPhoneNumberInput}
            error={formErrors.phoneNumber}
            helperText={formErrors?.phoneNumber}
          />
        </PhoneNumberField>
        <hr />
        <Button
          fullWidth
          variant="contained"
          className={classes.submit}
          onClick={handleSubmit}
          disabled={formIsValid()}
        >
          CHOOSE YOUR ACCOUNT TYPE
        </Button>
      </form>
    </AuthContentWrapper>
  );
};

PersonalInfo.propTypes = {
  user: PropTypes.object.isRequired,
  toggleShowPersonalInfo: PropTypes.func.isRequired,
  sendAlertInfoToParent: PropTypes.func.isRequired,
};

export default PersonalInfo;

const LoginTextFields = styled(StyledTextField)`
  width: 90%;
  margin: 1rem;
`;

const PhoneNumberField = styled.div`
  width: 90%;
  margin: 1rem;
`;
