import React from 'react'
import { 
    Edit,
    SimpleForm,
    useRedirect,
    useNotify,
} from 'react-admin'
import CustomerForm from './BizCustomerForm'
import WithRecord from "../../../../components/WithRecord";
import SaveCompanyOnSaveToolbar from "../../../../components/saveCompanyOnSave";

//edit an existing customer
const BizCustomerEdit = (props) => {
    const redirect = useRedirect();
    const notify = useNotify();
  
    const onSuccess = () => {
      notify("The customer profile was updated successfully","success");
      redirect("list", props.basePath);
    };
  
    const onFailure = (error) => {
      notify("The customer profile was not updated!", "warning");
      console.log(error);
    };
  
    return (
      <Edit
        {...props}
        title="Edit a Customer"
        onSuccess={onSuccess}
        mutationMode="undoable"
        onFailure={onFailure}
      >
           <WithRecord>
        {({ record, ...props }) => (
          <SimpleForm
            {...props}
            record={record}
            toolbar={<SaveCompanyOnSaveToolbar />}
            warnWhenUnsavedChanges
          >
            <CustomerForm record={record}/>
          </SimpleForm>
        )}
      </WithRecord>
      </Edit>
    );
  };
  export default BizCustomerEdit;