import * as React from "react";
import Error from "../assets/fix.svg";
import styled from "styled-components";
import { Box, Typography, Grid, Button } from "@material-ui/core";
import { Title } from "react-admin";
import History from "@material-ui/icons/History";
import useAuthUser from "../pages/auth/useAuthUser";

const BugImage = styled.img`
  width: 15rem;
  height: 15rem;
  margintop: 1rem;
`;
const Text = styled.div`
  margin: 1rem;
`;
//show error message image
const ErrorMessage = () => {
  return <BugImage src={Error} />;
};

const ErrorBoundary = () => {
  // get the auth user
  const authUser = useAuthUser();

  // const handleHomeRedirect = () => {
  //   if (authUser) {
  //     // redirect to the dashboard if there's an authUser, i.e if user is still signed in
  //     window.location.assign("/home");
  //   } else {
  //     // redirect to the login page if the user is logged out
  //     window.location.assign("/");
  //   }
  // };
  return (
    <Box textAlign="center" m={1}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Title title="Error" />
          <ErrorMessage />
          <Text>
            <Typography variant="h5" color="textSecondary">
              An error occured
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              Please try again
            </Typography>
          </Text>
          <div>
            <Button
              variant="contained"
              onClick={() => window.location.reload}
              color="primary"
              startIcon={<History />}
            >
              Refresh
            </Button>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ErrorBoundary;
