import styled from "styled-components";

export const AuthContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6rem;
  background-color: #fff;
  width: 55vw;
  height: 100vh;
  max-height: 100vh;
  hr {
    width: 80%;
    margin: 2rem auto;
    height: 1px;
    background-color: #d8d8d8;
    border: 0 none;
  }
  h1,
  p {
    text-align: left;
  }
  h1 {
    font-size: 2rem;
    font-weight: 600;
    font-family: "Ubuntu", sans-serif;
    margin: 0.5rem 0;
  }
  #setup {
    font-size: 1rem;
    font-weight: 500;
    color: #707070;
    font-family: "Fira Sans", sans-serif;
    margin-bottom: 3rem;
  }
  form {
    width: 100%;
  }
  #morning {
    font-size: 1.5rem;
    font-weight: 600;
    font-family: "Fira Sans", sans-serif;
    margin: 0.5rem;
  }
  .helper-text {
    margin-bottom: 1rem;
    background-color: #f7f7f7;
    border-radius: 0.5rem;
    padding: 0.5rem;
    span {
      font-family: "Fira Sans", sans-serif;
      font-size: 0.8rem;
      line-height: 1.8;
      color: #707070;
    }
  }
  .row {
    display: flex;
    flex-direction: row;
  }
  .radio-btn-container {
    margin: 0.5rem 0;
  }
  @media (max-width: 768px) {
    padding: 1rem 12%;
    margin-top: 2rem;
    width: 100vw;
    max-width: 100vw;
    min-height: 100%;
    height: auto;
    max-height: auto;
    justify-content: center;
    h1,
    p {
      text-align: center;
    }
    .row {
      display: flex;
      flex-direction: column;
    }
    h1 {
      font-size: 1.3rem;
      margin-bottom: 0 !important;
      margin: 1rem;
      line-height: 1.8;
    }
    .auth_title {
      display: none;
    }
    .MuiButton-root {
      min-width: 0 !important;
    }
    hr {
      display: none;
    }
    #morning {
      font-size: 1rem;
      margin: 0.2rem;
    }
    #welcome,
    #setup {
      font-size: 0.8rem;
      margin-bottom: 1.5rem;
    }
    #congrats_img {
      height: 10rem !important;
      width: auto;
      margin-bottom: 1rem !important;
    }
    #otp_img {
      height: 10rem !important;
      width: auto;
    }
    #congrats_text {
      font-size: 0.8rem !important;
      margin: 0.5rem;
    }
    .helper-text {
      margin: auto auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span {
        font-size: 0.8rem;
      }
    }
  }
  @media (max-width: 540px) {
    margin-top: 9rem;
    padding: 0.5rem 5%;
    h1 {
      font-size: 1.2rem;
    }
    #morning,
    #welcome,
    #setup {
      display: none;
    }
    #congrats_img {
      margin-top: -8rem !important;
      height: 15rem !important;
      width: auto;
      margin-bottom: 2rem !important;
    }
    #congrats_text {
      font-size: 1.2rem !important;
    }
    #otp_img {
      display: none;
    }
  }
  @media (max-width: 414px) {
    margin-top: 3rem;
    #congrats_img {
      margin-top: 2rem !important;
    }
  }
  @media (max-width: 411px) and (max-height: 823px) {
    margin-top: 0;
  }
  @media (max-width: 375px) and (max-height: 812px) {
    margin-top: 0;
  }
  @media (max-width: 375px) and (max-height: 667px) {
    margin-top: 5rem;
    padding: 0.5rem 8%;
    #congrats_img {
      margin-top: -4rem !important;
    }
  }
  @media (max-width: 360px) and (max-height: 640px) {
    margin-top: 6rem;
    #congrats_img {
      margin-top: -5rem !important;
    }
  }
  @media (max-width: 320px) {
    margin-top: 14rem;
    padding: 0.5rem 6%;
    #congrats_img {
      margin-top: -10rem !important;
    }
  }
  @media (max-width: 280px) {
    margin-top: 7rem;
    padding: 0.5rem 4%;
    #congrats_img {
      margin-top: -6rem !important;
    }
  }
`;

export const AuthPageWrapper = styled.main`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  min-width: 100vw;
  padding: 0;
  @media (max-width: 768px) {
    height: auto;
    max-height: auto;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    align-items: center;
    padding: 0;
  }
`;

export const WorkspacePickerWrapper = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100vw;
  padding: 5%;
  background-color: #fafafa;
  background-image: url("https://ik.imagekit.io/twendemobility/Auth/empty_bg_Zyv5qgyzM.svg?updatedAt=1632807390310");
  background-size: auto;
  p,
  h1,
  h2 {
    text-align: center;
  }
  p {
    margin: 0.3rem 0;
    font-size: 0.9rem;
    font-family: "Fira Sans", sans-serif;
    font-weight: 500;
    color: #707070;
  }
  h1 {
    color: #00acc1;
    font-size: 1.5rem;
    font-weight: 600;
    font-family: "Ubuntu", sans-serif;
    margin: 0.3rem 0;
    line-height: 1.8;
  }
  h2 {
    color: #000;
    font-size: 1.1rem;
    font-weight: 600;
    font-family: "Ubuntu", sans-serif;
    margin: 0.3rem 0;
    line-height: 1.8;
  }
  @media (max-width: 540px) {
    h1 {
      font-size: 1.1rem;
    }
    h2 {
      font-size: 0.9rem;
    }
    p {
      font-size: 0.8rem;
    }
    #logo {
      height: 2rem;
    }
  }
`;

export const AuthHomeWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 3% 5%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
