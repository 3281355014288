import React, { useContext, useState, useEffect, useRef } from "react";
//material ui imports
import {
	Button,
	StepLabel,
	Step,
	Stepper,
	StepConnector,
	Tabs,
	Tab,
	Paper,
} from "@material-ui/core";
//RA imports
import {
	Toolbar,
	Create,
	useRedirect,
	useNotify,
	SaveButton,
	SimpleForm,
	useWarnWhenUnsavedChanges,
} from "react-admin";
import { ProfileContext } from "../../context/ProfileContext";
import createRecord from "../../utils/createRecord";
import { db } from "../../config/firebaseConfig";
import firebase from "firebase";
import PaymentQuickCreate from "../PaymentQuickCreate";
import { useStyles } from "./OrderStyles";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import RateReviewIcon from "@material-ui/icons/RateReview";
import clsx from "clsx";
import PropTypes from "prop-types";
import DispatchForm from "./DispatchForm";
import CustomerForm from "./CustomerForm";
import RecipientForm from "./RecipientForm";
import ReviewOrder from "./Reviews";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
	createCustomer,
	customerExists,
	updateCustomer,
} from "../../utils/createCustomer";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import CustomerExistsModal from "./CustomerExistsModal";
import BulkOrdersForm from "./multipleOrders/bulk/BulkOrdersForm";
import CollectionForm from "./multipleOrders/collection/CollectionsForm";
import { useHistory } from "react-router-dom";

const useColorlibStepIconStyles = makeStyles({
	root: {
		backgroundColor: "#ccc",
		zIndex: 1,
		color: "#fff",
		width: 50,
		height: 50,
		display: "flex",
		borderRadius: "50%",
		justifyContent: "center",
		alignItems: "center",
	},
	active: {
		backgroundImage:
			"linear-gradient( 136deg, #C2F8FF 0%, #00acc1 50%, #00acc1 100%)",
		boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
	},
	completed: {
		backgroundImage:
			"linear-gradient( 136deg, #C2F8FF 0%, #00acc1 50%, #00acc1 100%)",
	},
});
function ColorlibStepIcon(props) {
	const classes = useColorlibStepIconStyles();
	const { active, completed } = props;

	const icons = {
		1: <PersonPinIcon />,
		2: <GroupAddIcon />,
		3: <LocalShippingIcon />,
		4: <RateReviewIcon />,
	};

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active,
				[classes.completed]: completed,
			})}
		>
			{icons[String(props.icon)]}
		</div>
	);
}

ColorlibStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 */
	active: PropTypes.bool,
	/**
	 * Mark the step as completed. Is passed to child components.
	 */
	completed: PropTypes.bool,
	/**
	 * The label displayed in the step icon.
	 */
	icon: PropTypes.node,
};
const ColorlibConnector = withStyles({
	alternativeLabel: {
		top: 22,
		left: "calc(-50% + 16px)",
		right: "calc(50% + 16px)",
	},
	active: {
		"& $line": {
			backgroundImage:
				"linear-gradient( 95deg,#C2F8FF 0%,#00acc1 50%,#00acc1 100%)",
		},
	},
	completed: {
		"& $line": {
			backgroundImage:
				"linear-gradient( 95deg,#C2F8FF 0%,#00acc1 50%,#00acc1 100%)",
		},
	},
	line: {
		height: 3,
		border: 0,
		backgroundColor: "#eaeaf0",
		borderTopWidth: 3,
		borderRadius: 1,
	},
})(StepConnector);

const OrdersCreate = (props) => {
	const classes = useStyles();
	const { profile } = useContext(ProfileContext);
	const [showPaymentDialog, setShowPaymentDialog] = useState(false);
	const serviceProviderId = profile.company.id;
	const redirect = useRedirect();
	const notify = useNotify();
	// eslint-disable-next-line no-unused-vars
	const [agents, setAgents] = useState([]);
	const [consignmentId, setConsignmentId] = useState("");
	const [activeStep, setActiveStep] = React.useState(0);
	const [showCustomerExistDialog, setShowCustomerExistDialog] = useState(false);
	const [senderCustomerId, setSenderCustomerId] = useState("");
	const [modalCustomerId, setModalCustomerId] = useState("");
	const [duplicateAction, setDuplicateAction] = useState("");
	const [customerModalRole, setCustomerModalRole] = useState("");
	const [existingCustomerData, setExistingCustomerData] = useState({
		name: "",
		address: "",
		phoneNumber: "",
		id: "",
	});
	const [recipientCustomerId, setRecipientCustomerId] = useState("");
	const [recipientInputValues, setRecipientInputValues] = useState({
		name: "",
		address: "",
		phoneNumber: "",
		role: "recipient",
		id: "",
	});
	const [senderInputValues, setSenderInputValues] = useState({
		name: "",
		address: "",
		phoneNumber: "",
		role: "sender",
		id: "",
	});
	const [tabValue, setTabValue] = React.useState(0);

	const handleTabChange = (event, newTabValue) => {
		setTabValue(newTabValue);
	};

	const onRecipientChangeHandler = (event) => {
		const { name, value } = event.currentTarget;
		// dynamically update Recipient  form inputs
		setRecipientInputValues((recipientInputValues) => ({
			...recipientInputValues,
			[name]: value,
		}));
	};

	const onSenderChangeHandler = (event) => {
		const { name, value } = event.currentTarget;
		// dynamically update Sender form inputs
		setSenderInputValues((senderInputValues) => ({
			...senderInputValues,
			[name]: value,
		}));
	};

	const handlePhoneNumberChange = (value) => {
		if (activeStep === 0) {
			setSenderInputValues((senderInputValues) => ({
				...senderInputValues,
				phoneNumber: value,
			}));
		} else if (activeStep === 1) {
			setRecipientInputValues((recipientInputValues) => ({
				...recipientInputValues,
				phoneNumber: value,
			}));
		}
	};

	const closeCustomerExistsDialog = () => {
		setShowCustomerExistDialog(false);
		console.log("Closed duplicate customer dialog");
	};

	const updateAutocompletedCustomerData = ({
		id,
		name,
		address,
		phoneNumber,
	}) => {
		if (activeStep === 0) {
			setSenderInputValues((senderInputValues) => ({
				...senderInputValues,
				name,
				address,
				phoneNumber,
				id,
			}));
		} else if (activeStep === 1) {
			setRecipientInputValues((recipientInputValues) => ({
				...recipientInputValues,
				name,
				address,
				phoneNumber,
				id,
			}));
		}
	};

	//update the state if the user chose to use existing customer data
	const updateExistingCustomerData = ({ id, name, address, phoneNumber }) => {
		if (activeStep === steps.length - 3) {
			setSenderInputValues((senderInputValues) => ({
				...senderInputValues,
				name,
				address,
				phoneNumber,
				id,
			}));
		} else if (activeStep === steps.length - 2) {
			setRecipientInputValues((recipientInputValues) => ({
				...recipientInputValues,
				name,
				address,
				phoneNumber,
				id,
			}));
		}
	};

	//show step label
	const steps = [
		"Sender Details",
		"Recipient Details",
		"Dispatch Details",
		"Review Order",
	];

	const validateForm = React.useRef(null);

	const updateGeoAddress = (address) => {
		if (activeStep === 0) {
			setSenderInputValues((senderInputValues) => ({
				...senderInputValues,
				address: address,
			}));
		} else if (activeStep === 1) {
			setRecipientInputValues((recipientInputValues) => ({
				...recipientInputValues,
				address: address,
			}));
		}
	};

	//function to get the current step
	function getStepContent(step) {
		switch (step) {
			case 0:
				return (
					<CustomerForm
						formName="sender"
						values={senderInputValues}
						onChangeHandler={onSenderChangeHandler}
						updateAutocompletedCustomerData={updateAutocompletedCustomerData}
						validateForm={validateForm}
						handlePhoneNumberChange={handlePhoneNumberChange}
						updateGeoAddress={updateGeoAddress}
					/>
				);
			case 1:
				return (
					<RecipientForm
						formName="recipient"
						values={recipientInputValues}
						onChangeHandler={onRecipientChangeHandler}
						updateAutocompletedCustomerData={updateAutocompletedCustomerData}
						validateForm={validateForm}
						handlePhoneNumberChange={handlePhoneNumberChange}
						updateGeoAddress={updateGeoAddress}
					/>
				);
			case 2:
				return <DispatchForm />;
			case 3:
				return (
					<ReviewOrder
						senderValues={senderInputValues}
						recipientValues={recipientInputValues}
					/>
				);
			default:
				throw new Error("Unknown step");
		}
	}

	//renders the next step
	const handleNext = () => {
		setActiveStep(activeStep + 1);
	};

	//renders the previous step
	const handleBack = () => {
		setActiveStep(activeStep - 1);
	};

	//renders from sender to recipients step
	const onSenderStep = async () => {
		if (!validateForm.current.checkValidity()) {
			notify(
				"Please fill in all details before proceeding to the next step ",
				"warning",
				{ duration: 2 },
			);
			return;
		}
		const senderId = await SaveCustomer(senderInputValues);
		//update customer id of role sender
		setSenderCustomerId(senderId);
		setActiveStep(activeStep + 1);
	};

	//renders from recipient to dispatch step
	const onRecipientStep = async () => {
		if (!validateForm.current.checkValidity()) {
			notify(
				"Please fill in all details before proceeding to the next step ",
				"warning",
				{ duration: 2 },
			);
			return;
		}
		const recipientId = await SaveCustomer(recipientInputValues);
		//update customer id of role recipient
		setRecipientCustomerId(recipientId);
		setActiveStep(activeStep + 1);
	};

	async function fetchData(collectionUrl) {
		// DRY function to fetch data from a collection url
		let querySnapshot = await db.collection(collectionUrl).get();
		const data = querySnapshot.docs.map((doc) => {
			// create object using doc data and id
			let obj = doc.data();
			obj.id = doc.id;
			return obj;
		});
		return data;
	}

	useEffect(() => {
		// set agents
		const collectionUrl = `companies/${serviceProviderId}/agents`;
		fetchData(collectionUrl).then((data) => {
			setAgents(data);
		});
	}, [serviceProviderId]);

	//initial consignments route
	const formRootPathname = "/consignments/create";
	const history = useHistory();
	const initialLocation = useRef(formRootPathname || history.location.pathname);

	useEffect(() => {
		const release = history.block((location) => {
			const isInsideForm = location.pathname.startsWith(
				initialLocation.current,
			);

			if (!isInsideForm && !activeStep - 1) {
				return alert(
					"You are about to exit the page. Are you sure you want to do this",
				);
			}

			return undefined;
		});

		return () => {
			if (release) {
				release();
			}
		};
	}, [history]);

	//show alert before page gets reloaded, contains 2 options (cancel & reload)
	useEffect(() => {
		const checkUrlPath = () => {
			if (formRootPathname === history.location.pathname) {
				return true;
			} else {
				return false;
			}
		};
		//trigger the reload prompt if the current page is equal to the location
		window.onbeforeunload = function () {
			return checkUrlPath()
				? "If you leave this page you will lose your unsaved changes."
				: null;
		};

		return () => {
			window.onbeforeunload = function () {
				return null;
			};
		};
	}, [history]);

	const onSuccess = (data) => {
		setConsignmentId(data);
		notify("The order was created successfully", "success");
		redirect("list", props.basePath);
	};

	const onPaymentSuccess = (data) => {
		setConsignmentId(data);
		setShowPaymentDialog(true);
		notify("The order was created successfully", "success");
	};

	const onDialogClosed = () => {
		setShowPaymentDialog(false);
		redirect("list", props.basePath);
	};

	const onFailure = () => {
		notify("The Order was not created!", "warning");
	};
	const onCustomerSuccess = () => {};

	const onCustomerFailure = () => {};

	const duplicateCustomerCreation = (action) => {
		// using a switch instead of a boolean field since there can be more than 2 choices
		// when this is moved to the settings page for automatic selection.
		switch (action) {
			case "updateExisting":
				console.log("user choose to update the customer details");
				setDuplicateAction("updateExisting");
				setShowCustomerExistDialog(false);
				break;
			case "useExisting":
				console.log("user choose use the existing customer details");
				setDuplicateAction("useExisting");
				setShowCustomerExistDialog(false);
				break;
			default:
				setActiveStep(activeStep - 1);
				setDuplicateAction("");
				break;
		}
	};

	// handles customer creation during orders creation in step 1 and 2
	const SaveCustomer = async (values) => {
		const { name, address, phoneNumber, id, role } = values;

		// split name into first name and last name
		const nameArray = name.split(" ");
		const firstName = nameArray[0];
		const lastName = nameArray[1] || "XX";

		//users collection payload
		const customerUserData = {
			name: `${firstName} ${lastName}`,
			firstName,
			lastName,
			address,
			phoneNumber,
		};

		//customers collection payload
		const customerData = {
			serviceProviders: serviceProviderId,
			type: "individual",
			name: `${firstName} ${lastName}`,
			phoneNumber: phoneNumber,
			address: address,
		};
		const resource = "customers";
		//render if the user selects existing customers from the search input(existing customers have IDs)
		if (id === "") {
			// save Customer's user data
			const isExistingCustomer = await customerExists(
				phoneNumber,
				serviceProviderId,
			);
			//render if customers phone number exists in this SP
			if (isExistingCustomer) {
				//pass existing customer name to the dialog component
				console.log("isExistingCustomer ==", isExistingCustomer);
				setExistingCustomerData((existingCustomerData) => ({
					...existingCustomerData,
					name: isExistingCustomer.name,
					address,
					phoneNumber: isExistingCustomer.phoneNumber,
					id: isExistingCustomer.id,
				}));
				// open customer exists dialog
				setShowCustomerExistDialog(true);
				// update customer role
				setCustomerModalRole(role);
				const clientId = isExistingCustomer.id;
				// pass existing customer id to dialog component
				setModalCustomerId(clientId);
				return clientId;
			}
			//create a customer if phone number does not exist using free form.
			else {
				console.log("not exists");
				console.log("customerData", customerData);
				//create new customer record and return id
				const clientId = await createCustomer(
					customerUserData,
					customerData,
					"customers",
					onCustomerSuccess,
					onCustomerFailure,
				);
				return clientId;
			}
		} else {
			return id;
		}
	};

	/*
uses a custom save button component that creates and saves address docs whenever a consignment is saved
*/
	const handleSaveOrder = async (
		formData,
		senderInputValues,
		recipientInputValues,
		raisePaymentModal,
	) => {
		async function saveAddress() {
			const createAddress = async (address) => {
				// a function to create an address doc given an object
				try {
					let queryDocSnap = await db.collection("addresses").add(address);
					return queryDocSnap.id;
				} catch (error) {
					alert("Address was not successfully created. Please try again");
				}
			};

			// define origin from submitted data
			let origin = {
				name: senderInputValues?.address || "",
				country: "Kenya",
				city: senderInputValues?.city || "",
				nearestLandmark: senderInputValues?.nearestLandmark || "",
				type: "home",
				serviceProviderId: serviceProviderId,
			};

			// define destination from submitted data
			let destination = {
				name: recipientInputValues?.address || "",
				country: "Kenya",
				city: recipientInputValues?.city || "",
				nearestLandmark: recipientInputValues?.nearestLandmark || "",
				type: "home",
				serviceProviderId: serviceProviderId,
			};

			// create origin and destination docs saving their ids
			const originId = await createAddress(origin);
			const destinationId = await createAddress(destination);
			return [
				{ id: originId, name: senderInputValues.address },
				{ id: destinationId, name: recipientInputValues.address },
			];
		}
		const [origin, destination] = await saveAddress();
		const resource = "consignments";
		/*
create a payload to responsible for manipulating the created orders data
to match the security rules specs
*/
		let sender = {
			name: `${senderInputValues.name}`,
			phoneNumber: senderInputValues.phoneNumber,
			id: senderInputValues.id === "" ? senderCustomerId : senderInputValues.id,
			type: senderInputValues.role,
		};
		let recipient = {
			name: `${recipientInputValues.name}`,
			phoneNumber: recipientInputValues.phoneNumber,
			id:
				recipientInputValues.id === ""
					? recipientCustomerId
					: recipientInputValues.id,
		};

		let orderPayload = {
			origin,
			destination,
			sender,
			recipient,
			agent: {
				id: formData.agent.id,
			},
			serviceProviderId: formData.serviceProviderId,
			status: {
				value: formData.status.value,
			},
			date: formData.date,
			size: formData.size,
			description: formData.description,
			numberOfPackages: formData.numberOfPackages,
		};

		// add common fields to the payload
		const currentTime = firebase.firestore.FieldValue.serverTimestamp();
		orderPayload = {
			...orderPayload,
			createdBy: profile.authUser.email,
			updatedBy: profile.authUser.email,
			createdAt: currentTime,
			updatedAt: currentTime,
		};

		//create a consignment record to the db
		createRecord(
			orderPayload,
			resource,
			raisePaymentModal ? onPaymentSuccess : onSuccess,
			onFailure,
		);
	};

	//TODO: Create code to handle bulk order creation
	const handleCreateBulkOrder = () => {
		console.log("Created bulk order");
	};

	const StyledToolbar = (props) => (
		<Toolbar {...props}>
			<React.Fragment>
				<div className={classes.buttons}>
					{activeStep !== 0 && (
						<Button onClick={handleBack} variant="outlined" color="primary">
							Previous
						</Button>
					)}
					{activeStep === steps.length - 1 ? (
						<>
							<SaveButton
								handleSubmitWithRedirect={props.handleSubmitWithRedirect}
								submitOnEnter={true}
								onSave={(data) =>
									handleSaveOrder(
										data,
										senderInputValues,
										recipientInputValues,
										false,
									).then(() => console.log("saving order details"))
								}
							/>
							<SaveButton
								label="Add Payment Information"
								onSave={(data) =>
									handleSaveOrder(
										data,
										senderInputValues,
										recipientInputValues,
										true,
									).then(() =>
										console.log("saving orders with payment details"),
									)
								}
								variant="outlined"
								handleSubmitWithRedirect={props.handleSubmitWithRedirect}
								color="primary"
							/>
						</>
					) : (
						<Button
							variant="contained"
							color="primary"
							onClick={
								tabValue === 0
									? async () =>
											activeStep === steps.length - 4
												? await onSenderStep()
												: activeStep === steps.length - 3
												? await onRecipientStep()
												: activeStep === steps.length - 2
												? await handleNext()
												: null
									: handleCreateBulkOrder
							}
							disabled={props.invalid}
						>
							{tabValue === 0 ? "CONTINUE" : "CREATE ORDER"}
						</Button>
					)}
				</div>
			</React.Fragment>
		</Toolbar>
	);

	return (
		<>
			{showCustomerExistDialog && (
				<CustomerExistsModal
					isOpen={showCustomerExistDialog}
					isClosed={closeCustomerExistsDialog}
					duplicateCustomerCreation={duplicateCustomerCreation}
					customerData={existingCustomerData}
					updateExistingCustomerData={updateExistingCustomerData}
					values={
						customerModalRole === "sender"
							? senderInputValues
							: customerModalRole === "recipient"
							? recipientInputValues
							: null
					}
					customerId={modalCustomerId}
				/>
			)}
			{showPaymentDialog && (
				<PaymentQuickCreate
					consignmentId={consignmentId}
					onDialogClosed={onDialogClosed}
					defaultState={true}
				/>
			)}
			<Paper square>
				<Tabs
					value={tabValue}
					indicatorColor="primary"
					textColor="primary"
					onChange={handleTabChange}
					variant="fullWidth"
				>
					<Tab label="Single" />
					<Tab label="Bulk" />
					<Tab label="Collection" />
				</Tabs>
			</Paper>

			{tabValue === 0 && (
				<Create
					{...props}
					onSuccess={onSuccess}
					undoable={false}
					onFailure={onFailure}
					title="Create order"
				>
					<SimpleForm
						toolbar={<StyledToolbar alwaysEnableSaveButton />}
						warnWhenUnsavedChanges
						ref={initialLocation}
					>
						<main className={classes.layout}>
							<Stepper
								alternativeLabel
								activeStep={activeStep}
								connector={<ColorlibConnector />}
							>
								{steps.map((label) => (
									<Step key={label}>
										<StepLabel StepIconComponent={ColorlibStepIcon}>
											{label}
										</StepLabel>
									</Step>
								))}
							</Stepper>
							{getStepContent(activeStep)}
						</main>
					</SimpleForm>
				</Create>
			)}

			{tabValue === 1 && (
				<Create
					{...props}
					onSuccess={onSuccess}
					undoable={false}
					onFailure={onFailure}
					title="Create order"
				>
					<BulkOrdersForm
						className={classes}
						toolbar={<StyledToolbar alwaysEnableSaveButton />}
					/>
				</Create>
			)}

			{tabValue === 2 && (
				<Create
					{...props}
					onSuccess={onSuccess}
					undoable={false}
					onFailure={onFailure}
					title="Create order"
				>
					<CollectionForm
						className={classes}
						toolbar={<StyledToolbar alwaysEnableSaveButton />}
					/>
				</Create>
			)}
		</>
	);
};
OrdersCreate.propTypes = {
	basePath: PropTypes.string.isRequired,
	invalid: PropTypes.func.isRequired,
	handleSubmitWithRedirect: PropTypes.func.isRequired,
};
export default OrdersCreate;
