import React, { useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";

export default function TwAccordion({ panelsArray = [] }) {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {panelsArray.map((panel) => (
        <Accordion
          key={panel.id}
          expanded={expanded === panel.id}
          onChange={handleChange(panel.id)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <div>{panel.title}</div>
            <div>{panel.subtitle}</div>
          </AccordionSummary>
          <AccordionDetails>
            <div>{panel.body}</div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

TwAccordion.propTypes = {
  /** Array of panels to be rendered */
  panelsArray: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.node.isRequired,
      body: PropTypes.node.isRequired,
    })
  ).isRequired,
};
