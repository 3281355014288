import { useEffect, useState } from "react";
import { auth } from "../../config/firebaseConfig";

const useAuthUser = () => {
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        // update state.authUser
        setAuthUser(user);
      } else {
        // No user is signed in.
        console.error("*** err: no firebase auth user found *****");
      }
    });
  }, []);

  return authUser;
};

export default useAuthUser;
