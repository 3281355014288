/**
 * Function to handle agent creation on the agents page and orders page
 */
import createUser from "./createUser";
import { db } from "../config/firebaseConfig";
import firebase from "firebase";

const createAgent = async (record, profile, notify, onSuccess, onFailure, resource) => {
  const { firstName, lastName, emailAddress, phoneNumber, bio } = record;
  const serviceProviderId = profile.company.id;
  let createdBy, updatedBy = profile.admin.emailAddress;
  let createdAt, updatedAt = firebase.firestore.FieldValue.serverTimestamp();
  const agentUserData = {
    firstName,
    lastName,
    emailAddress,
    phoneNumber,
    bio,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
  };
  const agentData = {
    serviceProviderId,
    companyType: "serviceProvider",
    name:firstName.concat(" ",lastName),
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    ...record
  };
  /**
   * 1. check if an agent with the given details exists in the current sp.
   * 2. if so, show an alert notifying the user that such an agent already exists
   * context: we can't have more than one agent with the same phone number for each sp
   */

  try {
    const agentSnap = await db
      .collection(`companies/${serviceProviderId}/agents`)
      .where("phoneNumber", "==", phoneNumber)
      .get();
    if (agentSnap.size > 0) {
      // an agent exists
      notify(
        "An agent with that phone number already exists, please use a different phone number",
        "warning"
      );
    } else {
      // agent doesn't exists; proceed with the creation
      // Save agent data to the users collection and the rest in agents collection
      try {
        // save Agent's user data
        createUser(
          agentUserData,
          agentData,
          resource,
          onSuccess,
          onFailure
        );
      } catch (error) {
        onFailure(error);
        console.log(error);
      }
    }
  } catch (error) {
    console.error("Error: fetching agent.", error);
  }
};

export default createAgent;
