/* eslint-disable */
import React, { useEffect } from "react";
import { theme } from "../config/themeConfig";
import { Router } from "@reach/router";
import SignIn from "../pages/auth/Login";
import { ProfileProvider, ProfileContext } from "../context/ProfileContext";
import TwAdmin from "../pages/admin";
import PasswordReset from "../pages/auth/CustomForgotPassword";
import ValidateInvite from "../pages/auth/ValidateInvite";
import { ThemeProvider } from "@material-ui/core/styles";
import SignOut from "../pages/auth/SignOut";
import LogRocket from "logrocket";
// import 404 page for testing
import PageNotFound from "../pages/404";
import ComingSoonPage from "../pages/agents/ComingSoon";
import SignInPage from "../pages/authScreens/SignIn";
import SignUp from "../pages/authScreens/SignUp";
import AboutYouPage from "../pages/authScreens/signup/AboutYou";
import OTPPage from "../pages/authScreens/signup/OTP";
import CongratsPage from "../pages/authScreens/signup/Congrats";
import WorkSpacePicker from "../pages/authScreens/WorkspacePicker";
import CompanyTypePickerPage from "../pages/authScreens/onboarding/CompanyType";
import CompanyDetailsPage from "../pages/authScreens/onboarding/CompanyDetails";
import GeneralAppInfo from "../pages/authScreens/onboarding/GeneralAppInfo";
import AuthHome from "../pages/authScreens/AuthHome";
import { ErrorBoundary } from "react-error-boundary";
import FallBackUI from "../layout/ErrorBoundary";
import ErrorBoundaryTest from "../pages/auth/ErrorBoundaryTest";
import useAuthUser from "../pages/auth/useAuthUser";
import { getUser } from "../pages/auth/authHelpers";
import SlowInternetBanner from "../SlowNetBanner";

//firebase data and authprovider
function App() {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID);

  const authUser = useAuthUser();

  useEffect(() => {
    (async () => {
      if (authUser) {
        // use authUser attributes as default user attributes
        let userId = authUser.uid;
        let displayName = authUser.displayName;
        let email = authUser.email;
        let phoneNumber = authUser.phoneNumber;
        // get twift user
        const twiftUser = await getUser(authUser.email);

        // update user attributes to match those of the twiftUser
        if (twiftUser) {
          userId = twiftUser.id;
          displayName = `${twiftUser.firstName} ${twiftUser.lastName}`;
          phoneNumber = twiftUser.phoneNumber;
        }

        /**
         * associate user id with a LogRocket session
         * @see the following @links for more info
         * - https://docs.logrocket.com/reference/identify
         * - https://docs.logrocket.com/reference/user-traits
         */
        LogRocket.identify(userId, {
          name: displayName,
          emailAddress: email,
          phoneNumber: phoneNumber,
        });
      }
    })();
  }, [authUser]);

  let netSpeed = 2;

  return netSpeed >= 4 ? (
    <SlowInternetBanner />
  ) : (
    <ProfileProvider>
      <ThemeProvider theme={theme}>
        <ErrorBoundary FallbackComponent={FallBackUI}>
          <Router>
            {/* Auth */}
            <TwAdmin path="/home" />
            {/* <SignUp path="/signup" /> */}
            <ErrorBoundaryTest path="/signup/error-boundary-test" />
            <AuthHome path="/auth-home" />
            <SignInPage path="/new-signin" />
            <AboutYouPage path="/about-you" />
            <OTPPage path="/otp" />
            <CongratsPage path="/congrats" />
            <WorkSpacePicker path="/workspace-picker" />
            <CompanyTypePickerPage path="/company-picker" />
            <CompanyDetailsPage path="/company-details" />
            <GeneralAppInfo path="/get-started" />
            <SignIn path="/" />
            <SignIn path="/signin" />
            <SignIn path="signin/:isNewlyVerifiedAdmin" />
            <PasswordReset path="password-reset" />
            <SignOut path="/signout" />
            <ValidateInvite path="/accept-invite" />
            <ComingSoonPage path="/agents" />
            <PageNotFound default />
          </Router>
        </ErrorBoundary>
      </ThemeProvider>
    </ProfileProvider>
  );
}

export default App;
