import React from "react";
import { Paper, Typography, Button, Divider, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import styles from "./styles.js";
import data from "../../assets/noOrder.svg";
import { CustomOrderStatus } from "../Orders/CustomOrderStatus";
import transformOrderStatus from "../../utils/transformOrderStatus";
import PropTypes from "prop-types";

const PendingOrders = ({ orders, title }) => {
	return (
		<div>
			<Paper style={styles.wrapper}>
				<h4 style={styles.title}> {title} Scheduled orders</h4>
				{orders?.length > 0 ? (
					orders.map((order) => (
						<div key={order.id}>
							<Grid alignItems="center" container spacing={1}>
								<Grid item xs={6} sm={3}>
									<Typography color="textSecondary" variant="body1">
										Order #:{" "}
										<span style={styles.order}>{order.trackingCode}</span>
									</Typography>
								</Grid>
								<Grid item xs={6} sm={3}>
									<Typography color="textSecondary" variant="body1">
										Destination:{" "}
										<span style={styles.order}>{order.destination.name}</span>
									</Typography>
								</Grid>
								<Grid item xs={6} sm={3}>
									<Typography color="textSecondary" variant="body1">
										Recipient:{" "}
										<span style={styles.order}>
											{order.recipient.name || order.recipient.firstName}
										</span>
									</Typography>
								</Grid>
								<Grid item xs={6} sm={3}>
									<CustomOrderStatus
										status={transformOrderStatus(order.status.value)}
									/>
								</Grid>
							</Grid>
							<Divider style={styles.divider} />
						</div>
					))
				) : (
					<div style={styles.data}>
						<div>
							<img style={styles.logo} alt="logo" src={data} />
						</div>
						<div>
							<Typography color="textSecondary" variant="body1">
								There are no available orders at the moment
							</Typography>
						</div>
						<Divider style={styles.divider} />
					</div>
				)}
				<Link to="/consignments" style={styles.cta}>
					<Button style={styles.button} variant="contained" color="primary">
						view all orders
					</Button>
				</Link>
			</Paper>
		</div>
	);
};

PendingOrders.propTypes = {
	orders: PropTypes.array.isRequired,
	title: PropTypes.string.isRequired,
};

export default PendingOrders;
