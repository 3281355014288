import React, { useContext, useState } from "react";
import {
	SimpleForm,
	useRedirect,
	useNotify,
	Edit,
	TextInput,
	SelectInput,
	BooleanInput,
	FormDataConsumer,
	FunctionField,
} from "react-admin";
import {
	validateName,
	validateText,
	validateIdNumber,
	validateRequired,
} from "../../../../utils/validators";
import PhoneNumberField from "../../../../layout/TwPhoneNumberInput";
import PageHeader from "../../../../components/PageHeader";
import { Grid } from "@material-ui/core";
import WithRecord from "../../../../components/WithRecord";
import { ProfileContext } from "../../../../context/ProfileContext";
import { db } from "../../../../config/firebaseConfig";

const vehicleTypes = [
	{ id: "Trolley", name: "Trolley" },
	{ id: "Hand Cart", name: "Hand Cart" },
	{ id: "Motorbike", name: "Motorbike" },
	{ id: "Car", name: "Car" },
	{ id: "Van", name: "Van" },
	{ id: "Pick-up", name: "Pick-up" },
	{ id: "Mini-Lorry", name: "Mini-Lorry" },
	{ id: "Lorry", name: "Lorry" },
	{ id: "Trailer", name: "Trailer" },
];

const AgentEdit = (props) => {
	const redirect = useRedirect();
	const { profile } = useContext(ProfileContext);
	const notify = useNotify();
	const serviceProviderId = profile.company.id;

	const onSuccess = () => {
		notify("The agent profile was updated successfully", "success");
		redirect("list", props.basePath);
	};

	const onFailure = (error) => {
		notify("The agent profile was not updated!", "warning");
		console.log(error);
	};
	const saveAgent = async (record, value) => {
		const {
			firstName,
			lastName,
			emailAddress,
			phoneNumber,
			nationalIDNumber,
			vehicleType,
			drivingLicenseNo,
			address,
			id,
		} = record;
		db.collection(`companies/${serviceProviderId}/agents`)
			.doc(id)
			.update({
				name: `${firstName} ${lastName}`,
				firstName: firstName,
				lastName: lastName,
				emailAddress: emailAddress ? emailAddress : "",
				phoneNumber: phoneNumber,
				nationalIDNumber: nationalIDNumber,
				vehicleType: vehicleType ? vehicleType : "",
				address: address ? address : "",
				drivingLicenseNo: drivingLicenseNo ? drivingLicenseNo : "",
			});

		try {
			onSuccess();
		} catch (error) {
			onFailure(error);
			console.log(error);
		}
	};
	return (
		<Edit
			{...props}
			onSuccess={onSuccess}
			undoable={false}
			onFailure={onFailure}
			title="Agent edit"
		>
			<WithRecord>
				{({ record, ...props }) => {
					return (
						<SimpleForm
							warnWhenUnsavedChanges
							{...props}
							record={record}
							save={(record) => saveAgent(record)}
						>
							<div style={{ width: "100%" }}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<PageHeader title="Personal Details" />
									</Grid>
									<Grid container spacing={2} item xs={12} sm={6}>
										<Grid item xs={12} sm={6}>
											<TextInput
												source="firstName"
												validate={validateName}
												fullWidth
												variant="outlined"
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextInput
												source="lastName"
												validate={validateName}
												fullWidth
												variant="outlined"
											/>
										</Grid>

										<Grid item xs={12} sm={6}>
											<TextInput
												source="emailAddress"
												variant="outlined"
												fullWidth
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextInput
												variant="outlined"
												source="nationalIDNumber"
												label="National ID No"
												validate={validateIdNumber}
												fullWidth
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<FunctionField
												render={(record) => (
													<PhoneNumberField
														variant="outlined"
														defaultValue={record?.phoneNumber}
													/>
												)}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextInput source="bio" variant="outlined" fullWidth />
										</Grid>
									</Grid>
								</Grid>

								<Grid container spacing={2}>
									<Grid item xs={12}>
										<PageHeader title="Vehicle Details" />
									</Grid>
									<Grid item xs={12} sm={3}>
										<SelectInput
											variant="outlined"
											source="vehicleType"
											label="Vehicle Type"
											choices={vehicleTypes}
											validate={validateText}
											fullWidth
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<BooleanInput source="hasPrimaryVehicle" />
									</Grid>
									<FormDataConsumer>
										{({ formData }) => {
											const registeredVehicles = [
												"Motorbike",
												"Car",
												"Van",
												"Pick-up",
												"Mini-Lorry",
												"Lorry",
												"Trailer",
											];
											if (
												registeredVehicles.includes(formData.vehicleType) &&
												formData.hasPrimaryVehicle === true
											) {
												return (
													<Grid container spacing={2} item xs={12} sm={6}>
														<Grid item xs={12} sm={6}>
															<TextInput
																source="registrationNumber"
																label="Registration Number"
																validate={validateRequired}
																variant="outlined"
																fullWidth
															/>
														</Grid>
														<Grid item xs={12} sm={6}>
															<TextInput
																source="drivingLicenseNo"
																label="Driving License Number"
																validate={validateRequired}
																variant="outlined"
																fullWidth
															/>
														</Grid>
													</Grid>
												);
											}
										}}
									</FormDataConsumer>
								</Grid>
							</div>
						</SimpleForm>
					);
				}}
			</WithRecord>
		</Edit>
	);
};

export default AgentEdit;
