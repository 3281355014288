import { Datagrid } from "react-admin";
import styled from "styled-components";

const StyledTableHeader = styled(Datagrid)`
  th {
    span {
      font-weight: 500;
      color: #707070;
      font-family: "Fira Sans", sans-serif;
      font-size: 12px;
    }
  }
`;

export default StyledTableHeader;
