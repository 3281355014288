import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  layout: {
    width: "100%",
  },
  paper: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  buttons: {
    display: "grid",
    gridTemplateColumns:"repeat(3, auto)",
    gap:"1rem",
    padding: "0.3rem",
  },
  wrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(2,auto)",
    justifyContent: "space-evenly",
    gap: "3rem",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  icon: {
    fill: "grey",
  },
}));

