import React, {useContext} from "react";
//material ui imports
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import {FormSpy} from "react-final-form";
import PageHeader from "../PageHeader";
import styles from "./styles";
import PropTypes from "prop-types";
import {ProfileContext} from "../../context/ProfileContext";
import styled from "styled-components";

const Title = styled.h3`
	display: grid;
	justify-content: center;
`;

const Reviews = (props) => {
    const {profile} = useContext(ProfileContext);
    let cachedProfile = JSON.parse(localStorage.getItem("cachedProfile"));
    const compType = profile.admin
        ? profile.admin.companyType
        : cachedProfile.admin.companyType;
    return (
        <>
            <FormSpy subscription={{values: true}}>
                {(formState) => (
                    <div style={{marginBottom: "2rem"}}>
                        <PageHeader title="Order summary"/>
                        <Title>Senders Details</Title>
                        <TableContainer component={Paper}>
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={styles.row}> Name</TableCell>
                                        <TableCell style={styles.row}>Contact</TableCell>
                                        <TableCell style={styles.row}>Address</TableCell>
                                    </TableRow>
                                </TableHead>
                                {compType === "serviceProvider" ? (
                                    <>
                                        <TableBody>
                                            <TableRow key={props.senderValues.role}>
                                                <TableCell>
                                                    {/*{props.senderValues.firstName}{" "}*/}
                                                    {/*{props.senderValues.lastName}*/}
                                                    {props.senderValues.name}
                                                </TableCell>
                                                <TableCell>{props.senderValues.phoneNumber}</TableCell>
                                                <TableCell>{props.senderValues.address}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </>
                                ) : (
                                    <>
                                        <TableBody>
                                            <TableRow key={formState.values.sender.id}>
                                                <TableCell>{formState.values.sender.name}</TableCell>
                                                <TableCell>
                                                    {formState.values.sender.phoneNumber}
                                                </TableCell>
                                                <TableCell>{formState.values.sender.address}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </>
                                )}
                            </Table>
                        </TableContainer>

                        <Title>Recipients Details</Title>
                        <TableContainer component={Paper}>
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={styles.row}> Name</TableCell>
                                        <TableCell style={styles.row}>Contact</TableCell>
                                        <TableCell style={styles.row}>Address</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={props.recipientValues.role}>
                                        <TableCell>
                                            {/*{props.recipientValues.firstName}{" "}*/}
                                            {/*{props.recipientValues.lastName}*/}
                                            {props.recipientValues.name}
                                        </TableCell>
                                        <TableCell>{props.recipientValues.phoneNumber}</TableCell>
                                        <TableCell>{props.recipientValues.address}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}
            </FormSpy>
        </>
    );
};
Reviews.propTypes = {
    recipientValues: PropTypes.object.isRequired,
    senderValues: PropTypes.object.isRequired,
};
export default Reviews;
