import React, { forwardRef } from "react";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";

/**
 * @file is meant to be used as the input component in TwPhoneNumberInput which
 * uses react-phone-number-input's PhoneInput (which requires it's inputComponent be wrapped
 * in a React.forwardRef()
 * @see https://www.npmjs.com/package/react-phone-number-input
 * @summary
 * - as lifted from the docs:
 *    - inputComponent: component? — A custom <input/> component can be passed.
 *    In that case, it must be a React.forwardRef() to the actual <input/>.
 * @example
 * <PhoneInput
    ...
    inputComponent={MUIPhoneInput} // where MUIPhoneInput is this component
    error={formErrors.phoneNumber}
    helperText={formErrors?.phoneNumber}
  />
 */
const MUIPhoneNumberInput = forwardRef(
  ({ error, helperText, ...rest }, ref) => {
    return (
      <TextField
        {...rest}
        inputRef={ref}
        size="medium"
        label="Phone Number"
        variant="outlined"
        inputProps={{
          autoComplete: "new-password",
        }}
        error={error}
        helperText={helperText}
      />
    );
  }
);

// manually define the Component.displayName to avoid the esLint error: Component definition is missing display name
// for more info @see https://stackoverflow.com/questions/67992894/component-definition-is-missing-display-name-for-forwardref
MUIPhoneNumberInput.displayName = "MUIPhoneNumberInput";

MUIPhoneNumberInput.propTypes = {
  error: PropTypes.string,
  helperText: PropTypes.string,
};

export default MUIPhoneNumberInput;
