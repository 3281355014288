import React, { useEffect, Fragment, Suspense } from "react";
import { db } from "../../config/firebaseConfig";
import {
  SimpleShowLayout,
  useShowController,
  Show,
  ReferenceField,
  FunctionField,
  EditButton,
  TopToolbar,
  ShowButton,
} from "react-admin";
import PaymentQuickCreate from "../../components/PaymentQuickCreate";
import { makeStyles } from "@material-ui/core/styles";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Grid,
  Box,
} from "@material-ui/core";
import styled from "styled-components";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import TwTooltip from "../../layout/TwToolTip";
import enums from "../../enums";
import { ProfileContext } from "../../context/ProfileContext";
import PageHeader from "../../components/PageHeader";
import getResourceLink from "../../routes/resource-names";
import transformOrderStatus from "../../utils/transformOrderStatus";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import PropTypes from "prop-types";
import AgentDetails from "./AgentDetails";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  stepper: {
    overflow: "scroll",
    maxHeight: "250px",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  text: {
    fontWeight: "bold",
    fontSize: "1rem",
    color: "black",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    gap: "2rem",
  },
  main: {
    width: "100%",
  },
  pos: {
    marginBottom: 7,
  },
  position: {
    marginBottom: 16,
  },
  cardContent: {
    marginLeft: "0.5rem",
  },
  grid: {
    marginBottom: "-3rem",
  },
  box: {
    display: "grid",
    justifyContent: "center",
  },
  reference:{
    fontWeight: "bold",
    fontSize:"1rem",
    marginLeft:"0.5rem"
  }
}));

const { consignmentStatusArray } = enums;

const OrdersDetail = (props) => {
  const classes = useStyles();
  const { profile } = React.useContext(ProfileContext);
  let cachedProfile = JSON.parse(localStorage.getItem("cachedProfile"));
  const compType = profile.admin
    ? profile.admin.companyType
    : cachedProfile.admin.companyType;
  const [activeStep, setActiveStep] = React.useState(0);
  const [state, setState] = React.useState({
    paymentInfo: {},
    showPaymentModal: false,
  });
  // eslint-disable-next-line no-unused-vars
  const { paymentInfo, showPaymentModal } = state;
  const serviceProviderId = profile.company.id;
  const resourceName = getResourceLink(serviceProviderId);

  const handleNext = (diff) => {
    setActiveStep((prevActiveStep) => prevActiveStep + diff);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const closePaymentModal = () => {
    setState((state) => ({
      ...state,
      showPaymentModal: false,
    }));
  };

  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props);

  useEffect(() => {
    const getConsPayment = async () => {
      const paymentRef = db
        .collection("payments")
        .where("serviceProviderId", "==", profile.company.id);

      // payments - a list of all payments
      const payments = await paymentRef.get();
      if (payments.docs.length) {
        payments.docs.forEach((payment) => {
          if (payment.data()?.consignment?.id === props.match.params.id) {
            setState((state) => ({
              ...state,
              paymentInfo: payment.data(),
            }));
          }
        });
      }
    };

    getConsPayment();
  }, [props.match.params.id, profile.company.id]);

  useEffect(() => {
    const setActiveSteps = () => {
      const consignmentStatus = record.status.value;
      const elementPosition = consignmentStatusArray.findIndex(
        (step) => step.STATUS === consignmentStatus
      );
      handleNext(elementPosition);
    };
    if (record !== undefined) {
      handleReset();
      setActiveSteps();
    }
  }, [record]);

  const StatusHistoryItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `;

  const Wrapper = styled(CardActions)`
    display: grid;
    justify-content: center;
  `;

  const ContentWrapper = styled(Typography)`
    margin-top: 0.3rem;
  `;

  const Text = styled(Typography)`
    margin-top: 1rem;
  `;

  const ShowViewActions = ({ basePath, data }) => (
    <TopToolbar className={classes.toolbar}>
      <EditButton
        label="All Orders"
        icon={<ArrowBackIosIcon />}
        color="primary"
        to={"/consignments"}
        size="medium"
      />
      {/* <EditButton basePath={basePath} record={data} /> */}
    </TopToolbar>
  );

  return (
    <Show {...props} title="Order details" actions={<ShowViewActions />}>
      <SimpleShowLayout>
        {record !== undefined && (
          <div style={{ width: "100%" }}>
            {/* {showPaymentModal && (
              <PaymentQuickCreate
                consignmentId={props.match.params.id}
                onDialogClosed={closePaymentModal}
                defaultState={true}
              />
            )} */}
            <Grid container spacing={4}>
              {/* Customer Info */}
              <Grid item xs={12} sm={12} lg={12} className={classes.grid}>
                <PageHeader title={`Order  #${record.id}`} />
              </Grid>
              {profile.admin.companyType === "serviceProvider" && (
                <Grid item xs={12} sm={6} lg={6}>
                  <Card className={classes.main}>
                    <CardContent className={classes.cardContent}>
                      <PageHeader title="Sender" />
                      <ContentWrapper className={classes.pos} gutterBottom>
                      <Typography className={classes.pos}>
                        {" "}
                        Name :  
                      <ReferenceField  source="sender.id" reference={resourceName.customers} link={false}>
                        <FunctionField
                          className={classes.reference}
                          render={(record) => record.name ? `${record.name}`:`${record.name} `}
                        />
                        </ReferenceField>
                        </Typography>
                      </ContentWrapper>
                      <Typography className={classes.pos}>
                        {" "}
                        Phone Number: 

                        <ReferenceField  source="sender.id" reference={resourceName.customers} link={false}>
                        <FunctionField
                          className={classes.reference}
                          render={(record) => record.phoneNumber }
                        />
                        </ReferenceField>
                      </Typography>
                      <Typography>
                        Address: <b>{record.origin.name}</b>
                      </Typography>
                    </CardContent>
                    <Wrapper>
                      <ReferenceField
                        source="sender.id"
                        reference="customers"
                        link={false}
                      >
                        <FunctionField
                          render={(record) =>
                            record.type && record?.deleted !== true ? (
                              <ShowButton
                                basePath="/customers"
                                label="View Customer"
                                record={record}
                              />
                            ) : null
                          }
                        />
                      </ReferenceField>
                    </Wrapper>
                  </Card>
                </Grid>
              )}
              <Grid item xs={12} sm={6} lg={6}>
                <Card className={classes.main}>
                  <CardContent>
                    <PageHeader title="Recipient" />

                    <ContentWrapper className={classes.pos} gutterBottom>
                      <Typography className={classes.pos}>
                        {" "}
                        Name :  
                      {/*<ReferenceField  source="recipient.id" reference={resourceName.customers} link={false}>*/}
                      {/*  <FunctionField*/}
                      {/*    className={classes.reference}*/}
                      {/*    render={(record) => record.firstName ? `${record.firstName} ${record.lastName}`:`${record.name} `}*/}
                      {/*  />*/}
                      {/*  </ReferenceField>*/}
                        {" "} {record.recipient.name}
                        </Typography>
                      </ContentWrapper>
                      <Typography className={classes.pos}>
                        {" "}
                        Phone Number:
                        {" "} {record.recipient.phoneNumber}
                        {/*<ReferenceField  source="recipient.id" reference={resourceName.customers} link={false}>*/}
                        {/*<FunctionField*/}
                        {/*  className={classes.reference}*/}
                        {/*  render={(record) => record.phoneNumber }*/}
                        {/*/>*/}
                        {/*</ReferenceField>*/}
                      </Typography>
                    <Typography>
                      {" "}
                      Address: <b>{record.destination.name}</b>
                    </Typography>
                  </CardContent>
                  <Wrapper>
                    <ReferenceField
                      source="recipient.id"
                      reference="customers"
                      link={false}
                    >
                      <FunctionField
                        render={(record) =>
                          record.type && record?.deleted !== true ? (
                            <ShowButton
                              basePath="/customers"
                              label="View Customer"
                              record={record}
                            />
                          ) : null
                        }
                      />
                    </ReferenceField>
                  </Wrapper>
                </Card>
              </Grid>

              {/* Package Information */}
              <Grid container item xs={12} sm={6} lg={6}>
                <Grid item xs={12} sm={12} lg={12}>
                  <Card className={classes.main}>
                    <CardContent>
                      <Grid item xs={12} sm={12}>
                        <PageHeader title="Package Details" />
                      </Grid>
                      {compType === "serviceProvider" ? (
                        <Text className={classes.position} gutterBottom>
                          Agent:
                          <b>
                            {record.agent.id ? (
                              <AgentDetails
                                serviceProviderId={serviceProviderId}
                                agentId={record.agent.id}
                              />
                            ) : (
                              <b> </b>
                            )}
                          </b>
                        </Text>
                      ) : null}
                      <Typography className={classes.position}>
                        {" "}
                        Tracking Code: <b>{record.trackingCode}</b>
                      </Typography>
                      <Typography className={classes.position}>
                        {" "}
                        Status:{" "}
                        <b>{transformOrderStatus(record.status.value)}</b>
                      </Typography>
                      <Typography className={classes.position}>
                        Number of Packages:{" "}
                        <b>{record.numberOfPackages || "N/A"}</b>
                      </Typography>
                      <Typography className={classes.position}>
                        {" "}
                        Description: <b>{record.description || "N/A"}</b>
                      </Typography>
                      <Typography className={classes.position}>
                        Size: <b>{record.size || "N/A"}</b>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              {/* Status History */}
              <Grid container spacing={1} item xs={12} sm={6} lg={6}>
                <Card className={classes.main}>
                  <CardContent>
                    <Grid item xs={12} sm={12} lg={12}>
                      <Grid item xs={12} sm={12}>
                        <PageHeader title="Status history" />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <div className={classes.root}>
                        <Stepper
                          className={classes.stepper}
                          activeStep={activeStep}
                          orientation="vertical"
                        >
                          {consignmentStatusArray.map((status) => (
                            <Step key={status.STATUS}>
                              <StepLabel>
                                <StatusHistoryItem>
                                  <Fragment>
                                    {transformOrderStatus(status.STATUS)}
                                  </Fragment>
                                  <TwTooltip
                                    title={status.MSG}
                                    icon={<InfoOutlinedIcon />}
                                  />
                                </StatusHistoryItem>
                              </StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </div>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {compType === "serviceProvider" ? (
              <Box sx={{ p: 1.5, pt: 1.5 }} className={classes.box}>
                <Button
                  disabled={paymentInfo.status === "completed"}
                  color="primary"
                  variant="outlined"
                  onClick={(e) => {
                    e.preventDefault();
                    setState((state) => ({
                      ...state,
                      showPaymentModal: true,
                    }));
                  }}
                >
                  request payment
                </Button>
              </Box>
            ) : null}
          </div>
        )}
      </SimpleShowLayout>
    </Show>
  );
};
OrdersDetail.propTypes = {
  data: PropTypes.object.isRequired,
  basePath: PropTypes.string,
};
export default OrdersDetail;
