import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { AuthBtn } from "../../../../../components/authComponents/Buttons";

const PrimaryView = ({ handleSignInWithGoogle }) => {
  return <AuthBtn google signin handleClick={handleSignInWithGoogle} />;
};

PrimaryView.propTypes = {
  handleSignInWithGoogle: PropTypes.func.isRequired,
};

export default PrimaryView;
