import React from 'react'
import PropTypes from 'prop-types'
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

const StyledHeader = styled(Grid)`
background-color: #E2E7E7;
color: #00000;
border-radius: 5px;
opacity: 1;
padding:0.5rem;
text-align: center;
margin-bottom: 2rem;
@media (min-width: 600px) {
   text-align: start;
   padding:0.5rem;
}
  `;

const StyledText = styled(Typography)`
  font-weight: 600;
    `;

const PageHeader = ({title}) => {
    return (
     <StyledHeader item xs={12} sm={12}>
        <StyledText  gutterBottom>
              { title}
        </StyledText>
    </StyledHeader>
    )

}
PageHeader.propTypes ={
    title: PropTypes.string.isRequired,
}
export default PageHeader;
