// based off of https://www.npmjs.com/package/react-phone-number-input?activeTab=readme
import React from "react";
import { useField } from "react-final-form";
import PropTypes from "prop-types";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


const CustomSelectInput = (props) => {
  const classes = useStyles();
  const { choices, name, label } = props
  const {
    input: { onChange },
    meta: { touched, error }
} = useField(name);

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel htmlFor="custom-select">{label}</InputLabel>
      <Select
        native
        size="small"
        label={label}
        onChange={onChange}
        name= {name}
        inputProps={{
          id: "custom-select",
        }}
        error={!!(touched && error)}
      >
        <option aria-label="None" value="" />
        {choices ? choices.map((option, index) => {
        return(
          <option key={index} value={option.id}>{option.name}</option>
        )
      }): null}
      </Select>
    </FormControl>
  );
};

CustomSelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  choices: PropTypes.array.isRequired
};

export default CustomSelectInput;
