import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {Chip} from "@material-ui/core";


/*
// order statuses
========================
    1. created -
    2. accepted -
    3. declined -
    4. dispatched -
    5. returned -
    6. delivered -

 */

//TODO extract to order styles

const useStyles = makeStyles({
    danger: {
        color: "white",
        backgroundColor: "#C1004B",

    },
    warning: {
        backgroundColor: 'orange',
    },
    success: {
        color: "white",
        backgroundColor: "#00ACC1",


    },
    accepted: {
        color: "white",
        backgroundColor: "#ad7eb7",

    },

    pending: {
        color: "white",
        backgroundColor: "#C17600",

    },
    default: {
        color: "white",
        backgroundColor: "#707070",


    },

});


export const CustomOrderStatus = ({status}) => {
    const styles = useStyles();


    const customChip = (value) => {
        switch (value) {
            case "Dispatched":
                return <Chip className={styles.pending} label={value} />
            case "Dispatched - Assigned":
                return <Chip className={styles.pending} label={value} />
            case "Dispatched - In transit":
                return <Chip className={styles.pending} label={value} />
            case "Dispatched - Out for delivery":
                return <Chip className={styles.pending} label={value} />
            case "Accepted":
                return <Chip className={styles.accepted} label={value} />

            case "Delivered":
                return <Chip className={styles.success} label={value} />
            case "Delivered - Collected":
                return <Chip className={styles.success} label={value}/>
            case "Declined":
                return <Chip className={styles.danger} label={value} />

            case "Returned":
                return <Chip className={styles.danger} label={value} />

            case "Created":
                return <Chip className={styles.default} label={value} />

            default:
                return <Chip className={styles.default} label={value} />

        }
    }


    return status ? (
            <>
                {customChip(status)}
            </>
        ) :
        null;

}