import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  WorkspaceSwitcherContentWrapper,
  WorkspaceSwitcherAvatar,
  WorkspaceSwitcherName,
} from "./styles";
import { getCompany, handleSelectWorkspace } from "../authHelpers";
import useAuthUser from "../useAuthUser";
import { ProfileContext } from "../../../context/ProfileContext";

const WorkspaceSwitcherContent = ({ workspace, photoURL }) => {
  const [state, setState] = useState({
    company: null,
  });
  const authUser = useAuthUser();
  const { updateProfile } = useContext(ProfileContext);

  // destrucuture state
  const { company } = state;

  // get initials from the company name
  const companyInitials =
    company &&
    company.name &&
    company.name
      .split(" ")
      .map((word) => word[0])
      .join("");

  useEffect(() => {
    const fetchCompany = async () => {
      const fetchedCompany = await getCompany(
        workspace.companyType,
        workspace.companyId
      );
      setState((state) => ({
        ...state,
        company: fetchedCompany,
      }));
    };

    fetchCompany();
  }, []);

  // role: is the selected workspace tied to an admin or an agent?
  const role =
    workspace.companyId !== undefined
      ? "admin"
      : workspace.serviceProviderId !== undefined
      ? "agent"
      : undefined;

  // redirect to the selected workspace
  const redirectToWorkspace = () => {
    handleSelectWorkspace(authUser, company, workspace, role, updateProfile);
  };

  return (
    <WorkspaceSwitcherContentWrapper onClick={redirectToWorkspace}>
      <WorkspaceSwitcherAvatar
        alt={workspace.name}
        size="large"
        src={photoURL}
        hasBg={photoURL}
      >
        {/* use company initials if the user doesn't have a photoURL */}
        {!photoURL && companyInitials}
      </WorkspaceSwitcherAvatar>
      <WorkspaceSwitcherName>{companyInitials}</WorkspaceSwitcherName>
    </WorkspaceSwitcherContentWrapper>
  );
};
WorkspaceSwitcherContent.propTypes = {
  workspace: PropTypes.object.isRequired,
  photoURL: PropTypes.string.isRequired,
};

export default WorkspaceSwitcherContent;
