import { useEffect, useState } from "react";
import _ from "lodash";
import { getAdminsOrAgents } from "./authHelpers";
import useAuthUser from "./useAuthUser";

const useGetWorkspaces = () => {
  const [state, setState] = useState({
    workspaces: [],
  });
  const authUser = useAuthUser();

  const { workspaces } = state;

  useEffect(() => {
    const getWorkspaces = async () => {
      if (authUser) {
        const emailAddress = authUser && authUser.email;
        const fetchedAgents = await getAdminsOrAgents(emailAddress, "agents");
        const fetchedAdmins = await getAdminsOrAgents(emailAddress, "admins");

        // admins
        if (!_.isEmpty(fetchedAdmins)) {
          if (fetchedAdmins.hasMultipleAdminsOrAgents) {
            setState((state) => ({
              ...state,
              workspaces: [
                ...state.workspaces,
                ...fetchedAdmins.hasMultipleAdminsOrAgents,
              ],
            }));
          } else {
            // we have just one admin
            setState((state) => ({
              ...state,
              workspaces: [...state.workspaces, fetchedAdmins],
            }));
          }
        }

        // agents
        if (!_.isEmpty(fetchedAgents)) {
          if (fetchedAgents.hasMultipleAdminsOrAgents) {
            setState((state) => ({
              ...state,
              workspaces: [
                ...state.workspaces,
                ...fetchedAgents.hasMultipleAdminsOrAgents,
              ],
            }));
          } else {
            // we have just one admin
            setState((state) => ({
              ...state,
              workspaces: [...state.workspaces, fetchedAgents],
            }));
          }
        }
      } else {
        console.log("*** user is logged out *****", authUser);
      }
    };

    getWorkspaces();
  }, [authUser]);

  return workspaces;
};

export default useGetWorkspaces;
