export default {
  consignmentStatusArray: [
    {
      STATUS: "created",
      MSG:
        "Someone on your team has recorded an incoming parcel/consignment request (or, a sender has generated request to have their item shipped",
    },
    {
      STATUS: "accepted",
      MSG:
        "You have confirmed that you can fulfill this request",
    },
    {
      STATUS: "declined",
      MSG:
        "You have indicated that you cannot fulfill this order for a reason (please specify)",
    },
    {
      STATUS: "dispatched",
      MSG:
        "The item has been assigned to a delivery agent and/or is in transit to the destination",
    },
    {
      STATUS: "dispatched-assigned",
      MSG:
        "You have assigned the order to a particular agent (rider/driver/clerk) on your team",
    },
    {
      STATUS: "dispatched-awaiting-delivery",
      MSG:
        "The item is in your possession awaiting to be loaded and sent",
    },
    {
      STATUS: "dispatched-in-transit",
      MSG:
        "The item has been handed to your agent and is currently being carried to its destination",
    },
    {
      STATUS: "delivered",
      MSG:
        "The item has arrived at the final destination and is ready for pickup (if you do not offer door-to-door services) or has reached the recipient (if you offer D2D services)",
    },
    {
      STATUS: "delivered-collected",
      MSG:
        "The item has been received by the recipient at the drop-off point",
    },
    {
      STATUS: "returned",
      MSG:
        "A customer (sender or recipient) has requested for the item to be sent back to sender",
    },
  ],
};
