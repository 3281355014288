import React from "react";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { TextField, FunctionField } from "react-admin";
import ResendInviteButton from "../ResendInviteButton";
import {
  CardActions,
  CardContent,
  Card,
  Divider,
  Typography,
} from "@material-ui/core";
import InviteStatus from "../InviteStatus";
import PropTypes from "prop-types";
import { useStyles } from "../../pages/admin/Agents/views/AgentsShow";
import moment from "moment";

const InviteDetailsCard = ({ record }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      {record !== undefined && (
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Card className={classes.card}>
            <div className={classes.wrapper}>
              <Avatar alt={record.businessName} className={classes.image} />
              <FunctionField
                className={classes.text}
                render={(record) => `${record.businessName}`}
              />
              <React.Fragment>
                <div className={classes.text}>
                  <FunctionField
                    className={classes.main}
                    label="Invite status"
                    render={(record) => (
                      <InviteStatus
                        label="Invite Status"
                        inviteConfirmed={record.status}
                      />
                    )}
                  />
                </div>
              </React.Fragment>
            </div>
            <Divider className={classes.divider} />
            <CardContent>
              <Grid container spacing={2} className={classes.main}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    component="p"
                    className={classes.main}
                  >
                    Contact:{" "}
                  </Typography>
                  <div>
                    <TextField
                      className={classes.text}
                      source="businessPhoneNumber"
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    component="p"
                    className={classes.main}
                  >
                    Email:{" "}
                  </Typography>
                  <div className={classes.text}>
                    {record.businessEmailAddress || "N/A"}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    component="p"
                    className={classes.main}
                  >
                    Invited on:{" "}
                  </Typography>
                  <div className={classes.text}>
                  {moment(record.createdAt).format("DD-MM-YYYY")}
                  </div>
                </Grid>
              </Grid>
            </CardContent>
            {record.status === "pending" && (
              <CardActions className={classes.main}>
                <ResendInviteButton
                  path="invites"
                  docId={record.id}
                  inviteConfirmed={record.status}
                />
              </CardActions>
            )}
          </Card>
        </Grid>
      )}
    </Grid>
  );
};
InviteDetailsCard.propTypes = {
  record: PropTypes.object.isRequired,
};
export default InviteDetailsCard;
