import React from "react";
import {
  makeStyles,
  Dialog,
  DialogContent,
  Typography,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  useTheme,
  Button,
  useMediaQuery,
} from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 345,
  },
  textHeader: {
    color: "#c1004b",
    textAlign: "center",
    marginTop: "10%",
    fontWeight: "bold",
  },
  media: {
    paddingTop: "56.25%", // 16:9
    height: "100%",
    width: "70%",
    marginLeft: "15%",
    marginRight: "15%",
  },
  bodyText: {
    textAlign: "center",
  },
  closeButton: {
    backgroundColor: "#00ACC1",
    color: "white",
    alignItem: "center",
    marginTop: "8%",
    marginBottom: "15%",
    marginLeft: "24%",
    width: "50%",
    fontSize: "110%",
  },
}));

const TwDialog = ({  openDialog,  closeDialog,avatar,buttonHelperText,title,description,helperText }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.up("bg"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={ openDialog}
      onClose={ closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ position: "absolute" }}
    >
      <DialogContent style={{ margin: 0, padding: 0 }}>
        <Card className={classes.root} style={{ width: "100%" }}>
          <CardHeader className={classes.textHeader} title={title} />
          <CardMedia
            className={classes.media}
            image={avatar}
            title="Card Title"
          />
          <CardContent>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes.bodyText}
            >
             {description}
            </Typography>
          </CardContent>
          <Button
            variant="contained"
            className={classes.closeButton}
            onClick={closeDialog}
          >
           {buttonHelperText}
          </Button>
          <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes.bodyText}
            >
             {helperText}
            </Typography>
        </Card>
      </DialogContent>
    </Dialog>
  );
};
TwDialog.propTypes = {
   openDialog: PropTypes.bool.isRequired,
   closeDialog: PropTypes.func.isRequired,
   buttonHelperText: PropTypes.string.isRequired,
   description: PropTypes.string.isRequired,
   avatar: PropTypes.bool.isRequired,
   title: PropTypes.string.isRequired,
   helperText:PropTypes.string
};
export default TwDialog;

