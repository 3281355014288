/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { navigate } from "@reach/router";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import {
  functions,
  sendSignInLinkToEmail,
} from "../../../config/firebaseConfig";
import { auth, googleProvider, db } from "../../../config/firebaseConfig";
import { useStyles } from "../AuthStyles";
import IndeterminateLoading from "../../../components/IndeterminateLoading";
import { AuthLink } from "../AuthStyles";
import PrimaryView from "./components/PrimaryView";
import SecondaryView from "./components/SecondaryView";
import syncGlobalProfile from "../syncGlobalProfile";
import { ProfileContext } from "../../../context/ProfileContext";
import { VpnKeyRounded } from "@material-ui/icons";
import { EmailAndPswdBtnWrapper } from "../../../components/Auth/Buttons";
import { CircularProgress } from "@material-ui/core";
import { getInviterCompanyName } from "../../../utils/invites";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import AccountSelector from "./components/MultipleAccountsChooser/AccountSelector";
import {
  getUser,
  hasPendingInvite,
  sendAccountVerificationLink,
} from "../authHelpers";
import { Button } from "@material-ui/core";
import TwSnackBar from "../../../layout/TwSnackBar";
import {
  AuthContentWrapper,
  AuthPageWrapper,
} from "../../../components/authComponents/layout/Wrappers";
import AuthSidebar from "../../../components/authComponents/Sidebar";
import Divider from "../../../components/authComponents/layout/Divider";
import { AuthLinkBtn } from "../../../components/authComponents/Buttons";
import PasswordReset from "../../authScreens/PasswordReset";
import StickyAnnouncementBanner, { Appbar } from "../../../components/StickyAnnouncementBanner";
import { updateFcmToken } from "../../../config/fcm-notifications";

const SignIn = (props) => {
  // use the context API to persist the firebase user
  const { updateAuthUser } = useContext(ProfileContext);

  const classes = useStyles();
  const [showSnackBar, setShowSnackBar] = useState({
    isOpen: false,
    message: "",
    info: "",
  });
  const [email, setEmail] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState(null);
  const [errSeverity, setErrSeverity] = useState("error");
  const [showAlertAction, setShowAlertAction] = useState(false);
  const [resendVerLinkData, setResendVerLinkData] = useState({
    inviteeId: "",
    inviterId: "",
    companyId: "",
    collName: "",
    inviteeEmailAddress: "",
  });
  const [showDialog, setShowDialog] = React.useState(false);
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState({
    email: null,
    password: null,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isSigningInWithEmailAndPswd, setIsSigningInWithEmailAndPswd] =
    useState(false);
  const { updateProfile } = useContext(ProfileContext);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  // the isNewlyVerifiedAdmin prop is passed by the router from the params i.e ../signin/true
  // it is used to determine whether the current user is a newly verified admin
  // upon successfully verifying an admin, the backend will redirect to signin/true
  // to trigger display of both login page and verification success message.
  // For usuall login, the message will not be displayed
  const isNewlyVerifiedAdmin = props.isNewlyVerifiedAdmin || false;

  const spInviterCompanyId = localStorage.getItem("spInviterCompanyId");
  const accountType = localStorage.getItem("accountType");
  const isNewBusinessInvite = accountType == "business" && accountType;
  const [inviterCompanyName, setInviterCompanyName] = useState("");

  // complete email and link signIn, i.e, email verification
  useEffect(() => {
    const completeEmailLinkSignIn = async () => {
      // is the user completing email+link signIn?
      if (auth.isSignInWithEmailLink(window.location.href)) {
        let signInEmail = localStorage.getItem("signInWithLinkEmail");
        if (!signInEmail) {
          // ask the user to provide the associated email again
          /** @TODO: use custom UI for this instead of the default browser prompt */
          signInEmail = window.prompt(
            "Please provide your email for confirmation"
          );
        }

        // complete singInWithEmailLink
        // The client SDK will parse the code from the link for you.
        try {
          const userCredential = await auth.signInWithEmailLink(
            signInEmail,
            window.location.href
          );

          console.log("*** emailLinkSignIn success: ****", userCredential);

          // redirect accordingly
          // first get the twiftUser associated with this email
          const twiftUser = await getUser(signInEmail);
          if (twiftUser) {
            navigate("/auth-home", { state: { twiftUserId: twiftUser.id } });
          } else {
            // there's no twiftUser associated with that email address
            // redirect to signup
            navigate("/signup");
          }

          // clear the email from localStorage
          localStorage.removeItem("signInWithLinkEmail");
        } catch (error) {
          console.error(
            "*** err: err completing email+link sign-in ****",
            error
          );
          /**
           * @TODO: handle 'expired code/link' errors
           */
        }
      } else {
        console.log("**** user isn't completeing email+link sign in");
      }
    };
    completeEmailLinkSignIn();
  }, []);

  useEffect(() => {
    // get email from pswd reset email to pre-populate the email input
    const qString = window.location.search;
    if (qString) {
      const urlParams = new URLSearchParams(qString);
      const pswdResetEmail = urlParams.get("email");
      setEmail(pswdResetEmail);
    }

    //get sp inviter name and company from b/s invite
    if (spInviterCompanyId) {
      const getName = async () => {
        const companyName = await getInviterCompanyName(spInviterCompanyId);
        setInviterCompanyName(companyName);
      };
      getName();
    }

    // check if an email + pswd user has been redirected from the sign-up page
    if (localStorage.getItem("signInUserFromSignUp")) {
      const { message, email, password } = JSON.parse(
        localStorage.getItem("signInUserFromSignUp")
      );

      // show the message
      setError(`${message}. Sign in instead`);

      // prefill the form
      setEmail(email);
      setPassword(password);
    }
  }, []);

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const getMultipleAccsDataFromModal = (info) => {
    const { isModalOpen, isAdminInvitePending } = info;
    // close the MAC modal
    !isModalOpen && handleModalClose();

    // display the error to the user
    // display the error to the user
    if (isAdminInvitePending) {
      // set error
      setError(
        `Looks like your email address hasn't been verified, please follow the link we've just sent to your email address to verify it`
      );
      // set error severity
      setErrSeverity("info");
      // ensure that the alert actions are shown
      setShowAlertAction(true);
    }
  };

  // helper function to complete sign-in
  const completeSignIn = async (user, twiftUser) => {
    const timerStart = Date.now();
    console.log(">>> completeSignIn starts at ", timerStart);

    // setEmail()
    setEmail(user.email);
    // persist the auth user object so it's available app-wide
    updateAuthUser(user);

    const adminsQuery = db
      .collectionGroup("admins")
      .where("emailAddress", "==", user.email)
      .where("inviteConfirmed", "==", false);

    // hasPendingInvite ?
    const hasPendingAdminInvite = await hasPendingInvite(adminsQuery);

    // get the syncedProfile
    const syncedProfile = await syncGlobalProfile(user, updateProfile);
    if (
      syncedProfile.authUser &&
      !syncedProfile.admin &&
      !syncedProfile.agent
    ) {
      // redirect to "/auth-home"
      navigate("/auth-home", {
        state: { twiftUserId: twiftUser.id },
      });
    } else if (syncedProfile.agent) {
      // handle cases where a user is registered as an agent
      // ( and is also either an admin(s) or is registered as an agent in other companies)
      if (syncedProfile.admin || syncedProfile.agent.hasMultipleAgents) {
        /**
         * am only caching the authUser, agent and admin
         * because the company and team will depend on
         * what the user will choose to continue as.
         * i.e, syncedProfile.company will be the company the admin belongs, i.e, admin.companyId.
         * However admin.companyId is not necessarilly equal to agent.companyId.
         */
        navigate("/workspace-picker", {
          state: {
            emailAddress: user.email,
            firstName: twiftUser.firstName,
            userId: twiftUser.id,
          },
        });
      } else {
        navigate("/agents");
      }
    } else if (syncedProfile.admin && syncedProfile.admin.hasMultipleAdmins) {
      navigate("/workspace-picker", {
        state: {
          emailAddress: user.email,
          firstName: twiftUser.firstName,
          userId: twiftUser.id,
        },
      });
      const timeNow = Date.now();
      const timeDiff = timeNow - timerStart;
      console.log(
        `>>> started at: ${timerStart} to: ${timeNow}. it took ${timeDiff} to completeSignIn. multipleAccounts`
      );

      handleModalOpen();
    } else if (
      syncedProfile.company &&
      syncedProfile.admin &&
      syncedProfile.team
    ) {
      // authUser.emailVerifed === false
      /**
       * we should only send a verification link if:
       * 1. the admin trying to sign-in is not an Owner, i.e they belong any other team that's not the Owners
       * 2. their email is not verified and
       * 3. they have a pending invite
       *
       * if(!user.emailVerified && syncedProfle.admin?.team?.name !== "Owners" && !syncedProfile.admin?.inviteConfirmed)
       */

      if (
        !user.emailVerified &&
        syncedProfile.admin?.team?.name !== "Owners" &&
        !syncedProfile.admin?.inviteConfirmed
      ) {
        // set error
        setError(
          `Looks like your email address hasn't been verified, please follow the link we've just sent to your email address to verify it`
        );
        // set error severity
        setErrSeverity("info");
        // ensure that the alert actions are shown
        setShowAlertAction(true);

        // call the http function 'verifyAccount'
        const inviteeId = syncedProfile.admin.id;
        const inviterId = syncedProfile.admin.inviterId;
        const companyId = syncedProfile.admin.companyId;
        const inviteeEmailAddress = syncedProfile.admin.emailAddress;
        const collName =
          syncedProfile.admin.companyType === "serviceProvider"
            ? "companies"
            : "businesses";

        // set resendVerLinkData -> will be used later on in the 'resend invite' alert btn
        setResendVerLinkData({
          inviteeId,
          inviterId,
          companyId,
          collName,
          inviteeEmailAddress,
        });

        sendAccountVerificationLink(
          inviteeId,
          inviterId,
          companyId,
          collName,
          inviteeEmailAddress
        );
      } else if (syncedProfile.admin.companyType === "serviceProvider") {
        const timeNow = Date.now();
        const timeDiff = timeNow - timerStart;
        console.log(
          `>>> (redirecting to /home). timeNow: ${timeNow}. it took ${timeDiff} to completeSignIn`
        );

        // ##### update fcm token
        updateFcmToken(syncedProfile.admin.userId)
          .then(() => {
            // redirect to "/home"
            navigate("/home");
          })
          .catch((err) => {
            console.error(
              "**** err: error updating fcm token during sign-in ****",
              err
            );
          });
      } else {
        const timeNow = Date.now();
        const timeDiff = timeNow - timerStart;
        console.log(
          `>>> (redirecting to /home). timeNow: ${timeNow}. it took ${timeDiff} to completeSignIn`
        );

        // ##### update fcm token
        updateFcmToken(syncedProfile.admin.userId)
          .then(() => {
            // redirect to "/home"
            navigate("/home");
          })
          .catch((err) => {
            console.error(
              "**** err: error updating fcm token during sign-in ****",
              err
            );
          });
      }
    } else if (syncedProfile.isTeamCreated === undefined) {
      // in syncGlobalProile, syncedProfile.isTeamCreated is set only if there exists a user with the given email
      // therefore, if there doesn't exist a user with the given email, syncedProfile.isTeamCreated will be undefined,
      // which is why we're using that to check whether we should redirect the user to the signup page
      localStorage.setItem("authUserInfoFromSignIn", JSON.stringify(user));
      navigate("signup");
    } else if (syncedProfile.isTeamCreated === false) {
      // i.e, team hasn't been created yet, thus we wait for that to happen
      setShowLoader(true);
    }
  };

  const handleSignInWithGoogle = async (e) => {
    e.preventDefault();
    try {
      // show loader
      setShowLoader(true);
      const authResult = await auth.signInWithPopup(googleProvider);
      const timeSt = Date.now();
      console.log(">>> handeSIWG. firebase auth modal launched now: ", timeSt);
      const user = authResult.user;

      // check if there exists a matching twift user
      const twiftUser = await getUser(user.email);
      if (twiftUser) {
        // complete sign-in
        await completeSignIn(user, twiftUser);
      } else {
        // this should've been a signup
        window.location.assign(`${process.env.REACT_APP_V3_LINK}/auth/signup`);
        // navigate("/signup");
      }
      // hide loader
      setShowLoader(false);
    } catch (err) {
      // hide the loader when we have an error
      setShowLoader(false);
      console.error(
        "*** an error occurred while signing in with google ****",
        err
      );
      const { code, message } = err;
      if (code === "auth/popup-closed-by-user") {
        // use an alert to display the message to the user
        // firebaseAuth Err: The popup has been closed by the user before finalizing the operation
        setErrSeverity("warning");
        setError(
          "You've closed the google account chooser popup before choosing an account, please try again"
        );
      } else {
        // use a snackbar to display a genereic error message to the user
        setShowSnackBar({
          isOpen: true,
          message:
            "Something went wrong during login, please contact us if this persists",
          info: err.message,
        });
      }
    }
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(value);
    } else {
      setPassword(value);
    }
  };

  const handleEmailAndPasswordSignin = async (e) => {
    e.preventDefault();
    if (!email && !password) {
      setFormErrors({
        email: "* This field is required",
        password: "* This field is required",
      });
    } else if (!email) {
      setFormErrors({
        email: "* This field is required",
      });
    } else if (!password) {
      setFormErrors({
        password: "* This field is required",
      });
    } else {
      try {
        // show loader
        setShowLoader(true);
        const authResult = await auth.signInWithEmailAndPassword(
          email,
          password
        );
        const user = authResult.user;

        // check if there exists a matching twift user
        const twiftUser = await getUser(user.email);
        if (twiftUser) {
          if (!user.emailVerified) {
            // hide loader
            setShowLoader(false);

            // set setIsSigningInWithEmailAndPswd to false as we're no longer signing in
            setIsSigningInWithEmailAndPswd(false);
            // redirect to '/auth-home'
            navigate("/auth-home", {
              state: {
                twiftUserId: twiftUser.id,
                firstName: twiftUser.firstName,
              },
            });
          } else {
            // complete sign in
            await completeSignIn(user, twiftUser);
            // set setIsSigningInWithEmailAndPswd to false as we're no longer signing in
            setIsSigningInWithEmailAndPswd(false);
            console.log(
              "***** success: here's the user *****",
              authResult.user
            );
          }
        } else {
          // this should've been a signup
          navigate("/signup");
        }
        /**
         * when you sign out, RA redirects you to "/login" where we show the sign-in component.
         * when that happens and you successfully sign-in with email + pswd, we'll redirect you to "/".
         * However, when you launch the app for the first time, you'll be in "/" but you'll see the sign-in component.
         * In that case, we don't need to do the redirection because you're already in "/"
         *
         * see <TwAdmin />, to see how the UI components are evaluated, depending on whether a user is signed-in or not
         */
        if (props.location && props.location.pathname !== "/") {
          navigate("/");
        }
        // hide loader
        setShowLoader(false);
      } catch (err) {
        // hide the loader when we have an error
        setShowLoader(false);
        console.log(
          "***** an error occurred while signing in with email + pswd *******",
          err
        );

        const { code, message } = err;

        switch (code) {
          case "auth/wrong-password":
            setError(
              "The password is invalid, please enter the correct password. Alternatively, use the 'forgot password' option below to reset your password"
            );
            break;
          case "auth/user-not-found":
            /**
             * handle 'user-not-found'
             * cache the email and pswd so that it's available in the signup page
             * where we'll do a lookup and prefill the form with these details
             * so that users don't have to start from scratch
             */
            localStorage.setItem(
              "completeSignInWithEmail",
              JSON.stringify({
                email,
                password,
              })
            );

            // redirect to '/signup'
            navigate("signup");
          default:
            // show snackbar with a generic error
            setShowSnackBar({
              isOpen: true,
              message:
                "Something went wrong during login, please contact us if this persists",
              info: message,
            });
        }
      }
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleBack = (e) => {
    e.preventDefault();
    setIsSigningInWithEmailAndPswd(false);
    setShowSubsequentSteps(false);
  };

  const handleResendVerificationLink = () => {
    // destructure resendVerLinkData
    const { inviteeId, inviterId, companyId, collName, inviteeEmailAddress } =
      resendVerLinkData;

    // call functions.httpsCallable("companies-verifyAccount")
    sendAccountVerificationLink(
      inviteeId,
      inviterId,
      companyId,
      collName,
      inviteeEmailAddress
    );
  };

  const AlertAction = () => (
    <div style={{ display: "block" }}>
      <a
        href={`mailto:${email}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <Button color="inherit" size="small">
          go to email
        </Button>
      </a>
      <Button
        color="inherit"
        size="small"
        onClick={handleResendVerificationLink}
      >
        re-send link
      </Button>
    </div>
  );

  const closeSnackBar = () =>
    setShowSnackBar({
      isOpen: false,
      message: "",
      info: "",
    });

  const getAlertInfoFromChild = (alertInfo) => {
    // set the error message
    setError(alertInfo.message);
    // set the error severity
    setErrSeverity(alertInfo.severity);
  };

  return (
    <AuthPageWrapper>
      {showLoader && (
        <IndeterminateLoading
          showDialog={true}
          message="Signing in. Please do not leave this page or quit your browser"
        />
      )}
      <>
        <AuthSidebar login />
        <AuthContentWrapper>
          <StickyAnnouncementBanner />
          <h1 className="auth_title" style={{ marginBottom: "3rem" }}>
            Sign in
          </h1>
          <IndeterminateLoading
            showDialog={showDialog}
            message="Waiting for email verification. Please check the message we sent to your email address"
          />
          {isNewlyVerifiedAdmin && (
            <Paper className={classes.paper}>
              <Typography align="center">
                Your email was verified successfully!
                <br />
                Please login to access your company's dashboard
              </Typography>
            </Paper>
          )}
          {isNewBusinessInvite && (
            <Paper className={classes.paper}>
              <Typography align="center">
                You are about to accept an invite from {inviterCompanyName}
                <br />
                Please login to accept the Invite
              </Typography>
            </Paper>
          )}
          {error !== null && (
            <Alert
              onClose={() => setError(null)}
              severity={errSeverity}
              action={showAlertAction ? <AlertAction /> : null}
            >
              {error}
            </Alert>
          )}
          {showSnackBar.isOpen && (
            <TwSnackBar
              isOpen={showSnackBar.isOpen}
              handleClose={closeSnackBar}
              message={showSnackBar.message}
              errContext={showSnackBar.info}
            />
          )}
          <PrimaryView
            handleEmailAndPasswordSignin={handleEmailAndPasswordSignin}
            handleSignInWithGoogle={handleSignInWithGoogle}
            email={email}
          />
          <Divider />
          <SecondaryView
            email={email}
            password={password}
            formErrors={formErrors}
            onChangeHandler={onChangeHandler}
            showPassword={showPassword}
            handleClickShowPassword={handleClickShowPassword}
            handleMouseDownPassword={handleMouseDownPassword}
            isSigningInWithEmailAndPswd={isSigningInWithEmailAndPswd}
            handleClick={handleEmailAndPasswordSignin}
            handleBack={handleBack}
          />
          <Grid
            className={classes.helperText}
            container
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {/* <AuthLinkBtn register /> */}
            <PasswordReset sendAlertInfoToLogin={getAlertInfoFromChild} />
          </Grid>
        </AuthContentWrapper>
      </>
    </AuthPageWrapper>
  );
};

export default SignIn;
