import { SimpleForm } from "react-admin";
import {
  Button,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Tooltip,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import TwTextField from "../common/inputs/TextField";
import AddressField from "../common/inputs/AddressField";
import DateField from "../common/inputs/DateField";
import PhoneInput from "react-phone-number-input";
import styled from "styled-components";
import MUIPhoneNumberInput from "../../../../layout/TwPhoneNumberInput/MUIPhoneInput";

const CollectionForm = ({ toolbar, className }) => {
  const [state, setState] = React.useState({
    packageDetails: false,
    fragile: false,
    phoneNumber: "",
    senders: 1,
    formErrors: {
      phoneNumber: null,
    },
  });

  const { phoneNumber, formErrors } = state;

  const handleCheckboxChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handlePhoneNumberChange = (value) => {
    setState((state) => ({
      ...state,
      phoneNumber: value,
    }));
  };

  const handleAddSender = () => {
    console.log("sender added");
    setState((state) => ({
      ...state,
      senders: state.senders + 1,
    }));
    console.log("senders", state.senders);
  };

  const handleRemoveSender = () => {
    console.log("sender removed");
    setState((state) => ({
      ...state,
      senders: state.senders - 1,
    }));
    console.log("senders", state.senders);
  };

  return (
    <SimpleForm toolbar={toolbar} warnWhenUnsavedChanges>
      <main className={className.layout}>
        <div style={{ margin: "1rem 0" }}>
          <p>Shipment</p>
          <hr
            style={{
              width: "100%",
              opacity: "0.4",
              marginBottom: "2.5rem",
              marginTop: "1.8rem",
            }}
          />
          {/* <AddressField /> */}
          <TwTextField variant="outlined" label="To" required />
          <DateField />
          {/* <TwTextField variant="outlined" label="Date" required />
          <TwTextField variant="outlined" label="Time" required /> */}
        </div>
        <p>Recipient</p>
          <hr
            style={{
              width: "100%",
              opacity: "0.4",
              marginBottom: "2.5rem",
              marginTop: "1.8rem",
            }}
          />
        <div style={{ margin: "1rem 0" }}>
          <TwTextField variant="outlined" label="Name" required />
          {/* <TwTextField variant="outlined" label="Phone Number" required /> */}
            <PhoneNumberField
              defaultCountry="KE"
              placeholder="701 234 567"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              inputComponent={MUIPhoneNumberInput}
              error={formErrors.phoneNumber}
              helperText={formErrors?.phoneNumber}
            />
        </div>

        <p>Package(s)</p>
          <hr
            style={{
              width: "100%",
              opacity: "0.4",
              marginBottom: "2.5rem",
              marginTop: "1.8rem",
            }}
          />
        {new Array(state.senders).fill(0).map((_, index) => (
          <div key={index}>
            <div style={{ margin: "1rem 0" }}>
          {/* <AddressField /> */}
          <TwTextField variant="outlined" label="From" required />
          <TwTextField variant="outlined" label="Agent" required />
          <TwTextField variant="outlined" label="Sender name" required />
          <TwTextField variant="outlined" label="Sender contacts" required />
          {state.packageDetails && (
            <TwTextField variant="outlined" label="Additional details" />
          )}
          {state.senders > 1 && (
                <Tooltip title="Remove sender">
                  <IconButton
                    onClick={handleRemoveSender}
                    color="secondary"
                    aria-label="Remove sender"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}

          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.packageDetails}
                  onChange={handleCheckboxChange}
                  name="packageDetails"
                  color="primary"
                />
              }
              label="Package details"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.fragile}
                  onChange={handleCheckboxChange}
                  name="fragile"
                  color="primary"
                />
              }
              label="Fragile"
            />
          </FormGroup>
        </div>
      </div>
        ))}
        <div>
          <Button onClick={handleAddSender} color="primary">+ add another package</Button>
        </div>
      </main>
    </SimpleForm>
  );
};

export default CollectionForm;

const PhoneNumberField = styled(PhoneInput)`
 && {
    width: 25%;
    margin: 0.5rem 1rem;
    @media (max-width: 484px) {
      width: 95%;
    }
    @media (max-width: 414px) {
      margin: 0.5rem 0;
    }
 }
`;
