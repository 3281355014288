import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  DateField,
  TextField,
  ChipField,
  EditButton,
  DeleteButton,
} from "react-admin";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Grid,
} from "@material-ui/core";

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, auto);
  gap: 3rem;
  margin: 0 1rem 0;
  @media (min-width: 800px) {
    grid-template-columns: repeat(2, auto);
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "grid",
    borderRadius: "10px",
  },
  Content: {
    display: "grid",
    gridTemplateColumns: "repeat(2, auto)",
    gap: "0.8rem",
  },
  text: {
    fontWeight: "bold",
    fontSize: "1rem",
    marginLeft: "0.2rem",
  },
}));

const PaymentsMobile = ({ ids, data, basePath }) => {
  const classes = useStyles();
  if (!ids || !data || !basePath) {
    return null;
  }

  return (
    <CardWrapper>
      {ids.map((id) => (
        <div
          onClick={() => window.location.assign(`/home#/payments/${id}/show`)}
          key={data[id]}
        >
          <Card className={classes.root} key={data[id]} elevation={2}>
            <CardHeader
              action={<ChipField record={data[id]} source="status" />}
              title={
                <div>
                  <Typography
                    variant="caption"
                    display="block"
                    color="textSecondary"
                    gutterBottom
                  >
                    Booking Code # :
                  </Typography>
                  <TextField
                    record={data[id]}
                    className={classes.text}
                    source="bookingCode"
                  />
                </div>
              }
              subheader={
                <div>
                  <Typography variant="caption" display="block" gutterBottom>
                    Payment date:
                  </Typography>
                  <DateField
                    record={data[id]}
                    className={classes.title}
                    source="createdAt"
                  />
                </div>
              }
            />
            <CardContent>
              <Divider style={{ marginBottom: "1rem", marginTop: "-1rem" }} />
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <div>
                    <Typography variant="caption" display="block" gutterBottom>
                      Account Name:
                    </Typography>
                    <p className={classes.text}>{data[id].accountName}</p>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div>
                    <Typography variant="caption" display="block" gutterBottom>
                      Account Number:
                    </Typography>
                    <p className={classes.text}>{data[id].accountNumber}</p>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div>
                    <Typography variant="caption" display="block" gutterBottom>
                      Transaction Amount:
                    </Typography>
                    <p className={classes.text}> Ksh {data[id].amount}</p>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div>
                    <Typography variant="caption" display="block" gutterBottom>
                      Transaction Type:
                    </Typography>
                    <p className={classes.text}>{data[id].type}</p>
                  </div>
                </Grid>
              </Grid>
              <Divider />
            </CardContent>
            {/* <CardActions className={classes.Content}>
              <EditButton basePath={basePath} record={data[id]} />
              <DeleteButton basePath={basePath} record={data[id]} />
            </CardActions> */}
          </Card>
        </div>
      ))}
    </CardWrapper>
  );
};
PaymentsMobile.defaultProps = {
  data: {},
  ids: [],
};
PaymentsMobile.propTypes = {
  ids: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  basePath: PropTypes.string,
};
export default PaymentsMobile;