import * as React from "react";
import {
  useEditController,
  EditContextProvider,
  TextInput,
  SimpleForm,
  DateField,
  TextField,
} from "react-admin";
import { IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";

import MessageEditToolbar from "./MessageEditToolbar";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 40,
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "1em",
  },
  form: {
    [theme.breakpoints.up("xs")]: {
      width: 400,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginTop: -30,
    },
  },
  inlineField: {
    display: "inline-block",
    width: "50%",
  },
}));


const MessageEdit = ({ ...props }) => {
  const classes = useStyles();
  const controllerProps = useEditController(props);
  if (!controllerProps.record) {
    return null;
  }
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6">{"Message detail"}</Typography>
        <IconButton onClick={props.onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <EditContextProvider value={controllerProps}>
        <SimpleForm
          className={classes.form}
          basePath={controllerProps.basePath}
          record={controllerProps.record}
          save={controllerProps.save}
          version={controllerProps.version}
          redirect="list"
          resource="messages"
          toolbar={<MessageEditToolbar />}
        >
          <TextField source="sender" formClassName={classes.inlineField} />
          <TextField source="recipient" formClassName={classes.inlineField} />
          <DateField
            label="Date"
            source="createdAt"
            formClassName={classes.inlineField}
            showTime
          />
          <TextField source="status" formClassName={classes.inlineField} />
          <TextInput source="body" rowsMax={15} multiline fullWidth disabled />
        </SimpleForm>
      </EditContextProvider>
    </div>
  );
};

MessageEdit.propTypes = {
    onCancel: PropTypes.func.isRequired,
  };

export default MessageEdit;
