import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import AuthSidebar from "../../../components/authComponents/Sidebar";
import { TwButton } from "../../../components/authComponents/Buttons";
import PhoneInput from "react-phone-number-input";
import styled from "styled-components";
import { StyledTextField } from "../../auth/AuthStyles";
import MUIPhoneNumberInput from "../../../layout/TwPhoneNumberInput/MUIPhoneInput";
import { syncCustomClaims, syncTeamData } from "../../auth/syncGlobalProfile";
import { auth, db } from "../../../config/firebaseConfig";
import {
  AuthContentWrapper,
  AuthPageWrapper,
} from "../../../components/authComponents/layout/Wrappers";
import { navigate } from "@reach/router";
import { ProfileContext } from "../../../context/ProfileContext";
import {
  createAdmin,
  createBusinessCustomer,
  createCompany,
  getAdminById,
  getCompany,
} from "../../auth/authHelpers";
import { addSpId, updateBsInvite } from "../../../utils/invites";
import useAuthUser from "../../auth/useAuthUser";

const CompanyDetailsPage = ({ location }) => {
  const [state, setState] = useState({
    companyName: "",
    companyEmail: "",
    phoneNumber: "",
    companyAddress: "",
    formErrors: {
      companyName: null,
      companyEmail: null,
      phoneNumber: null,
      companyAddress: null,
    },
    isLoading: false,
  });
  const spInviterCompanyId = localStorage.getItem("spInviterCompanyId");
  const inviteId = localStorage.getItem("inviteId");
  const bsInviteStatus = spInviterCompanyId ? true : false;

  // get the user profile updater function from ProfileContext
  const { updateProfile } = useContext(ProfileContext);

  // destructure state
  const {
    companyName,
    companyEmail,
    phoneNumber,
    companyAddress,
    formErrors,
    isLoading,
  } = state;

  // load the current firebase auth user
  const authUser = useAuthUser();

  const handleTextFieldChange = (e) => {
    const { name, value } = e.currentTarget;
    setState((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handlePhoneNumberChange = (value) => {
    setState((state) => ({
      ...state,
      phoneNumber: value,
    }));
  };

  const createABusiness = async () => {
    // create customer
    // create business
    // create admin

    // set isLoading to true
    setState((state) => ({
      ...state,
      isLoading: true,
    }));
    // create customer because we need a customerId to create a business
    const customerId = await createBusinessCustomer();

    if (spInviterCompanyId) {
      addSpId(customerId, spInviterCompanyId);
      updateBsInvite(inviteId, companyName);
    }

    // create business
    const businessId = await createCompany("business", {
      customerId,
      name: companyName,
      phoneNumber,
      emailAddress: companyEmail,
    });

    // add businessId to customer object
    db.collection("customers").doc(customerId).update({
      businessId: businessId,
    });

    let ownerAdminCreated = false;
    let ownerAdminTeamCreated = false;

    // create admin
    const adminId = await createAdmin(businessId, "business", {
      userId: location.state.userId,
      companyType: location.state.accountType,
      companyId: businessId,
      emailAddress: authUser.email,
    });
    console.log("**** success: admin was created successfully ***", adminId);

    // set ownerAdminCreated to true after an admin has been created
    ownerAdminCreated = true;

    // sync custom claims
    await syncCustomClaims(authUser, {
      emailAddress: authUser.email,
      companyDocId: businessId,
    });

    // next we sync the admin's team, update the profile context and redirect the user
    do {
      // get the created admin
      const admin = await db
        .doc(`businesses/${businessId}/admins/${adminId}`)
        .get();

      if (admin.data().team && admin.data().team.id) {
        ownerAdminTeamCreated = true;

        // get the team
        const team = await syncTeamData(
          businessId,
          admin.data().team.id,
          "businesses"
        );

        // populate a profile object
        const syncedProfile = {
          authUser,
          company: await getCompany("business", businessId),
          admin: {
            id: admin.id,
            ...admin.data(),
          },
          team,
        };

        // update the profile context
        updateProfile(syncedProfile);
        localStorage.setItem("cachedProfile", JSON.stringify(syncedProfile));

        // set isLoading to false
        setState((state) => ({
          ...state,
          isLoading: false,
        }));
        // redirect to '/home'
        navigate("/home");
      }
    } while (!ownerAdminTeamCreated);
  };

  const createServiceProvider = async () => {
    // set isLoading to true
    setState((state) => ({
      ...state,
      isLoading: true,
    }));
    // create service provider
    const spId = await createCompany("serviceProvider", {
      name: companyName,
      phoneNumber,
      emailAddress: companyEmail,
    });

    const timerStart = Date.now();
    let timeNow = Date.now();
    let timeDiff = timeNow - timerStart;
    console.log(
      `>>> company creation done at ${timeNow}. ${timeDiff}ms so far...`
    );

    let ownerAdminCreated = false;
    let ownerAdminTeamCreated = false;
    // listen for changes in sp
    db.collection("companies")
      .doc(spId)
      .onSnapshot(
        async (spDocSnapshot) => {
          // only create an admin if there's an `adminsCount` field and there's only one
          // such admin, else we'd end up with multiple admin records for the same SP
          timeNow = Date.now();
          timeDiff = timeNow - timerStart;
          console.log(
            `>>> onSnapshot called at ${timeNow}. ${timeDiff}ms so far...`
          );

          const spData = spDocSnapshot.data();

          if (!ownerAdminCreated && spData && spData.adminsCount < 1) {
            // create admin
            const ownerAdminId = await createAdmin(spId, "serviceProvider", {
              userId: location.state.userId,
              companyType: location.state.accountType,
              companyId: spId,
              emailAddress: authUser.email, // adding this as it is required while setting custome calims in the onCreateAdmin function
            });

            timeNow = Date.now();
            timeDiff = timeNow - timerStart;
            console.log(
              `>>> owner admin creation done at ${timeNow}. ${timeDiff}ms so far...`
            );

            ownerAdminCreated = true;
            // sync custom claims
            await syncCustomClaims(authUser, {
              emailAddress: authUser.email,
              companyDocId: spId,
            });

            timeNow = Date.now();
            timeDiff = timeNow - timerStart;
            console.log(
              `>>> custom claims sync done at ${timeNow}. ${timeDiff}ms so far...`
            );

            do {
              timeNow = Date.now();
              timeDiff = timeNow - timerStart;
              console.log(`>>> inside do..while loop; ${timeDiff}ms elapsed`);
              // get the owner admin
              const ownerAdmin = await getAdminById(spId, ownerAdminId);

              if (ownerAdmin.team && ownerAdmin.team.id) {
                ownerAdminTeamCreated = true;

                // get the team
                const team = await syncTeamData(
                  spId,
                  ownerAdmin.team.id,
                  "companies"
                );

                const syncedProfile = {
                  authUser: authUser,
                  company: await getCompany("serviceProvider", spId),
                  admin: ownerAdmin,
                  team,
                };

                // update the profile context
                updateProfile(syncedProfile);

                // add the profile object as it'll be used to replenish the profile context across page refreshes
                localStorage.setItem(
                  "cachedProfile",
                  JSON.stringify(syncedProfile)
                );

                timeNow = Date.now();
                timeDiff = timeNow - timerStart;
                console.log(
                  `>>> team data sync + profile caching done at ${timeNow}. ${timeDiff}ms so far...`
                );

                // set isLoading to false
                setState((state) => ({
                  ...state,
                  isLoading: false,
                }));
                // redirect to the dashboard
                navigate("/home");
              } else {
                timeNow = Date.now();
                timeDiff = timeNow - timerStart;
                console.log(
                  `>>> no team yet for admin ${ownerAdmin.id};, ${timeDiff}ms elapsed`
                );
              }
            } while (!ownerAdminTeamCreated);
          } else {
            console.log(`>>> skipping admin/sCC/do..while loop`);
          }
        },
        (err) => console.error("*** err: listening to sp snapshot ******", err)
      );
  };
  const handleCreateWorkspace = async (e) => {
    // prevent default behaviour, i.e, prevent refreshing the page when onSubmit
    e.preventDefault();
    switch (location.state.accountType) {
      case "serviceProvider":
        {
          // create an sp
          await createServiceProvider();
        }
        break;
      case "business": {
        // create a business
        await createABusiness();
      }
      break;
      default:
        {
          // unsopported company type
          console.error(
            "*** err: unsupported value for company type ****",
            location.state.accountType
          );
        }
        break;
    }
  };

  // navigate back to the '/company-picker' page
  const handleBack = () =>
    navigate("/company-picker", {
      state: { accountType: location.state.accountType },
    });
  return (
    <AuthPageWrapper>
      <AuthSidebar data />
      <AuthContentWrapper>
        <h1 className="auth_title" style={{ marginBottom: "3rem" }}>
          About your company
        </h1>
        <p
          style={{
            lineHeight: "1.8",
            fontSize: "0.8rem",
            fontFamily: "Fira Sans",
            color: "#707070",
            textAlign: "center",
          }}
        >
          This data is only meant to improve your experience on Twift. We will
          not Communicate nor share it with any third party without your
          consent.
        </p>
        <form>
          <div className="row">
            <LoginTextFields
              variant="outlined"
              margin="normal"
              label="Company name"
              name="companyName"
              value={companyName}
              placeholder="e.g, Twift"
              id="companyName"
              onChange={handleTextFieldChange}
              autoComplete="company name"
              error={formErrors.companyName}
              helperText={formErrors?.companyName}
              required
              fullWidth
            />
            <LoginTextFields
              variant="outlined"
              margin="normal"
              label="E-mail"
              name="companyEmail"
              type="email"
              value={companyEmail}
              placeholder="e.g, example@email.com"
              id="companyEmail"
              onChange={handleTextFieldChange}
              autoComplete="company email"
              error={formErrors.companyEmail}
              helperText={formErrors?.companyEmail}
              required
              fullWidth
            />
          </div>
          <div className="row">
            <PhoneNumberField>
              <PhoneInput
                defaultCountry="KE"
                placeholder="701 234 567"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                inputComponent={MUIPhoneNumberInput}
                error={formErrors.phoneNumber}
                helperText={formErrors?.phoneNumber}
              />
            </PhoneNumberField>
            <LoginTextFields
              variant="outlined"
              margin="normal"
              label="Address (optional)"
              name="companyAddress"
              value={companyAddress}
              placeholder="e.g, CBD Tom Mboya Street, Nairobi"
              id="companyAddress"
              onChange={handleTextFieldChange}
              autoComplete="company address"
              error={formErrors.companyAddress}
              helperText={formErrors?.companyAddress}
              fullWidth
            />
          </div>
          <Row>
            <TwButton flat backBtn handleClick={handleBack} />
            <TwButton
              contained
              text="Create workspace"
              isLoading={isLoading}
              style={{ width: "50%" }}
              handleClick={handleCreateWorkspace}
              disabled={!companyName || !companyEmail || !phoneNumber}
            />
          </Row>
        </form>
      </AuthContentWrapper>
    </AuthPageWrapper>
  );
};

CompanyDetailsPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default CompanyDetailsPage;

const LoginTextFields = styled(StyledTextField)`
  && {
    width: 100%;
    margin: 1rem;
    @media (max-width: 414px) {
      margin: 0.5rem 0;
    }
  }
`;

const PhoneNumberField = styled.div`
  width: 95%;
  margin: 1rem;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 414px) {
    margin: 0.5rem 0;
  }
`;

const Row = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
`;
