/* eslint-disable */

import React, { useState, useContext } from "react";
import { auth } from "../../config/firebaseConfig";
import { UserContext } from "../../providers/UserProvider";
import { Link } from "@reach/router";
import { StyledTextField, useStyles, SignUpWrapper } from "./AuthStyles";
import { Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { sanitizeEmailAddress } from "./authHelpers";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [error, setError] = useState(null);
  const classes = useStyles();

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    }
  };

  const handleCloseAlert = (e) => {
    e.preventDefault();
    setEmailHasBeenSent(false);
    setError(null);
  };

  const sendResetEmail = async (event) => {
    event.preventDefault();

    /**
     *  - add settings to add a continue url ( adds a continue btn in the pswd reset UI ) and pass state
     *  - after a successfull pswd reset, users will be redirected to process.env.REACT_APP_AUTH_PWD_RESET_SUCCESS_URL
     *  - which is fetched from doppler and redirects to the signin component.
     *
     *  - see @link - https://firebase.google.com/docs/auth/web/passing-state-in-email-actions and https://stackoverflow.com/questions/45563604/firebase-reset-password-template-ui/50178308
     */
    const actionCodeSettings = {
      url: `${process.env.REACT_APP_AUTH_PWD_RESET_SUCCESS_URL}?email=${email}`,
      handleCodeInApp: false,
    };

    // sanitize the email input
    // remove all white space and convert the result to lowercase
    const sanitizedEmail = sanitizeEmailAddress(email);

    try {
      await auth.sendPasswordResetEmail(sanitizedEmail, actionCodeSettings);

      // notify the user that we've sent them a pswd reset email
      setEmailHasBeenSent(true);
    } catch (error) {
      console.error("**** err: send pswd reset ****", error);
      // extract the message from the error object
      const { message } = error;

      // show an alert with the message
      setError(message);
    }
  };
  return (
    <SignUpWrapper>
      <h1 className="text-xl text-center font-bold mb-3">
        Reset your Password
      </h1>
      <form action="">
        {emailHasBeenSent && (
          <Alert onClose={handleCloseAlert} severity="info">
            We have sent you an email with a link to reset your password
          </Alert>
        )}
        {error !== null && (
          <Alert onClose={handleCloseAlert} severity="error">
            {error}
          </Alert>
        )}
        <StyledTextField
          variant="outlined"
          margin="normal"
          label="Email address"
          name="userEmail"
          value={email}
          placeholder="E.g: abcd@example.com"
          id="email"
          onChange={(event) => onChangeHandler(event)}
          autoComplete="email"
          required
          fullWidth
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          className={classes.submit}
          onClick={(event) => {
            sendResetEmail(event);
          }}
          disabled={!email || emailHasBeenSent ? true : false}
        >
          Send me a reset link
        </Button>
      </form>

      <Link
        to="/"
        className="my-2 text-blue-700 hover:text-blue-800 text-center block"
      >
        &larr; back to sign in page
      </Link>
    </SignUpWrapper>
  );
};

export default PasswordReset;
