import { pickBy, identity } from 'lodash'
import { db } from "../config/firebaseConfig";

// Remove undefined values from incoming data
const cleanObject = (data) => {
  return pickBy(data, identity)
}

const getShortCode = function(inputStr) {
  let firstSix = inputStr.substring(0, 6); // grab the first 6 digits of the input string
  return firstSix.toUpperCase();
}

//write to the respective resource 
const createRecord = async ( payload, resourceLink, onSuccess, onFailure) => {
  const data = cleanObject(payload)
  const docRef = db.collection(resourceLink).doc()
  try {
    // Clean the object by removing undefined values and save the resource
    await docRef.set({...data, id:docRef.id,trackingCode:getShortCode(docRef.id)});//set the trackingCode when saving the order
    //pass docId on success
    onSuccess(docRef.id);
  } catch (error) {
    onFailure(error);
    console.error("Error:  request failed ", error);
  }
};

export default createRecord
