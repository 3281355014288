//styles the related to dashboard elements
const styles = {
  card: {
    textAlign: "center",
    width: "auto",
    display: "grid",
    gridTemplateColumns: "repeat(1, 2fr)",
    justifyContent: "center",
    padding: "1rem 2rem 1rem",
    margin: "0 1rem 0.5rem 1rem",
    boxShadow: "10",
    borderRadius: "1%",
  },
  section: {
    textAlign: "center",
    width: "auto",
    display: "grid",
    gridTemplateColumns: "repeat(1, 2fr)",
    justifyContent: "center",
    padding: "1rem 2rem 1rem",
    margin: "0 1rem 0.5rem 1rem",
    boxShadow: "10",
    borderRadius: "1%",
  },
  paper: {
    display: "grid",
    gridTemplateRows: "repeat(1, 2fr)",
    justifyContent: "space-evenly",
  },
  accordion: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(1, 2fr)",
    padding: "1rem",
  },
  text: {
    color: "#00acc1",
    width: "auto",
    display: "grid",
    gridTemplateColumns: "repeat(1, 2fr)",
    justifyContent: "center",
    padding: "1rem",
  },
  wrapper: {
    width: "100%",
  },
  header: {
    textAlign: "start",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 2fr)",
    textAlign: "start",
    paddingLeft: "2rem",
  },
  grey: {
    color: "grey",
  },
  table: {
    padding: "0.7rem",
    textAlign: "left",
    backgroundColor: "#00acc1",
    color: "white",
  },
  row: {
    fontWeight:"650"
  },
  bold: {
    fontWeight:"650"
  },
  button: {
    display: "flex",
    gap: "3rem",
  },
  main: {
    display: "grid",
    justifyContent:"center",
    margin:"0 3rem 0",
  },
};

export default styles;
