import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
//RA imports
import { TextInput, NumberInput, BooleanInput, SelectInput } from "react-admin";
import styled from "styled-components";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "auto",
    display:"flex",
    justifyContent: "center"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "60%",
    flexShrink: 0,
    fontWeight: "600"
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    gap: "2rem",
  },
  order: {
  fontWeight:"bold"
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export const dimensionsChoices = [
  {
    id: "xs",
    name: "Extra Small",
    dimensions: { width: "30", length: "25", height: "25", weight: 3 },
  },
  {
    id: "sm",
    name: "Small",
    dimensions: { width: "41", length: "30", height: "30", weight: 7 },
  },
  {
    id: "md",
    name: "Medium",
    dimensions: { width: "46", length: "46", height: "41", weight: 17 },
  },
  {
    id: "lg",
    name: "Large",
    dimensions: { width: "46", length: "46", height: "61", weight: 26 },
  },
  {
    id: "xlg",
    name: "Extra Large",
    dimensions: { width: "61", length: "46", height: "61", weight: 34 },
  },
];

export const RenderConsignmentSizeOptions = (sizeOption) => {
  // destructure sizeOption
  const {
    record: { name, id },
  } = sizeOption;

  // get option by filtering from dimensionsChoices the choice that matches the selected one.
  const option = dimensionsChoices.filter((choice) => choice.id === id)[0]
    .dimensions;

  // destructure option to get the dimensions
  const { length, width, height } = option;
  // styles
  const SizeOption = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
  `;

  const DimensionsText = styled.small`
    color: #707070;
  `;

  return (
    <SizeOption>
      <div>{name}</div>
      <DimensionsText>{`${length} x ${width} x ${height} LxWxH (cm)`}</DimensionsText>
    </SizeOption>
  );
};

export default function PackageDetailsAccordions() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div>
              <span className={classes.heading}>Package Details</span>
               <span className={classes.secondaryHeading}>(optional)</span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={12} sm={12} lg={10}>
            <Grid item lg={12}>
              <TextInput
                source="description"
                variant="outlined"
                helperText="*package description"
                fullWidth
                multiline
              />
              <SelectInput
                source="size"
                label="Size"
                variant="outlined"
                optionValue="name"
                optionText={<RenderConsignmentSizeOptions />}
                choices={dimensionsChoices}
                fullWidth
              />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={8}>
                  <NumberInput
                    source="numberOfPackages"
                    type="number"
                    variant="outlined"
                    InputProps={{ inputProps: { min: 0} }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={3}>
                  <BooleanInput source="fragile" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
