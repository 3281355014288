import React from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import notFound from "../assets/404.svg";

const PageNotFound = () => {
  return (
    <Wrapper>
      <img src={notFound} alt="404" width="45%" />
      <h1>Page Not Found</h1>
      <p>Sorry, we cannot find the page you are looking for</p>
      <GoBackHomeBtn
        variant="contained"
        color="primary"
        disableElevation
        onClick={() => window.location.assign("/home")}
      >
        <span>BACK TO HOME</span>
      </GoBackHomeBtn>
    </Wrapper>
  );
};

export default PageNotFound;

const Wrapper = styled.main`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  background-image: linear-gradient(
    to right,
    rgba(255, 0, 0, 0),
    rgba(220, 251, 255, 1)
  );
  h1,
  p {
    text-align: center;
    line-height: 1.6;
    letter-spacing: 1px;
  }
  h1 {
    margin-top: 3rem;
    font-family: "Ubuntu", sans-serif;
    font-size: 3rem;
    font-weight: 600;
  }
  p {
    margin-bottom: 3rem;
    color: #707070;
    font-size: 1rem;
    font-weight: 600;
    font-family: "Fira Sans", sans-serif;
  }
  @media (max-width: 1024px) {
    h1 {
      font-size: 2rem;
    }
    img {
      width: 80%;
    }
  }
`;

const GoBackHomeBtn = styled(Button)`
  span {
    color: #fff;
    font-weight: 600;
    font-family: "Fira Sans", sans-serif;
  }
`;
