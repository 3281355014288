import React from "react";
import { db } from "../config/firebaseConfig";
import { ProfileContext } from "../context/ProfileContext";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Notification } from 'react-admin';
import { onMessageListener } from "../config/fcm-notifications";
import { useHistory } from "react-router-dom";

const TwNotification = (props) => {
  const { profile } = React.useContext(ProfileContext);
  const [notification, setNotification] = React.useState([]);
  const [message, setMessage] = React.useState([]);
  const [open, setOpen] = React.useState(true);
  const [orderId, setOrderId] = React.useState([]);
  const history = useHistory();


  React.useEffect(() => {
    db.collection("notifications")
      .where("spAdmins", "array-contains", profile.admin.userId)
      .where("isRead", "==", false)
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            const spAdmins = change.doc.data().spAdmins;
            const notificationData = change.doc.data();
            // check if this spAdmins array contains userId of the current
            // logged in SP. If so , we show a FE banner notification to this user
            if (spAdmins.includes(profile.admin.userId)) {
              onMessageListener()
                .then(async (payload) => {
                  setNotification(true);
                  console.log(payload);
                  // construct message for foreground notification
                  // for foreground notifications, we will use the payload we receive from the notifications.js backend file
                  setMessage(payload.data.body);
                  setOrderId(notificationData.orderId);
                  //update isRead value to true once we show the notification
                  const notificationSnap = change.doc;
                  notificationSnap.ref.update({isRead:true});
                })
                .catch((err) => {
                  setNotification(false);
                  console.log("failed: ", err);
                });
            }
          }
        });
      });
  }, [profile.admin.userId]);

  const handleRedirectToPage = async () => {
    // handle redirecting to the actual order show view
    // this is only available for foregroud notifications
    console.log(`Redirecting to actual order view`);
    history.push(`/consignments/${orderId}/show`);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
     <Notification {...props} autoHideDuration={5000} />  {/* we have to add this as per the react-admin docs */}
      {notification === true ? <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={10000}
        onClose={handleClose}
        message={message}
        action={
          <>
            <Button
              color='secondary'
              size='small'
              onClick={handleRedirectToPage}
            >
              View Order
            </Button>
            <IconButton
              size='small'
              aria-label='close'
              color='inherit'
              onClick={handleClose}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </>
        }
      /> : null }
    </>
  ) 
};

export default TwNotification;
