import React from "react";
import { ReferenceField, TextField } from "react-admin";

import FullNameField from "./FullNameField";

const UserReferenceField = (props) => {
  return props.type === "individual" ? (
    // we set the link to false on these userreference fields to prevent the 
    // name fields from linking to other pages.
    <ReferenceField source="userId" reference="users" {...props} link={false}>
      <FullNameField />
    </ReferenceField>
  ) : (
    <ReferenceField source="businessId" reference="businesses" {...props} link={false}>
      <TextField source="name" />
    </ReferenceField>
  );
};

UserReferenceField.defaultProps = {
  addLabel: true,
};

export default UserReferenceField;
