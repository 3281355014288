/* eslint-disable */
import * as React from "react";
import { Layout } from "react-admin";
import TwAppBar from "./TwAppBar";
import TwMenu from "./TwMenu";
import ErrorBoundary from "./ErrorBoundary";
import TwNotification from "./TwNofitication";

const TwLayout = (props) => (
  <Layout {...props} appBar={TwAppBar} menu={TwMenu} error={ErrorBoundary} notification={TwNotification} />
);

export default TwLayout;
