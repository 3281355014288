import React, { useContext } from "react";
import {
	SimpleShowLayout,
	useShowController,
	Show,
	Toolbar,
	SaveButton,
	EditButton,
	FunctionField,
	ReferenceManyField,
	Datagrid,
	TextField,
	ReferenceField,
	DateField,
	ChipField,
	TopToolbar,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Avatar } from "@material-ui/core";
import ResendInviteButton from "../../../../components/ResendInviteButton";
import InviteStatus from "../../../../components/InviteStatus";
import { Grid, Typography, Card } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { ProfileContext } from "../../../../context/ProfileContext";
import { CardContent, CardActions } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		alignItems: "center",
		textAlign: "center",
	},
	paper: {
		padding: theme.spacing(2),
		margin: "auto",
		maxWidth: 500,
	},
	image: {
		width: 100,
		height: 100,
		display: "flex",
		justifyContent: "center",
	},
	label: {
		marginTop: "10px",
		display: "flex",
		fontSize: "10px",
		width: "100%",
		color: "#707070",
	},
	img: {
		margin: "auto",
		display: "block",
		maxWidth: "100%",
		maxHeight: "100%",
	},
	detail: {
		fontWeight: "bold",
	},
	text: {
		fontSize: "1rem",
		fontWeight: "bold",
		color: "black",
		display: "flex",
		justifyContent: "center",
	},
	toolbar: {
		display: "flex",
		justifyContent: "space-between",
		gap: "2rem",
	},
	main: {
		display: "flex",
		justifyContent: "center",
	},
	divider: {
		margin: "0.4rem 3rem 0",
	},
	wrapper: {
		display: "grid",
		justifyContent: "center",
		marginTop: "0.5rem",
		gap: "0.5rem",
	},
	card: {
		margin: "0.5rem",
		borderRadius: "10px",
	},
	order: {
		margin: "0.5rem",
	},
	list: {
		margin: "0.5rem",
		borderRadius: "10px",
		overflowX: "auto",
	},
}));

const AgentsShow = (props) => {
	const classes = useStyles();
	const { profile } = useContext(ProfileContext);
	const companyId = profile.company.id;
	const isSmall = useMediaQuery(`(max-width:950px)`);

	const RecentOrderListView = () => (
		<div>
			<ReferenceManyField
				label="Received Consignments"
				reference="consignments"
				target="agent.id"
			>
				<Card className={classes.list}>
					<Typography
						color="textSecondary"
						variant="subtitle1"
						className={classes.order}
					>
						Recent orders history
					</Typography>
					<Datagrid>
						<TextField label="Order#" source="trackingCode" />
						<ReferenceField
							label="Sender"
							reference="customers"
							source="sender.id"
							link="show"
						>
							<TextField source="name" />
						</ReferenceField>
						<TextField label="Origin" source="origin.name" />
						<ReferenceField
							label="Recipient"
							reference="customers"
							source="recipient.id"
							link="show"
						>
							<TextField source="name" />
						</ReferenceField>
						<TextField label="Destination" source="destination.name" />

						<DateField label="Delivery date" source="date" />
						<ChipField label="Status" source="status.value" />
					</Datagrid>
				</Card>
			</ReferenceManyField>
		</div>
	);

	const {
		record, // record fetched via dataProvider.getOne() based on the id from the location
	} = useShowController(props);

	const UserEditToolbar = (props) => (
		<Toolbar {...props}>
			<SaveButton />
		</Toolbar>
	);

	const ShowViewActions = ({ basePath, data }) => (
		<TopToolbar className={classes.toolbar}>
			<EditButton
				label="All Agents"
				icon={<ArrowBackIosIcon />}
				color="primary"
				to={`//companies/${companyId}/agents`}
				size="medium"
			/>
			{/* <EditButton basePath={basePath} record={data} /> */}
		</TopToolbar>
	);

	return (
		<Show
			{...props}
			title="Agent/Details"
			actions={<ShowViewActions />}
			toolbar={<UserEditToolbar />}
		>
			<SimpleShowLayout>
				<Grid container spacing={1}>
					{record !== undefined && (
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Card className={classes.card}>
								<div className={classes.wrapper}>
									<Avatar
										alt={record.firstName}
										className={classes.image}
										// src="https://i.pravatar.cc/150?img=70"
									/>
									<FunctionField
										className={classes.text}
										render={(record) =>
											record.name
												? record.name
												: `${record.firstName} ${record.lastName}`
										}
									/>
									<React.Fragment>
										<div className={classes.text}>
											<FunctionField
												className={classes.main}
												label="Invite status"
												render={(record) => (
													<InviteStatus
														label="Invite Status"
														inviteConfirmed={record.inviteStatus}
													/>
												)}
											/>
										</div>
									</React.Fragment>
								</div>
								<Divider className={classes.divider} />
								<CardContent>
									<Grid container spacing={2} className={classes.main}>
										<Grid item xs={12}>
											<Typography
												variant="subtitle2"
												color="textSecondary"
												component="p"
												className={classes.main}
											>
												Contact:{" "}
											</Typography>
											<div>
												<TextField
													className={classes.text}
													source="phoneNumber"
												/>
											</div>
										</Grid>
										<Grid item xs={12}>
											<Typography
												variant="subtitle2"
												color="textSecondary"
												component="p"
												className={classes.main}
											>
												National Identification:{" "}
											</Typography>
											<div className={classes.text}>
												{record.nationalIDNumber}
											</div>
										</Grid>
										<Grid item xs={12}>
											<Typography
												variant="subtitle2"
												color="textSecondary"
												component="p"
												className={classes.main}
											>
												Vehicle Type:{" "}
											</Typography>
											<div className={classes.text}>
												{record.vehicleType || "N/A"}
											</div>
										</Grid>
										<Grid item xs={12}>
											<Typography
												variant="subtitle2"
												color="textSecondary"
												component="p"
												className={classes.main}
											>
												Vehicle Registration:{" "}
											</Typography>
											<div className={classes.text}>
												{record.registrationNumber || "N/A"}
											</div>
										</Grid>
										<Grid item xs={12}>
											<Typography
												variant="subtitle2"
												color="textSecondary"
												component="p"
												className={classes.main}
											>
												Driving License:{" "}
											</Typography>
											<div className={classes.text}>
												{record.drivingLicenseNo || "N/A"}
											</div>
										</Grid>
										<Grid item xs={12}>
											<Typography
												variant="subtitle2"
												color="textSecondary"
												component="p"
												className={classes.main}
											>
												Email:{" "}
											</Typography>
											<div className={classes.text}>
												{record.emailAddress || "N/A"}
											</div>
										</Grid>
									</Grid>
								</CardContent>
								<CardActions className={classes.main}>
									{/* <ResendInviteButton
										path={props.resource}
										docId={props.id}
										inviteConfirmed={record.inviteConfirmed}
									/> */}
								</CardActions>
							</Card>
						</Grid>
					)}
					{!isSmall ? <RecentOrderListView /> : null}
				</Grid>
			</SimpleShowLayout>
		</Show>
	);
};

export default AgentsShow;
