import React,{ useContext, useState,useEffect} from 'react'
import { ProfileContext } from "../../../../context/ProfileContext";
import {
  TextInput,
} from "react-admin";
import { validateName } from "../../../../utils/validators";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CountryDropdownField from "../../../../layout/TwCountryDropdownField";
import PhoneField from "../../../../layout/TwPhoneNumberInput";
import { db } from "../../../../config/firebaseConfig";

const BizCustomerForm = (props) => {
    const { profile } = useContext(ProfileContext);
    const [customers, setCustomers] = useState([]);
      useEffect(() => {
      const fetchCustomers= async (companyId) => {
        let custObjectsList = [];
        const custList = (
          await db
            .collection("customers")
            .where("companyId", "==", companyId)
            .get()
        ).docs;
  
        custList.forEach((cust) => {
          const custData = {
            id: cust.id,
            ...cust.data(),
          };
  
          custObjectsList = [...custObjectsList, custData];
        });
  
        setCustomers(custObjectsList);
      };
  
      fetchCustomers(profile.company.id);
    }, [profile.company.id]);
  
    return(
    <div style={{ width: "100%" }}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{ backgroundColor: "#b6b6b6", borderRadius: "10px" }}
        >
          <Typography variant="h6" gutterBottom>
            Customer information
          </Typography>
        </Grid>
        <Grid container spacing={2} item xs={12} sm={6}>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="firstName"
              validate={validateName}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="lastName"
              validate={validateName}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput source="emailAddress" fullWidth />
          </Grid>
          <Grid item xs={12} sm={12}>
            <PhoneField
              placeholder="phone"
              name="phoneNumber" 
              defaultValue={props.record.phoneNumber}
              data={customers}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput multiline source="bio" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput source="address" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput source="city" fullWidth />
          </Grid>
          <Grid item xs={12} style={{ display: "flex" }}>
            <CountryDropdownField
              name="country"
              placeholder="Country"
            />
          </Grid>
  
          <Grid item xs={12} sm={12}>
            <TextInput source="nearestLandmark" fullWidth />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )};
  export default BizCustomerForm;