import React, { useContext } from "react";
import { ProfileContext } from "../../context/ProfileContext";
import {
  Create,
  SimpleForm,
  TextInput,
  useRedirect,
  useNotify,
  TabbedForm,
  FormTab,
} from "react-admin";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { validateName } from "../../utils/validators";
import { HiddenTextInput } from "../../components/hiddenTextInput";
import { functions } from "../../config/firebaseConfig";
import { useStyles } from "../../components/Orders/PackageDetails";
import { validateEmail } from "../../utils/validators";
import SaveCompanyOnSaveToolbar from "../../components/saveCompanyOnSave";
import PhoneField from "../../layout/TwPhoneNumberInput";
import createUser from "../../utils/createUser";
import PageHeader from "../../components/PageHeader";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import throttle from "lodash/throttle";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import parse from "autosuggest-highlight/parse";
import PropTypes from "prop-types";

//renders the location option while typing
export function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

export const autocompleteService = { current: null };

const CustomerCreate = (props) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const notify = useNotify();
  const { profile } = useContext(ProfileContext);
  const serviceProviderId = profile.company.id;
  let cachedProfile = JSON.parse(localStorage.getItem("cachedProfile"));
  const compType = profile.admin
    ? profile.admin.companyType
    : cachedProfile.admin.companyType;
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  // uses google api keys to autosuggest the current location
  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  //makes request to google places library for each location
  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  //updates the location based on the input values
  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const onSuccess = () => {
    notify("The customer profile was created successfully","success");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify(error, "warning");
    console.log(error);
  };

  //saves the customers record and address value to the DB
  const saveCustomer = async (record,value) => {
    const { name, emailAddress, phoneNumber, bio } = record;
    const customerUserData = {
      name,
      emailAddress,
      phoneNumber,
      bio,
      address: value.structured_formatting.main_text,
    };
    const customerData = {
      serviceProviders: serviceProviderId,
      name: name,
      address: value.structured_formatting.main_text,
      placeId: value.place_id,
      ...record,
    };
    // Save customer data to the users collection and the rest in customers
    try {
      // save Customer's user data
      createUser(
        customerUserData,
        customerData,
        props.resource,
        onSuccess,
        onFailure
      );
    } catch (error) {
      onFailure(error);
      console.log(error);
    }
  };

  const WrappedSimpleForm = () => {
    const saveInvite = async (record) => {
      //save business data to invites collection
      console.log(record, "this is the record");
      const inviteData = {
        bsName: record.businessName,
        bsEmailAddress: record.businessEmailAddress,
        bsPhoneNumber: record.phoneNumber,
        spId: serviceProviderId,
        spAdminDocId: profile.admin.id,
      };
      let inviteBusiness = functions.httpsCallable("createBusinessInvite");
      await inviteBusiness(inviteData)
        .then(function (result) {
          if (result.data === false) {
            notify("Invite record already exists. Please update your invite", "success");
          } else {
            notify("Business Invite was sent successfully.", "success");
            redirect("list", props.basePath);
          }
        })
        .catch(function (error) {
          console.log(error);
          notify(`Error inviting business`, 'warning');
        });
    };

    return (
      <SimpleForm
        save={saveInvite}
        toolbar={<SaveCompanyOnSaveToolbar />}
        warnWhenUnsavedChanges
      >
        <div style={{ width: "100%" }}>
          <Grid container spacing={2}>
            <PageHeader title="Business information" />
            <Grid container spacing={2} item xs={12} sm={8}>
              <Grid item sm={12} md={6} xs={12}>
                <TextInput
                  source="businessName"
                  fullWidth
                  validate={validateName}
                  variant="outlined"
                />
              </Grid>
              <Grid item sm={12} md={6} xs={12}>
                <TextInput
                  source="businessEmailAddress"
                  fullWidth
                  placeholder="Email Address"
                  variant="outlined"
                />
              </Grid>
              <Grid item sm={12} md={6} xs={12}>
                <PhoneField placeholder="phone" name="phoneNumber" />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    );
  };

  return (
    <Create
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Customer/Create"
    >
      <TabbedForm toolbar={false}>
        <FormTab label="individual">
          <SimpleForm
            style={{ width: "100%" }}
            save={(record) =>
                saveCustomer(record, value)
            }
            toolbar={<SaveCompanyOnSaveToolbar />}
            warnWhenUnsavedChanges
          >
            <div style={{ width: "100%" }}>
              <Grid container spacing={2}>
                <PageHeader title="Customer information" />
                <Grid container spacing={2} item xs={12} sm={6}>
                  <Grid item xs={12} sm={12}>
                    <TextInput
                      source="name"
                      validate={validateName}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={5}>
                    <TextInput
                      source="lastName"
                      validate={validateName}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid> */}
                  <Grid item xs={12} sm={10}>
                    <PhoneField placeholder="phone" />
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    {/* custom MUI Autocomplete address field component */}
                    <Autocomplete
                      id="google-map-demo"
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.description
                      }
                      filterOptions={(x) => x}
                      options={options}
                      autoComplete
                      includeInputInList
                      filterSelectedOptions
                      value={value}
                      onChange={(event, value) => {
                        setOptions(value ? [value, ...options] : options);
                        setValue(value);
                      }}
                      onInputChange={(event, inputValue) => {
                        setInputValue(inputValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Address"
                          placeHolder="Residence"
                          variant="outlined"
                          required
                          fullWidth
                        />
                      )}
                      renderOption={(option) => {
                        const matches =
                          option.structured_formatting
                            .main_text_matched_substrings;
                        const parts = parse(
                          option.structured_formatting.main_text,
                          matches.map((match) => [
                            match.offset,
                            match.offset + match.length,
                          ])
                        );

                        return (
                          <Grid container alignItems="center">
                            <Grid item>
                              <LocationOnIcon className={classes.icon} />
                            </Grid>
                            <Grid item xs>
                              {parts.map((part, index) => (
                                <span
                                  key={index}
                                  style={{
                                    fontWeight: part.highlight ? 700 : 400,
                                  }}
                                >
                                  {part.text}
                                </span>
                              ))}

                              <Typography variant="body2" color="textSecondary">
                                {option.structured_formatting.secondary_text}
                              </Typography>
                            </Grid>
                          </Grid>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <Accordion style={{ margin: "0.6rem 0 1rem" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <div>
                          <span className={classes.heading}>
                            Additional Details
                          </span>{" "}
                          <span className={classes.secondaryHeading}>
                            (optional)
                          </span>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid item xs={12} sm={12} lg={10}>
                          <Grid item lg={12}>
                            <TextInput
                              source="emailAddress"
                              variant="outlined"
                              validate={validateEmail}
                              fullWidth
                              placeholder="johndoe@example.com"
                            />
                          </Grid>
                          <Grid item lg={12}>
                            <TextInput
                              variant="outlined"
                              source="bio"
                              fullWidth
                              placeholder="Add any descriptive information.."
                            />
                            <TextInput
                              source="nearestLandmark"
                              fullWidth
                              variant="outlined"
                              placeholder="Building, street or apartment name.."
                            />
                            <TextInput source="city" variant="outlined" />
                            <TextInput source="country" variant="outlined" />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={12} style={{ display: "flex" }}>
                    <HiddenTextInput source="type" initialValue="individual" />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </SimpleForm>
        </FormTab>
        {compType === "serviceProvider" ? (
          <FormTab label="business">
            <WrappedSimpleForm />
          </FormTab>
        ) : null}
      </TabbedForm>
    </Create>
  );
};
CustomerCreate.propTypes = {
    basePath: PropTypes.string.isRequired,
    resource: PropTypes.string.isRequired,
};
export default CustomerCreate;
