import styled from "styled-components";

export const AuthHomeTodoCard = styled.div`
  height: auto;
  width: 100%;
  padding: 1.5rem;
  background-color: #fff;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  span {
    color: #000;
  }
  .todo {
    font-weight: 300;
    color: #707070;
  }
  .completed {
    text-decoration: line-through;
  }
  .row {
    display: flex;
    flex-direction: row;
    h1,
    p {
      margin: 0;
    }
    span {
      margin: 0;
      margin-right: 0.5rem;
    }
  }
  .helper-text {
    margin-top: -1.9rem;
    background-color: #f7f7f7;
    border-radius: 0.23rem;
    padding: 0.8rem;
    span {
      font-family: "Fira Sans", sans-serif;
      font-size: 0.8rem;
      line-height: 1.8;
      color: #707070;
      font-weight: 300;
    }
  }
  @media (max-width: 1024px) {
    h1,p,span {
      margin: 2rem 0;
    }
  }
`;

export const GetStartedCard = styled.div`
height: 90%;
width: 60%;
padding: 2rem;
background-color: #fff;
box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
border-radius: 0.5rem;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
img {
  height: 30%;
  margin-bottom: 3rem;
}
h1,
p {
  text-align: center;
  line-height: 1.8;
}
h1 {
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "Ubuntu", sans-serif;
}
p {
  font-size: 0.9rem;
  font-family: "Fira Sans", sans-serif;
  color: #707070;
}
@media (max-width: 1024px) {
  width: 80%;
  padding: 1rem;
  img {
    height: 20%;
    margin-bottom: 1.5rem;
  }
  h1 {
    font-size: 1.2rem;
  }
  p {
    font-size: 0.8rem;
  }
}
`;
