import styled from "styled-components";

const AuthContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 3rem;
  width: 70%;
  hr {
    width: 80%;
    margin: 2rem auto;
    height: 1px;
    background-color: #d8d8d8;
    border: 0 none;
  }
  h1,
  p {
    text-align: left;
  }
  h1 {
    font-size: 2rem;
    font-weight: 600;
    font-family: "Ubuntu", sans-serif;
    margin: 0.5rem 0;
  }
  #setup {
    font-size: 1rem;
    font-weight: 500;
    color: #707070;
    font-family: "Fira Sans", sans-serif;
    margin-bottom: 3rem;
  }
  form {
    width: 100%;
  }
  #morning {
    font-size: 1.5rem;
    font-weight: 600;
    font-family: "Fira Sans", sans-serif;
    margin: 0.5rem;
  }
  #welcome {
    font-size: 1rem;
    font-weight: 500;
    color: #707070;
    font-family: "Fira Sans", sans-serif;
    margin-bottom: 3rem;
  }
  .helper-text {
    margin-bottom: 1rem;
    background-color: #f7f7f7;
    border-radius: 0.5rem;
    padding: 0.5rem;
    span {
      font-family: "Fira Sans", sans-serif;
      font-size: 0.8rem;
      line-height: 1.8;
      color: #707070;
    }
  }
  .row {
    display: flex;
    flex-direction: row;
  }
  @media (max-width: 1024px) {
    width: 100vw;
    justify-content: center;
    h1,
    p {
      text-align: center;
    }
    .row {
      display: flex;
      flex-direction: column;
    }
    h1 {
      font-size: 1.3rem;
      margin: 0.2rem;
    }
    hr {
      display: none;
    }
    #morning {
      font-size: 1rem;
      margin: 0.2rem;
    }
    #welcome, #setup {
      font-size: 0.8rem;
      margin-bottom: 1.5rem;
    }
    .helper-text {
      margin: auto auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span {
        font-size: 0.8rem;
      }
    }
  }
  @media (max-width: 414px) {
    h1 {
      font-size: 1rem;
    }
    #morning,
    #welcome,
    #setup {
      display: none;
    }
  }
`;

export default AuthContentWrapper;
