import React, { useContext, cloneElement, useState } from "react";
import { ProfileContext } from "../../context/ProfileContext";
import {
	List,
	Show,
	SimpleShowLayout,
	TextField,
	Edit,
	SimpleForm,
	TextInput,
	EditButton,
	Filter,
	useRedirect,
	useNotify,
	ReferenceManyField,
	ReferenceField,
	DateField,
	TopToolbar,
	CreateButton,
	ExportButton,
	useQuery,
	Loading,
	FunctionField,
	Pagination,
	ChipField,
	useRefresh,
	useShowController,
	ListContextProvider,
} from "react-admin";
import {
	Tabs,
	Tab,
	Grid,
	Typography,
	Button,
	Card,
	CardHeader,
	Avatar,
} from "@material-ui/core";
import SaveCompanyOnSaveToolbar from "../../components/saveCompanyOnSave";
import EmptyListPage from "../../components/EmptyListPage";
import CustomerIcon from "@material-ui/icons/PeopleOutline";
import PhoneField from "../../layout/TwPhoneNumberInput";
import exporter from "../../components/customDataExporter";
import useFetchCurrentUserPermissions from "../../components/useFetchCurrentUserPermissions";
import getResourceLink from "../../routes/resource-names";
import { db } from "../../config/firebaseConfig";
import CustomerDetailsCard from "../../components/customers/CustomerDetailsCard";
import Paper from "@material-ui/core/Paper";
import keyBy from "lodash/keyBy";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PageHeader from "../../components/PageHeader";
import BioReferenceField from "./Agents/components/AgentBioField";
import UserReferenceField from "../../components/customers/UserReferenceField";
import StyledTableHeader from "../../layout/TableHeader";
import InviteDetailsCard from "../../components/invites/InviteDetailsCard";
import { useStyles } from "../../components/Orders/PackageDetails";
import { useMediaQuery } from "@material-ui/core";
import CustomerCard from "../../components/userComponent/CustomerCard";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import InviteCard from "../../components/userComponent/InviteCard";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { makeStyles } from "@material-ui/core/styles";

const CustomersFilter = (props) => (
	<Filter {...props}>
		<TextInput
			label="phone number"
			placeholder="Search ..."
			source="phoneNumber"
			alwaysOn
			variant="outlined"
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon style={{ fill: "grey" }} />
					</InputAdornment>
				),
			}}
		/>
	</Filter>
);

export const InvitesEdit = (props) => {
	const notify = useNotify();
	const redirect = useRedirect();
	const onSuccess = () => {
		notify("The Invite record was updated successfully", "success");
		redirect("show", `${props.basePath}/${props.id}/show`);
	};

	const onFailure = (error) => {
		notify("The invite record was not updated!", "warning");
		console.log(error);
	};

	return (
		<Edit
			{...props}
			onSuccess={onSuccess}
			undoable={false}
			onFailure={onFailure}
			title="Invite/Edit"
		>
			<SimpleForm
				// save={editInvite}
				toolbar={<SaveCompanyOnSaveToolbar />}
				warnWhenUnsavedChanges
			>
				<div style={{ width: "100%" }}>
					<Grid container spacing={2}>
						<PageHeader title="Invite information" />
						<Grid container spacing={2} item xs={12} sm={6}>
							<Grid item xs={12} sm={12}>
								<TextInput source="businessName" fullWidth variant="outlined" />
							</Grid>
							<Grid item xs={12} sm={12}>
								<TextInput
									source="businessEmailAddress"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<FunctionField
									label="Phone"
									render={(record) =>
										record.businessPhoneNumber !== undefined ? (
											<PhoneField defaultValue={record?.businessPhoneNumber} />
										) : null
									}
								/>
							</Grid>
						</Grid>
					</Grid>
				</div>
			</SimpleForm>
		</Edit>
	);
};

export const InvitesShow = (props) => {
	const classes = useStyles();

	const ShowViewActions = ({ basePath, data }) => (
		<TopToolbar className={classes.toolbar}>
			<EditButton
				label="All Customers"
				icon={<ArrowBackIosIcon />}
				color="primary"
				to={"/customers"}
				size="medium"
			/>
			<EditButton basePath={basePath} record={data} />
		</TopToolbar>
	);

	const {
		record, // record fetched via dataProvider.getOne() based on the id from the location
	} = useShowController(props);

	return (
		<Show {...props} title="Invite/Show" actions={<ShowViewActions />}>
			<SimpleShowLayout>
				<InviteDetailsCard record={record} />
			</SimpleShowLayout>
		</Show>
	);
};

const InvitesList = () => {
	const notify = useNotify();
	const { profile } = useContext(ProfileContext);
	const serviceProviderId = profile.company.id;
	const [page, setPage] = useState(1);
	const perPage = 5;
	const refresh = useRefresh();
	const redirect = useRedirect();
	const isSmall = useMediaQuery(`(max-width:950px)`);
	const { data, total, loading, error } = useQuery({
		type: "getList",
		resource: "invites",
		payload: {
			pagination: { page, perPage },
			filter: { spId: serviceProviderId },
		},
	});

	if (loading) {
		return <Loading />;
	}
	if (error) {
		return <p>ERROR: {error}</p>;
	}

	const deleteInvite = (id) => {
		// this is just a soft delete, and in this case we just update and
		// add a deleted:true field
		db.collection("invites")
			.doc(id)
			.update({
				deleted: true,
			})
			.then(() => {
				notify("Invite record deleted", "success");
				refresh();
			})
			.catch((e) => {
				console.log("error deleting invite record ", e);
				notify("Invite record was not deleted", "warning");
			});
	};

	return (
		<>
			<ListContextProvider
				value={{
					data: keyBy(data, "id"),
					ids: data.map(({ id }) => id),
					total,
					page,
					perPage,
					setPage,
					currentSort: { field: "createdAt", order: "DESC" },
					selectedIds: [],
				}}
			>
				{isSmall ? (
					<InviteCard />
				) : (
					<>
						<StyledTableHeader
							data={keyBy(data, "id")}
							ids={data.map(({ id }) => id)}
							total={total}
						>
							<TextField source="businessName" label="Business Name" />
							<TextField source="businessEmailAddress" label="Email Address" />
							<TextField source="businessPhoneNumber" label="Phone Number" />
							<ChipField source="status" label="Invite Status" />
							{/* Here we filter this createdAt field by the spId to avoid scenarions where for a newly created SP,
          we see this createdAt field with some values. This is a strange error as we are initally filtering this Datagrid
          by the spId.  */}
							<FunctionField
								label="Invited on"
								render={(record) =>
									record.spId === serviceProviderId
										? new Date(record.inviteDateTime * 1).toLocaleString()
										: "N/A"
								}
							/>

							{/* define a custom show button */}
							<FunctionField
								render={(record) =>
									record.spId === serviceProviderId ? (
										<Button
											color="primary"
											startIcon={<VisibilityIcon />}
											onClick={() => {
												redirect("show", "/invites", record.id);
											}}
										>
											Show
										</Button>
									) : null
								}
							/>

							{/* define a custom delete icon button */}
							{/* This is because using react-admins default delete button would affect data in the 
          customers collection as opposed to the invites collection which is what we want in this case */}
							{/* Here we also filter the delete Button by spId to avoid scenarios wherer we see this delete button on records 
          that dont belong to the current sp */}
							<FunctionField
								render={(record) =>
									record.spId === serviceProviderId ? (
										<Button
											color="secondary"
											startIcon={<DeleteIcon />}
											onClick={() => {
												deleteInvite(record.id);
											}}
										>
											Delete
										</Button>
									) : null
								}
							/>
						</StyledTableHeader>
						<Pagination />
					</>
				)}
			</ListContextProvider>
		</>
	);
};

export const CustomerList = (props) => {
	const { profile } = useContext(ProfileContext);
	const serviceProviderId = profile.company.id;
	const { formattedPermissions } = useFetchCurrentUserPermissions();
	const resourceName = getResourceLink(serviceProviderId);
	let cachedProfile = JSON.parse(localStorage.getItem("cachedProfile"));
	const compType = profile.admin
		? profile.admin.companyType
		: cachedProfile.admin.companyType;
	const isSmall = useMediaQuery(`(max-width:950px)`);

	const ListActions = (props) => (
		<TopToolbar>
			{cloneElement(props.filters, { context: "button" })}
			{/* <CreateButton /> */}
			<ExportButton />
		</TopToolbar>
	);

	const customersExporter = (customers) =>
		exporter({
			resourceArr: customers,
			serviceProviderId: serviceProviderId,
			resourceType: "customers",
		});

	const [inviteTabValue, setInviteTabValue] = React.useState(0);

	const handleTabChange = (event, newInviteTabValue) => {
		setInviteTabValue(newInviteTabValue);
	};

	const InvitesPagination = () => {
		// we return an empty component to override the default pagination we get from the
		// customers resource
		return null;
	};

	const useStyles = makeStyles((theme) => ({
		text: {
			fontStyle: "italics",
		},
	}));

	const classes = useStyles();

	return (
		<>
			{compType === "serviceProvider" ? (
				<Paper square>
					<Tabs
						value={inviteTabValue}
						indicatorColor="primary"
						textColor="primary"
						onChange={handleTabChange}
						variant="fullWidth"
					>
						<Tab label="Customers" />
						<Tab label="Invites" />
					</Tabs>
				</Paper>
			) : null}
			{inviteTabValue === 0 ? (
				<List
					{...props}
					title="Customers"
					actions={<ListActions />}
					sort={{ field: "createdAt", order: "DESC" }}
					filters={<CustomersFilter />}
					filter={{ serviceProviders: serviceProviderId }}
					empty={<EmptyListPage icon={CustomerIcon} />}
					bulkActionButtons={false}
					exporter={
						formattedPermissions.customers &&
						formattedPermissions.customers.list
							? customersExporter
							: false
					}
				>
					{isSmall ? (
						<CustomerCard />
					) : (
						<StyledTableHeader rowClick="show">
							{/* Customer data field */}
							<FunctionField
								label="Customer details"
								render={(record) =>
									record.type == "business" ? (
										<UserReferenceField />
									) : (
										<CardHeader
											avatar={
												<Avatar alt={record.name} className={classes.image} />
											}
											title={`${record.name}`}
											subheader={
												<>
													<div>
														<Typography
															variant="caption"
															display="block"
															className={classes.text}
														>
															{record.emailAddress}
														</Typography>
													</div>
													<div>
														<Typography
															variant="caption"
															display="block"
															className={classes.text}
														>
															{record.phoneNumber}
														</Typography>
													</div>
												</>
											}
										/>
									)
								}
							/>

							{/* customer type field */}
							<FunctionField
								label="Type"
								render={(record) =>
									record.type == "business" ? (
										<ChipField source="type" />
									) : (
										<ChipField source="type" />
									)
								}
							/>
							<FunctionField
								label="Added at"
								render={(record) =>
									record.createdAt
										? new Date(record.createdAt * 1).toLocaleString()
										: "N/A"
								}
							/>
							{/* {formattedPermissions[resourceName.admins] &&
								formattedPermissions[resourceName.admins].edit && (
									<EditButton />
								)} */}
						</StyledTableHeader>
					)}
				</List>
			) : (
				// show invites list inside invites tab
				<List
					{...props}
					empty={<InvitesList />}
					pagination={<InvitesPagination />}
				>
					<InvitesList />
				</List>
			)}
		</>
	);
};

export const CustomerShow = (props) => {
	const classes = useStyles();
	const isSmall = useMediaQuery(`(max-width:950px)`);

	const RecentOrderListView = () => (
		<Grid item xs={10} sm={8} md={8} lg={6}>
			<ReferenceManyField
				label="Sent Orders"
				reference="consignments"
				target="sender.id"
			>
				<Card
					style={{ margin: "0.5rem", borderRadius: "10px", overflowX: "auto" }}
				>
					<Typography
						color="textSecondary"
						variant="caption"
						style={{ margin: "0.4rem" }}
					>
						Sent Orders
					</Typography>
					<StyledTableHeader>
						<DateField source="date" className={classes.order} />
						<ReferenceField
							label="Recipient"
							reference="customers"
							source="recipient.id"
							link="show"
						>
							<TextField source="name" className={classes.order} />
						</ReferenceField>
						<TextField
							label="Origin"
							source="origin.name"
							className={classes.order}
						/>
						<TextField
							label="Destination"
							source="destination.name"
							className={classes.order}
						/>
						<ChipField
							label="Status"
							source="status.value"
							className={classes.order}
						/>
					</StyledTableHeader>
				</Card>
			</ReferenceManyField>

			<ReferenceManyField
				label="Received Orders"
				reference="consignments"
				target="recipient.id"
			>
				<Card
					style={{ margin: "0.5rem", borderRadius: "10px", overflowX: "auto" }}
				>
					<Typography
						color="textSecondary"
						variant="caption"
						style={{ margin: "0.4rem" }}
					>
						Received Orders
					</Typography>
					<StyledTableHeader>
						<DateField source="date" className={classes.order} />
						<ReferenceField
							label="Sender"
							reference="customers"
							source="sender.id"
							link="show"
						>
							<TextField source="name" className={classes.order} />
						</ReferenceField>
						<TextField
							label="Origin"
							source="origin.name"
							className={classes.order}
						/>
						<TextField
							label="Destination"
							source="destination.name"
							className={classes.order}
						/>
						<ChipField
							label="Status"
							source="status.value"
							className={classes.order}
						/>
					</StyledTableHeader>
				</Card>
			</ReferenceManyField>
		</Grid>
	);

	const ShowViewActions = ({ basePath, data }) => (
		<TopToolbar className={classes.toolbar}>
			<EditButton
				label="All Customers"
				icon={<ArrowBackIosIcon />}
				color="primary"
				to={"/customers"}
				size="medium"
			/>
			{/* <FunctionField
				render={(record) =>
					record.type === "individual" ? (
						<EditButton basePath={basePath} record={data} />
					) : null
				}
			/> */}
		</TopToolbar>
	);

	return (
		<Show {...props} title="Customer/Details" actions={<ShowViewActions />}>
			<SimpleShowLayout>
				<CustomerDetailsCard />
				{!isSmall ? <RecentOrderListView /> : null}
				{/* Update consignment history to match the designs or update designs */}
			</SimpleShowLayout>
		</Show>
	);
};
