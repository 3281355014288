import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  useRedirect,
  useNotify,
  FunctionField,
} from "react-admin";
import { 
  Grid, 
  TextField, 
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { validateName } from "../../utils/validators";
import { HiddenTextInput } from "../../components/hiddenTextInput";
import { useStyles } from "../../components/Orders/PackageDetails";
import { validateEmail } from "../../utils/validators";
import SaveCompanyOnSaveToolbar from "../../components/saveCompanyOnSave";
import PhoneField from "../../layout/TwPhoneNumberInput";
import PageHeader from "../../components/PageHeader";
import throttle from "lodash/throttle";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import parse from "autosuggest-highlight/parse";
import PropTypes from "prop-types";
import WithRecord from "../../components/WithRecord";
import { db } from "../../config/firebaseConfig";
import { loadScript, autocompleteService } from "./CustomerCreate";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const CustomerEdit = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const classes = useStyles();
  const [value, setValue] = React.useState();
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  // uses google api keys to autosuggest the current location
  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  //makes request to google places library for each location
  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  //updates the location based on the input values
  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const onSuccess = () => {
    notify("The customer profile was updated successfully","success");
    redirect("show", `${props.basePath}/${props.id}/show`);
  };

  const onFailure = (error) => {
    notify("The customer profile was not updated!", "warning");
    console.log(error);
  };

  //saves the customers record and address value to the DB
  const saveCustomer = async (record, value) => {
    const { name , phoneNumber, id,address,place_id } = record;
    db.collection("customers")
      .doc(id)
      .update({
        name: name,
        address: value !== undefined ? value.structured_formatting.main_text : address,
        placeId: value !== undefined ? value.place_id : place_id !== undefined ? place_id:"",
        phoneNumber,
      });

    try {
      onSuccess();
    } catch (error) {
      onFailure(error);
      console.log(error);
    }
  };

  return (
    <Edit
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Customer/Edit"
    >
      <WithRecord>
        {({ record, ...props }) => {
          return (
            <SimpleForm
              {...props}
              save={(record) => saveCustomer(record, value)}
              record={record}
              toolbar={<SaveCompanyOnSaveToolbar />}
              warnWhenUnsavedChanges
            >
              <div style={{ width: "100%" }}>
                <Grid container spacing={2}>
                  <PageHeader title="Customer information" />
                  <Grid container spacing={2} item xs={12} sm={6}>
                    <Grid item xs={12} sm={12}>
                      <TextInput
                        source="name"
                        validate={validateName}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                      <TextInput
                        source="lastName"
                        validate={validateName}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={12}>
                      <FunctionField
                        render={(record) => (
                          <PhoneField
                            variant="outlined"
                            defaultValue={record?.phoneNumber}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FunctionField
                        render={(record) => (
                          <Autocomplete
                            id="google-map-demo"
                            getOptionLabel={(option) =>
                              typeof option === "string"
                                ? option
                                : option.description
                            }
                            filterOptions={(x) => x}
                            options={options}
                            autoComplete
                            defaultValue={record?.address}
                            includeInputInList
                            filterSelectedOptions
                            value={value}
                            onChange={(event, value) => {
                              setOptions(value ? [value, ...options] : options);
                              setValue(value);
                            }}
                            onInputChange={(event, inputValue) => {
                              setInputValue(inputValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Address"
                                placeHolder="Residence"
                                variant="outlined"
                                fullWidth
                                required
                              />
                            )}
                            renderOption={(option) => {
                              const matches =
                                option.structured_formatting
                                  .main_text_matched_substrings;
                              const parts = parse(
                                option.structured_formatting.main_text,
                                matches.map((match) => [
                                  match.offset,
                                  match.offset + match.length,
                                ])
                              );

                              return (
                                <Grid container alignItems="center">
                                  <Grid item>
                                    <LocationOnIcon className={classes.icon} />
                                  </Grid>
                                  <Grid item xs>
                                    {parts.map((part, index) => (
                                      <span
                                        key={index}
                                        style={{
                                          fontWeight: part.highlight
                                            ? 700
                                            : 400,
                                        }}
                                      >
                                        {part.text}
                                      </span>
                                    ))}

                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      {
                                        option.structured_formatting
                                          .secondary_text
                                      }
                                    </Typography>
                                  </Grid>
                                </Grid>
                              );
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} lg={12}>
                    <Accordion style={{ margin: "0.6rem 0 1rem" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <div>
                          <span className={classes.heading}>
                            Additional Details
                          </span>{" "}
                          <span className={classes.secondaryHeading}>
                            (optional)
                          </span>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid item xs={12} sm={12} lg={10}>
                          <Grid item lg={12}>
                            <TextInput
                              source="emailAddress"
                              variant="outlined"
                              validate={validateEmail}
                              fullWidth
                              placeholder="johndoe@example.com"
                            />
                          </Grid>
                          <Grid item lg={12}>
                            <TextInput
                              variant="outlined"
                              source="bio"
                              initialValue=""
                              fullWidth
                              placeholder="Add any descriptive information.."
                            />
                            <TextInput
                              source="nearestLandmark"
                              fullWidth
                              initialValue=""
                              variant="outlined"
                              placeholder="Building, street or apartment name.."
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  </Grid>
                </Grid>
              </div>
            </SimpleForm>
          );
        }}
      </WithRecord>
    </Edit>
  );
};
CustomerEdit.propTypes = {
  basePath: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
};
export default CustomerEdit;
