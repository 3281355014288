import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { syncCompanyData, syncTeamData } from "../../../syncGlobalProfile";

const useStyles = makeStyles({
  root: {
    marginBottom: 10,
    alignItems: 'center',
    '&:hover':{
      borderColor: '#00acc1',
      cursor:"pointer" 
    }
  },
  company: {
    fontWeight: 600,
  },
  logo: {
    width: 72,
    height: 72,
    margin: '5px auto'
  },
  small: {
    fontSize: '0.75rem'
  }
});

export default function AccountCard({ account }) {
  const classes = useStyles();
  const [company, setCompany] = useState(null);
  const [team, setTeam] = useState(null);
  const coType =
    account.companyType === "serviceProvider" ? "Service Provider" : "Business";

  useEffect(() => {
    const spId =
      account.role === "agent" ? account.serviceProviderId : account.companyId;
    // get company info for the current account
    syncCompanyData(spId, account.companyType).then((res) => setCompany(res));

    // get team info for the current account
    account.role === "admin" &&
      account.team &&
      syncTeamData(
        account.companyId,
        account.team.id,
        account.companyType === "serviceProvider" ? "companies" : "businesses"
      ).then((res) => setTeam(res));
  }, [account.companyId,account.companyType,account.team,account.role]);

  return (
    <Card className={classes.root} variant="outlined" elevation={2}>
      <CardContent>
        <Typography align="center" variant="subtitle2" className={classes.company} color="primary">
          {company && company.name}
        </Typography>
        <Typography align="center" className={classes.small} color="textSecondary">
          ({coType})
        </Typography>
        <Avatar alt={company && company.name} src="/broken-image.jpg" className={classes.logo} />
        <Typography align="center" variant="body2">
          Role: {account.role}
        </Typography>
        {account.role === "admin" && (
          <Typography
            align="center"
            className={classes.small}
            variant="body2"
            color="textSecondary"
            component="p"
          >
            Team: {team && team.name}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

AccountCard.propTypes = {
  account: PropTypes.object.isRequired,
};
