import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Title = styled.p`
  font-weight: 700;
  margin: 1.5rem 0;
`;

const Content = styled.p`
  margin: 0;
  font-size: 0.8rem;
  line-height: 1.7;
`;

const FAQbodyItem = ({ title, content }) => {
  return (
    <div>
      <Title>{title}</Title>
      <Content>{content}</Content>
    </div>
  );
};

FAQbodyItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default FAQbodyItem;
