import React, { useState } from "react";
import styled from "styled-components";
import { TwButton } from "../../../components/authComponents/Buttons";
import { GetStartedCard } from "../../../components/authComponents/layout/Cards";

const GeneralAppInfo = () => {
  const [step, setStep] = useState(0);

  const trackingInfo = {
    title: "Tracking & fleet management",
    description:
      "Twift allows you to easily locate your agents in real time & observe how deliveries are being handled.",
    image:
      "https://ik.imagekit.io/twendemobility/Auth/tracking_wk2f1niM2l.svg?updatedAt=1632224800023",
  };
  const ordersInfo = {
    title: "Orders",
    description: "Easily & efficiently manage your orders",
    image:
      "https://ik.imagekit.io/twendemobility/Auth/orders_FZ67_MJeWp.svg?updatedAt=1632224834225",
  };
  const paymentsInfo = {
    title: "Payments",
    description: "Easily manage & handle all payments",
    image:
      "https://ik.imagekit.io/twendemobility/Auth/payments_iKbxfI-OK.svg?updatedAt=1632224835063",
  };

  return (
    <Wrapper>
      <GetStartedCard>
        {step === 0 && (
          <img src={trackingInfo.image} alt={trackingInfo.title} />
        )}
        {step === 1 && <img src={ordersInfo.image} alt={ordersInfo.title} />}
        {step === 2 && (
          <img src={paymentsInfo.image} alt={paymentsInfo.title} />
        )}
        {step === 0 && <h1>{trackingInfo.title}</h1>}
        {step === 1 && <h1>{ordersInfo.title}</h1>}
        {step === 2 && <h1>{paymentsInfo.title}</h1>}
        {step === 0 && <p>{trackingInfo.description}</p>}
        {step === 1 && <p>{ordersInfo.description}</p>}
        {step === 2 && <p>{paymentsInfo.description}</p>}
        <ButtonRow>
          {step === 0 && <TwButton flat text="Skip" />}
          {step === 1 && (
            <TwButton
              flat
              backBtn
              handleClick={() => {
                setStep(step - 1);
              }}
            />
          )}
          {step === 2 && (
            <TwButton
              flat
              backBtn
              handleClick={() => {
                setStep(step - 1);
              }}
            />
          )}
          {step === 0 && (
            <TwButton
              flat
              nextBtn
              handleClick={() => {
                setStep(step + 1);
              }}
            />
          )}
          {step === 1 && (
            <TwButton
              flat
              nextBtn
              handleClick={() => {
                setStep(step + 1);
              }}
            />
          )}
          {step === 2 && (
            <TwButton contained text="Get started" style={{ width: "30%" }} />
          )}
        </ButtonRow>
      </GetStartedCard>
    </Wrapper>
  );
};

export default GeneralAppInfo;

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #fafafa;
  padding: 3%;
  background-image: url("https://ik.imagekit.io/twendemobility/Auth/empty_bg_Zyv5qgyzM.svg?updatedAt=1632807390310");
  background-size: auto;
  .MuiButton-root {
    min-width: 70% !important;
  }
`;

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }
`;
