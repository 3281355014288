import styled from "styled-components";

const AuthPageWrapper = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100vw;
  padding: 0;
  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
`;

export default AuthPageWrapper;
