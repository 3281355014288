import { Backdrop, Fade, makeStyles, Modal } from "@material-ui/core";
import React, { useState } from "react";
import Alert from "@material-ui/lab/Alert";
import styled from "styled-components";
import { TwButton } from "../../components/authComponents/Buttons";
import { EmailInputField } from "../../components/authComponents/InputFields";
import { actionCodeSettings, functions } from "../../config/firebaseConfig";
import { sanitizeEmailAddress } from "../auth/authHelpers";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: "0.3rem",
    padding: theme.spacing(2, 4, 3),
  },
}));

const EmailVerificationModal = ({ open, handleClose, email }) => {
  const classes = useStyles();
  const [state, setState] = useState({
    emailAddress: email,
    formErrors: {
      email: null,
    },
    isSendingLink: false,
  });
  const [error, setError] = useState(null);
  const [errorSeverity, setErrorSeverity] = useState(null);

  // destructure state
  const { emailAddress, formErrors, isSendingLink } = state;

  // capture email address from the user
  const handleEmailChange = (e) => {
    /**
     * Set event.persist() to prevent react from resetting the event object, i.e react sets the properties of the event to null.
     * If we don't do this we'll get an error saying 'can'r read propert 'value' of null'
     * For more info @see: https://reactjs.org/docs/legacy-event-pooling.html
     */
    e.persist();
    setState((state) => ({
      ...state,
      emailAddress: e.target.value,
    }));
  };

  /**
   * use firebase to verify email
   * - it's important to note that the way that firebase verifies
   * email addresses is by signing in via the email and link auth provider
   * and not by just sending a verification link.
   */
  const handleVerifyEmail = async () => {
    if (!emailAddress) {
      // email cannot be empty, set form errors
      setState((state) => ({
        ...state,
        formErrors: {
          ...formErrors,
          email: "* This field is required and cannot be empty",
        },
      }));
    } else {
      // sanitize the email address
      const sanitizedEmail = sanitizeEmailAddress(emailAddress);
      try {
        // show loader and disable the submit button
        setState((state) => ({
          ...state,
          isSendingLink: true,
        }));

        // send the signInLink to email
        let sendEmailVerification = functions.httpsCallable('sendEmailVerification');
        const emailVerificationData = {
          sanitizedEmail: sanitizedEmail,
          actionCodeSettings: actionCodeSettings,
        }

        //call cloud function
        await sendEmailVerification(emailVerificationData)
        .then(function (result) {
          setState((state) => ({
            ...state,
            isSendingLink: false,
          }));
          console.log("**** success: link sent to email *****");
          // link successfully sent to email
          // notify the user that we've sent a link to their email
          setErrorSeverity("success");
          setError(
            `We've sent a verification link to ${sanitizedEmail}. Please check your inbox.`
          );
        }).catch(function (error) {
            console.error('Error sending email verification link ', error);
            setErrorSeverity("error");
            setError(
              `Error sending email verification link to ${sanitizedEmail}.`
            );
        });

        // use localStorage to cache the email address as we'll need it when completing sign-in after the user clicks the link sent to them
        localStorage.setItem("signInWithLinkEmail", sanitizedEmail);
      } catch (err) {
        const { code, message } = err;
        /**
         * handle errorrs
         * @see: https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#sendsigninlinktoemail
         */
        switch (code) {
          case "auth/invalid-email":
            setErrorSeverity("error");
            setError(
              "The email address that you entered is invalid. Please enter a valid email to continue"
            );
            break;
          default:
            console.error(
              `*** err: err sending link to ${sanitizedEmail}: ${code} :: ${message}`
            );
            break;
        }
      }
    }
  };
  return (
    <div>
      <ModalWrapper
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {error !== null ? (
              <Alert
                onClose={() => {
                  setError(null);
                }}
                severity={errorSeverity}
              >
                {error}
              </Alert>
            ) : null}
            <h2 className="title" id="transition-modal-title">
              Verify your email address
            </h2>
            <p className="subtitle" id="transition-modal-description">
              Enter the email address you signed up with
            </p>
            <EmailInputField
              onChangeHandler={handleEmailChange}
              formErrors={formErrors}
              email={emailAddress}
            />
            <TwButton
              style={{ margin: "2rem auto" }}
              contained
              text="Send link"
              handleClick={handleVerifyEmail}
              isLoading={isSendingLink}
              disabled={error || isSendingLink}
            />
            <p className="description">
              * A link will be sent to your e-mail address allowing you to
              verify it
            </p>
          </div>
        </Fade>
      </ModalWrapper>
    </div>
  );
};

const ModalWrapper = styled(Modal)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 6%;
    h2,
    p {
      text-align: center;
      line-height: 1.8;
      margin: 3rem 0;
    }
    .title {
      font-size: 1.2rem;
      color: #000;
      font-weight: bold;
      font-family: "Ubuntu", sans-serif;
    }
    .subtitle {
      font-size: 1rem;
      color: #707070;
      font-family: "Fira Sans", sans-serif;
    }
    .description {
      font-size: 0.8rem;
      color: #707070;
      font-family: "Fira Sans", sans-serif;
    }
  }
`;

export default EmailVerificationModal;
