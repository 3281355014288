/**
 * a component that implements a google map view using the google-map-react
 * project. solution partially adapted from https://blog.logrocket.com/a-practical-guide-to-integrating-google-maps-in-react/
 */
 import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
 import GoogleMapReact from 'google-map-react';
 import bike from "../../assets/bike.svg";
 import styled from "styled-components";

 import roam from "roam-js";

 import { db } from "../../config/firebaseConfig";
 import { ProfileContext } from "../../context/ProfileContext";

 // define a component that displays a pin icon (and optional text)
 // memoize the component to re-render only if our geo-codes actually changed
 const LocationPin = React.memo(({ text }) => (
     <AgentPin>
         <img src={bike} alt="agent pin" />
         <p className="pin-text">{text}</p>
     </AgentPin>
 ));

 // sets the default map center area
 const defaultProps = {
     center: {
         lat: -1.291,
         lng: 36.792,
     },
     zoomLevel: 14
 };

 const OPTIONS = {
     minZoom: 13,
     maxZoom: 17,
   };

 const Map = (/*{ location, zoomLevel }*/) => {
     const { profile } = useContext(ProfileContext);
     const [locations, updateLocations] = useState({});
     const [unknownLocationAgents, updateUnknownLocationAgents] = useState({});
     const [pendingInviteAgents, updatePendingInviteAgents] = useState({});

     // roam location subscribing (client side)
     const roamPublishKey = process.env.REACT_APP_ROAM_PUBLISH_KEY;

     /**
      * a useState hook that allows setting a callback on it. the callback
      * is only executed on state updates (omitted on initial render). typically,
      * useState on functional components don't support callbacks, only accepting
      * an initial state. adapted from https://stackoverflow.com/a/61842546/1145905
      *
      * @param {Object} initialState an initial value of the object to be tracked
      * @returns a stateful value, and a function to update it.
      */
     function useStateCallback(initialState) {
         const [state, setState] = useState(initialState);
         const cbRef = useRef(null); // mutable ref to store current callback

         const setStateCallback = useCallback((state, cb) => {
             cbRef.current = cb; // store passed callback to ref
             setState(state);
         }, []);

         useEffect(() => {
             // cb.current is `null` on initial render, so we only execute cb on state *updates*
             if (cbRef.current) {
                 cbRef.current(state);
                 cbRef.current = null; // reset callback after execution
             }
         }, [state]);

         return [state, setStateCallback];
     }

     useEffect(() => {
         /**
          * queries the database for the most recent location update
          * for each registered agent of an SP
          */
         const getAgentLocations = async () => {
            let lastKnownLocs = {}; // last known agent locations
            let unknownLocAgents = {}; // agents authed on the flutter app but that haven't pushed any location update yet
            let pendingInviteAgents = {}; // agents invited to a company's fleet but that haven't installed or signed in on the app yet
            let roamUserIds = []; // roam user ID corresponding to each agent

            /**
            * construct the 'unknown location agents' list. for context, see the
            * description of this task: https://app.clickup.com/t/1tz39gh
            * each element in the list is a roamUserId-indexed map that looks like:
            * {
            *  617feb0851efb00b9a2ab0b3: {
            *      agentId: "dMDTC0XLOrlG2Scd0t4x"
            *      agentName: "Otis Baba",
            *      userId: "Z4AnulkLhMih626owkZ4",
            *      roamUserId: "617feb0851efb00b9a2ab0b3",
            *      companyId: "cqpm3pcTK2cKQrOuihW1",
            *      inviteDate: "11 March 2021 at 1:14:19 PM" // timestamp
            *  }
            * }
            * we use roamUserId indices to make it easier to extract, and possibly delete, the elements
            * of agents whose location update we received (see the subscribe callback)
            **/
            const addUnknownLocAgent = (agentDoc, roamUserId) => {
                appendAgentsList(unknownLocAgents, agentDoc, roamUserId);
                // perform custom logic after the common fields. in this case, append the
                // roamUserId key-val pair, as the agent <> roam user mapping should be valid
                unknownLocAgents[roamUserId].roamUserId = roamUserId;
            }

            /**
             * similar to addUnknownLocAgent() above, but for pending invitees.
             * each element in the list is a agentId-indexed map that looks like:
            * {
            *  dMDTC0XLOrlG2Scd0t4x: {
            *      agentId: "dMDTC0XLOrlG2Scd0t4x"
            *      agentName: "Tembo Tena",
            *      userId: "UUWoVvF0GyOu0KPOV7IL",
            *      companyId: "cqpm3pcTK2cKQrOuihW1",
            *      inviteDate: "11 March 2021 at 1:14:19 PM" // timestamp
            *  }
             **/
            const addPendingAgent = (agentDoc) => {
                // produce an agentId-indexed list of yet-to-auth agents
                // unlike in the 'unknown loc' case, there's no roamUser to set here
                appendAgentsList(pendingInviteAgents, agentDoc, agentDoc.id);
            }

            // helper function to support different types of 'offline' agent lists
            const appendAgentsList = (list, agentDoc, key) => {
                const agentDocData = agentDoc.data();
                const agentMetadata = {
                    agentId: agentDoc.id,
                    agentName: agentDocData.name,
                    userId: agentDocData.userId,
                    companyId: agentDocData.serviceProviderId,
                    inviteDate: agentDocData.updatedAt // TODO: read from inviteDate when https://app.clickup.com/t/3xa960 is done
                };
                list[key] = agentMetadata;
            }

             // get all the SP's agents
             const spAgents = await getSPAgents(profile.company.id);

             /**
              * obtain the most recent location of each of the SP's agents
              * as persisted from the Roam API callback. construct the locations
              * as a map of maps, indexed by the roam user id. thus, lastKnownLocs looks like:
              * {
                     "60ea1aa606f8a077fc6da0f6": {
                         lat: -1.2811111,
                         lng: 36.7811111,
                         name: "Moja One",
                         roamUserId: "60ea1aa606f8a077fc6da0f6",
                     },
                     "6108529706f8a04e2ee52b87": {
                         lat: -1.292222,
                         lng: 36.792222,
                         name: "Mbili Two",
                         roamUserId: "6108529706f8a04e2ee52b87",
                     },
                     ...
                 }
              **/
             for (let agent of spAgents.docs) {
                let roamUserId;
                // extract the roam user record for this agent
                const roamUserSnap = await db.collection('roam_users')
                    .where('agentId', '==', agent.id)
                    .limit(1)
                    .get();

                if (roamUserSnap.size !== 1) {
                    // todo: render a 'location tracking for <agent name> not activated - log in on app?' UI
                    // todo: OR, we might have 1:n between a roam user and agent profiles (not yet supported).
                    console.log(`found ${roamUserSnap.size} roam users. location tracking for agentId ${agent.id} not activated - logged in on app? SP id: ${profile.company.id}`);
                    addPendingAgent(agent); // roamUserId is unknown at this point
                } else {
                    // the agent is mapped to a roam user; add this agent's roam user ID to the list
                    roamUserSnap.forEach(doc => {
                        const aruData = doc.data(); // 'agent<>roam<>user'
                        roamUserId = aruData.roamUserId;
                        console.log(`found 1 agent_roam_user mapping (id: ${doc.id}) for agentId ${agent.id}, roam user_id: ${roamUserId}`);
                        roamUserIds.push(roamUserId);
                    });

                    // fetch the most recent location of this agent, if any
                    const roamLocRef = db.collection(`roam_locations`);
                    const recentLocSnap = await roamLocRef
                        .where('agentId', '==', agent.id)
                        .orderBy( 'recorded_at')
                        .limit(1)
                        .get();

                    if (recentLocSnap.size < 1) {
                        // todo: render a 'agent location for <agent name> not found' UI
                        console.log(`no matching roam location records for SP ${profile.company.id} agent ${agent.id}`);
                        addUnknownLocAgent(agent, roamUserId);
                    }

                    // add this agent's most recent location to the main list to be rendered
                    recentLocSnap.forEach(doc => {
                        const locData = doc.data();
                        const agentPinLoc = { lat: locData.latitude, lng: locData.longitude, name: locData.agentName, roamUserId: locData.user_id };
                        lastKnownLocs[locData.user_id] = agentPinLoc;
                        console.log("==> agent doc: ", doc.id, " roam user id: ", locData.user_id)
                    });
                }
             }

             updateLocations(lastKnownLocs);
             updateUnknownLocationAgents(unknownLocAgents);
             updatePendingInviteAgents(pendingInviteAgents);
             console.log("unknownAgentLocs ===> ", unknownLocAgents);
             console.log("pendingInviteAgents ===> ", pendingInviteAgents);

             console.log("roam user ids ===> ", roamUserIds);

             // register real-time listening of updates for agents locations
             subscribeAllAgentLocations(roamUserIds, lastKnownLocs);

             console.log("=== inside getAgentLocations: lastKnownLocs = ", lastKnownLocs)
             console.log("=== inside getAgentLocations: locations = ", locations)
         }

         getAgentLocations();

     }, [profile.company.id,]);

     /**
      * queries the firestore db for agents belonging to an SP
      * @param {string} spId the doc ID of the current admin's SP
      * @returns {QueryDocumentSnapshot} a document snapshot containing the list of matched agent docs
      */
     const getSPAgents = async (spId) => {
         const agentsRef = db
         .collectionGroup("agents")
         .where("serviceProviderId", "==", spId);

         // fetch the agents of this SP
         try {
             const agentsSnap = await agentsRef.get();
             return agentsSnap;
         } catch (error) {
             console.log(`error fetching agents for SP ${spId}: `, error);
         }
     }

    // fetch the name for the agentcorresponding to  the given roam user
     const getAgentUserName = async (roamUserId) => {
        // fetch the mapper record
        const roamUserSnap = await db.collection('roam_users')
        .where('roamUserId', '==', roamUserId)
        .get();

        if (roamUserSnap.size > 1) {
            throw (`${roamUserSnap.size} roam_user records were found for roam user_id ${roamUserId}. there should only be one!`);
        }

        const agentUserData = roamUserSnap.docs[0].data();
        let agentName = agentUserData.agentName;
        // as the roam_user.agentName field is optional, it might be
        // missing, so fetch the value from the agent's document
        // NB: it might have been easier to reuse the existing 'offline' agents lists, but
        // for some reason, state isn't accessible from within the roam subscriber calls. i.e.,
        // unknownLocationAgents is a '[[Prototype]]: Object' and it's not clear how to extract its value
        if (!agentName) {
            const agentSnap = await db.doc(`companies/${agentUserData.companyId}/agents/${agentUserData.agentId}`)
            .get();
            if (agentSnap.exists) {
                console.log("agentSnap data >>>", agentSnap.data())
                agentName = agentSnap.data()["name"];
            } else {
                // agent record not found
                console.log(`agent record not found for agent ${agentUserData.companyId}/agents/${agentUserData.agentId}`);
            }
        }

        return agentName;
     }

     /**
      * registers realtime location listeners for *all* the agents of an SP
      */
     const subscribeAllAgentLocations = async (roamUserIds, initialLocations) => {
         console.log("===> in subscribeAllAgentLocations()");
         const roamClient = await roam.Initialize(roamPublishKey);

         console.log("current agent locs ===> ", initialLocations)

         // cache userNames of all users
         const userNames = {};
         for (const userId in initialLocations) {
             userNames[userId] = initialLocations?.[userId]?.name
         }

         try {
             // subscribe to all the SP's agents' locations at once
             roamClient.userSubscription(roamUserIds)
             .then((subscription)=>{
                 console.log("pre-subscribe locations ==> ", initialLocations);
                 subscription.subscribe()
                 .then((msg)=>{
                     console.log(`agents roam subscription: ${msg}`);
                     console.log("post-subscribe locations ==> ", initialLocations);
                 })
             })
             .catch((err)=>{
                 throw(err)
             });

             // attach listener for location update events
             roamClient.setCallback(async function(message, messageType, userId) {
                 // 'message' is of string type, convert it to a JSON object
                 const locResult = JSON.parse(message);
                 console.log(`agent ${userId} roam location result received: `, locResult, "messageType: ", messageType)
                 console.log("previous locations ==> ", initialLocations);

                 // prepare the position of the moved agent in the 'global' list
                 const agentName = userNames[userId] || await getAgentUserName(userId); // fallback to a lookup value for newly-tracked agents
                 const newAgentLoc = { lat: locResult.latitude, lng: locResult.longitude, name: agentName, roamUserId: userId };
                 console.log("newAgentLoc ==>: ", newAgentLoc);

                 // update locations state: replace the entry of the agent that moved with its new location
                 updateLocations((prev) => {
                     delete prev?.[userId]; // remove the previous entry of the agent that moved
                     return { ...prev, [userId]: newAgentLoc };
                 });

                // bump off the corresponding record, if any, in the unknownLocationAgents list since its record
                // is presumably now saved in the backend and its location pin should now be showing on the map view
                updateUnknownLocationAgents((prevUnknownLocAgents) => {
                    // remove any entry matching the moved agent
                    delete prevUnknownLocAgents[userId];
                    return { prevUnknownLocAgents };
                });
             });
         } catch (error) {
             console.log("error with subscribe location: ", error);
         }
     }
     useStateCallback()

     /**
      * loops thru each agent, displaying their current or last
      * received locations
     */
     const displayAgentMarkers = () => {
         const newLocations = [...Object.values(locations)];
         console.log("displayAgentMarkers. locations ==> ", newLocations);

         console.log("displayAgentMarkers. unknownLocationAgents ==> ", unknownLocationAgents);
         console.log("displayAgentMarkers. pendingInviteAgents ==> ", pendingInviteAgents);

         return newLocations.map((agentLoc, index) => {
             return <LocationPin
                 key={agentLoc.roamUserId}
                 lat={agentLoc.lat}
                 lng={agentLoc.lng}
                 text={agentLoc.name} />;
         });
     }

     return (
         <MapWrapper>
             <MapTitle>Your Agents' Locations</MapTitle>
                 <GoogleMapReact
                     bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
                     defaultCenter={defaultProps.center}
                     defaultZoom={defaultProps.zoomLevel}
                     options={OPTIONS}
                     >
                     {displayAgentMarkers()}
                 </GoogleMapReact>
         </MapWrapper>
     );
 };

 export default Map;

 const AgentPin = styled.div`
   img {
     height: 2rem;
     width: auto;
   }
   .pin-text {
     visibility: hidden;
     width: 120px;
     font-family: "Fira Sans";
     background-color: #fff;
     color: #000;
     text-align: center;
     border-radius: 6px;
     padding: 5px 0;
     position: absolute;
     z-index: 1;
   }
   :hover {
     transition: 1s;
     .pin-text {
       visibility: visible;
     }
   }
 `;

 const MapWrapper = styled.div`
   width: 100%;
   height: 60vh;
   @media screen and (min-width: 799px) {
     height: 80vh;
   }
 `;

 const MapTitle = styled.h2`
   font-family: "Ubuntu";
   font-size: 1.5rem;
   color: #000;
   font-weight: 700;
   margin: 0.5rem;
   text-align: center;
   @media screen and (min-width: 799px) {
     font-size: 1.2rem;
   }
 `;

