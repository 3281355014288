import * as React from "react";
import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  invited: {
    color: "#C17600",
  },
});

const InviteStatus = ({ inviteConfirmed }) => {
  const classes = useStyles();

  switch (inviteConfirmed) {
    case "accepted":
      return (
        <Chip color="primary" label="Accepted"/>
      );
    default:
      return (
        <Chip className={classes.invited} label="Pending"/>
      );
  }
};

export default InviteStatus;
