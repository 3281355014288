import * as React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";

import rowStyle from './rowStyle';
import StyledTableHeader from '../../layout/TableHeader';

const useListStyles = makeStyles({
    headerRow: {
        borderLeftColor: 'transparent',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    headerCell: {
        padding: '6px 8px 6px 8px',
    },
    rowCell: {
        padding: '6px 8px 6px 8px',
    },
    message: {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

const MessageListDesktop= ({ selectedRow,...props }) => {
    const classes = useListStyles();
    return (
        <StyledTableHeader
            rowClick="edit"
            rowStyle={rowStyle(selectedRow)}
            classes={{
                headerRow: classes.headerRow,
                headerCell: classes.headerCell,
                rowCell: classes.rowCell,
            }}
            optimized
            {...props}
        >
            <TextField source="body" label="Message" cellClassName={classes.message} />
            <TextField source="sender" />
            <TextField source="recipient" />
            <DateField label="Date" source="createdAt" />
            <TextField source="status" />
        </StyledTableHeader>
    );
};

MessageListDesktop.propTypes = {
  selectedRow: PropTypes.number,
};

export default MessageListDesktop;
