import React, { useContext, useCallback } from "react";
import { Route, useHistory } from "react-router-dom";
import { Drawer, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import classnames from "classnames";
import PropTypes from "prop-types";

import { ProfileContext } from "../../context/ProfileContext";
import {
  List,
  Show,
  Create,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  useRedirect,
  useNotify,
} from "react-admin";
import SaveCompanyOnSaveToolbar from "../../components/saveCompanyOnSave";
import EmptyListPage from "../../components/EmptyListPage";
import exporter from "../../components/customDataExporter";
import useFetchCurrentUserPermissions from "../../components/useFetchCurrentUserPermissions";
import MessageListDesktop from "../../components/messages/MessageListDesktop";
import MessageListMobile from "../../components/messages/MessageListMobile";
import MessageEdit from "../../components/messages/MessageEdit";
import MessageFilter from "../../components/messages/MessageFilter";
import TwPhoneNumberInput from "../../layout/TwPhoneNumberInput";
import CustomTextInput from "../../components/CustomTextInput";
import CustomSelectInput from "../../components/CustomSelectInput";
import { functions } from "../../config/firebaseConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(["all"], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 400,
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
  msgdrawerPaper: {
    zIndex: 200,
    width: "auto",
  },
  msgForm: {
    margin: "5%",
    padding: "2%",
    maxWidth: 500
  },
  inputWidth: {
    width: "100%",
    marginBottom: "1rem"
  }
}));

export const MessageList = (props) => {
  const classes = useStyles();
  const { profile } = useContext(ProfileContext);
  const serviceProviderId = profile.company.id;
  const { formattedPermissions } = useFetchCurrentUserPermissions();

  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push("/messages");
  }, [history]);

  const msgsExporter = (msgs) =>
    exporter({
      resourceArr: msgs,
      serviceProviderId: serviceProviderId,
      resourceType: "messages",
    });

  return serviceProviderId ? (
    <div className={classes.root}>
      <Route path="/messages/:id">
        {({ match }) => {
          const isMatch = !!(match && match.params);
          return (
            <>
              <List
                {...props}
                title="Messages"
                className={classnames(classes.list, {
                  [classes.listWithDrawer]: isMatch,
                })}
                filter={{ serviceProviderId: serviceProviderId }}
                filters={<MessageFilter />}
                empty={<EmptyListPage icon={MailOutlineIcon} />}
                exporter={
                  formattedPermissions.messages &&
                  formattedPermissions.messages.list
                    ? msgsExporter
                    : false
                }
                perPage={25}
                sort={{ field: "createdAt", order: "DESC" }}
              >
                {isXSmall ? (
                  <MessageListMobile />
                ) : (
                  <MessageListDesktop
                    selectedRow={
                      isMatch ? parseInt(match.params.id, 10) : undefined
                    }
                  />
                )}
              </List>
              <Drawer
                variant="persistent"
                open={isMatch}
                anchor="right"
                onClose={handleClose}
                classes={{
                  paper: classes.msgdrawerPaper,
                }}
              >
                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                {isMatch ? (
                  <MessageEdit
                    id={match.params.id}
                    onCancel={handleClose}
                    resource={props.resource}
                    basePath={props.basePath}
                  />
                ) : null}
              </Drawer>
            </>
          );
        }}
      </Route>
    </div>
  ) : null;
};

export const MessageShow = (props) => (
  <Show {...props} title="Message">
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="body" />
      <TextField source="sender" />
      <TextField source="recipient" />
      <TextField source="type" />
      <TextField source="status" />
      <TextField source="statusDesc" />
    </SimpleShowLayout>
  </Show>
);

const MessageForm = () => {
  const classes = useStyles();
  return (<>
    <CustomTextInput
      name="title"
      source="title"
      label="Title"
      className={classes.inputWidth}/>
    <br />
    <CustomTextInput
      name="body"
      source="body"
      label="Body"
      className={classes.inputWidth}/>
    <br />
    <TwPhoneNumberInput
      label="Phone Number"
      title="recipient"
      name="recipient"
      className={classes.inputWidth}
      source="recipient" />
    <br />
    <CustomSelectInput
      name="type" 
      source="type"
      label="Type"
      className={classes.inputWidth}
      choices={[
        { id: "SMS", name: "SMS" },
        // { id: 'PN, name: 'Push Notification' },
        // { id: 'Chat', name: 'In-App Chat' },
      ]}
    />
    
  </>)
};

export const MessageCreate = (props) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const notify = useNotify();
  const { profile } = useContext(ProfileContext);
  const serviceProviderId = profile.company.id;

  const onSuccess = () => {
    notify("The message was created successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The message was not created!", "warning");
    console.log(error);
  };

  const saveMessage = async (record) => {
    // the sendMessage cloud function expects a phone number, message and serviceProviderId
    const { recipient, title, body } = record
    const message = `${title}. ${body}`
    const payload = {recipient, message, serviceProviderId}
    const sendSMS = functions.httpsCallable('addMessage');
    await sendSMS(payload)
    .then(() => {
      console.log("message sent to:", recipient);
      onSuccess();
    }).catch((error) => {
      onFailure(error)
    })
  }
  return (
    <Create
      {...props}
      onSuccess={onSuccess}
      undoable={'false'}
      onFailure={onFailure}
      title="Message/Create"
    >
      <SimpleForm
        className={classes.msgForm}
        save={saveMessage}
        toolbar={<SaveCompanyOnSaveToolbar
        label="Send" />}
        warnWhenUnsavedChanges
      >
        <MessageForm />
      </SimpleForm>
    </Create>
  );
};

MessageList.propTypes = {
  basePath: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
};

MessageCreate.propTypes = {
  basePath: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
};
