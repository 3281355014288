import * as React from "react";
import {
  List,
  Show,
  Datagrid,
  SimpleShowLayout,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  EditButton,
  DeleteButton,
  useRedirect,
  useNotify,
} from "react-admin";
import SaveCompanyOnSaveToolbar from "../../components/saveCompanyOnSave";

const VehicleForm = () => (
  <>
    <TextInput source="make" />
    <TextInput source="model" />
    <TextInput source="type" />
  </>
)

export const VehicleList = (props) => (
  <List {...props} title="Fleet">
    <Datagrid rowClick="show">
      <TextField source="make" />
      <TextField source="model" />
      <TextField source="type" />
      {/* <EditButton />
      <DeleteButton undoable={false} /> */}
    </Datagrid>
  </List>
);

export const VehicleShow = (props) => (
  <Show {...props} title="Vehicle">
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="make" />
      <TextField source="model" />
      <TextField source="type" />
    </SimpleShowLayout>
  </Show>
);

export const VehicleCreate = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The vehicle was created successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The vehicle was not created!", "warning");
    console.log(error);
  };
  return (
    <Create
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Vehicle/Create"
    >
      <SimpleForm toolbar={<SaveCompanyOnSaveToolbar />} warnWhenUnsavedChanges>
        <VehicleForm />
      </SimpleForm>
    </Create>
  );
};

export const VehicleEdit = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The vehicle was updated successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The vehicle was not updated!", "warning");
    console.log(error);
  };
  return (
    <Edit
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Vehicle/Edit"
    >
      <SimpleForm warnWhenUnsavedChanges>
        <VehicleForm />
      </SimpleForm>
    </Edit>
  );
};
