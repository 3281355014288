import React, { useContext, useState, useEffect } from "react";
import { ProfileContext } from "../../context/ProfileContext";
import {
  List,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  EditButton,
  DeleteButton,
  DateField,
  Filter,
  useRedirect,
  useNotify,
  NumberInput,
  BooleanInput,
  SelectInput,
  RadioButtonGroupInput,
  FormDataConsumer,
  useRefresh,
  NumberField,
  FunctionField,
} from "react-admin";
import {
  validateRequired,
  validateAmount,
} from "../../utils/validators";
import { DateTimeInput } from "react-admin-date-inputs2";
import DateUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import { HiddenTextInput } from "../../components/hiddenTextInput";
import EmptyListPage from "../../components/EmptyListPage";
import PaymentIcon from "@material-ui/icons/LocalAtm";
import InputAdornment from "@material-ui/core/InputAdornment";
import PhoneNumberField from "../../layout/TwPhoneNumberInput";
import WithRecord from "../../components/WithRecord";
import { db } from "../../config/firebaseConfig";
import parseDateTime from "../../utils/parseDateTime";
import exporter from "../../components/customDataExporter";
import useFetchCurrentUserPermissions from "../../components/useFetchCurrentUserPermissions";
import PaymentShow from "../../components/Payments/PaymentShow";
import createRecord from "../../utils/createRecord"
import StyledTableHeader from "../../layout/TableHeader";
import PaymentsMobile from "../../components/Payments/PaymentsMobile";
import { useMediaQuery } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';


const PaymentsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="account number" placeholder="Search ..." source="accountNumber" alwaysOn variant="outlined" InputProps={{
              startAdornment: (
                <InputAdornment position="start"><SearchIcon style={{fill:"grey"}}/></InputAdornment>
              ),
            }} />
    <TextInput label="Status" source="status" />
  </Filter>
);

const PaymentsDatagrid = ()=> {
  const { formattedPermissions } = useFetchCurrentUserPermissions();
  return (
  <StyledTableHeader rowClick="expand" expand={<PaymentShow />}>
      <TextField source="trackingCode" />
      <TextField source="accountName" />
      <TextField source="accountNumber" />
      <NumberField source="amount" />
      <TextField source="type" />
      <TextField source="status" />
      <DateField label="Date" source="createdAt" />
      {/* <FunctionField
        render={(record) => record.type !== "disbursement"
        ? formattedPermissions.payments && formattedPermissions.payments.delete && (
            <EditButton />
          )
        : null}
      />
      {formattedPermissions.payments && formattedPermissions.payments.delete && (
        <DeleteButton label="" undoable={false} />
      )} */}
  </StyledTableHeader>
)};

export const PaymentList = (props) => {
  const { profile } = useContext(ProfileContext);
  const serviceProviderId = profile.company.id;
  const { formattedPermissions } = useFetchCurrentUserPermissions();
  const isSmall = useMediaQuery(`(max-width:950px)`);

  const paymentsExporter = (payments) =>
    exporter({
      resourceArr: payments,
      serviceProviderId: serviceProviderId,
      resourceType: "payments",
    });
  // watch the collection and refresh the view if any data changes
  const refresh = useRefresh();
  db.collection("payments").onSnapshot((snap) => {
    refresh();
  });

  return serviceProviderId ? (
    <List
      {...props}
      sort={{ field: "createdAt", order: "DESC" }}
      title="Payments"
      filters={<PaymentsFilter />}
      filter={{ serviceProviderId: serviceProviderId }}
      empty={<EmptyListPage icon={PaymentIcon} />}
      exporter={
        formattedPermissions.payments && formattedPermissions.payments.list
          ? paymentsExporter
          : false
      }
    >
      {isSmall ? (
        <PaymentsMobile />
      ):(
       <PaymentsDatagrid />
      )}
    </List>
  ) : null;
};

export const PaymentForm = ({ record }) => {
  const { profile } = useContext(ProfileContext);
  const [paymentOptionHelperText, setPaymentOptionHelperText] = useState("");
  const [requestPaymentHelperText, setRequestPaymentHelperText] = useState("");

  const paymentModeChoices = [
    {
      id: "mpesaCheckout",
      name: "Mpesa Checkout",
      helperText:
        "Mpesa Checkout. Send a prompt to the customer's phone directly. They only need to enter their M-Pesa PIN to confirm the payment - the funds will be credited to your account",
    },
    {
      id: "mpesaPaybill",
      name: "Mpesa Paybill",
      helperText:
        "Mpesa Paybill. This option will send an SMS to the customer with instructions on how to make a payment to our paybill number - the funds will be credited to your account",
    },
    {
      id: "cash",
      name: "Cash/Credit",
      helperText:
        "Cash/Credit. Already received cash or is the customer booking on credit? Just record the payment to keep your accounts up to date",
    },
  ];

  const paymentTypes = [
    { id: "collection", name: "Collection" },
    { id: "disbursement", name: "Disbursement" },
  ];

  const handleOnPaymentOptionChange = (value) => {
    let optionHelperText = paymentModeChoices.find(
      (choice) => choice.id === value
    );
    setPaymentOptionHelperText(optionHelperText.helperText);
  };

  const handleOnRequestPaymentChange = (value) => {
    const helperText =
      "This will send a reminder to the customer for them to complete making the payment using the option you selected above";
    value
      ? setRequestPaymentHelperText(helperText)
      : setRequestPaymentHelperText("");
  };

  return (
    <div style={{ width: "100%" }}>
      <Grid container spacing={1}>
        <Grid item sm={6} lg={5} xs={12}>
          {/*Set this payment type select to read only 
           with disbursement as default payment for two reasons:
             (1) Restrict all create actions to disbursement payment
             (2) Restrict altering payment type during edits
            During edit actions, the saved payment type will be loaded and 
            if it is a collection payment, the collection dependent inputs will be displayed
        */}
          <SelectInput
            source="type"
            choices={paymentTypes}
            validate={validateRequired}
            initialValue={paymentTypes[1].id}
            variant= "outlined"
            InputProps={{
              readOnly: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item sm={6} lg={5}  xs={12}>
          <NumberInput
            source="amount"
            validate={validateAmount}
            variant= "outlined"
            InputProps={{
              inputProps: { min: 0},
              startAdornment: (
                <InputAdornment position="start">Ksh</InputAdornment>
              ),
            }}
            fullWidth
          />
        </Grid>
        <Grid item sm={12} lg={5}  xs={12}>
          <FormDataConsumer>
            {({ formData }) =>
              formData.type === "collection" && (
                <RadioButtonGroupInput
                  source="mode"
                  style={{ height: "7em" }}
                  choices={paymentModeChoices}
                  label="Select the method through which the customer will make the payment"
                  onChange={handleOnPaymentOptionChange}
                  helperText={paymentOptionHelperText}
                  validate={validateRequired}
                  fullWidth
                />
              )
            }
          </FormDataConsumer>
        </Grid>
        <Grid item sm={12} lg={5}  xs={12}>
          <FormDataConsumer>
            {({ formData }) =>
              formData.type === "collection" && (
                <BooleanInput
                  label="Send a payment request"
                  style={{ height: "5em" }}
                  source="requestPayment"
                  initialValue={false}
                  onChange={handleOnRequestPaymentChange}
                  helperText={requestPaymentHelperText}
                  fullWidth
                />
              )
            }
          </FormDataConsumer>
        </Grid>
        <Grid item sm={6} lg={5}  xs={12}>
          <TextInput source="accountName" variant= "outlined" fullWidth />
        </Grid>
        <Grid item sm={6} lg={5} xs={12} >
          <PhoneNumberField
            variant= "outlined"
            name="accountNumber"
            defaultValue={record && record.accountNumber}
          />
        </Grid>
        <HiddenTextInput source="hub" initialValue={profile.admin.hub} />
        <HiddenTextInput source="status" initialValue="initiated" />
        <FormDataConsumer>
          {({ formData }) =>
            formData.type === "collection" && (
              <HiddenTextInput
                source="consignment.id"
                validate={validateRequired}
                fullWidth
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) =>
            formData.type === "disbursement" && (
              <HiddenTextInput
                source="mode"
                initialValue="mpesaB2C"
                validate={validateRequired}
                fullWidth
              />
            )
          }
        </FormDataConsumer>
        <Grid item xs={12} sm={12} style={{ display: "none" }}>
          <MuiPickersUtilsProvider utils={DateUtils}>
            <DateTimeInput
              source="date"
              label="Create Date"
              initialValue={new Date()}
              validate={validateRequired}
              parse={parseDateTime}
              options={{
                format: "DD/MM/YYYY, HH:mm:ss",
                ampm: false,
                clearable: true,
              }}
              fullWidth
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    </div>
  );
};

export const PaymentCreate = (props) => {
  const { profile } = useContext(ProfileContext);
  const redirect = useRedirect();
  const notify = useNotify();
  const [balance, setBalance] = useState();
  const [mpesaBalance, setMpesaBalance] = useState();
  const serviceProviderId = profile.company.id;
  const createdBy = profile.authUser.email
  const updatedBy = profile.authUser.email

  useEffect(() => {
    const getPayments = async () => {
      try {
        // fetch the value of the company's summary (main) account balance
        // it is assumed the backend is responsible for continually updating the
        // wallet totals and thus all we need to do is to just read it
        const companyId = profile.company.id;
        const unsubscribe = db.collection(`companies/${companyId}/accounts`)
        .where("type", "==", "summary")
        .onSnapshot((snapshot) => {
          // extract the balance from the fetched account (we assume there's only 1)
          snapshot.forEach((doc) => {
            const acct = doc.data();
            setBalance(acct.balance);
          });
        });
        
        return unsubscribe;
      } catch (error) {
        console.log("##### error fetching: ", error)
      }
    };

    const getMpesaBalance = async () => {
      try {
        // fetch the value of the company's Mpesa account balance
        // it is assumed the backend is responsible for continually updating the
        // wallet totals and thus all we need to do is to just read it
        const companyId = profile.company.id;
        const mpesaSnapshot = db.collection(`companies/${companyId}/accounts`)
        .where("type", "==", "mpesaWallet")
        .onSnapshot((snapshot) => {
          // extract the balance from the fetched account (we assume there's only 1)
          snapshot.forEach((doc) => {
            const mpesaAcct = doc.data();
            setMpesaBalance(mpesaAcct.balance);
          });
        });
        
        return mpesaSnapshot;
      } catch (error) {
        console.log("##### error fetching Mpesa Account Balance: ", error)
      }
    }
    
    getPayments();
    getMpesaBalance();
  }, [profile.company.id]);

  
  const createPayment = (record) => {
    const paymentData = {...record, serviceProviderId, createdBy, updatedBy, createdAt:record.date, updatedAt:record.date}
    // Here we check if the disbursement amount is greater than the main account 
    // & Mpesa account balance
    if (record.amount > balance && record.amount > mpesaBalance && record.type == 'disbursement') {
      notify("Insufficient funds to complete disbursement. Please top up your account balance", "warning");
    } else {
      createRecord(paymentData, props.resource, onSuccess, onFailure)
    }
  }

  const onSuccess = () => {
    notify("The payment was created successfully","success");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The payment was not created!", "warning");
    console.log(error);
  };

  return (
    <Create
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Payment/Create"
    >
      <SimpleForm save={createPayment} warnWhenUnsavedChanges>
        <div style={{ width: "100%" }}>
          <PaymentForm />
        </div>
      </SimpleForm>
    </Create>
  );
};

export const PaymentEdit = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The payment was updated successfully","success");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The payment was not updated!", "warning");
    console.log(error);
  };
  return (
    <Edit
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Payment/Edit"
    >
      <WithRecord>
        {({ record, ...props }) => (
          <SimpleForm record={record} {...props} warnWhenUnsavedChanges>
            <PaymentForm />
          </SimpleForm>
        )}
      </WithRecord>
    </Edit>
  );
};
