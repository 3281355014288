import { db } from "../config/firebaseConfig";
import firebase from "firebase/app";

export const addSpId = (customerId, spInviterCompanyId) => {
  try {
    var custRef = db.collection("customers").doc(customerId);
    custRef
      .update({
        serviceProviders:
          firebase.firestore.FieldValue.arrayUnion(spInviterCompanyId),
      })
      .then(() => {
        console.log("Successfully added spId to serviceProviders list");
      });
  } catch (err) {
    console.log("**** err: adding sp Inviter Id to customer ***");
  }
};

export const updateBsInvite= (inviteId, businessName) => {
  const invitesRef = db.collection("invites").doc(inviteId);
  // Update the status and businessName
  //check if businessName is :undefined i.e only status is being updated
  let invitePayload;
  if (businessName===undefined) {
    invitePayload = {
      status: "accepted",
    }
  } else {
    invitePayload = {
      status: "accepted",
      businessName: businessName,
    }
  }
  return invitesRef
  .update(invitePayload)
  .then(() => {
    console.log("Invites record successfully updated!");
  })
  .catch((error) => {
    // The document probably doesn't exist.
    console.error("Error updating invites record: ", error);
  });
};


export const getInviterCompanyName = async(spInviterCompanyId) => {
    try {
      const spRef = await db.collection('companies').doc(spInviterCompanyId).get();
      return spRef.data().name;
    } catch (error) {
      console.log("Error getting company data", error);
    }
}
