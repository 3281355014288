const transformOrderStatus = (str) => {
  // transform order statuses to a more readable format
  let status
  switch (str) {
    case "created":
    case "Created":
      status = "Created"
      break
    case "accepted":
    case "Accepted":
      status = "Accepted"
      break;
    case "declined":
    case "Declined":
      status = "Declined"
      break;
    case "dispatched":
    case "Dispatched":
      status = "Dispatched"
      break;
    case "dispatched-assigned":
    case "Dispatched - Assigned":
      status = "Dispatched - Assigned"
      break;
    case "dispatched-awaiting-delivery":
    case "Dispatched - Awaiting Delivery":
      status = "Dispatched - Out for delivery"
      break
    case "dispatched-in-transit":
    case "Dispatched - in Transit":
      status = "Dispatched - In transit"
      break;
    case "delivered":
    case "Delivered":
      status = "Delivered"
      break;
    case "delivered-pickup":
      status = "Delivered - Awaiting pickup"
      break;
    case "delivered-collected":
    case "Delivered - Collected":
      status = "Delivered - Collected"
      break;
    case "returned":
    case "Returned":
      status = "Returned"
      break;
    case "unknown":
      status = "Unknown"
      break;
    default:
  }
  return status
}

export default transformOrderStatus
