import styled from "styled-components";
import { Button } from "@material-ui/core";

export const GoogleAuthBtnWrapper = styled(Button)`
  width: 100%;
  margin: 1rem;
  background-color: #fff;
  border: 1px solid #00acc1;
  span {
    color: #00acc1;
    font-size: 1rem;
    margin: 0 0.8rem;
  }
  @media (max-width: 1024px) {
    margin: 0.5rem;
    span {
      font-size: 0.7rem;
    }
`;

export const EmailAndPswdBtnWrapper = styled(Button)`
  span {
    font-size: 1rem;
    margin: 0 0.8rem;
    color: #fff;
  }
  @media (max-width: 1024px) {
    margin: 0.5rem;
    span {
      font-size: 0.7rem;
    }
`;

export const EmailAndLinkBtnWrapper = styled(Button)`
  width: 100%;
  margin: 1rem;
  background-color: #fff;
  border: 1px solid #00acc1;
  span {
    color: #00acc1;
    font-size: 1rem;
    margin: 0 0.8rem;
  }
  @media (max-width: 1024px) {
    margin: 0.5rem;
    span {
      font-size: 0.7rem;
    }
  }
`;
