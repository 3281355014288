import React from "react";
import styled from "styled-components";

const ComingSoonPage = () => {
  return (
    <Wrapper>
      <TopBar>
        <img
          src="https://ik.imagekit.io/twendemobility/logo_rdg9vkc7W.svg?updatedAt=1630574915829"
          alt="Twende Mobility Logo"
        />
        <ComingSoonOnGooglePlayButton>
          <img
            src="https://ik.imagekit.io/twendemobility/google-play-icon_IWR-Ah7FXJ.svg?updatedAt=1630575922416"
            alt="Google Play Store"
          />
          <span>
            Coming soon on
            <br />
            Google Play
          </span>
        </ComingSoonOnGooglePlayButton>
      </TopBar>
      <img
        src="https://ik.imagekit.io/twendemobility/Coming_soon_img_PNnCfK-Ha.svg?updatedAt=1630574942757"
        alt="Building..."
      />
      <TitleText>COMING SOON!</TitleText>
      <SubtitleText>
        Our team is currently working hard. <br />
        Check back soon.
      </SubtitleText>
      <BackToHomeButton>
        <ButtonText>BACK TO HOME</ButtonText>
      </BackToHomeButton>
      <BottomBar>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/company/twendemobility/"
        >
          <img
            src="https://ik.imagekit.io/twendemobility/Social_Media_Icons/linkedin_m1ftonixo.svg?updatedAt=1630578223286"
            alt="LinkedIn"
          />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://facebook.com/twendemobility/"
        >
          <img
            src="https://ik.imagekit.io/twendemobility/Social_Media_Icons/fb_NAgK2tSyC.svg?updatedAt=1630578223248"
            alt="Facebook"
          />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/twendemobility/"
        >
          <img
            src="https://ik.imagekit.io/twendemobility/Social_Media_Icons/ig__-n_3GiNu.svg?updatedAt=1630578212508"
            alt="Instagram"
          />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/twendemobility?lang=en"
        >
          <img
            src="https://ik.imagekit.io/twendemobility/Social_Media_Icons/twitter_mzB2uU-Bx.svg?updatedAt=1630578203735"
            alt="Twitter"
          />
        </a>
      </BottomBar>
    </Wrapper>
  );
};

export default ComingSoonPage;

const Wrapper = styled.main`
  background-color: #fdfdfd;
  height: 100vh;
  width: 100vw
  padding: 6%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-image: url("https://ik.imagekit.io/twendemobility/background_KewmxNr-L.svg?updatedAt=1630576549482");
  p, h1 {
      text-align: center;
      line-height: 1.8;
  }
  img {
    width: 20rem;
    margin: 0.8rem;
   }
@media(max-width: 1024px) {
    height: auto;
    min-height: 100vh;
}
@media (max-width: 414px) {
    img {
        width: 13rem;
        margin: 0.6rem;
    }
  }
`;

const TitleText = styled.h1`
  font-family: "Ubuntu", sans-serif;
  font-size: 2.5rem;
  color: #000;
  margin: 0.5rem;
  @media (max-width: 1024px) {
    font-size: 2rem;
  }
  @media (max-width: 414px) {
    font-size: 1.5rem;
  }
`;

const SubtitleText = styled.p`
font-family:"Fira Sans", sans-serif
font-size: 1.5rem;
font-weight: 600;
color: #707070;
margin: 0.5rem;
@media(max-width: 1024px) {
    font-size: 1rem;
}
@media (max-width: 414px) {
    font-size: 0.7rem;
  }
`;

const BackToHomeButton = styled.button`
  border-radius: 0.2rem;
  padding: 1rem;
  margin: 1rem;
  border: solid 2px;
  border-radius: 0.4rem;
  margin: 1rem;
  border-color: #00acc1;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  background-color: #00acc1;
  transition: 0.2s;
  :hover {
    background-color: #006875;
    border-color: #006875;
  }
  @media (max-width: 1280px) {
    margin: 0.5rem;
  }
`;

const ButtonText = styled.span`
  font-family: "Ubuntu", sans-serif;
  font-size: 1.1rem;
  color: #fff;
  font-weight: 100 !important;
  @media (max-width: 414px) {
    font-size: 0.8rem;
  }
`;

const TopBar = styled.div`
  background-color: transparent;
  position: relative;
  top: 0.5rem;
  margin-bottom: 1rem;
  width: 100vw;
  padding: 0.5rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  img {
    width: 3rem;
  }
`;

const ComingSoonOnGooglePlayButton = styled.div`
  background-color: #000;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem;
  span {
    color: #fff;
    font-family: "Fira Sans", sans-serif;
    font-size: 0.8rem;
    line-height: 1.5;
  }
  img {
    width: 1.5rem;
    margin-right: 0.5rem;
  }
  @media (max-width: 414px) {
    padding: 0.3rem;
    span {
      font-size: 0.6rem;
    }
    img {
      width: 1rem;
      margin-right: 0.3rem;
    }
  }
`;

const BottomBar = styled.div`
  background-color: transparent;
  position: relative;
  bottom: 0.5rem;
  margin-top: 1rem;
  width: 100vw;
  padding: 0.5rem 2rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  img {
    width: 3rem;
  }
  @media (max-width: 1024px) {
    flex-direction: row;
    justify-content: center;
  }
  @media (max-width: 414px) {
    img {
      width: 2.5rem;
    }
  }
`;
