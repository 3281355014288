import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  overrides: {
    // you declare you override default material-ui styles
    MuiDrawer: {
      paper: {
        background: "#FFFFF !important",
        margin: "0 !important",
        paddingTop: "2em !important",
        minHeight: "100vh !important",
        height: "100% !important",
        color: "#000",
        width: "25vw",
      },
    },
    RaMenuItemLink: {
      root: {
        color: "#000000",
      },
      active: {
        color: "#00acc1 ",
        fontWeight: "650",
      },
      icon: {
        color: "inherit",
      },
    },
    RaAppBar: {
      toolbar: {
        '& button': {
          '&:not(:nth-child(1))': {
              display: 'none'
          }
      }
      }
    },
    RaSidebar:{
      root: {
        backgroundColor:"#FFFFFF",
      },
      fixed: {
        position: 'inherit'
      }
      },
    RaLayout: {
      root: {
        backgroundColor:"#FAFAFA",
      }
      },
      RaFilterFormInput: {
        body: {
          padding:"1.2rem 0.2rem 0.2rem",
        }
        },
    MuiStepper: {
      root: {
        padding: "10px",
        },
      },
    MuiStepIcon: {
      text: {
        fill: "#FFFFFF",
        },
      },
    MuiButton: {
      containedPrimary: {
        color: "#FFFFFF",
        },
      },
      MuiStepConnector: {
        lineVertical: {
          minHeight: "3px",
          },
        },
        MuiListItemIcon:{
          root: {
          color: "#000000",
          },
          },
  },
  palette: {
    primary: {
      main: "#00acc1",
    },
    secondary: {
      main: "#e55425",
    },
    tertiary: {
      main: "#000039",
    },
  },
});
