import React from "react";
import styled from "styled-components";

const Divider = () => {
  return (
    <Wrapper>
      <span>OR</span>
    </Wrapper>
  );
};

export default Divider;

const Wrapper = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span {
    color: #707070;
    margin: auto 0 !important;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;
