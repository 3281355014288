import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TwButton } from "../../components/authComponents/Buttons";
import { WorkspacePickerWrapper } from "../../components/authComponents/layout/Wrappers";
import styled from "styled-components";
import { getAdminsOrAgents, handleLogout } from "../auth/authHelpers";
import Workspace from "./Workspace";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { navigate } from "@reach/router";
import useGetWorkspaces from "../auth/useGetWorkspaces";

const WorkSpacePicker = ({ location }) => {
  const [state, setState] = useState({
    firstName: "",
  });
  // get workspaces
  const workspaces = useGetWorkspaces();

  const { firstName } = state;
  useEffect(() => {
    // update the firstName
    if (location.state && location.state.firstName) {
      setState((state) => ({
        ...state,
        firstName: location.state.firstName,
      }));
    }
  }, []);

  const handleCreateWorkspace = () =>
    navigate("/company-picker", { state: { userId: location.state.userId } });

  return (
    <WorkspacePickerWrapper>
      <TopRow>
        <Logo
          id="logo"
          src="https://ik.imagekit.io/twendemobility/logo_rdg9vkc7W.svg?updatedAt=1630574915829"
          alt="Twende Mobility Logo"
        />
        <TwButton flat logoutBtn handleClick={handleLogout} />
      </TopRow>
      <p>Your workspaces</p>
      <h1>Welcome back to Twift, {firstName}</h1>
      <h2>Select the workspace you wish to log into or create a new one.</h2>
      <WorkspaceRow>
        {workspaces.length
          ? workspaces.map((workspace) => (
              <Workspace
                key={workspace.id}
                workspace={workspace}
                companyType={workspace.companyType}
                companyId={
                  workspace.companyId !== undefined
                    ? workspace.companyId
                    : workspace.serviceProviderId
                }
                role={
                  workspace.companyId !== undefined
                    ? "admin"
                    : workspace.serviceProviderId !== undefined
                    ? "agent"
                    : undefined
                }
              />
            ))
          : null}
        {/* <CreateWorkspaceWrapper onClick={handleCreateWorkspace}>
          <CreateWorkspaceContentWrapper>
            <AddCircleOutlineIcon fontSize="large" />
          </CreateWorkspaceContentWrapper>
          <WorkspaceText>create workspace</WorkspaceText>
        </CreateWorkspaceWrapper> */}
      </WorkspaceRow>
    </WorkspacePickerWrapper>
  );
};

WorkSpacePicker.propTypes = {
  location: PropTypes.object,
};

export default WorkSpacePicker;

const CreateWorkspaceWrapper = styled.div`
  text-align: center;
  cursor: pointer;
`;

const WorkspaceText = styled.small`
  text-align: center;
  text-transform: capitalize;
  font: normal normal medium 18px/40px Ubuntu;
  font-weight: bold;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
`;

const CreateWorkspaceContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 159px;
  height: 151px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  font-size: 32px;
`;

const Logo = styled.img`
  height: 4rem;
`;

const WorkspaceRow = styled.div`
  margin-top: 80px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  width: 80%;
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 5rem;
`;
