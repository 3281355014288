import React, { useEffect } from "react";

const ErrorBoundaryTest = () => {
  useEffect(() => {
    throw new Error(
      "This is a hardcoded error to test the error-boundary component"
    );
  }, []);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p>Testing error boundaries</p>
    </div>
  );
};

export default ErrorBoundaryTest;
