import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  CardHeader,
  Avatar,
  CardActions,
  Typography,
  Grid,
  Divider,
  Chip,
} from "@material-ui/core";
import moment from "moment";
import { EditButton, useListContext } from "react-admin";
import { useStyles } from "./AgentCard";

const CustomerCard = (props) => {
  const classes = useStyles();
  const { ids, data, basePath } = useListContext(props);
  if (!ids || !data) {
    return null;
  }

  return (
    <div className={classes.root}>
      {ids.map((id) => (
        <div
          onClick={() => window.location.assign(`/home#/customers/${id}/show`)}
          key={data[id]}
        >
          <Card key={id} className={classes.card}>
            <CardHeader
              // action={<EditButton basePath={basePath} record={data[id]} />}
              title={
                <p className={classes.text}>
                  {`${data[id].name} `}
                </p>
              }
              avatar={<Avatar alt={`${data[id].firstName}`} />}
              subheader={`${data[id].phoneNumber} `}
            />
            <CardContent className={classes.cardContent}>
              <Divider className={classes.divider} />
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <div className={classes.wrapper}>
                    <Typography variant="caption" display="block" gutterBottom>
                      Customer Type: &nbsp;
                    </Typography>

                    <b>{data[id].type}</b>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.wrapper}>
                    <Typography variant="caption" display="block" gutterBottom>
                      Added On : &nbsp;
                    </Typography>
                    <b>{moment(data[id].createdAt * 1).format("DD-MM-YYYY")}</b>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default CustomerCard;
