import React, { useEffect, useState, useContext } from "react";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import RadioButtonUncheckedRoundedIcon from "@material-ui/icons/RadioButtonUncheckedRounded";
import moment from "moment";
import styled from "styled-components";
import { AuthHomeWrapper } from "../../components/authComponents/layout/Wrappers";
import { AuthHomeTodoCard } from "../../components/authComponents/layout/Cards";
import { db } from "../../config/firebaseConfig";
import { getAdminsOrAgents } from "../auth/authHelpers";
import _ from "lodash";
import { navigate } from "@reach/router";
import useAuthUser from "../auth/useAuthUser";
import EmailVerificationModal from "./EmailVerificationModal";
import syncGlobalProfile from "../auth/syncGlobalProfile";
import { ProfileContext } from "../../context/ProfileContext";
import { async } from "regenerator-runtime";
import { Alert } from "@material-ui/lab";

const AuthHome = ({ location }) => {
  const todayDate = moment().format("MMMM Do YYYY");

  // hooks
  const authUser = useAuthUser();
  const { updateProfile } = useContext(ProfileContext);
  const [state, setState] = useState({
    firstName: "",
    isCompanySetup: false,
    isEmailVerified: null,
    showEmailVerificationModal: false,
  });
  const [error, setError] = useState(null);
  const [errSeverity, setErrSeverity] = useState("error");
  const [showAlertAction, setShowAlertAction] = useState(false);

  const {
    firstName,
    isCompanySetup,
    isEmailVerified,
    showEmailVerificationModal,
  } = state;

  useEffect(() => {
    if (location.state && location.state.twiftUserId) {
      // use a self-invoking function to update state.firstName
      (async () => {
        const twiftUserDoc = await db
          .collection("users")
          .doc(location.state.twiftUserId)
          .get();
        // update state.firstName
        setState((state) => ({
          ...state,
          firstName: twiftUserDoc.data().firstName,
        }));

        // determine whether the current user has any companies set up
        const admins = await getAdminsOrAgents(
          twiftUserDoc.data().emailAddress,
          "admins"
        );
        if (!_.isEmpty(admins)) {
          // current user has at least one company set up
          // update state.isCompanySetup
          setState((state) => ({
            ...state,
            isCompanySetup: true,
          }));
        }
      })();
    }
  }, []);

  useEffect(() => {
    // check if email is verified and update state.isEmailVerified
    if (authUser) {
      setState((state) => ({
        ...state,
        isEmailVerified: authUser.emailVerified,
      }));
    }
  }, [authUser]);

  /**
   * useEffect to complete the sign in process for a user that has just verified their email address
   */
  useEffect(() => {
    (async () => {
      if (authUser && authUser.emailVerified && isCompanySetup) {
        /**
         * all steps have been completed, complete the sign in process and redirect accordingly
         */
        const syncedProfile = await syncGlobalProfile(authUser, updateProfile);
        if (
          syncedProfile?.admin?.hasMultipleAdmins ||
          syncedProfile?.agent?.hasMultipleAgents
        ) {
          navigate("/workspace-picker", {
            state: {
              emailAddress: authUser.email,
              firstName: location.state.firstName,
              userId: location.state.twiftUserId,
            },
          });
        } else if (syncedProfile.admin && syncedProfile.agent) {
          navigate("/workspace-picker", {
            state: {
              emailAddress: authUser.email,
              firstName: location.state.firstName,
              userId: location.state.twiftUserId,
            },
          });
        } else if (syncedProfile.admin && !syncedProfile.agent) {
          navigate("/home");
        } else if (syncedProfile.agent && !syncedProfile.admin) {
          navigate("agents");
        } else if(syncedProfile?.usersCount && syncedProfile?.usersCount > 1) {
          // we have duplicate user records
          // show error page
          throw new Error("Duplicate user records found");
        }
      }
    })();
  }, [authUser, isCompanySetup]);

  const handleCompleteAccountSetup = () => {
    // redirect to '/company-picker' only if isCompanySetup is false
    if (!isEmailVerified) {
      // ask the user to verify their email address before continuing with the other steps
      setError(
        "Please verify your email address first for you to continue with the remaining steps"
      );
      setErrSeverity("info");
    } else if (!isCompanySetup && isEmailVerified) {
      navigate("/company-picker", {
        state: { userId: location.state.twiftUserId },
      });
    }
  };

  const toggleEmailVerModal = () =>
    setState((state) => ({
      ...state,
      showEmailVerificationModal: !state.showEmailVerificationModal,
    }));

  return (
    <AuthHomeWrapper>
      {showEmailVerificationModal && (
        <EmailVerificationModal
          open={showEmailVerificationModal}
          handleClose={toggleEmailVerModal}
          email={authUser && authUser.email}
        />
      )}
      <Col>
        <p className="date">{todayDate}</p>
        <p>Hello {firstName}, Welcome to Twift!</p>
        <h1>
          Before you can create your First order on Twift, We need you to finish
          Setting up your account.
        </h1>
      </Col>
      <Col>
        {error !== null && (
          <Alert onClose={() => setError(null)} severity={errSeverity}>
            {error}
          </Alert>
        )}
        <p>To-do list</p>
        <AuthHomeTodoCard>
          <div className="row">
            <AuthHomeStepsHeader>
              <CheckCircleRoundedIcon className="authHomeIcon" />
              <span>Done</span>
              <AuthHomeSteps
                completed={true}
                className="todo completed card_text"
              >
                Create account
              </AuthHomeSteps>
            </AuthHomeStepsHeader>
          </div>
          {/* email verification section */}
          <AuthHomeStepsHeader>
            {isEmailVerified ? (
              <>
                <CheckCircleRoundedIcon className="authHomeIcon" />
                <span>Done</span>
              </>
            ) : (
              <TwUncheckedRadioBtn
                className="authHomeIcon"
                onClick={toggleEmailVerModal}
              />
            )}
            <AuthHomeSteps
              onClick={toggleEmailVerModal}
              completed={isEmailVerified}
              className="todo card_text"
            >
              Verify email address
            </AuthHomeSteps>
          </AuthHomeStepsHeader>
          <AuthHomeStepsHeader style={{ display: "flex" }}>
            {isCompanySetup ? (
              <>
                <CheckCircleRoundedIcon className="authHomeIcon" />
                <span>Done</span>
              </>
            ) : (
              <TwUncheckedRadioBtn
                className="authHomeIcon"
                onClick={handleCompleteAccountSetup}
              />
            )}
            <AuthHomeSteps
              onClick={handleCompleteAccountSetup}
              completed={isCompanySetup}
              className="todo card_text"
            >
              Select your company type
            </AuthHomeSteps>
          </AuthHomeStepsHeader>
          {!isCompanySetup && (
            <div className="helper-text">
              <span className="card_text">
                Your company has riders or drivers who carry out deliveries for
                other companies & individuals. That is, you are a
                courier/delivery service.
              </span>
            </div>
          )}
          <AuthHomeStepsHeader>
            {isCompanySetup ? (
              <>
                <CheckCircleRoundedIcon className="authHomeIcon" />
                <span>Done</span>
              </>
            ) : (
              <TwUncheckedRadioBtn
                className="authHomeIcon"
                onClick={handleCompleteAccountSetup}
              />
            )}
            <AuthHomeSteps
              onClick={handleCompleteAccountSetup}
              completed={isCompanySetup}
              className="todo card_text"
            >
              Set your company details
            </AuthHomeSteps>
          </AuthHomeStepsHeader>
          {!isCompanySetup && (
            <div className="helper-text">
              <span className="card_text">
                You sell products online or at a physical location, and want to
                find third-party logistics service providers to help fulfil your
                orders.
              </span>
            </div>
          )}
        </AuthHomeTodoCard>
      </Col>
    </AuthHomeWrapper>
  );
};

export default AuthHome;

const AuthHomeSteps = styled.p`
  cursor: ${(props) => props.completed === false && "pointer"};
  text-decoration: ${(props) => props.completed === true && "line-through"};
`;

const AuthHomeStepsHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;

  .authHomeIcon {
    color: #00acc1;
  }
`;

const Col = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 50%;
  padding: 1rem;
  p,
  h1,
  span {
    line-height: 1.8;
    font-family: "Ubuntu", sans-serif;
    font-weight: bold;
    margin: 3rem 0;
  }
  p {
    color: #000;
    font-size: 1rem;
  }
  h1 {
    color: #00acc1;
    font-size: 1.3rem;
    width: 70%;
  }
  .date {
    color: #707070;
    font-size: 0.8rem;
    font-family: "Fira Sans", sans-serif;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    padding: 0;
    p,
    h1,
    span {
      text-align: center;
      margin: 2.3rem 0;
    }
    h1 {
      width: 100%;
      font-size: 1.1rem;
      margin-left: 0;
    }
  }
`;

const TwUncheckedRadioBtn = styled(RadioButtonUncheckedRoundedIcon)`
  && {
    cursor: pointer;
  }
`;
