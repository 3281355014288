import React, { useContext, useState, useEffect } from "react";
//material ui imports
import { Grid, Button } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
//RA imports
import { AutocompleteInput } from "react-admin";
import { DateTimeInput } from "react-admin-date-inputs2";
//module imports
import parseDateTime from "../../utils/parseDateTime";
import { validateRequired } from "../../utils/validators";
import DateUtils from "@date-io/moment";
import PageHeader from "../PageHeader";
import { HiddenTextInput } from "../hiddenTextInput";
import { ProfileContext } from "../../context/ProfileContext";
import AgentQuickCreate from "../../pages/admin/Agents/components/AgentQuickCreate";
import { db } from "../../config/firebaseConfig";
import { makeStyles } from "@material-ui/core/styles";
import PackageDetailsAccordion from "./PackageDetails";
import styled from "styled-components";
import firebase from "firebase";

export const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    margin: "0 3rem 0.5rem",
  },
  paper: {
    width: "auto",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-evenly",
    gap: "3rem",
    padding: "0 1rem 1rem",
  },
  wrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(2,auto)",
    justifyContent: "space-evenly",
    gap: "3rem",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  hr {
    width: 30% !important;
    margin-left: auto;
    margin-right: auto;
    height: 1px;
    background-color: #d8d8d8;
    border: 0 none;
  }
  span {
    color: #00acc1;
    margin: auto 0 !important;
    display: flex;
    flex-direction: row;
    font-weight: 600;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;


const Wrapper = styled(Grid)`
  justify-content: center;
  margin-left: 7rem;
  @media (max-width: 600px) {
    margin-left: 0;
  }
`;

const DeliveryDate = styled.div`
 display:grid;
 justify-content: center;
 margin-top:2rem;
`;
const AssignAgent = styled.div`
  margin-top:1rem
`;
const AgentButton = styled.div`
 display:grid;
 justify-content: center;
`;



//dispatch orders form
const DispatchForm = () => {
  const classes = useStyles();
  const { profile } = useContext(ProfileContext);
  const deliveryDate = new Date(firebase.firestore.Timestamp.now().seconds*1000)
  const [showDialog, setShowDialog] = useState(false);
  const serviceProviderId = profile.company.id;
  const [agents, setAgents] = useState([]);
  //closes all modals on orders page
  const closeModal = () => {
    setShowDialog(false);
  };

  const handleAddAgent = () => {
    setShowDialog(true);
  };

  async function fetchData(collectionUrl) {
    // DRY function to fetch data from a collection url
    let querySnapshot = await db.collection(collectionUrl).get();
    const data = querySnapshot.docs.map((doc) => {
      // create object using doc data and id
      let agent = doc.data();
      agent.id = doc.id;
      //do not return the object if  deleted is true or undefined
      if (agent.deleted === false || agent.deleted === undefined){
        return agent;
        }  
    });
    return data;
  }

  useEffect(() => {
    // set agents
    const collectionUrl = `companies/${serviceProviderId}/agents`;
    fetchData(collectionUrl).then((data) => {
      setAgents(data);
    });
  }, [showDialog, serviceProviderId]);

  return (
    <>
      {showDialog && (
        <AgentQuickCreate
          defaultState={true}
          onDialogClosed={closeModal}
          serviceProviderId={serviceProviderId}
        />
      )}
      <Grid container spacing={2}>
            <Wrapper item lg={6} sm={12}  xs={12}  >   
                <Grid item lg={12} sm={12}  xs={12}>
                <PageHeader title="Dispatch information" />
                <AssignAgent>
                  <AutocompleteInput
                    source="agent.id"
                    label="Assign existing agent"
                    variant="outlined"
                    choices={agents}
                    allowEmpty={false}
                    emptyText="Create New"
                    emptyValue="create-new"
                    optionText="name"
                    optionValue="id"
                    validate={validateRequired}
                    inputProps={{
                      form: {
                        autocomplete: "off",
                      },
                    }}
                    fullWidth
                  />
                  </AssignAgent>
                   <Row>
                        <hr /> <span> OR  </span>
                        <hr />
                   </Row>
                   <AgentButton>
                    <Button
                      variant="outlined"
                      onClick={handleAddAgent}
                      className={classes.button}
                      color="primary"
                    >
                      add new agent
                    </Button>
                    </AgentButton>
                </Grid>
                <DeliveryDate item lg={12} sm={12}  xs={12} >
                <MuiPickersUtilsProvider utils={DateUtils}>
                  <DateTimeInput
                    source="date"
                    variant="outlined"
                    label="Delivery Date"
                    parse={parseDateTime}
                    validate={validateRequired}
                    initialValue={deliveryDate}
                    fullWidth
                    options={{
                      format: "DD/MM/YYYY, HH:mm:ss",
                      ampm: false,
                      clearable: true,
                      showTodayButton: true,
                    }}
                  />
                </MuiPickersUtilsProvider>
                </DeliveryDate>
                {/* status === created */}
                <HiddenTextInput source="status.value" initialValue="created" />
            </Wrapper>
            <Grid item lg={4} sm={10} xs={12}>
              <PackageDetailsAccordion />
            </Grid>
          </Grid>
    </>
  );
};
export default DispatchForm;
