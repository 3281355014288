import React, {useState, useContext, useEffect} from "react";
import { Link } from "react-router-dom";
import {Paper,
    Grid,
    Avatar,
    Divider,
    Typography,
    Button
} from "@material-ui/core";
import { ProfileContext } from "../../context/ProfileContext";
import { db } from "../../config/firebaseConfig";

import styles from "./styles.js";

const Messages = () => {
  const { profile } = useContext(ProfileContext);
  const companyId = profile.company.id;
  const [messages, setMessages] = useState([])
  const messageProfile =
    "https://miro.medium.com/max/600/1*PiHoomzwh9Plr9_GA26JcA.png";


  useEffect(() => {
    const getMessages = async () => {
      // fetch the 3 latest messages
      let newMessages = [];

      const messageList = (
        await db.collection('messages')
          .where("serviceProviderId", "==", companyId)
          .orderBy("createdAt", "desc").limit(3)
          .get()
      ).docs

      messageList.forEach((msg) => {
        const message = msg.data()
        return !message.deleted || message.deleted !== true
          ? newMessages.push(message)
          : null;
      })
        setMessages(newMessages)
    };

    getMessages();
  }, [companyId]);

  return (
    <div>
      <Paper style={styles.wrapper}>
        <h4 style={styles.title}>Messages</h4>
        { messages.map((message) => (
          <div key={message.id}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item style={styles.avatar}>
                <Avatar src={messageProfile} />
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography variant="subtitle1" color="textPrimary" component="p">
                  {message.sender}
                </Typography>
                <Typography style={styles.messages} variant="body2" color="textSecondary" component="p">
                  {message.body}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={styles.divider} />
          </div>
        ))}
        <Link to="/messages" style={styles.cta}>
          <Button style={styles.button} variant="contained" color="primary">
            view all messages
          </Button>
        </Link>
      </Paper>
    </div>
  );
};

export default Messages;
