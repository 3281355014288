import React, { useContext } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
	CardHeader,
	Avatar,
	CardActions,
	Typography,
	Grid,
	Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { EditButton, useListContext, DeleteButton } from "react-admin";
import InviteStatus from "../InviteStatus";
import { ProfileContext } from "../../context/ProfileContext";

export const useStyles = makeStyles((theme) => ({
	root: { margin: "1em" },
	card: {
		borderRadius: "10px",
		margin: "0.3rem 0 0.2rem",
	},
	cardTitleContent: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
	},
	cardContent: {
		...theme.typography.body2,
		display: "flex",
		flexDirection: "column",
		marginLeft: "1rem",
	},
	Content: {
		display: "grid",
		gridTemplateColumns: "repeat(2, auto)",
		gap: "0.8rem",
	},
	text: {
		fontSize: "1.2rem",
		fontWeight: "bold",
		color: "black",
		margin: " 0.2rem  0 -0.3rem",
	},
	divider: {
		marginBottom: "1rem",
		marginTop: "-1rem",
	},
	wrapper: {
		marginLeft: "1rem",
	},
}));

const AdminCard = (props) => {
	const classes = useStyles();
	const { ids, data, basePath } = useListContext(props);
	const { profile } = useContext(ProfileContext);
	const serviceProviderId = profile.company.id;
	if (!ids || !data) {
		return null;
	}

	return (
		<div className={classes.root}>
			{ids.map((id) => (
				<div
					onClick={() =>
						window.location.assign(
							`/home#//companies/${serviceProviderId}/admins/${id}/show`,
						)
					}
					key={data[id]}
				>
					<Card key={id} className={classes.card}>
						<CardHeader
							action={<InviteStatus vehicleType={data[id].inviteConfirmed} />}
							title={
								<p className={classes.text}>
									{data[id].name
										? data[id].name
										: `${data[id].firstName} ${data[id].lastName}`}
								</p>
							}
							avatar={<Avatar alt={`${data[id].firstName}`} />}
							subheader={`${data[id].phoneNumber} `}
						/>
						<CardContent className={classes.cardContent}>
							<Divider className={classes.divider} />
							<Grid container spacing={1}>
								<Grid item xs={6}>
									<div className={classes.wrapper}>
										<Typography variant="caption" display="block" gutterBottom>
											Title: &nbsp;
										</Typography>

										<b>{data[id].team.name || "N/A"}</b>
									</div>
								</Grid>
								<Grid item xs={6}>
									<div className={classes.wrapper}>
										<Typography variant="caption" display="block" gutterBottom>
											Added On : &nbsp;
										</Typography>
										<b>{moment(data[id].createdAt).format("DD-MM-YYYY")}</b>
									</div>
								</Grid>
							</Grid>
						</CardContent>
						{/* <CardActions className={classes.Content}>
							<EditButton basePath={basePath} record={data[id]} />
							<DeleteButton basePath={basePath} record={data[id]} />
						</CardActions> */}
					</Card>
				</div>
			))}
		</div>
	);
};

export default AdminCard;
