import React, { useContext } from "react";
import {
  List,
  Filter,
  TextInput,
  DateField,
  Datagrid,
  ReferenceField,
} from "react-admin";
import { ProfileContext } from "../../../context/ProfileContext";
import EmptyListPage from "../../../components/EmptyListPage";
import CustomerIcon from "@material-ui/icons/PeopleOutline";
import ActionsPopOver from "../../admin/Agents/components/ActionsPopOver";
import BioReferenceField from "../../admin/Agents/components/AgentBioField";

//filter customers by their phonenumber
const CustomersFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="phoneNumber" alwaysOn />
    <TextInput label="Email Address" source="emailAddress" />
  </Filter>
);

//read the available customers
const BizCustomerList = (props) => {
  const { profile } = useContext(ProfileContext);
  const companyId = profile.company.id;

  return (
    <List
      {...props}
      title="Customers"
      sort={{ field: "createdAt", order: "DESC" }}
      filters={<CustomersFilter />}
      filter={{ companyId: companyId }}
      empty={<EmptyListPage icon={CustomerIcon} />}
    >
      <Datagrid>
        {/* Name field */}
        <ReferenceField
          label="Customer Details"
          source="userId"
          reference="users"
          link={false}
        >
          <BioReferenceField />
        </ReferenceField>
        <DateField label="Added on" source="createdAt" showTime />
        <ActionsPopOver />
      </Datagrid>
    </List>
  );
};
export default BizCustomerList;
