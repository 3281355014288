import React,{ useContext} from 'react'
import { 
    Create,
    SimpleForm,
    useRedirect,
    useNotify,
} from 'react-admin'
import CustomerForm  from './BizCustomerForm.js'
import {db} from "../../../../config/firebaseConfig";
import {ProfileContext} from "../../../../context/ProfileContext"

//post a new customer
const BizCustomerCreate = (props) => {
    const redirect = useRedirect();
    const notify = useNotify();
    const { profile } = useContext(ProfileContext);
    const companyId = profile.company.id;
  
    const onSuccess = () => {
      notify("The customer profile was created successfully","success");
      redirect("list", props.basePath);
    };
  
    const onFailure = (error) => {
      notify("The customer profile was not created!", "warning");
      console.log(error);
    };

    const saveCustomers = async (record) => {
      const {firstName, lastName, emailAddress, phoneNumber, bio, ...rest} = record
      const customerUserData = {firstName, lastName, emailAddress, phoneNumber, bio}
      const customerData = {companyId, companyType:"serviceProvider",...rest}
      // Save customer data to the users collection and the rest in customers
      try {
        // save Customer's user data
        await db.collection("users").add(customerUserData)
        .then((docRef) => {
          // get the doc id and set it as userId for the customer data
          customerData.userId = docRef.id
          console.log("Success: User created with ID: ", docRef.id);
        })
        .then( async() => {
          // save the Customer's data and show toast
          await db.collection(props.resource).add(customerData)
          onSuccess()
          console.log(`Success: Customer ${customerData.userId} created`);
        })
        .catch((error) => {
          onFailure(error)
          console.error("Error: Create customer failed ", error);
        });
      } catch (error) {
        onFailure(error)
        console.log(error);
      }
    }


  return (
    <Create {...props}
     title='Create a Customer' 
     onSuccess={onSuccess}
     mutationMode="undoable"
     onFailure={onFailure}
    >
      <SimpleForm save={saveCustomers} warnWhenUnsavedChanges>
      <CustomerForm />
      </SimpleForm>
    </Create>
  )
}
 export default BizCustomerCreate;