const getResourceLink = (companyId) => {
  const companies = "companies",
    payments = "payments",
    customers = "customers",
    permissions = "permissions",
    messages = "messages",
    trips = "trips",
    bookings = "bookings",
    fleet = "fleet",
    owners = "owners",
    dispatches = "dispatches",
    consignments = "consignments",
    address = "address",
    ratings = "ratings",
    orders= "orders",
    requests= "requests",
    settings= "settings",
    help= "help",
    businesses= "businesses",
    home="home",
    agentsMapView="live-map",
    authHome = "auth-home",
    invites="invites";

  const admins = `/companies/${companyId}/admins`,
    hubs = `/companies/${companyId}/hubs`,
    accounts = `/companies/${companyId}/accounts`,
    teams = `/companies/${companyId}/teams`,
    routes = `/companies/${companyId}/route`,
    agents = `/companies/${companyId}/agents`,
    waybills = `/companies/${companyId}/waybills`,
    status = `/companies/${companyId}/status`,
    businessAdmins = `/businesses/${companyId}/admins`,
    businessTeams = `/businesses/${companyId}/teams`;

  const resourceNames = {
    companies,
    admins,
    payments,
    customers,
    permissions,
    messages,
    trips,
    bookings,
    fleet,
    owners,
    dispatches,
    consignments,
    hubs,
    accounts,
    teams,
    routes,
    agents,
    waybills,
    address,
    status,
    ratings,
    orders,
    requests,
    settings,
    help,
    home,
    businesses,
    agentsMapView,
    invites,
    authHome,
    businessTeams,
    businessAdmins,
  };

  return resourceNames;
};

export default getResourceLink;
