import { db } from "../config/firebaseConfig";
import { pickBy, identity } from 'lodash'

// Remove undefined values from incoming data
const cleanObject = (data) => {
  return pickBy(data, identity)
}

//write to the respective resource i.e agents, admins or customers
const createResource = async (resourceLink, payload, onSuccess, onFailure) => {
  try {

    // save the Agent, Admin or Customer data and show toast
    await db.collection(resourceLink).add(cleanObject(payload));
    onSuccess();
    console.log(`Success: User ${payload.userId} created`);
  } catch (error) {
    onFailure(error);
    console.error("Error: Create user failed ", error);
  }
};

const createUser = async (userData, resourceData, resource, onSuccess, onFailure) => {
  // by default, phone numbers are required for all user types
  // thus, at a minimum each query should start with the field
  let usersRef = db
  .collection("users")
  .where("phoneNumber", "==", userData.phoneNumber);

  // attach email address filter if value is set
  // see https://stackoverflow.com/a/48037033/1145905
  if (userData.emailAddress !== undefined) {
      console.log("createUser - adding filter for emailAddress: ", userData.emailAddress);
      usersRef = usersRef.where("emailAddress", "==", userData.emailAddress);
  }

  usersRef
    .get()
    .then((querySnap) => {
      const matchedUsers = querySnap.size;
      console.log(`${matchedUsers} matched users for email addr ${userData.emailAddress} and/or phone number ${userData.phoneNumber}`);
      if (matchedUsers == 0) {
        // there doesn't exist a user, create one
        db.collection("users")
          .add(cleanObject(userData))
          .then((createdUser) => {
            resourceData.userId = createdUser.id;
            // create child record
             createResource(resource, resourceData, onSuccess, onFailure);
          });
      } else if (matchedUsers == 1) {
        // there exists one user record, link it w/ the to-be-created user child
        querySnap.forEach((doc) => {
          console.log(`setting userId ${doc.id} (${doc.data().emailAddress}) to new resource ${resource}`);
          resourceData.userId = doc.id;
          // create agent, admin or customer
          createResource(resource, resourceData, onSuccess, onFailure);
        });
      } else {
        // possible error state: multiple linked users with the email address + phone number combo
        // exempt child records that *don't* have a valid email address, they might still be linked
        // to the same user via phone number, depending on the creating admin's intent

        // if there's no email address specified, we don't know the creator's intent so we'll have
        // to create a new (possibly duplicate) user record (could consolidate them later in an onUpdate trigger)
        // after all, currently the user records technically serve no real purpose
        if (userData.emailAddress == undefined) {
          db.collection("users")
            .add(cleanObject(userData))
            .then((createdUser) => {
              console.log(`created user record (id ${createdUser.id}) - ${createdUser.emailAddress}. will link to new resource ${resource}`);
              resourceData.userId = createdUser.id;
              // create child record
              createResource(resource, resourceData, onSuccess, onFailure);
            });
        } else {
          // an email address was specified and yet we still have duplicates
          const errorMsg = `there are multiple (${matchedUsers}) matched users for this combination of credentials: email address ${userData.emailAddress} and/or phone number ${userData.phoneNumber}`
          console.error(errorMsg);
          onFailure(errorMsg);
        }
      }
    })
  .catch((error) => {
    // On error, notify failure
    onFailure(error)
    console.error("Error: Create user failed ", error);
  });
}

export default createUser;