import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  getCompany,
  getCompanyRefString,
  handleSelectWorkspace,
} from "../auth/authHelpers";
import useAuthUser from "../auth/useAuthUser";
import { syncCustomClaims, syncTeamData } from "../auth/syncGlobalProfile";
import { ProfileContext } from "../../context/ProfileContext";
import { navigate } from "@reach/router";

const Workspace = ({ workspace, companyType, companyId, role }) => {
  const [state, setState] = useState({
    company: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  // hooks
  const authUser = useAuthUser();
  const { updateProfile } = useContext(ProfileContext);

  // destructure state
  const { company } = state;

  useEffect(() => {
    const loadCompany = async () => {
      const fetchedCompany = await getCompany(companyType, companyId);
      setState((state) => ({
        ...state,
        company: fetchedCompany,
      }));
    };

    loadCompany();
  }, []);

  // get initials from the company name
  const companyInitials =
    company &&
    company.name &&
    company.name
      .split(" ")
      .map((word) => word[0])
      .join("");

  const redirectToWorkspace = () => {
    setIsLoading(true);
    handleSelectWorkspace(authUser, company, workspace, role, updateProfile);
    setIsLoading(false);
  };

  return (
    <WorkspaceWrapper onClick={redirectToWorkspace}>
      <WorkspaceContentWrapper>
        {isLoading ? <CircularProgress color="inherit" /> : companyInitials}
      </WorkspaceContentWrapper>
      <WorkspaceText>{company && company.name}</WorkspaceText>
    </WorkspaceWrapper>
  );
};

Workspace.propTypes = {
  companyId: PropTypes.string.isRequired,
  companyType: PropTypes.string.isRequired,
  workspace: PropTypes.object.isRequired,
  role: PropTypes.any.isRequired,
};
export default Workspace;

const WorkspaceWrapper = styled.div`
  text-align: center;
  cursor: pointer;
`;

const WorkspaceText = styled.small`
  text-align: center;
  text-transform: capitalize;
  font: normal normal medium 18px/40px Ubuntu;
  font-weight: bold;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
`;

const WorkspaceContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font: normal normal medium 42px/70px Ubuntu;
  letter-spacing: 0px;
  font-weight: bolder;
  font-size: 2rem;
  height: 151px;
  width: 159px;
  background: #00acc1 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
`;
