import * as React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer ,
} from "recharts";
import Paper from "@material-ui/core/Paper";
import styles from "./styles.js";

// use this data if revenue is undefined or all values are 0
const data = [
  {
    day: "No Data for this period",
    ksh: 0,
  },
];

const LineGraph = ({ revenue }) => {
  //check if weekly revenue data exists and all ksh values are not 0
  const checkEmpty = revenue && revenue !== "undefined" ? revenue.every(el => el.ksh === 0) : true

  return (
    <div>
      <Paper style={styles.wrapper}>
        <h4 style={styles.title}>Revenue overview</h4>
        <ResponsiveContainer width="100%" height={270}>
        <AreaChart  data={!checkEmpty ? revenue : data}>
          <defs>
            <linearGradient id="colorksh" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#00acc1" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#00acc1" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="day" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 " />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="ksh"
            stroke="#00acc1"
            fillOpacity={1}
            fill="url(#colorksh)"
          />
        </AreaChart>
       </ResponsiveContainer>
      </Paper>
    </div>
  );
};

export default LineGraph;
