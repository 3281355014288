import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  AuthPageWrapper,
  AuthContentWrapper,
} from "../../../components/authComponents/layout/Wrappers";
import AuthSidebar from "../../../components/authComponents/Sidebar";
import { TwButton } from "../../../components/authComponents/Buttons";
import {
  CssBaseline,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import { navigate } from "@reach/router";

const CompanyTypePickerPage = ({ location }) => {
  const [accountType, setAccountType] = useState("");

  useEffect(() => {
    // prefill the account type if it exists in location.state which will be true if a user navigates back to this page from the '/company-details' page
    if (location.state && location.state.accountType) {
      setAccountType(location.state.accountType);
    }
  }, []);

  const handleChange = (e) => {
    setAccountType(e.target.value);
  };

  // redirect to '/company-details' and pass the accountType chosen by the user as state
  const handleNext = () =>
    navigate("/company-details", {
      state: { accountType, userId: location.state.userId },
    });

  const handleBack = () =>
    navigate("/auth-home", { state: { userId: location.state.userId } });

  return (
    <AuthPageWrapper>
      <AuthSidebar workspace />
      <AuthContentWrapper>
        <Typography
          className="auth_title"
          style={{ marginBottom: "2rem" }}
          component="h1"
        >
          Choose your account type
        </Typography>
        <CssBaseline />
        <RadioGroup
          aria-label="chooseAccountType"
          name="chooseAccountType"
          value={accountType}
          onChange={handleChange}
        >
          <div className="radio-btn-container">
            <FormControlLabel
              value="serviceProvider"
              control={<Radio style={{ color: "#00acc1" }} />}
              label="Service Provider"
            />
            <div className="helper-text">
              <span>
                Your company has riders or drivers who carry out deliveries for
                other companies & individuals. That is, you are a
                courier/delivery service
              </span>
            </div>
          </div>

          <div className="radio-btn-container">
            <FormControlLabel
              value="business"
              control={<Radio style={{ color: "#00acc1" }} />}
              label="Business"
            />
            <div className="helper-text">
              <span>
                You sell products online or at a physical location, and want to
                find third-party logistics service providers to help fulfill
                your orders
              </span>
            </div>
          </div>
        </RadioGroup>
        <CssBaseline />
        <Row>
          <TwButton flat backBtn handleClick={handleBack} />
          <TwButton
            nextBtn
            contained
            disabled={!accountType ? true : false}
            text="Next"
            style={{ width: "50%" }}
            handleClick={handleNext}
          />
        </Row>
      </AuthContentWrapper>
    </AuthPageWrapper>
  );
};

CompanyTypePickerPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default CompanyTypePickerPage;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
`;
