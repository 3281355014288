import * as React from "react";
import { Doughnut, defaults } from "react-chartjs-2";
import Paper from "@material-ui/core/Paper";
import styles from "./styles.js";

const Dashboard = ({totalOrders, completedCount, returnedCount, pendingCount}) => {

  const data = {
    labels: ["Completed", "Returned", "Pending"],
    datasets: [
      {
        backgroundColor: ["#00acc1", "rgb(255, 99, 132)", "rgb(255, 205, 86)"],
        data: [completedCount, returnedCount, pendingCount],
      },
    ],
  };

  const noData = {
    labels: ["You have no orders"],
    datasets: [
      {
        backgroundColor: ["#00acc1"],
        data: [1],
        hoverOffset: 4,
      },
    ],
  };
  
  defaults.global.maintainAspectRatio = false;


  return (
    <div>
      <Paper style={styles.wrapper}>
        <h4 style={styles.title}>Orders overview</h4>
        <div>
        <Doughnut
          height={270}
          data={totalOrders === 0 ? noData : data}
          options={{
            tooltips: {
              enabled: totalOrders === 0 ? false : true,
            },
            legend: {
              display: true,
              position: "bottom",
              labels: {
                fontSize: 8,
                padding: 40,
              }
            },
          }}
        />
        </div>
      </Paper>
    </div>
  );
};

export default Dashboard;
