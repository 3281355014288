import React, { useContext } from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
} from "@material-ui/core";
import PeopleIcon from "@material-ui/icons/People";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import { makeStyles } from "@material-ui/core/styles";
import {
  DateField,
  EditButton,
  ReferenceField,
  TextField,
  ChipField,
  FunctionField,
  DeleteButton,
} from "react-admin";
import PropTypes from "prop-types";
import getResourceLink from "../../routes/resource-names";
import { ProfileContext } from "../../context/ProfileContext";
import styled from "styled-components";

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, auto);
  gap: 3rem;
  @media (min-width: 800px) {
    grid-template-columns: repeat(2, auto);
  }
`;

const useListStyles = makeStyles((theme) => ({
  cardTitleContent: {
    width: "70%",
    margin: "0 2.5rem 0",
  },
  Content: {
    display: "grid",
    gridTemplateColumns: "repeat(2, auto)",
    gap: "0.8rem",
  },
  main: {
    marginBottom: "-3rem",
  },
  avatar: {
    backgroundColor: "#00acc1 ",
  },
  card: {
    margin: "0 1.5rem 0",
    borderRadius: "10px",
  },
  text: {
    fontWeight: "bold",
    fontSize: "1.5rem",
  },
  title: {
    fontWeight: "bold",
    fontSize: "0.8rem",
  },
  link: {
    textDecoration: "none"
  },
}));

const MobileGrid = ({ ids, data, basePath }) => {
  const classes = useListStyles();
  const { profile } = useContext(ProfileContext);
  let cachedProfile = JSON.parse(localStorage.getItem("cachedProfile"));
  const compType = profile.admin
  ? profile.admin.companyType
  : cachedProfile.admin.companyType;
  const resource_names = getResourceLink(profile.company.id);
  if (!ids || !data || !basePath) {
    return null;
  }

  return (
    <CardWrapper>
      {ids.map((id) => (
        <div key={id}  onClick={() => window.location.assign(`/home#/consignments/${id}/show`)}>
          <Card className={classes.card} elevation={2} >
            <CardHeader
              action={<ChipField record={data[id]} source="status.value" />}
              title={
                <div>
                  <Typography
                    variant="caption"
                    display="block"
                    color="textSecondary"
                    gutterBottom
                  >
                    order reference:
                  </Typography>
                  <TextField
                    className={classes.text}
                    record={data[id]}
                    source="trackingCode"
                  />
                </div>
              }
              subheader={
                <div>
                  <Typography variant="caption" display="block" gutterBottom>
                    delivery date:
                  </Typography>
                  <DateField
                    record={data[id]}
                    className={classes.title}
                    source="date"
                  />
                </div>
              }
            />
            <CardContent>
              <Divider />
              <List>
                <div>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar className={classes.avatar}>
                        <PersonPinCircleIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <ReferenceField
                          record={data[id]}
                          label="Sender"
                          reference="customers"
                          source="sender.id"
                          link="show"
                        >
                          <FunctionField
                            className={classes.title}
                            render={(record) =>
                              `${record.name}`
                            }
                          />
                        </ReferenceField>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            color="textPrimary"
                          >
                            <TextField record={data[id]} source="origin.name" />
                          </Typography>
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                          >
                            sender
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </div>

                <div className={classes.cardTitleContent}>
                  <ListItem alignItems="center">
                    {compType === "serviceProvider" && (
                    <ListItemText
                      primary={
                        <ReferenceField
                          record={data[id]}
                          source="agent.id"
                          reference={resource_names.agents}
                          className={classes.cardLinkLink}
                          link={false}
                        >
                          <FunctionField
                            className={classes.title}
                            render={(record) => record !== null ? `${record.firstName} ${record.lastName}`:"N/A"}
                          />
                        </ReferenceField>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                          >
                            agent
                          </Typography>
                        </React.Fragment>
                      }
                    />
                    )}
                  </ListItem>
                  <Divider />
                </div>
                <div>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar className={classes.avatar}>
                        <PeopleIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <FunctionField
                          className={classes.title}
                          render={(record) => 
                            record.sender.firstName ? `${record.sender.firstName} ${record.sender.lastName}`:`${record.sender.name}`
                          }
                        />
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            color="textPrimary"
                          >
                            <TextField
                              record={data[id]}
                              source="destination.name"
                            />
                          </Typography>
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                          >
                            recipient
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </div>
              </List>
              <Divider />
            </CardContent>
            {/* <CardActions className={classes.Content}>
              <EditButton basePath={basePath} record={data[id]} />
              <DeleteButton basePath={basePath} record={data[id]} />
            </CardActions> */}
          </Card>
          </div>
      ))}
    </CardWrapper>
  );
};
MobileGrid.defaultProps = {
  data: {},
  ids: [],
};
MobileGrid.propTypes = {
  ids: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  basePath: PropTypes.string
};
export default MobileGrid;
