import React from "react";
import Typography from "@material-ui/core/Typography";

import styled from "styled-components";

const FAQWrapper = styled.div`
  border: 1px solid #f2f2f2;
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 1rem;
  width: 100%;
  justify-content: start;
  margin-bottom: 2rem;
  p {
    font-family: "Ubuntu", sans-serif;
    font-weight: 700;
    font-size: 1.1rem;
  }
  @media (max-width: 1024px) {
    width: 95%;
  }
`;

export default function SimplePaper({label}) {
  return (
    <FAQWrapper>
      <Typography variant="body2" component="p">
        {label}
      </Typography>
    </FAQWrapper>
  );
}
