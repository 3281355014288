import React, { useContext } from "react";
import { Typography, Avatar, Popover } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ProfileContext } from "../context/ProfileContext";

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: "0.7rem",
    borderRadius: "15px",
  },
  wrapper: {
    marginLeft: "0.9rem",
  },
  text: {
    color: "#00acc1",
  },
}));

export default function MouseOverPopover() {
  const { profile } = useContext(ProfileContext);
  const companyName = profile.company.name;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  
 //shows popover onFocus
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
//shows popover out off Focus
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classes.wrapper}>
      <Avatar
        alt={companyName}
        src="/static/images/avatar/1.jpg" //todo add dynamic avatar/logo
        aria-owns={open ? "mouse-over-popover" : undefined}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography className={classes.text}>{companyName}</Typography>
      </Popover>
    </div>
  );
}
