import React from "react";
import AuthSidebar from "../../../components/authComponents/Sidebar";
import { TwButton } from "../../../components/authComponents/Buttons";
import {
  AuthContentWrapper,
  AuthPageWrapper,
} from "../../../components/authComponents/layout/Wrappers";

const CongratsPage = () => {
  return (
    <AuthPageWrapper>
      <AuthSidebar congrats />
      <AuthContentWrapper>
        <img
          height="300rem"
          id="congrats_img"
          style={{ marginBottom: "5rem" }}
          src="https://ik.imagekit.io/twendemobility/Auth/welcome_CL-LypSFF7.svg?updatedAt=1632224767435"
          alt="welcome"
        />
        <h1 id="congrats_text" style={{ marginBottom: "2rem", fontSize: "1rem" }}>
          Welcome to Twift! We are excited to have you onboard!
        </h1>
        <TwButton contained text="Launch Twift" style={{ width: "50%" }} />
      </AuthContentWrapper>
    </AuthPageWrapper>
  );
};

export default CongratsPage;
