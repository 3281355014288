import React from "react";
import styled from "styled-components";
import { StyledTextField } from "../../pages/auth/AuthStyles";
import { InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

export const EmailInputField = ({ email, onChangeHandler, formErrors }) => {
  return (
    <Wrapper
      variant="outlined"
      margin="normal"
      label="E-mail address"
      name="email"
      value={email}
      placeholder="example@email.com"
      id="userEmail"
      onChange={onChangeHandler}
      autoComplete="email"
      required
      fullWidth
      autoFocus
      error={formErrors.email}
      helperText={formErrors.email && formErrors.email}
    />
  );
};

export const PasswordInputField = ({
  password,
  onChangeHandler,
  formErrors,
  showPassword,
  handleClickShowPassword,
  handleMouseDownPassword,
}) => {
  return (
    <Wrapper
      variant="outlined"
      margin="normal"
      label="Password"
      name="password"
      value={password}
      id="password"
      onChange={(event) => onChangeHandler(event)}
      autoComplete="password"
      required
      fullWidth
      autoFocus
      error={formErrors.password}
      helperText={formErrors?.password}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

const Wrapper = styled(StyledTextField)`
  && {
    width: 100%;
  }
`;
