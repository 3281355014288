import React from "react";
import styled from "styled-components";
import { validateRequired } from "../../../../../utils/validators";
import DateUtils from "@date-io/moment";
import parseDateTime from "../../../../../utils/parseDateTime";
import { DateTimeInput } from "ra-ui-materialui";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import firebase from "firebase";

const DateField = () => {
  const deliveryDate = new Date(
    firebase.firestore.Timestamp.now().seconds * 1000
  );

  return (
    <MuiPickersUtilsProvider utils={DateUtils}>
      <DeliveryDate
        source="date"
        variant="outlined"
        label="Delivery Date & Time"
        parse={parseDateTime}
        validate={validateRequired}
        initialValue={deliveryDate}
        options={{
          format: "DD/MM/YYYY, HH:mm:ss",
          ampm: false,
          clearable: true,
          showTodayButton: true,
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateField;

const DeliveryDate = styled(DateTimeInput)`
  && {
    margin: 0.5rem 1rem;
  }
`;
