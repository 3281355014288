import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button } from "@material-ui/core";
import styled from "styled-components";
import { EmailInputField } from "../../components/authComponents/InputFields";
import { TwButton } from "../../components/authComponents/Buttons";
import { sanitizeEmailAddress } from "../auth/authHelpers";
import { auth } from "../../config/firebaseConfig";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: "0.3rem",
    padding: theme.spacing(2, 4, 3),
  },
}));

const PasswordReset = ({ sendAlertInfoToLogin }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState("");
  const [formErrors, setFormErrors] = useState({
    email: null,
    password: null,
  });

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePasswordReset = async () => {
    if (!email) {
      setFormErrors("* This field is required");
    } else {
      /**
       *  - add settings to add a continue url ( adds a continue btn in the pswd reset UI ) and pass state
       *  - after a successfull pswd reset, users will be redirected to process.env.REACT_APP_AUTH_PWD_RESET_SUCCESS_URL
       *  - which is fetched from doppler and redirects to the signin component.
       *
       *  - see @link - https://firebase.google.com/docs/auth/web/passing-state-in-email-actions and https://stackoverflow.com/questions/45563604/firebase-reset-password-template-ui/50178308
       */
      const actionCodeSettings = {
        url: `${process.env.REACT_APP_AUTH_PWD_RESET_SUCCESS_URL}?email=${email}`,
        handleCodeInApp: false,
      };

      // sanitize the email input
      // remove all white space and convert the result to lowercase
      const sanitizedEmail = sanitizeEmailAddress(email);

      try {
        await auth.sendPasswordResetEmail(sanitizedEmail, actionCodeSettings);

        console.log("**** success: pswd reset link sent ****");
        // notify the user that we've sent them a pswd reset email
        sendAlertInfoToLogin({
          message: `Please check your email. A password reset link has been sent to ${email}`,
          severity: `success`,
        });

        // close the password reset modal
        setOpen(false);
      } catch (error) {
        console.error("**** err: send pswd reset ****", error);
        // extract the message from the error object
        const { message } = error;

        // show an alert with the message
        sendAlertInfoToLogin({
          message: `${message}`,
          severity: `error`,
        });

        // close the password reset modal
        setOpen(false);
      }
    }
  };

  return (
    <div>
      <ResetPwdBtnWrapper onClick={handleOpen}>
        <span>Forgot password?</span>
      </ResetPwdBtnWrapper>

      <ModalWrapper
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 className="title" id="transition-modal-title">
              Recover your TWIFT account
            </h2>
            <p className="subtitle" id="transition-modal-description">
              Enter the email address you signed up with
            </p>
            <EmailInputField
              onChangeHandler={handleEmailChange}
              formErrors={formErrors}
            />
            <TwButton
              style={{ margin: "2rem auto"}}
              contained
              text="Send link"
              handleClick={handlePasswordReset}
            />
            <p className="description">
              * A link will be sent to your e-mail address allowing you to reset
              your password
            </p>
          </div>
        </Fade>
      </ModalWrapper>
    </div>
  );
};

PasswordReset.propTypes = {
  sendAlertInfoToLogin: PropTypes.func.isRequired,
};

export default PasswordReset;

const ResetPwdBtnWrapper = styled(Button)`
  && {
    text-transform: none;
    font-family: "Fira Sans", sans-serif;
    font-size: 0.9rem;
    color: #00acc1;
  }
`;

const ModalWrapper = styled(Modal)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 6%;
    h2,
    p {
      text-align: center;
      line-height: 1.8;
      margin: 3rem 0;
    }
    .title {
      font-size: 1.2rem;
      color: #000;
      font-weight: bold;
      font-family: "Ubuntu", sans-serif;
    }
    .subtitle {
      font-size: 1rem;
      color: #707070;
      font-family: "Fira Sans", sans-serif;
    }
    .description {
      font-size: 0.8rem;
      color: #707070;
      font-family: "Fira Sans", sans-serif;
    }
  }
`;
