import React, { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import {
  List,
  Show,
  Datagrid,
  SimpleShowLayout,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  ReferenceManyField,
  NumberField,
  EditButton,
  DeleteButton,
  useRedirect,
  useNotify,
} from "react-admin";
import SaveCompanyOnSaveToolbar from "../../components/saveCompanyOnSave";

export const TripList = (props) => {
  const profile = useContext(UserContext);
  const serviceProviderId = profile.company.id;

  return serviceProviderId ? (
    <List {...props} title="Trips" filter={{ serviceProviderId: serviceProviderId }}>
      <Datagrid rowClick="show">
        <TextField source="route" />
        <TextField source="fare" />
        <TextField source="driver" />
        <TextField source="vehicle" />
        <TextField source="bookings" />
        <TextField source="marshall" />
        <TextField source="rating" />
        <EditButton />
        <DeleteButton undoable={false} />
      </Datagrid>
    </List>
  ) : null;
};

export const TripShow = (props) => (
  <Show {...props} title="Trip">
    <SimpleShowLayout>
      <TextField source="route" />
      <TextField source="fare" />
      <TextField source="driver" />
      <TextField source="vehicle" />
      <TextField source="bookings" />
      <TextField source="marshall" />
      <ReferenceManyField label="Bookings" reference="bookings" target="trip">
        <Datagrid>
          <TextField source="id" />
          <TextField source="passenger" />
          <NumberField source="amount" />
          <TextField source="destination" />
          <TextField source="seat" />
          <TextField source="status" />
        </Datagrid>
        <TextField source="rating" />
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

const TripForm = () => (
  <>
    <TextInput source="route" />
    <TextInput source="fare" />
    <TextInput source="driver" />
    <TextInput source="vehicle" />
    <TextInput source="bookings" />
    <TextInput source="marshall" />
    <TextInput source="hub" />
    <TextField source="rating" />
  </>
)

export const TripCreate = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The trip was created successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The trip was not created!", "warning");
    console.log(error);
  };
  return (
    <Create
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Trip/Create"
    >
      <SimpleForm toolbar={<SaveCompanyOnSaveToolbar />} warnWhenUnsavedChanges>
        <TripForm />
      </SimpleForm>
    </Create>
  );
};

export const TripEdit = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The trip was updated successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The trip not updated!", "warning");
    console.log(error);
  };
  return (
    <Edit
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Trip/Edit"
    >
      <SimpleForm warnWhenUnsavedChanges>
        <TripForm />
      </SimpleForm>
    </Edit>
  );
};
