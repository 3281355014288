import * as React from "react";
import {
  Filter,
  SelectInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useFilterStyles = makeStyles({
  status: { width: 150 },
});

const MessageFilter = (props) => {
  const classes = useFilterStyles();
  return (
    <Filter {...props}>
      <SelectInput
        source="status"
        choices={[
          { id: "Success", name: "Success" },
          { id: "Failed", name: "Failed" },
          { id: "InvalidPhoneNumber", name: "InvalidPhoneNumber" },
        ]}
        className={classes.status}
      />
    </Filter>
  );
};

export default MessageFilter;
