import React, { useState, useEffect, useContext } from "react";
import { ProfileContext } from "../../context/ProfileContext";
import { Card, CardContent, CardMedia } from "@material-ui/core";
import { db } from "../../config/firebaseConfig";
import { Typography } from "@material-ui/core";
import Link from "../StyledLink";
import styles from "./styles.js";
import rider from "../../assets/agent.svg";

const AgentsCard = () => {
  const { profile } = useContext(ProfileContext);
  const [state, setState] = useState({
    totalAgents: 0,
  });
  const serviceProviderId = profile.company.id;

  useEffect(() => {
    const getAgents = async () => {
      try {
        // fetch the value of the company's agents
        const unsubscribe = db.collection(`companies/${serviceProviderId}/agents`)
          .onSnapshot((snapshot) => {
            //all available agents list
            const availableAgentsList = [];
            snapshot.forEach((doc) => {
              const agent = doc.data();
              //fetch for agents with no deleted field
              if (!agent.deleted){
              //add agents which haven't been deleted to agent list
                availableAgentsList.push(agent)
              }
              //update the agents available
              setState((state) => ({
                ...state,
                totalAgents: availableAgentsList.length,
              }));
            });
          });

        return unsubscribe;
      } catch (error) {
        console.log(" error fetching agents ", error);
      }
    };
    getAgents();
  }, [serviceProviderId]);

  return (
    <div>
      <Link to={`//companies/${serviceProviderId}/agents`}>
        <Card style={styles.card}>
          <CardMedia
            style={styles.logo}
            image={rider}
            title="Revenue"
          />
          <CardContent>
            <div style={styles.content}>
              <Typography variant="h4" gutterBottom style={styles.CardContent}>
                Total Agents
              </Typography>
              <Typography variant="h1" gutterBottom style={styles.h1}>
                {state.totalAgents}
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Link>
    </div>
  );
};

export default AgentsCard;
