import React, { useContext } from "react";
import {
  List,
  FunctionField,
  TopToolbar,
  CreateButton,
  ExportButton,
} from "react-admin";
import { ProfileContext } from "../../../../context/ProfileContext";
import EmptyListPage from "../../../../components/EmptyListPage";
import exporter from "../../../../components/customDataExporter";
import useFetchCurrentUserPermissions from "../../../../components/useFetchCurrentUserPermissions";
import getResourceLink from "../../../../routes/resource-names";
import AgentIcon from "@material-ui/icons/DriveEta";
import AgentVehicleType from "../components/AgentVehicleType";
import AgentBioField from "../components/AgentBioField";
import ActionsPopOver from "../components/ActionsPopOver";
import InviteStatus from "../../../../components/InviteStatus";
import StyledTableHeader from "../../../../layout/TableHeader";
import { useMediaQuery} from "@material-ui/core";
import AgentCard from "../../../../components/userComponent/AgentCard";

const AgentsList = (props) => {
  const { profile } = useContext(ProfileContext);
  const serviceProviderId = profile.company.id;
  const isSmall = useMediaQuery(`(max-width:950px)`);

  const ListActions = () => (
    <TopToolbar>
      {/* <CreateButton
        label="Create Agent"
        variant="contained"
        color="primary"
        to={`//companies/${serviceProviderId}/agents/create`}
      /> */}
      <ExportButton />
    </TopToolbar>
  );

  const { formattedPermissions } = useFetchCurrentUserPermissions();

  const resource = getResourceLink(serviceProviderId);

  const agentsExporter = (agents) =>
    exporter({
      resourceArr: agents,
      serviceProviderId: serviceProviderId,
      resourceType: "agents",
    });

  return serviceProviderId ? (
    <List
      {...props}
      title="Agents"
      sort={{ field: "createdAt", order: "DESC" }}
      filter={{ serviceProviderId: serviceProviderId }}
      empty={<EmptyListPage icon={AgentIcon} />}
      // actions={<ListActions />}
      exporter={
        formattedPermissions[resource.agents] &&
        formattedPermissions[resource.agents].list
          ? agentsExporter
          : false
      }
    >
      {isSmall ? (
        <AgentCard/>
        ) : (
      <StyledTableHeader rowClick="show" >
        <AgentBioField />
        <FunctionField
          label="Vehicle type"
          render={(record) => (
            <AgentVehicleType vehicleType={record.vehicleType || "N/A"} />
          )}
        />
         <FunctionField
          label="Invite status"
          render={(record) => (
            <InviteStatus inviteConfirmed={record.inviteStatus} />
          )}
        />
        {/* <ActionsPopOver   show={false}/> */}
      </StyledTableHeader>
        )}
    </List>
  ) : null;
};
export default AgentsList;
