import red from '@material-ui/core/colors/red';

const rowStyle = (selectedRow) => (record) => {
    let style = {};
    if (!record) {
        return style;
    }
    if (selectedRow && selectedRow === record.id) {
        style = {
            ...style,
            backgroundColor: '#EDEDED',
        };
    }
    if (record.status === 'Success')
        return {
            ...style,
            borderLeftColor: '#00ACC1',
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    else 
        return {
            ...style,
            borderLeftColor: red[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
};

export default rowStyle;