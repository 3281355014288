import React, { useContext, useState } from "react";
import { ProfileContext } from "../../../../context/ProfileContext";
import {
  Create,
  SimpleForm,
  TextInput,
  useRedirect,
  useNotify,
  SelectInput,
  BooleanInput,
  FormDataConsumer,
} from "react-admin";
import {
  validateName,
  validateText,
  validateIdNumber,
  validateRequired,
  validateEmail,
} from "../../../../utils/validators";
import PhoneNumberField from "../../../../layout/TwPhoneNumberInput";
import PageHeader from "../../../../components/PageHeader";
import { Grid } from "@material-ui/core";
import { HiddenTextInput } from "../../../../components/hiddenTextInput";
import GeocodedInputField from "../../../../components/common/GeocodedInputField";
import createAgent from "../../../../utils/createAgent";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useStyles } from "../../../../components/Orders/PackageDetails";

export const AgentForm = ({ record }) => {
  let [agentAddress, setAgentAddress] = useState();
  const classes = useStyles();
  const vehicleTypes = [
    { id: "Trolley", name: "Trolley" },
    { id: "Hand Cart", name: "Hand Cart" },
    { id: "Motorbike", name: "Motorbike" },
    { id: "Car", name: "Car" },
    { id: "Van", name: "Van" },
    { id: "Pick-up", name: "Pick-up" },
    { id: "Mini-Lorry", name: "Mini-Lorry" },
    { id: "Lorry", name: "Lorry" },
    { id: "Trailer", name: "Trailer" },
  ];

  return (
    <div style={{ width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageHeader title="Enter Agent Details" />
        </Grid>
        <Grid container spacing={2} item xs={12} sm={6} lg={12} justifyContent="center" >
          <Grid item xs={12} sm={5}>
            <TextInput
              source="firstName"
              validate={validateName}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextInput
              source="lastName"
              validate={validateName}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={10}>
            <PhoneNumberField variant="outlined" />
          </Grid>
          <HiddenTextInput source="inviteStatus" initialValue="pending" />
          <Grid item xs={12} sm={10}>
          <TextInput
                      variant="outlined"
                      source="nationalIDNumber"
                      label="National ID No"
                      validate={validateIdNumber}
                      fullWidth
                      placeholder="Enter Passport or ID number"
                    />
            </Grid>
          <Grid item xs={12} sm={10}>
            <Accordion style={{ margin: "0.6rem 0 1rem" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <div>
                  <span className={classes.heading}>Additional Details</span> <span className={classes.secondaryHeading}>(optional)</span>
                </div>
                  
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12} sm={12} lg={10}  justifyContent="center">
                  <Grid item lg={12}>
                    <TextInput
                      source="emailAddress"
                      variant="outlined"
                      validate={validateEmail}
                      fullWidth
                      placeholder="johndoe@example.com"
                    />
                    <Grid container spacing={2}>
                      <PageHeader title="Vehicle Details" />
                      <Grid item xs={12} sm={12} lg={7}>
                        <SelectInput
                          variant="outlined"
                          source="vehicleType"
                          label="Vehicle Type"
                          choices={vehicleTypes}
                          validate={validateText}
                          fullWidth
                          helperText="Choose means of transportation "
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} lg={5}>
                        <BooleanInput source="hasPrimaryVehicle" />
                      </Grid>
                    </Grid>
                    <FormDataConsumer>
                      {({ formData }) => {
                        const registeredVehicles = [
                          "Motorbike",
                          "Car",
                          "Van",
                          "Pick-up",
                          "Mini-Lorry",
                          "Lorry",
                          "Trailer",
                        ];
                        if (
                          registeredVehicles.includes(formData.vehicleType) &&
                          formData.hasPrimaryVehicle === true
                        ) {
                          return (
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6} lg={12} >
                                <TextInput
                                  source="registrationNumber"
                                  label="Registration Number"
                                  validate={validateRequired}
                                  variant="outlined"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} lg={12}>
                                <TextInput
                                  source="drivingLicenseNo"
                                  label="Driving License Number"
                                  validate={validateRequired}
                                  variant="outlined"
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                          );
                        }
                      }}
                    </FormDataConsumer>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const AgentsCreate = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const { profile } = useContext(ProfileContext);

  const onSuccess = () => {
    notify("The agent profile was created successfully","success");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify(error, "warning");
    console.log(error);
  };

  return (
    <Create
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Agent/Create"
    >
      <SimpleForm
        save={(record) => createAgent(record, profile, notify, onSuccess, onFailure, props.resource, profile)}
        warnWhenUnsavedChanges
      >
        <AgentForm />
      </SimpleForm>
    </Create>
  );
};
export default AgentsCreate;
