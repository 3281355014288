import React from "react";
import { Card, CardContent, CardMedia } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Link from "../StyledLink";
import styles from "./styles.js"
import clients from "../../assets/clients.svg";

const NumberofCustomers = ({customersNo}) => {

  return (
    <div>
      <Link to="/customers">
        <Card style={styles.card}>
          <CardMedia
            style={styles.logo}
            image={clients}
            title="Total Clients"
          />
          <CardContent>
          <div style={styles.content}>
              <Typography variant="h4" gutterBottom style={styles.CardContent}>
                Total Clients
              </Typography>
              <Typography variant="h1" gutterBottom style={styles.h1}>
              {customersNo}
            </Typography>
            </div>
          </CardContent>
        </Card>
      </Link>
    </div>
  );
};

export default NumberofCustomers;
