// Button to handle resending invites for agents and admins
import * as React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useNotify } from "react-admin"
import { db } from "../config/firebaseConfig";



const useStyles = makeStyles({
  inviter: {
    color: "#FFFFFF",
    display: "inline-flex",
    alignItems: "center",
  },
});

/**
 * takes in the resource path and the docId for either admins or agents.
 */
const ResendInviteButton = ({ path, docId, inviteConfirmed }) => {
  const classes = useStyles();
  const notify = useNotify();
  const inviteDateTime = {inviteDateTime: Date()}
  const docRef = db.collection(path).doc(docId)

  const onSuccess = () => {
    notify("Invite sent successfully!", "success");
  };

  const onFailure = (error) => {
    notify("Invite send failed!", "warning");
    console.log(error);
  };
  
  const handleClick = async () => {
    try {
      await docRef.update(inviteDateTime)
      .then(() => {
        onSuccess()
        console.log("Resend invite success");
      }).catch((error) => {
        onFailure()
        console.log(error);
      })
    } catch (error) {
      onFailure()
      console.log(error);
    }
  }
  return inviteConfirmed !== "accepted"
  ? (
    <Button
      size="small"
      variant="contained"
      disableElevation
      color="primary"
      className={classes.inviter}
      onClick={ handleClick }
    >
      Resend Invite
    </Button>
  ): null;
};

export default ResendInviteButton;
