import React, { useContext, useState, useEffect } from "react";
import {
  Edit,
  SimpleForm,
  useRedirect,
  useNotify,
  TextInput,
  SelectInput,
  AutocompleteInput,
  ReferenceInput,
} from "react-admin";
import { ProfileContext } from "../../context/ProfileContext";
import styled from "styled-components";
import {  Paper } from "@material-ui/core";
import { DateTimeInput } from "react-admin-date-inputs2";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import parseDateTime from "../../utils/parseDateTime";
import { validateRequired } from "../../utils/validators";
import DateUtils from "@date-io/moment";
import { db } from "../../config/firebaseConfig";

const EditView = styled.div`
  display: flex;
  gap: 2;
  flex-direction: row;
  width: 100%;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const StyledPaper = styled(Paper)`
  padding: 2rem;
  text-align: center;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content:center;
`;

const OrdersEdit = (props) => {
  const { profile } = useContext(ProfileContext);
  const serviceProviderId = profile.company.id;
  let cachedProfile = JSON.parse(localStorage.getItem("cachedProfile"));
  const compType = profile.admin
    ? profile.admin.companyType
    : cachedProfile.admin.companyType;
  const [serviceProviders, setServiceProviders] = useState([]);

  //fetches for a list of all available SPs
  const fetchServiceProviders = async () => {
    let ServiceProvidersList = [];
    const providersList = (await db.collection("companies").get()).docs;

    providersList.forEach((provider) => {
      const data = {
        id: provider.id,
        ...provider.data(),
      };
      /*eslint-disable eqeqeq*/
      return !data.deleted || data.deleted != true
        ? (ServiceProvidersList = [...ServiceProvidersList, data])
        : null;
    });

    setServiceProviders(ServiceProvidersList);
  };

  useEffect(() => {
    fetchServiceProviders();
  }, []);

  const redirect = useRedirect();
  const notify = useNotify();

  const AgentOptionRenderer = (option) =>
    option.name ? option.name : `${option.firstName} ${option.lastName}`;

  const onSuccess = () => {
    notify("The order was updated successfully", "success");
    redirect("list", props.basePath);
  };

  const onFailure = () => {
    notify("The order was not updated!", "warning");
  };

  //status history of an order
  const statusChoices = [
    { id: "created", name: "Created" },
    { id: "accepted", name: "Accepted" },
    { id: "declined", name: "Declined" },
    { id: "dispatched", name: "Dispatched" },
    { id: "dispatched-assigned", name: "Dispatched - Assigned" },
    {
      id: "dispatched-awaiting-delivery",
      name: "Dispatched - Out for Delivery",
    },
    { id: "dispatched-in-transit", name: "Dispatched - in Transit" },
    { id: "delivered", name: "Delivered" },
    { id: "delivered-collected", name: "Delivered - Collected" },
    { id: "returned", name: "Returned" },
  ];

  //saves the orders record to the DB
  const saveOrder = async (record) => {
    const { id } = record;
    console.log(`record==>`, record);
    db.collection("consignments").doc(id).update(record);

    try {
      onSuccess();
    } catch (error) {
      onFailure(error);
      console.log(error);
    }
  };

  return (
    <>
      <Edit
        {...props}
        onSuccess={onSuccess}
        undoable={false}
        onFailure={onFailure}
        title="Orders Edit"
      >
        <SimpleForm warnWhenUnsavedChanges save={(record) => saveOrder(record)}>
          <EditView>
            {compType === "serviceProvider" ? (
              <StyledPaper>
                <h3>Senders Details</h3>
                <TextInput
                  source="sender.name"
                  label="Name"
                  variant="outlined"
                />
                <TextInput
                  source="sender.phoneNumber"
                  label="Phone number"
                  variant="outlined"
                />
                <TextInput
                  source="origin.name"
                  label="Address"
                  variant="outlined"
                  helperText="*The primary pick up location"
                />
              </StyledPaper>
            ) : null}
            <StyledPaper>
              <h3>Recipients Details</h3>
              <TextInput
                source="recipient.name"
                label="Name"
                variant="outlined"
              />
              <TextInput
                source="recipient.phoneNumber"
                label="Phone number"
                variant="outlined"
              />
              <TextInput
                source="destination.name"
                label="Address"
                variant="outlined"
                helperText="*The primary drop off location"
              />
            </StyledPaper>
            <StyledPaper>
              <h3>Dispatch Details</h3>
              {compType === "serviceProvider" ? (
                <>
                  <ReferenceInput
                    label="Agent"
                    source="agent.id"
                    variant="outlined"
                    reference={`/companies/${serviceProviderId}/agents`}
					fullWidth
                  >
                    <AutocompleteInput optionText="name" />
                  </ReferenceInput>
                  <SelectInput
                    source="status.value"
                    label="Status"
                    variant="outlined"
                    choices={statusChoices}
                  />
                </>
              ) : (
                <SelectInput
                  source="serviceProviderId"
                  label="Service Provider"
                  choices={serviceProviders}
                  optionText="name"
                  variant="outlined"
                  optionValue="id"
                  fullWidth
                />
              )}
              <MuiPickersUtilsProvider utils={DateUtils}>
                <DateTimeInput
                  source="date"
                  variant="outlined"
                  label="Shipping Date"
                  parse={parseDateTime}
                  validate={validateRequired}
                  fullWidth
                  options={{
                    format: "DD/MM/YYYY, HH:mm:ss",
                    ampm: false,
                    clearable: true,
                    showTodayButton: true,
                  }}
                />
              </MuiPickersUtilsProvider>
            </StyledPaper>
          </EditView>
        </SimpleForm>
      </Edit>
    </>
  );
};
export default OrdersEdit;
