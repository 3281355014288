import React, { useContext } from "react";
import { ProfileContext } from "../context/ProfileContext";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { DashboardMenuItem, MenuItemLink } from "react-admin";

// icons
import SecurityIcon from "@material-ui/icons/Security";
import CreditCardIcon from "@material-ui/icons/CreditCard"; // payments
import CustomerIcon from "@material-ui/icons/People"; // customers
import MessageIcon from "@material-ui/icons/Message"; // messages
import TeamIcon from "@material-ui/icons/AssignmentInd"; // teams
import AdminIcon from "@material-ui/icons/SupervisorAccount"; // admins
import AgentIcon from "@material-ui/icons/DriveEta"; // agent
import HomeIcon from "@material-ui/icons/Home"; //home
import SendIcon from "@material-ui/icons/Send";
import HelpIcon from "@material-ui/icons/Help";
import useFetchCurrentUserPermissions from "../components/useFetchCurrentUserPermissions";
import getResourceLink from "../routes/resource-names";
import TwLogo from "../assets/twift-by-twende.svg";
import SettingsIcon from "@material-ui/icons/Settings";
import styled from "styled-components";
import CompanyAvatar from "../components/companyAvatar";
import { Typography } from "@material-ui/core";
import SubMenu from "./TwSubMenu";
import MapIcon from "@material-ui/icons/Map";
import ListIcon from "@material-ui/icons/List";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
`;

const MenuWrapper = styled.div`
  display: grid;
  justify-content: center;
  gap: 0.5rem;
`;

//twift logo styles
const LogoWrapper = styled(Wrapper)`
  margin-top: 2.5rem;
`;

const CompanyDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: -0.5rem;
  margin: 1rem 0.1rem 1rem;
`;

const Menu = ({ onMenuClick, dense, logout }) => {
  const { profile } = useContext(ProfileContext);
  const companyId = profile.company.id;
  const resource_name = getResourceLink(companyId);
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const compType = profile.admin.companyType;
  const companyName = profile.company.name;
  const [state, setState] = React.useState({
    toggleRolesAccess: false,
  });
  const [agentSubState, setAgentSubState] = React.useState({
    toggleAgentsViews: false,
  });

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  const handleToggleAgentSubMenu = (menu) => {
    setAgentSubState((agentSubState) => ({ ...agentSubState, [menu]: !agentSubState[menu] }));
  };

  useSelector((state) => state.theme); // force rerender on theme change

  const {
    permissions: {
      payments,
      agents,
      consignments,
      customers,
      admins,
      messages,
      teams,
    },
  } = useFetchCurrentUserPermissions();

  const desiredPermsArr = ["write", "read"];

  /**
   * checks whether a user has the permsissions required to view a given resource
   * @param {Array} resourcePermsArr - an array of resource permissions, i.e, ["read", "write", "delete"]
   * @returns {Boolean}
   */
  const hasPerms = (resourcePermsArr) =>
    desiredPermsArr.some((item) => resourcePermsArr.includes(item));

  // display only if current user is an admin
  return profile.admin ? (
    <div>
      <CompanyDetails>
        <CompanyAvatar />
        <Typography variant="h5" style={{ marginTop: "0.3rem" }}>
          {companyName}
        </Typography>
      </CompanyDetails>
      <MenuWrapper>
        <DashboardMenuItem
          onClick={onMenuClick}
          sidebarIsOpen={open}
          primaryText={open && "Dashboard"}
          tooltipProps={!open && { title: "Dashboard" }}
          leftIcon={<HomeIcon />}
        />
        {compType === "serviceProvider" ? (
          <>
            {consignments && consignments.length && hasPerms(consignments) ? (
              <MenuItemLink
                key={resource_name.consignments}
                to={`/${resource_name.consignments}`}
                primaryText={open && "Orders"}
                leftIcon={<SendIcon />}
                tooltipProps={!open && { title: "Orders" }}
                sidebarIsOpen={open}
                dense={dense}
              />
            ) : null}
            {payments && payments.length && hasPerms(payments) ? (
              <MenuItemLink
                to={`/${resource_name.payments}`}
                primaryText={open && "Payments"}
                leftIcon={<CreditCardIcon />}
                tooltipProps={!open && { title: "Payments" }}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            ) : null}
            {/* {dispatches && dispatches.length && hasPerms(dispatches) ? (
              <MenuItemLink
                to={`/${resource_name.dispatches}`}
                primaryText="Dispatches"
                leftIcon={<DispatchIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            ) : null} */}
            {customers && customers.length && hasPerms(customers) ? (
              <MenuItemLink
                to={`/${resource_name.customers}`}
                primaryText={open && "Customers"}
                leftIcon={<CustomerIcon />}
                onClick={onMenuClick}
                tooltipProps={!open && { title: "Customers" }}
                sidebarIsOpen={open}
                dense={dense}
              />
            ) : null}
            {agents && agents.length && hasPerms(agents) ? (
              // <SubMenu
              //   handleToggle={() => handleToggleAgentSubMenu("toggleAgentsViews")}
              //   isOpen={agentSubState.toggleAgentsViews}
              //   sidebarIsOpen={open}
              //   name="Agents"
              //   icon={<AgentIcon />}
              //   dense={dense}
              // >
                <MenuItemLink
                  to={`/${resource_name.agents}`}
                  primaryText={"Agents"}
                  leftIcon={<AgentIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  tooltipProps={!open && { title: "List" }}
                  dense={dense}
                />) : null}
                {/* <MenuItemLink
                  to={`/${resource_name.agentsMapView}`}
                  primaryText={open && "Live Map"}
                  leftIcon={<MapIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  tooltipProps={!open && { title: "Live Map" }}
                  dense={dense}
                />
              </SubMenu> */}
            
            {messages && messages.length && hasPerms(messages) ? (
              <MenuItemLink
                to={`/${resource_name.messages}`}
                primaryText={open && "Messages"}
                leftIcon={<MessageIcon />}
                onClick={onMenuClick}
                tooltipProps={!open && { title: "Messages" }}
                sidebarIsOpen={open}
                dense={dense}
              />
            ) : null}
          </>
        ) : (
          <>
            {consignments && consignments.length && hasPerms(consignments) ? (
              <MenuItemLink
                to={`/${resource_name.consignments}`}
                primaryText={open && "Orders"}
                leftIcon={<SendIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                tooltipProps={!open && { title: "Orders" }}
                dense={dense}
              />
            ) : null}
            {customers && customers.length && hasPerms(customers) ? (
              <MenuItemLink
                to={`/${resource_name.customers}`}
                primaryText={open && "Customers"}
                leftIcon={<CustomerIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                tooltipProps={!open && { title: "Customers" }}
                dense={dense}
              />
            ) : null}
          </>
        )}
        <SubMenu
          handleToggle={() => handleToggle("toggleRolesAccess")}
          isOpen={state.toggleRolesAccess}
          sidebarIsOpen={open}
          name="Roles & Access"
          icon={<SecurityIcon />}
          dense={dense}
        >
          {teams && teams.length && hasPerms(teams) ? (
            <MenuItemLink
              to={
                compType === "serviceProvider"
                  ? `/${resource_name.teams}`
                  : compType === "business"
                  ? `/${resource_name.businessTeams}`
                  : `/**`
              }
              primaryText={open && "Teams"}
              leftIcon={<CustomerIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              tooltipProps={!open && { title: "Teams" }}
              dense={dense}
            />
          ) : null}
          {admins && admins.length && hasPerms(admins) ? (
            <MenuItemLink
              to={
                compType === "serviceProvider"
                  ? `/${resource_name.admins}`
                  : compType === "business"
                  ? `/${resource_name.businessAdmins}`
                  : `/**`
              }
              primaryText={open && "Admins"}
              leftIcon={<TeamIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              tooltipProps={!open && { title: "Admins" }}
              dense={dense}
            />
          ) : null}
        </SubMenu>
        <MenuItemLink
          to={`/${resource_name.settings}`}
          primaryText={open && "Settings"}
          leftIcon={<SettingsIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          tooltipProps={!open && { title: "Settings" }}
          dense={dense}
        />
        <MenuItemLink
          to={`/${resource_name.help}`}
          primaryText={open && "Help"}
          leftIcon={<HelpIcon />}
          onClick={onMenuClick}
          tooltipProps={!open && { title: "Help" }}
          sidebarIsOpen={open}
          dense={dense}
        />
        {isXSmall && logout}
      </MenuWrapper>
      <LogoWrapper>
        <img src={TwLogo} alt="twift by twende" width="47%" />
      </LogoWrapper>
    </div>
  ) : null;
};

export default Menu;
