import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";

const TwSnackBar = ({
  isOpen,
  handleClose,
  vertPositioning,
  horPositioning,
  message,
  errContext,
  action
}) => {

  // a default action button to be used in the snackbar
  const SnackBarAction = (
    <div style={{ display: "block" }}>
      <a
        href={`https://wa.me/254792954505?text=${encodeURIComponent(`Error context: ${errContext}`)}`}
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none", color: "white", cursor: "pointer" }}
      >
        <Button color="inherit" size="small">
          CONTACT US
        </Button>
      </a>
    </div>
  );

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: vertPositioning, horizontal: horPositioning }}
        open={isOpen}
        onClose={handleClose}
        message={message}
        key={vertPositioning + horPositioning}
        action={action ? action : SnackBarAction} // show the action button if one has been given else use SnackBarAction
      />
    </div>
  );
};

TwSnackBar.defaultProps = {
  isOpen: false,
  vertPositioning: "top",
  horPositioning: "center",
  message: "example of a snackbar",
};

TwSnackBar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  vertPositioning: PropTypes.string.isRequired,
  horPositioning: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  action: PropTypes.node, // this is optional as the CTA on a snackbar is not required
  errContext: PropTypes.string.isRequired,
};

export default TwSnackBar;
