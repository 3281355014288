import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import { ProfileContext } from "../../context/ProfileContext";
import { addSpId, updateBsInvite } from "../../utils/invites";
import { db } from "../../config/firebaseConfig";

import TotalConsignments from "../../components/dashboardComponents/totalConsignments";
import NumberofCustomers from "../../components/dashboardComponents/numberOfCustomers";
import PendingOrders from "../../components/dashboardComponents/PendingOrders";
import DoughnutChart from "../../components/dashboardComponents/Doughnut";

const BusinessHome = () => {
  const { profile } = useContext(ProfileContext);
  const [customerCount, setCustomerCount] = useState();
  const [pending, setPending] = React.useState(0);
  const [returned, setReturned] = React.useState(0);
  const [completed, setCompleted] = React.useState(0);
  const [totalOrders, setTotalOrders] = React.useState(0);

  const companyName = profile.company.name;
  const companyId = profile.company.id;
  const userName = profile.admin.firstName;
  const spInviterCompanyId = localStorage.getItem("spInviterCompanyId");
  const inviteId = localStorage.getItem("inviteId");
  const ordersRef = db.collection("consignments");
  const customerId = profile.company.customerId;

  useEffect(() => {
    // get sent and received orders
    const fetchOrderDocs = async () => {
      const sentOrders = ordersRef.where("sender.id", "==", customerId).get();
      const receivedOrders = ordersRef
        .where("recipient.id", "==", customerId)
        .get();

      const [sentSnapshot, recievedSnapshot] = await Promise.all([
        sentOrders,
        receivedOrders,
      ]);

      const ordersArr = sentSnapshot.docs;
      const ordersArr2 = recievedSnapshot.docs;

      return ordersArr.concat(ordersArr2);
    };

    const getOrders = async () => {
      const orderDocs = await fetchOrderDocs();
      const bsnOrders = [];

      for (let i = 0; i < orderDocs.length; i++) {
        // filter non deleted values
        if (orderDocs[i].data().deleted === undefined) {
          bsnOrders.push(orderDocs[i].data().status.value);
        }
      }
      setTotalOrders(bsnOrders.length);
      // custom function to count number of occurences of a value in an array
      const countOccurrences = (arr, val) =>
        arr.reduce((a, v) => (v === val ? a + 1 : a), 0);

      // now we set the different order statuses to their respective groups
      // adopted from https://docs.google.com/spreadsheets/d/1IHn-Iwp0yqG-b1Xp7pnhaqAZNtxlWX3Hr4nMM0N02q0/edit#gid=1411939321,
      // the Twift Model actions excel file
      if (bsnOrders.includes("returned")) {
        // set number of returned statuses
        setReturned(countOccurrences(bsnOrders, "returned"));
      }
      if (
        bsnOrders.includes("declined") ||
        bsnOrders.includes("delivered") ||
        bsnOrders.includes("delivered-pickup") ||
        bsnOrders.includes("delivered-collected")
      ) {
        // set completed orders count
        const declinedCount = countOccurrences(bsnOrders, "declined");
        const deliveredCount = countOccurrences(bsnOrders, "delivered");
        const deliveredPickupCount = countOccurrences(
          bsnOrders,
          "delivered-pickup"
        );
        const deliveredCollectedCount = countOccurrences(
          bsnOrders,
          "delivered-collected"
        );
        const deliveredTotalCount =
          declinedCount +
          deliveredCount +
          deliveredPickupCount +
          deliveredCollectedCount;
        setCompleted(deliveredTotalCount);
      }
      if (
        bsnOrders.includes("created") ||
        bsnOrders.includes("accepted") ||
        bsnOrders.includes("dispatched") ||
        bsnOrders.includes("dispatched-assigned") ||
        bsnOrders.includes("dispatched-awaiting-delivery") ||
        bsnOrders.includes("dispatched-in-transit")
      ) {
        // set pending orders count
        const created = countOccurrences(bsnOrders, "created");
        const accepted = countOccurrences(bsnOrders, "accepted");
        const dispatched = countOccurrences(bsnOrders, "dispatched");
        const dispatched_assigned = countOccurrences(
          bsnOrders,
          "dispatched-assigned"
        );
        const dispatched_awaiting_delivery = countOccurrences(
          bsnOrders,
          "dispatched-awaiting-delivery"
        );
        const dispatched_in_transit = countOccurrences(
          bsnOrders,
          "dispatched-in-transit"
        );
        const pendingCount =
          created +
          accepted +
          dispatched +
          dispatched_assigned +
          dispatched_awaiting_delivery +
          dispatched_in_transit;
        setPending(pendingCount);
      }
    };

    // get customers
    const fetchCustomers = async () => {
      let custObjectsList = [];
      const custList = (
        await db
          .collection("customers")
          .where("serviceProviders", "array-contains", companyId)
          .get()
      ).docs;

      custList.forEach((cust) => {
        const custData = {
          id: cust.id,
          ...cust.data(),
        };
        // filter out deleted customers from the suggestion list
        /*eslint-disable eqeqeq*/
        return !custData.deleted || custData.deleted != true
          ? (custObjectsList = [...custObjectsList, custData])
          : null;
      });

      setCustomerCount(custObjectsList.length);
    };

    fetchCustomers();
    getOrders();
  }, [companyId]);

  React.useEffect(() => {
    if (spInviterCompanyId) {
      addSpId(profile.company.customerId, spInviterCompanyId);
      updateBsInvite(inviteId);
    }
  }, [
    inviteId,
    profile.admin.userId,
    profile.company.customerId,
    spInviterCompanyId,
  ]);

  const todayDate = moment().format("MMMM Do YYYY");
  return (
    <Main>
      <Wrapper>
        <Typography variant="body2" color="textSecondary" component="p">
          {todayDate}
        </Typography>
        <Typography
          style={{ marginBottom: 5, marginTop: 5 }}
          variant="h3"
          color="textPrimary"
        >
          Hello {userName}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Here is what has been happening this past week at {companyName}
        </Typography>

        <CardWrapper>
          <TotalConsignments consignmentsNo={totalOrders} />
          <NumberofCustomers customersNo={customerCount} />
        </CardWrapper>

        <GridWrapper>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <PendingOrders />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DoughnutChart
                totalOrders={totalOrders}
                completedCount={completed}
                returnedCount={returned}
                pendingCount={pending}
              />
            </Grid>
          </Grid>
        </GridWrapper>
      </Wrapper>
    </Main>
  );
};

export default BusinessHome;

const Main = styled.div`
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.div`
  margin: 2rem;
`;

const CardWrapper = styled.div`
  display: grid;
  margin: 0.5rem 1rem 1rem;
  gap: 1rem;
  grid-template-columns: repeat(1, auto);
  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 1rem;
  }
`;

const GridWrapper = styled(Grid)`
  margin: 0.5rem 1rem 1rem;
`;
