import { Avatar } from "@material-ui/core";
import styled from "styled-components";

const WorkspaceSwitcherWrapper = styled.div`
  margin: 1rem;
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
`;

const WorkspaceSwitcherContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  ::hover {
    cursor: pointer;
  }
`;

const WorkspaceSwitcherName = styled.small`
  text-align: center;
  text-transform: capitalize;
  font: normal normal medium 18px/40px Ubuntu;
  font-weight: bold;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  padding: 0.5rem;
`;

const WorkspaceSwitcherAvatar = styled(Avatar)`
  width: 3.5rem;
  height: 3.5rem;
  color: ${(props) => props.createWorkspace && "#00acc1"};
  border: ${(props) => props.createWorkspace && "1px solid #00acc1"};
  /* use background color if the avatar is using company intials, i.e, if photoURL is empty */
  background-color: ${(props) =>
    !props.hasBg && props.createWorkspace
      ? "white"
      : !props.hasBg && !props.createWorkspace
      ? "#00acc1"
      : ""};
`;

export {
  WorkspaceSwitcherAvatar,
  WorkspaceSwitcherWrapper,
  WorkspaceSwitcherName,
  WorkspaceSwitcherContentWrapper,
};
