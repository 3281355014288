import * as React from "react";
import {
  List,
  Show,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  EditButton,
  DeleteButton,
  CheckboxGroupInput,
  useRedirect,
  useNotify,
  FunctionField
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import EmptyListPage from "../../components/EmptyListPage";
import TeamIcon from "@material-ui/icons/AssignmentInd";
import { ProfileContext } from "../../context/ProfileContext";
import exporter from "../../components/customDataExporter";
import useFetchCurrentUserPermissions from "../../components/useFetchCurrentUserPermissions";
import getResourceLink from "../../routes/resource-names";
import StyledTableHeader from "../../layout/TableHeader";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import styled from "styled-components";

const useStyles = makeStyles({
  yellow: {
    color: "#C17600",
    backgroundColor: "#fff7df",
    borderColor: "#C17600",
  },
  blue: {
    color: "#00acc1",
    backgroundColor: "#e5fdff",
    borderColor: "#00acc1",
  },
  red: {
    color: "#e55425",
    backgroundColor: "#fcede8",
    borderColor: "#e55425",
  },
});

export const TeamName = ({ name }) => {
  const classes = useStyles();
  switch (name) {
    case "Administrators":
      return (
        <Chip label={name } variant="outlined" className={classes.yellow} />
      );
    case "Owners":
      return (
        <Chip
          label={name }
          variant="outlined"
          className={classes.blue}
        />
      );
    default:
      return (
        <Chip variant="outlined" className={classes.red} label={name } />
      );
  }
};




export const TeamList = (props) => {
  const { profile } = React.useContext(ProfileContext);
  const { formattedPermissions } = useFetchCurrentUserPermissions();
  const serviceProviderId = profile.company.id;

  const resource_names = getResourceLink(serviceProviderId);
  const teamsResource = resource_names.teams;
  const businessTeams = resource_names.businessTeams;
  let cachedProfile = JSON.parse(localStorage.getItem("cachedProfile"));

  const compType = profile.admin
    ? profile.admin.companyType
    : cachedProfile.admin.companyType;

  const teamsExporter = (teams) =>
    exporter({
      resourceArr: teams,
      serviceProviderId: serviceProviderId,
      resourceType: "teams",
    });
  return (
    <List
      {...props}
      title="Teams"
      empty={<EmptyListPage icon={TeamIcon} />}
      exporter={
        formattedPermissions[
          compType === "serviceProvider" ? teamsResource:businessTeams 
        ] &&
        formattedPermissions[
          compType === "serviceProvider" ? teamsResource:businessTeams
        ].list
          ? teamsExporter
          : false
      }
    >
      <StyledTableHeader rowClick="show">
      <FunctionField
          label="Teams"
          render={(record) => (
            <TeamName name={record.name} />
          )}
        />
        {/* <EditButton />
        <DeleteButton undoable={false} /> */}
      </StyledTableHeader>
    </List>
  );
};

export const TeamShow = (props) => (
  <Show {...props} title="Team">
    <SimpleForm toolbar={null} warnWhenUnsavedChanges>
      <TextField source="name" />
      <PermissionInputDisabled />
    </SimpleForm>
  </Show>
);

export const TeamCreate = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The team was created successfully","success");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The team details were not created!", "warning");
    console.log(error);
  };
  return (
    <Create
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Team/Create"
    >
      <SimpleForm warnWhenUnsavedChanges>
        <TextInput label="Team Name" source="name" variant="outlined" style={{ width: "80vw" }} />
        <PermissionInput disabled={true} />
      </SimpleForm>
    </Create>
  );
};

export const TeamEdit = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The team details were updated successfully","success");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The team details were not updated!", "warning");
    console.log(error);
  };
  return (
    <Edit
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Team/Edit"
    >
      <SimpleForm warnWhenUnsavedChanges>
        <TextInput label=" Team Name" source="name" variant="outlined" style={{ width: "80vw" }} />
        <PermissionInput />
      </SimpleForm>
    </Edit>
  );
};

const permissions = [
  { id: "read", name: "Read" },
  { id: "write", name: "Write" },
  { id: "delete", name: "Delete" },
];

const PermissionWrapper = styled.div`
  height: 28em;
  overflow-y: auto;
  margin-left:2rem;
  @media (max-width: 1024px) { 
    display:grid;
    justify-content:center;
    margin-left:0;
  }
`;

// set courier entities, will later come from the db
let courierEntities = [
  "Accounts",
  "Hubs",
  "Admins",
  "Teams",
  "Dispatches",
  "Consignments",
  "Fleet",
  "Agents",
  "Payments",
  "Customers",
  "Messages",
  "Routes",
];

const PermissionInput = () => (
  <PermissionWrapper>
    {courierEntities.map((entity) => (
      <Grid item xs={12} sm={12}>
        <CheckboxGroupInput
          source={`permissions.${entity.toLowerCase()}`}
          label={entity}
          choices={permissions}
        />
      </Grid>
    ))}
  </PermissionWrapper>
);

const PermissionInputDisabled = () => (
  <PermissionWrapper>
    {courierEntities.map((entity) => (
      <Grid item xs={12} sm={12}>
        <CheckboxGroupInput
          source={`permissions.${entity.toLowerCase()}`}
          label={entity}
          choices={permissions}
          options={{ disabled: true }}
        />
      </Grid>
    ))}
  </PermissionWrapper>
);
