import React from "react"
import firebase from "firebase/app";
import { Button } from "@material-ui/core";
import { auth, db, functions } from "../../config/firebaseConfig";
import { navigate } from "@reach/router";
import { syncCustomClaims, syncTeamData } from "./syncGlobalProfile";

/**
 * get a company ref string, i.e, gets a string to be used when creating a firestore company ref
 * @param {String} companyType
 * @returns {String | null} - company ref string or null
 */
const getCompanyRefString = (companyType) =>
  companyType === "serviceProvider"
    ? "companies"
    : companyType === "business"
    ? "businesses"
    : null;

const hasPendingInvite = async (adminsQuery) => {
  let isPendingInvite;
  try {
    // get admins
    const adminsSnap = await adminsQuery.get();
    if (adminsSnap.size > 0) {
      // we have admins
      isPendingInvite = true;
    }
  } catch (err) {
    console.error("******* err: fetching invited admin ********", err);
  }

  return isPendingInvite;
};

const sendAccountVerificationLink = (
  inviteeId,
  inviterId,
  companyId,
  collName,
  inviteeEmailAddress
) => {
  let verifyAccount = functions.httpsCallable("adminAccounts-verifyAccount");
  verifyAccount({
    inviteeId,
    inviterId,
    companyId,
    collName,
    inviteeEmailAddress,
  })
    .then((res) =>
      console.log(`*** success: res from verifyAccount *** ${res}`)
    )
    .catch((err) => `**** err: err from verifyAccount *** ${err}`);
};

/**
 *
 * @param {String} emailAddress
 * @returns {Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> | undefined} A Promise that will be resolved with a userQuerySnap
 */
const isExistingTwiftUser = async (emailAddress, phoneNumber) => {
  const usersSnap = await db.collection("users").get();
  let matchingUser;

  if (!usersSnap.empty) {
    usersSnap.forEach((userData) => {
      if (
        userData.data()?.emailAddress === emailAddress ||
        userData.data()?.phoneNumber === phoneNumber
      ) {
        matchingUser = userData;
      }
    });
  }
  return matchingUser;
};

/**
 *
 * @param {firebase.User} authUser
 * @param {String} firstName
 * @param {String} lastName
 * @param {String} phoneNumber
 * @param {String} fcmToken
 * @returns {Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>> | String | undefined} userDocRef | userId | undefined
 */
const createTwiftUser = async (
  authUser,
  firstName,
  lastName,
  phoneNumber,
  fcmToken
) => {
  const userDoc = await isExistingTwiftUser(
    authUser.email,
    phoneNumber
  );
  if (!userDoc) {
    // there doesn't exist a user with the given email address, thus create new
    const userPayload = {
      uid: authUser.uid,
      bio: "",
      firstName: firstName,
      lastName: lastName,
      gender: "unknown",
      emailAddress: authUser.email,
      emailAdressVerified: authUser.emailVerified,
      phoneNumber: phoneNumber,
      phoneNumberVerified: false, // will by dynamic once we start verifying phone numbers
      profilePicUrl: authUser.photoURL,
      fcmToken: firebase.firestore.FieldValue.arrayUnion(fcmToken),
    };

    // return the docRef after we've created a twift user
    return db.collection("users").add(userPayload);
  } else {
    /**
     * check if the existing user record has an email address
     * - when a customer is created, the email address is not required.
     */
    try {
      const matchingUser = userDoc.data();
      if(!matchingUser.emailAddress){
        userDoc.ref.update({
          emailAddress: authUser.email
        })
      }

      return userDoc;
    } catch (error) {
      console.error("*** err: error updating matching user's email address", error);
    }
  }
};

// sanitize an email address
// remove all white space and convert the result to lowercase
const sanitizeEmailAddress = (email) => email.replace(/\s+/g, "").toLowerCase();

/**
 * get a twift user
 * @param {String} email
 * @returns {Object | undefined}  - twift user | undefined if a user doesn't exist
 */
const getUser = async (email) => {
  let res;
  try {
    const users = await db
      .collection("users")
      .where("emailAddress", "==", email)
      .get();

    users.forEach((user) => {
      res = {
        id: user.id,
        ...user.data(),
      };
    });
  } catch (err) {
    // handle error
    console.error("**** err: fetching User by email *****", err);
  }
  return res;
};

const getUserByPhoneNumber = async (phoneNumber) => {
  let userMatchingPhoneNumber;
  const usersQuerySnap = await db.collection("users").get();

  usersQuerySnap.forEach((userDoc) => {
    if (userDoc.data().phoneNumber === phoneNumber) {
      userMatchingPhoneNumber = {
        ...userDoc.data(),
        id: userDoc.id,
      };
    }
  });
  return userMatchingPhoneNumber;
};

/**
 * get admin(s) or agetn(s) matching the given email address
 * @param {String} email
 * @param {String} subCollName
 * @returns {Object} an object representing admin(s) or agent(s)
 */
const getAdminsOrAgents = async (email, subCollName) => {
  const subCollRef = db.collectionGroup(subCollName);
  const user = await getUser(email);
  const adminsOrAgents = await subCollRef.where("userId", "==", user.id).get();
  let result = {};
  if (!adminsOrAgents.empty) {
    // there exist at least one admin
    if (adminsOrAgents.size > 1) {
      // there exists more than one admin
      result.hasMultipleAdminsOrAgents = [];
      adminsOrAgents.forEach((adminOrAgentDoc) => {
        const adminOrAgent = {
          id: adminOrAgentDoc.id,
          ...adminOrAgentDoc.data(),
        };
        result.hasMultipleAdminsOrAgents.push(adminOrAgent);
      });
    } else {
      // there exists just one admin
      adminsOrAgents.forEach((adminOrAgentDoc) => {
        result = {
          id: adminOrAgentDoc.id,
          ...adminOrAgentDoc.data(),
        };
      });
    }
  }
  return result;
};

/**
 * create a company
 * @param {String} companyType serviceProvider | business
 * @param {Object} payload object containing the payload to be used when creating a company
 * @returns {String} id of the created company
 */
const createCompany = async (companyType, payload) => {
  let companyId;
  const companyRefString = getCompanyRefString(companyType);

  try {
    // create a company
    const companyRef = await db.collection(companyRefString).add(payload);
    // get the companyId
    companyId = companyRef.id;
    // success
    console.log("**** success: company was created *****", companyId);
  } catch (err) {
    console.error("*** err: creating company *****", err);
  }

  return companyId;
};

/**
 * create an admin
 * @param {String} companyId company id to be used to reference the admins sub-collection
 * @param {String} companyType serviceProvider | business
 *  @param {Object} payload payload to be used when creating an Admin
 * @returns {String} - id of the created admin
 */
const createAdmin = async (companyId, companyType, payload) => {
  let adminId;
  const companyRefString = getCompanyRefString(companyType);
  try {
    const adminRef = await db
      .collection(`${companyRefString}/${companyId}/admins`)
      .add(payload);
    adminId = adminRef.id;
    console.log("**** success: admin was created *****", adminId);
  } catch (err) {
    console.error("**** err: creating admin ***", err);
  }
  return adminId;
};

/**
 * get a company
 * @param {String} companyType serviceProvider | business
 * @param {String} companyId serviceProviderId | businessId
 * @returns {Object} the fetched SP
 */
const getCompany = async (companyType, companyId) => {
  const companyRefString = getCompanyRefString(companyType);
  try {
    const companySnap = await db.collection(companyRefString).doc(`${companyId}`).get();
    return {
        id: companySnap.id,
        ...companySnap.data(),
      };
  } catch (err) {
    console.error("*** err: fetching service provider *****", err);
  }
};

/**
 * get an SP admin by it's id
 * @param {String} spId serviceProviderID
 * @param {String} adminId
 * @returns {Object} the fetched admin
 */
const getAdminById = async (spId, adminId) => {
  try {
    const admin = await db.doc(`companies/${spId}/admins/${adminId}`).get();
    return {
      id: admin.id,
      ...admin.data(),
    };
  } catch (err) {
    console.error("*** err: fetching admin *****", err);
  }
};

/**
 * create a customer of type business
 * @returns {String} id of the created customer
 */
const createBusinessCustomer = async () => {
  try {
    //
    const customerRef = await db.collection("customers").add({
      type: "business",
    });
    return customerRef.id;
  } catch (err) {
    console.error("**** err: creating a customer ***", err);
  }
};

/**
 * get a customer by their id
 * @param {String} customerId
 * @returns {Object} fetched customer
 */
const getCustomerById = async (customerId) => {
  try {
    const customer = await db
      .collection("customers")
      .doc(`${customerId}`)
      .get();
    return {
      id: customer.id,
      ...customer.data(),
    };
  } catch (err) {
    console.error("**** err: fetching a customer ***", err);
  }
};

const handleLogout = () => {
  // signOut the firebase auth user
  auth.signOut();

  // clear localStorage
  localStorage.clear();

  // redirect to "/"
  navigate("/");
};

const handleSelectWorkspace = async (
  authUser,
  company,
  workspace,
  role,
  updateProfile
) => {
  if (role === "agent") {
    navigate("/agents");
  } else {
    // sync custom claims
    try {
      await syncCustomClaims(authUser, { companyDocId: company.id });

      const companyRefString = getCompanyRefString(workspace.companyType);
      // get the workspace's team
      const team = await syncTeamData(
        company.id,
        workspace.team.id,
        companyRefString
      );

      // update the profile context
      updateProfile({
        authUser,
        admin: workspace,
        company,
        team,
      });
      // add to localStorage
      localStorage.setItem(
        "cachedProfile",
        JSON.stringify({ authUser, admin: workspace, company, team })
      );

      // redirect to "/home"
      navigate("/home");
    } catch (error) {
      // handle error
      console.error("******** err setting custom claims *******", error);
    }
  }
};

const getAdminsForUser = async (userId) => {
  let admins;
  try {
    const adminsSnap = await db
      .collectionGroup("admins")
      .where("userId", "==", userId)
      .get();
    if (adminsSnap.size > 0) {
      admins = [];
      adminsSnap.docs.forEach((adminDoc) => {
        admins.push({
          id: adminDoc.id,
          ...adminDoc.data(),
        });
      });
    }
  } catch (error) {
    console.error(
      `*** err: an error occurred while fetching admins for user ${userId} ****`,
      error
    );
  }

  return admins;
};

export {
  sanitizeEmailAddress,
  hasPendingInvite,
  sendAccountVerificationLink,
  isExistingTwiftUser,
  createTwiftUser,
  getUser,
  getUserByPhoneNumber,
  getAdminsOrAgents,
  createCompany,
  createAdmin,
  getCompany,
  getAdminById,
  createBusinessCustomer,
  getCustomerById,
  getCompanyRefString,
  handleLogout,
  handleSelectWorkspace,
  getAdminsForUser,
};
