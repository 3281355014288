import React, { Fragment } from "react";
import PropTypes from "prop-types";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { StyledTextField } from "../../../AuthStyles";
import styled from "styled-components";
import { AuthBtn } from "../../../../../components/authComponents/Buttons";

const SecondaryView = ({
  email,
  password,
  formErrors,
  onChangeHandler,
  showPassword,
  handleClickShowPassword,
  handleMouseDownPassword,
  handleClick,
  isSigningInWithEmailAndPswd,
  EmailAndPswdBtn,
  handleBack,
}) => {
  return (
    <Wrapper>
      <LoginTextFields
        variant="outlined"
        margin="normal"
        label="Email Address"
        name="userEmail"
        value={email}
        placeholder="E.g: faruq123@gmail.com"
        id="userEmail"
        onChange={(event) => onChangeHandler(event)}
        autoComplete="email"
        required
        fullWidth
        autoFocus
        error={formErrors.email}
        helperText={formErrors.email && formErrors.email}
      />
      {/* @todo - put this password functionality into a component to allow reuse */}

      <LoginTextFields
        variant="outlined"
        margin="normal"
        label="Password"
        name="password"
        value={password}
        id="password"
        onChange={(event) => onChangeHandler(event)}
        autoComplete="password"
        required
        fullWidth
        autoFocus
        error={formErrors.password}
        helperText={formErrors?.password}
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <AuthBtn emailAndPswd signin handleClick={handleClick} />
    </Wrapper>
  );
};

SecondaryView.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  formErrors: PropTypes.object.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  showPassword: PropTypes.bool.isRequired,
  handleClickShowPassword: PropTypes.func.isRequired,
  handleMouseDownPassword: PropTypes.func.isRequired,
  isSigningInWithEmailAndPswd: PropTypes.bool.isRequired,
  EmailAndPswdBtn: PropTypes.func,
  handleBack: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default SecondaryView;

const LoginTextFields = styled(StyledTextField)`
  margin: 1rem;
  @media (max-width: 1024px) {
    width: 100%;
    margin: 0.5rem;
  }
`;

const Wrapper = styled(Fragment)`
  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;
