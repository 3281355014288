/* eslint-disable */
import React, { useContext, useEffect } from "react";
import { ProfileContext } from "../../context/ProfileContext";
import { Avatar, Divider, CardContent, Typography } from "@material-ui/core";
import useGetWorkspaces from "./useGetWorkspaces";
import WorkspaceSwitcher from "./WorkspaceSwitcher";
import { getUser } from "./authHelpers";
import { useState } from "react";

const ProfilePage = () => {
  const { profile } = useContext(ProfileContext);
  let cachedProfile = JSON.parse(localStorage.getItem("cachedProfile"));
  const user = profile ? profile.authUser : cachedProfile.authUser;
  const { photoURL, email } = user;
  const workspaces = useGetWorkspaces();
  const [displayName, setDisplayName] = useState("");

  useEffect(() => {
    (async () => {
      if (user) {
        // get the associated twift user record
        const twiftUser = await getUser(user.email);
        setDisplayName(`${twiftUser.firstName} ${twiftUser.lastName}`);
      }
    })();
  }, [user]);

  return (
    <div>
      <div
        style={{
          display: "grid",
          justifyContent: "center",
        }}
      >
        <Avatar
          style={{
            height: "5rem",
            width: "5rem",
            display: "grid",
            justifyContent: "center",
            border: "1px solid #00acc1",
          }}
          alt={displayName}
          src={
            photoURL ||
            "https://res.cloudinary.com/dqcsk8rsc/image/upload/v1577268053/avatar-1-bitmoji_upgwhc.png"
          }
        />
      </div>
      <CardContent
        style={{ display: "grid", textAlign: "center", marginTop: "-0.4rem" }}
      >
        <Typography variant="h7" component="div" style={{ fontWeight: "bold" }}>
          {displayName}
        </Typography>
        <Typography variant="body2" component="div" color="textSecondary">
          {email}
        </Typography>
      </CardContent>
      <Divider />
      {workspaces ? <WorkspaceSwitcher workspaces={workspaces} /> : null}
    </div>
  );
};

export default ProfilePage;
