import React, { useContext } from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
  ReferenceField,
  Filter,
  TextInput,
  CreateButton,
  TopToolbar,
} from "react-admin";
import ConsignmentIcon from "@material-ui/icons/CardTravel";

import { ProfileContext } from "../../../context/ProfileContext";
import EmptyListPage from "../../../components/EmptyListPage";

const ListActions = () => (
  <TopToolbar>
      {/* <CreateButton
       label="Request an Order"
       variant="contained" 
       color="primary"
       size="large"
       to={"/orders/create"} /> */}
  </TopToolbar>
);

const OrdersFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Status" source="status" />
  </Filter>
);

export const OrdersList = (props) => {
  const { profile } = useContext(ProfileContext);
  const companyId = profile.company.id;

  return companyId ? (
    <List
      {...props}
      // actions={<ListActions />}
      sort={{ field: "date", order: "DESC" }}
      title="Orders"
      filters={<OrdersFilter />}
      filter={{ companyId: companyId }}
      empty={<EmptyListPage icon={ConsignmentIcon} />}
    >
      <Datagrid rowClick="show">
        <DateField source="date" />
        <ReferenceField
          label="Sender"
          reference="customers"
          source="sender.id"
          link="show"
        >
          <TextField source="firstName" />
        </ReferenceField>
        <ReferenceField
          label="recipient"
          reference="customers"
          source="recipient.id"
          link="show"
        >
          <TextField source="firstName" />
        </ReferenceField>
        <TextField label="Origin" source="sender.address" />
        <TextField label="Destination" source="recipient.address" />
        <TextField label="Status" source="status.value" />
          {/* <EditButton />
       <DeleteButton mutationMode="undoable" /> */}
      </Datagrid>
    </List>
  ):null;
};