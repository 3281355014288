import React from "react";
import {
  EditButton,
  Show,
  SimpleShowLayout,
  useShowController,
  TopToolbar,
} from "react-admin";
import { useStyles } from "../../components/Orders/OrdersDetail";
import { Typography, CardContent, Card, Grid } from "@material-ui/core";
import PageHeader from "../../components/PageHeader";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import moment from "moment";

const PaymentShowView = (props) => {
  const classes = useStyles();
  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props);

  const ShowViewActions = () => (
    <TopToolbar className={classes.toolbar}>
      <EditButton
        label="All Payments"
        icon={<ArrowBackIosIcon />}
        color="primary"
        to={"/payments"}
        size="medium"
      />
    </TopToolbar>
  );

  return (
    <Show {...props} title="Payment/Details" actions={<ShowViewActions />}>
      <SimpleShowLayout>
        {record !== undefined && (
          <div style={{ width: "100%" }}>
            <Grid container spacing={4}>
              {/* Payment Info */}
              <Grid item xs={12} sm={6} lg={6}>
                <Card className={classes.main}>
                  <CardContent>
                    <PageHeader title={`Payment : ${record.id}`} />
                    <div style={{ marginLeft: "1rem" }}>
                      <Typography className={classes.pos} gutterBottom>
                        {" "}
                        Order #: <b>{record.consignment.trackingCode}</b>
                      </Typography>
                      <Typography className={classes.pos} gutterBottom>
                        {" "}
                        Account Name: <b>{record.accountName}</b>
                      </Typography>
                      <Typography className={classes.pos}>
                        {" "}
                        Account Number: <b>{record.accountNumber}</b>
                      </Typography>
                      <Typography className={classes.pos}>
                        Transaction Amount: <b>{record.amount}</b>
                      </Typography>
                      <Typography className={classes.pos}>
                        Transaction Type: <b>{record.type}</b>
                      </Typography>
                      <Typography className={classes.pos}>
                        Payment Mode: <b>{record.mode}</b>
                      </Typography>
                      <Typography className={classes.pos}>
                        Payment Date: <b>{moment(record.createdAt).format('DD-MM-YYYY')}</b>
                      </Typography>
                      <Typography className={classes.pos}>
                        Status: <b>{record.status}</b>
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        )}
      </SimpleShowLayout>
    </Show>
  );
};

export default PaymentShowView;
