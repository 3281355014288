import React, { useContext, cloneElement } from "react";
import {
	List,
	TextField,
	DateField,
	Filter,
	TextInput,
	TopToolbar,
	CreateButton,
	ExportButton,
	ReferenceField,
	FunctionField,
	useRecordContext,
} from "react-admin";
import { useMediaQuery, Chip, LinearProgress } from "@material-ui/core";
import { ProfileContext } from "../../context/ProfileContext";
import EmptyListPage from "../EmptyListPage";
import exporter from "../customDataExporter";
import useFetchCurrentUserPermissions from "../useFetchCurrentUserPermissions";
import styles from "./styles";
import MobileGrid from "./MobileGrid";
import getResourceLink from "../../routes/resource-names";
import ActionsPopOver from "../../pages/admin/Agents/components/ActionsPopOver";
import StyledTableHeader from "../../layout/TableHeader";
import transformOrderStatus from "../../utils/transformOrderStatus";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { CustomOrderStatus } from "./CustomOrderStatus";

const OrdersFilter = (props) => (
	<Filter {...props}>
		<TextInput
			label="Order#"
			placeholder="Search ..."
			source="trackingCode"
			alwaysOn
			variant="outlined"
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon style={{ fill: "grey" }} />
					</InputAdornment>
				),
			}}
		/>
		<TextInput label="Status" source="status" />
	</Filter>
);

const ListActions = (props) => (
	<TopToolbar>
		{cloneElement(props.filters, { context: "button" })}
		{/* <CreateButton
			label="Create an Order"
			variant="contained"
			color="primary"
			to={"/consignments/create"}
		/> */}
		<ExportButton />
	</TopToolbar>
);

const OrdersTable = (props) => {
	const [loader, setLoader] = React.useState(false);
	const { profile } = useContext(ProfileContext);
	const resource_names = getResourceLink(profile.company.id);
	const serviceProviderId = profile.company.id;
	let cachedProfile = JSON.parse(localStorage.getItem("cachedProfile"));
	const compType = profile.admin
		? profile.admin.companyType
		: cachedProfile.admin.companyType;
	const { formattedPermissions } = useFetchCurrentUserPermissions();
	const isSmall = useMediaQuery(`(max-width:950px)`);
	const consExporter = (consignments) =>
		exporter({
			resourceArr: consignments,
			serviceProviderId: serviceProviderId,
			resourceType: "consignments",
		});
	if (!serviceProviderId || !resource_names.agents) {
		return setLoader(true);
	}
	// Create a custom field that checks for agent name in consignment otherwise uses the reference field
	const ConditionalAgentField = () => {
		const record = useRecordContext();
		return record && record.agent?.name ? (
			<TextField label="Agent" source="agent.name" style={styles.bold} />
		) : (
			<ReferenceField
				label="Agent"
				source="agent.id"
				reference={resource_names.agents}
				link={false}
			>
				<FunctionField
					// The deleted string is an extreme measure. record shouldn't be permanently deleted.
					render={(record) =>
						record.name ? record.name : `${record.firstName} ${record.lastName}`
					}
				/>
			</ReferenceField>
		);
	};

	return serviceProviderId ? (
		<List
			{...props}
			sort={{ field: "createdAt", order: "DESC" }}
			actions={<ListActions />}
			title="Orders"
			filters={<OrdersFilter />}
			filter={{ serviceProviderId: serviceProviderId }}
			empty={<EmptyListPage />}
			exporter={
				formattedPermissions.consignments &&
				formattedPermissions.consignments.list
					? consExporter
					: false
			}
		>
			{isSmall ? (
				<MobileGrid />
			) : (
				<StyledTableHeader rowClick="show">
					<TextField
						label="Order #"
						source="trackingCode"
						style={styles.bold}
					/>
					<DateField label="Delivery Date" source="date" style={styles.bold} />
					<TextField label="sender" source="sender.name" style={styles.bold} />
					{/*<ReferenceField label="Sender" source="sender.id" reference={resource_names.customers} link={false}>*/}
					{/*    <FunctionField*/}
					{/*        label="Sender"*/}
					{/*        // render={(record) => record.firstName ? `${record.firstName} ${record.lastName}`:`${record.name} `}*/}
					{/*        render={(record) => record.name}*/}
					{/*    />*/}
					{/*</ReferenceField>*/}
					<TextField
						label="recipient"
						source="recipient.name"
						style={styles.bold}
					/>
					{/*<ReferenceField label="Recipient" source="recipient.id" reference={resource_names.customers}*/}
					{/*                link={false}>*/}

					{/*    <FunctionField*/}
					{/*        label="Recipient"*/}
					{/*        // render={(record) => record.firstName ? `${record.firstName} ${record.lastName}`:`${record.name} `}*/}
					{/*        render={(record) => record.name}*/}
					{/*    />*/}
					{/*</ReferenceField>*/}
					<TextField label="Origin" source="origin.name" style={styles.bold} />
					<TextField
						label="Destination"
						source="destination.name"
						style={styles.bold}
					/>
					{compType === "serviceProvider" || loader ? (
						// <ReferenceField
						// 	label="Agent"
						// 	source="agent.id"
						// 	reference={resource_names.agents}
						// 	link={false}
						// >
						// 	<FunctionField
						// 		// The deleted string is an extreme measure. record shouldn't be permanently deleted.
						// 		render={(record) =>
						// 			record.name
						// 				? record.name
						// 				: `${record.firstName} ${record.lastName}`
						// 		}
						// 	/>
						// </ReferenceField>

						// <TextField
						//     label="Agent"
						//     source="agent.name"
						//     style={styles.bold}
						// />
						<ConditionalAgentField />
					) : (
						<LinearProgress />
					)}
					<FunctionField
						label="Status"
						render={(record) => (
							<CustomOrderStatus
								status={transformOrderStatus(record.status.value)}
							/>
						)}
					/>
					{/* <ChipField label="Status" source="status.value" /> */}
					{/* <ActionsPopOver show={false} /> */}
				</StyledTableHeader>
			)}
		</List>
	) : null;
};
export default OrdersTable;
