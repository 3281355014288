// Utils file containing fcm notification related logic
import firebase from "firebase";
import { db } from "./firebaseConfig";

const messaging = firebase.messaging();

// Function that Subscribes the messaging instance to push notifications. 
// Returns an FCM registration token that can be used to send push messages to that messaging instance.
export const getToken = (setTokenFound) => {
    return messaging.getToken({vapidKey: process.env.NODE_FCM_REGISTRATION_TOKEN}).then((currentToken) => {
      if (currentToken) {
        // console.log('current fcm token for client: ', currentToken);
        setTokenFound(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // we eventually add this token to the users collection on signin/signup
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(null);
        // shows on the UI that permission is required 
        // pops up the browser notifications permission
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
}

const deleteFcmTkn = async (userId, currentTkn) => {
  // delete the token
  try {
    await messaging.deleteToken();
    console.log("***** suceess: deleted token *****");

    // delete the token from user.fcmToken array
    const userRef = db.collection("users").doc(userId);
    return userRef
      .update({
        fcmToken: firebase.firestore.FieldValue.arrayRemove(currentTkn),
      })
      .then(() =>
        console.log("***** suceess: removed token from user.fcmTokens *****")
      )
      .catch((err) =>
        console.log("**** error: failed to delete token from user *****", err)
      );
  } catch (err) {
    console.log("****** error deleting token: ******", err);
  }
};

const generateFcmToken = (userId) => {
  // use the messaging instance to generate a new token
  messaging
    .getToken({
      vapidKey: process.env.NODE_FCM_REGISTRATION_TOKEN,
    })
    .then((tkn) => {
      console.log("####### token: fetched successfully #######", tkn);

      // update the associated user record with the new token
      const userRef = db.collection("users").doc(userId);
      return userRef
        .update({
          fcmToken: firebase.firestore.FieldValue.arrayUnion(tkn),
        })
        .then(() => {
          console.log("Fcm token successfully updated!");
        })
        .catch((error) => {
          console.error("Error updating token: ", error);
        });
    })
    .catch((err) => console.log("####### error: fetching token #######", err));
};

// Function that updates the Fcm token for each user on login.
// fcm tokens change from one device used to another, so we store this data
// inside the users collection as an array, so we can send notifications to all devices
// belonging to an SP
export const updateFcmToken = (userId) => {
    return messaging
      .getToken({ vapidKey: process.env.NODE_FCM_REGISTRATION_TOKEN })
      .then(async (currentToken) => {
        if (currentToken) {
          // delete the token from user.fcmToken array
          try {
            await deleteFcmTkn(userId, currentToken);
            // generate a new token
            generateFcmToken(userId);
          } catch (error) {
            console.error(
              "**** error: failed to delete token from user *****",
              error
            );
          }
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
          // shows on the UI that permission is required
        }
      })
      .catch((err) => {
        console.log("An error occurred while fetching the token. ", err);
        // catch error while creating client token
      });
}


// FCM method used to display notifications when a user is on an active tab on twift
export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
});
