import React from "react";
import { Card, CardContent, CardMedia } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Link from "../StyledLink";
import styles from "./styles";
import orders from "../../assets/orders.svg";

const NumberofConsignments = ({ consignmentsNo, title }) => {
  return (
    <div>
      <Link to="/consignments">
        <Card style={styles.card}>
          <CardMedia
            style={styles.logo}
            image={orders}
            title="Revenue"
          />
          <CardContent>
            <div style={styles.content}>
              <Typography variant="h4" gutterBottom style={styles.CardContent}>
                {title} Orders
              </Typography>
              <Typography variant="h1" gutterBottom style={styles.h1}>
                {consignmentsNo}
            </Typography>
            </div> 
          </CardContent>
        </Card>
      </Link>
    </div>
  );
};

export default NumberofConsignments;