import React, { useState, useContext, Fragment } from "react";
import PropTypes from "prop-types";
import { auth, googleProvider } from "../../../../config/firebaseConfig";
import syncGlobalProfile from "../../syncGlobalProfile";
import { ProfileContext } from "../../../../context/ProfileContext";
import { navigate } from "@reach/router";
import styled from "styled-components";
import googleIcon from "../../../../assets/google.svg";
import { GoogleAuthBtnWrapper } from "../../../../components/Auth/Buttons";

const Register = ({ getAuthUser, setNotification, sendAlertInfoToParent }) => {
  // use the context API to persist the firebase user, synced profile
  const { updateProfile, updateAuthUser } = useContext(ProfileContext);

  /**
   * sign up with the google auth provider
   */
  const handleSignUpWithGoogle = async () => {
    try {
      const authResult = await auth.signInWithPopup(googleProvider);
      // persist the auth user object so it's available app-wide
      updateAuthUser(authResult.user);
      const syncedProfile = await syncGlobalProfile(
        authResult.user,
        updateProfile
      );
      console.log(
        "********** syncedProfile from signup **********",
        syncedProfile
      );
      if (syncedProfile.admin) {
        // update profile
        updateProfile(syncedProfile);
        // redirect to dashboard
        if (syncedProfile.admin.companyType === "business") {
          navigate("/home");
        } else {
          navigate("/home");
        }
      } else {
        getAuthUser(authResult.user);
      }
    } catch (err) {
      // handle errors
      console.error(
        "**** an error occurred when signing up with google ****",
        err
      );
      const { code, message } = err;
      if(code === "auth/popup-closed-by-user"){
        // use an alert to display the message to the user
        // firebaseAuth Err: The popup has been closed by the user before finalizing the operation
        setNotification({
          severity: "warning",
          msg: "You've closed the google account chooser popup before choosing an account, please try again"
        });
      } else {
        // use a snackbar to display a genereic error message to the user
        sendAlertInfoToParent({
          isOpen: true,
          message: "Something went wrong during signup, please contact us if this persists",
          info: message
        });
      }
    }
  };

  return (
    <Wrapper>
      <GoogleAuthBtnWrapper
        fullWidth
        disableElevation
        variant="contained"
        onClick={handleSignUpWithGoogle}
      >
        <img src={googleIcon} alt="google signup"width="20rem" />
        <span>SIGN UP WITH GOOGLE</span>
      </GoogleAuthBtnWrapper>

      <Row>
        <hr /> <span>OR REGISTER WITH</span>
        <hr />
      </Row>
      {/* @todo - add email providers */}
    </Wrapper>
  );
};

Register.propTypes = {
  getAuthUser: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  sendAlertInfoToParent: PropTypes.func.isRequired,
};

export default Register;

const Wrapper = styled(Fragment)`
  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

const Row = styled.div`
  margin: 2rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  hr {
    width: 30% !important;
    margin-left: auto;
    margin-right: auto;
    height: 1px;
    background-color: #d8d8d8;
    border: 0 none;
  }
  span {
    color: #707070;
    margin: auto 0 !important;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;
