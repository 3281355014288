/* eslint-disable */

import React, { useContext, useEffect, useState } from "react";
import { Router } from "@reach/router";
import SignIn from "../auth/Login";
import SignUp from "../auth/SignUp";
import SignOut from "../auth/SignOut";
import ProfilePage from "../auth/ProfilePage";
import { ProfileContext } from "../../context/ProfileContext";
import PasswordReset from "../auth/CustomForgotPassword";
import { theme } from "../../config/themeConfig";
import TwLayout from "../../layout/TwLayout.js";
import { Admin, Resource, Title } from "react-admin";
import { firebaseConfig } from "../../config/firebaseConfig";
import { rafOptions } from "../../config/rafConfig";
import {
  FirebaseDataProvider,
  FirebaseAuthProvider,
} from "react-admin-firebase";
import { ResourceWithPermissions } from "ra-auth-acl";
import CircularProgress from "@material-ui/core/CircularProgress";

// models components
import SpDashboard from "./dashboard";
import {
  CompanyList,
  CompanyShow,
  CompanyCreate,
  CompanyEdit,
} from "./companies";
import { HubList, HubShow, HubCreate, HubEdit } from "./hubs";
import { AdminList, AdminShow, AdminCreate, AdminEdit } from "./admins";
import { TeamList, TeamCreate, TeamEdit } from "./teams";
import {
  PermissionList,
  PermissionShow,
  PermissionCreate,
  PermissionEdit,
} from "./permissions";
import {
  CustomerList,
  CustomerShow,
  InvitesShow,
  InvitesEdit,
} from "./customers";
import CustomerCreate from "./CustomerCreate";
import CustomerEdit from "./CustomerEdit";
import { AgentList, AgentShow, AgentCreate, AgentEdit } from "./agents";
import { VehicleList, VehicleShow, VehicleCreate, VehicleEdit } from "./fleet";
import { TripList, TripShow, TripCreate, TripEdit } from "./trips";
import {
  PaymentList,
  PaymentCreate,
  PaymentEdit,
} from "./payments";
import PaymentShowView from "../../components/Payments/PaymentShowView"
import {
  BookingList,
  BookingShow,
  BookingCreate,
  BookingEdit,
} from "./bookings";
import {
  DispatchList,
  DispatchShow,
  DispatchCreate,
  DispatchEdit,
} from "./dispatches";
import { RouteList, RouteShow, RouteCreate, RouteEdit } from "./routes";
import { MessageList, MessageShow, MessageCreate } from "./messages";
import { AccountList } from "./account";
import {
  AddressList,
  AddressShow,
  AddressEdit,
  AddressCreate,
} from "./address";
import { StatusEdit, StatusCreate, StatusList, StatusShow } from "./status";
import {
  WayBillList,
  WayBillShow,
  WayBillCreate,
  WayBillEdit,
} from "./waybills";
import { RatingList, RatingShow } from "./ratings";
import {
  makeStyles,
  withStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
//order views
import OrderList from "../../components/Orders/OrdersTable";
import OrderDetail from "../../components/Orders/OrdersDetail";
import OrderCreate from "../../components/Orders/OrdersCreate";
import OrderEdit from "../../components/Orders/OrdersEdit";
import BizOrdersCreate from "../../components/Orders/BizOrdersCreate"
import BizOrdersList from "../../components/Orders/BizOrdersList"
//Agents views
import AgentsCreate from "./Agents/views/AgentsCreate";
import AgentsEdit from "./Agents/views/AgentsEdit";
import AgentsList from "./Agents/views/AgentsList";
import AgentsShow from "./Agents/views/AgentsShow";

import BizCustomerCreate from '../business/BizCustomers/components/BizCustomerCreate'
import BizCustomerEdit from '../business/BizCustomers/components/BizCustomerEdit'
import BizCustomerList from '../business/BizCustomers/BizCustomerList'
import {OrdersCreate} from "../business/Orders/components/OrdersCreate"
import {OrdersList} from "../business/Orders/OrdersList"
import Help from "../business/Help"
import SettingsPage from "../common/Settings";
/**** icons ****/
// We are using aliases for imports [https://stackoverflow.com/questions/39282253/how-can-i-alias-a-default-import-in-javascript]
// so that we can stick to a defined Icon ref name independent of the icon we are importing
// This helps in that to change an icon, only the import statement is changed while the icon ref remains the same
import CompanyIcon from "@material-ui/icons/Business"; // companies
import HubIcon from "@material-ui/icons/AmpStories"; // hubs
import AdminIcon from "@material-ui/icons/SupervisorAccount"; // admins
import TeamIcon from "@material-ui/icons/AssignmentInd"; // teams
import PermissionIcon from "@material-ui/icons/Security"; // permissions
import CustomerIcon from "@material-ui/icons/PeopleOutline"; // customers
import AgentIcon from "@material-ui/icons/DriveEta"; // agents
import FleetIcon from "@material-ui/icons/Commute"; // fleet
import TripIcon from "@material-ui/icons/AirportShuttle"; // trips
import ConsignmentIcon from "@material-ui/icons/CardTravel"; // consignments
import PaymentIcon from "@material-ui/icons/LocalAtm"; // payments
import BookingIcon from "@material-ui/icons/Dashboard"; // bookings
import DispatchIcon from "@material-ui/icons/PresentToAll"; // dispatches
import RouteIcon from "@material-ui/icons/AccountTree"; // routes
import MessageIcon from "@material-ui/icons/MailOutline"; // messages
import AccountIcon from "@material-ui/icons/AccountBalanceWallet"; // accounts
import StatusIcon from "@material-ui/icons/LocalActivity"; // status
import WayBillIcon from "@material-ui/icons/ReceiptOutlined"; // waybill
import AddressIcon from "@material-ui/icons/AddLocation"; // address
import RatingsIcon from "@material-ui/icons/RateReview"; // ratings
import RequestIcon from "@material-ui/icons/Send"; //requests
import SettingsIcon from "@material-ui/icons/Settings"; //settings
import OrderIcon from "@material-ui/icons/Payment"; //orders
import HelpIcon from "@material-ui/icons/Help"; // help
import MapIcon from "@material-ui/icons/MapOutlined"; // map view
import Home from "../business/Home"; //home
import ValidateInvite from "../auth/ValidateInvite";
import Link from "../../components/StyledLink";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import useFetchCurrentUserPermissions from "../../components/useFetchCurrentUserPermissions";
import getResourceLink from "../../routes/resource-names";
import PermDialog from "../../layout/TwDialog";

import Forbidden from "../../assets/warning.svg";
import BusinessDashboard from "../business/Home";

import MapView from '../../components/Agents/Map' // the live google map view
import AuthHome from "../authScreens/AuthHome";
import StickyAnnouncementBanner from "../../components/StickyAnnouncementBanner";

const useStyles = makeStyles({
  title: {
    flex: 1,
    marginRight: "20px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: 28,
  },
});

const i18nMessages = { en: englishMessages };

function TwAdmin() {
  // check for cached profile data
  let cachedProfile = JSON.parse(localStorage.getItem("cachedProfile"));
  const { profile } = useContext(ProfileContext);
  // the profile from context API might not be unset during a page refresh,
  // fall back to the version set into local storage during the auth flow. see more at:
  // https://twendeapp.slack.com/archives/CB8L77NDV/p1622485876011000?thread_ts=1622451605.006700&cid=CB8L77NDV
  const serviceProviderId = profile.company
    ? profile.company.id
    : cachedProfile.company.id;
  const dataProvider = FirebaseDataProvider(firebaseConfig, rafOptions);
  let authProvider = FirebaseAuthProvider(firebaseConfig, rafOptions);
  const i18nProvider = polyglotI18nProvider(
    (locale) => i18nMessages[locale],
    "en",
    { allowMissing: true ,onMissingKey: (key, _, __) => key } 

  );

  const compType = profile.admin
    ? profile.admin.companyType
    : cachedProfile.admin.companyType;

  const [state, setState] = useState({
    showDashboard: false,
    showLoader: false,
    showSignin: false,
    showPermDialog: false,
  });
  const { showDashboard, showLoader, showSignin, showPermDialog } = state;
  const permsOpenModal = () => {
    setState((state) => ({
      ...state,
      showPermDialog: true,
    }));
  };
  const permsCloseModal = (e) => {
    setState((state) => ({
      ...state,
      showPermDialog: false,
    }));
  };

  let handleGetPermissions = async () => await profile.team.permissions;

  const { permissions, getUserPerms } = useFetchCurrentUserPermissions();

  React.useEffect(() => {
    handleGetPermissions = getUserPerms;

    if (profile.admin) {
      setState((state) => ({
        ...state,
        showDashboard: true,
        showLoader: false,
      }));
    } else if (cachedProfile && cachedProfile.admin) {
      setState((state) => ({
        ...state,
        showDashboard: false,
        showLoader: true,
      }));
    } else {
      setState((state) => ({
        ...state,
        showSignin: true,
      }));
      // await navigate("/signin");
    }
  }, [profile]);

  const handleCheckError = async (error) => {
    // method to override authProvider's checkError
    // forces a logout only for 401 (unauthorised) errors
    // alerts user that s/he lacks permission for 403 (forbidden) errors
    const status = error.status;

    if (status === 401) {
      return Promise.reject();
    } else if (status === 403) {
      permsOpenModal();
    }
  };

  // override these default authProvider methods with custom ones
  authProvider.getPermissions = handleGetPermissions;
  authProvider.checkError = handleCheckError;
  const classes = useStyles();
  const resource_name = getResourceLink(serviceProviderId);

  if (profile.admin) {
    // refresh page to enable offline persistence
    let setOfflinePersistence = localStorage.getItem("setOfflinePersistence");
    if (setOfflinePersistence) {
      localStorage.removeItem("setOfflinePersistence"); // to prevent infinite reload loop
      // reload to force re-execution of firebaseConfig.js thereby setting offline persistence
      location.reload();
    }
  }

  // all is set, return admin dashboard
  return (
    <div>
      <StickyAnnouncementBanner />
      {showPermDialog && (
        <PermDialog
          openDialog={permsOpenModal}
          closeDialog={permsCloseModal}
          avatar={Forbidden}
          buttonHelperText="Close"
          title="Warning"
          description="You lack permissions to view the requested resources"
        />
      )}
      {showDashboard && (
        <Admin
          theme={theme}
          layout={TwLayout}
          loginPage={SignIn}
          logoutButton={SignOut}
          dashboard={
            compType === "serviceProvider" ? SpDashboard : BusinessDashboard
          }
          dataProvider={dataProvider}
          authProvider={authProvider}
          i18nProvider={i18nProvider}
        >
          {(permissions) => [
            <Resource name="dashboard" />,
            <Resource name="users" />,
            <Resource name="businesses" />,
            <Resource name="companies" />,

            <ResourceWithPermissions
              name={resource_name.companies}
              permissions={permissions}
              options={{ label: "Companies" }}
              icon={CompanyIcon}
              list={CompanyList}
              show={CompanyShow}
              create={CompanyCreate}
              edit={CompanyEdit}
            />,
            <ResourceWithPermissions
              name={resource_name.hubs}
              permissions={permissions}
              options={{ label: "Hubs" }}
              icon={HubIcon}
              list={HubList}
              show={HubShow}
              create={HubCreate}
              edit={HubEdit}
            />,

            <Resource
              name={
                compType === "serviceProvider" ? 
                `${resource_name.admins}` : `${resource_name.businessAdmins}`
              }
              options={{ label: "Admins" }}
              icon={AdminIcon}
              list={AdminList}
              show={AdminShow}
              // create={AdminCreate}
              // edit={AdminEdit}
            />,

            <Resource
              name={
                compType === "serviceProvider" ? 
                `${resource_name.teams}` : `${resource_name.businessTeams}`
              }
              options={{ label: "Teams" }}
              icon={TeamIcon}
              list={TeamList}
              // create={TeamCreate}
              // edit={TeamEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.permissions}
              permissions={permissions}
              options={{ label: "Permissions" }}
              icon={PermissionIcon}
              list={PermissionList}
              show={PermissionShow}
              create={PermissionCreate}
              edit={PermissionEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.customers}
              permissions={permissions}
              options={{ label: "Customers" }}
              icon={CustomerIcon}
              list={CustomerList}
              show={CustomerShow}
              create={CustomerCreate}
              edit={CustomerEdit}
            />,

            <Resource
              // rendering the invites resource without permissions prevents
              // a blank screen when rendering 
              name={resource_name.invites}
              options={{ label: "Invites" }}
              edit={InvitesEdit}
              show={InvitesShow}
            />,

            <ResourceWithPermissions
              name={resource_name.agents}
              permissions={permissions}
              options={{ label: "Agents" }}
              icon={AgentIcon}
              list={AgentsList}
              show={AgentsShow}
              // create={AgentsCreate}
              // edit={AgentsEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.fleet}
              permissions={permissions}
              options={{ label: "Fleet" }}
              icon={FleetIcon}
              list={VehicleList}
              show={VehicleShow}
              create={VehicleCreate}
              edit={VehicleEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.trips}
              permissions={permissions}
              options={{ label: "Trips" }}
              icon={TripIcon}
              list={TripList}
              show={TripShow}
              create={TripCreate}
              edit={TripEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.consignments}
              permissions={permissions}
              options={{ label: "Orders" }}
              icon={ConsignmentIcon}
              list={
                compType === "serviceProvider" ? OrderList : BizOrdersList 
              }
              show={OrderDetail}
              // create={
              //   compType === "serviceProvider" ? OrderCreate : BizOrdersCreate 
              // }
              // edit={OrderEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.payments}
              permissions={permissions}
              options={{ label: "Payments" }}
              icon={PaymentIcon}
              list={PaymentList}
              // create={PaymentCreate}
              // edit={PaymentEdit}
              show={PaymentShowView }
            />,

            <ResourceWithPermissions
              name={resource_name.bookings}
              permissions={permissions}
              options={{ label: "Bookings" }}
              icon={BookingIcon}
              list={BookingList}
              show={BookingShow}
              create={BookingCreate}
              edit={BookingEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.dispatches}
              permissions={permissions}
              options={{ label: "Dispatches" }}
              icon={DispatchIcon}
              list={DispatchList}
              show={DispatchShow}
              create={DispatchCreate}
              edit={DispatchEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.routes}
              permissions={permissions}
              options={{ label: "Routes" }}
              icon={RouteIcon}
              list={RouteList}
              show={RouteShow}
              create={RouteCreate}
              edit={RouteEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.messages}
              permissions={permissions}
              options={{ label: "Messages" }}
              icon={MessageIcon}
              list={MessageList}
              show={MessageShow}
              // create={MessageCreate}
            />,

            <ResourceWithPermissions
              name={resource_name.accounts}
              permissions={permissions}
              options={{ label: "Account" }}
              icon={AccountIcon}
              list={AccountList}
            />,

            <ResourceWithPermissions
              name={resource_name.address}
              permissions={permissions}
              options={{ label: "Address" }}
              icon={AddressIcon}
              list={AddressList}
              show={AddressShow}
              create={AddressCreate}
              edit={AddressEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.status}
              options={{ label: "Status" }}
              icon={StatusIcon}
              list={StatusList}
              show={StatusShow}
              create={StatusCreate}
              edit={StatusEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.waybills}
              permissions={permissions}
              options={{ label: "Waybills" }}
              icon={WayBillIcon}
              list={WayBillList}
              show={WayBillShow}
              create={WayBillCreate}
              edit={WayBillEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.ratings}
              permissions={permissions}
              options={{ label: "Ratings" }}
              icon={RatingsIcon}
              list={RatingList}
              show={RatingShow}
            />,
            //Business resource names @todo: add list create edit views
            <Resource
              name={resource_name.home}
              options={{ label: "Home" }}
              list={Home}
            />,
            <ResourceWithPermissions
              name={resource_name.consignments}
              options={{ label: "Orders" }}
              permissions={permissions}
              icon={OrderIcon}
              list={OrdersList}
              // create={OrdersCreate}
            />,
            <ResourceWithPermissions
              name={resource_name.customers}
              permissions={permissions}
              options={{ label: "Customers" }}
              icon={CustomerIcon}
              list={BizCustomerList}
              create={BizCustomerCreate}
              edit={BizCustomerEdit}
            />,

            <Resource
              name={resource_name.settings}
              options={{ label: "Settings" }}
              icon={SettingsIcon}
              list={SettingsPage}
            />,

            <Resource
              name={resource_name.help}
              options={{ label: "Help" }}
              icon={HelpIcon}
              list={Help}
            />,

            <Resource
              name={resource_name.agentsMapView}
              options={{ label: "Live Map" }}
              icon={MapIcon}
              // render the map as a 'list' to allow viewing the entire view
              // else nothing appears. setting it to 'show' doesn't work as RA
              // would think that we're accessing the detail view of a particular record
              list={MapView}
            />,

            <Resource
              name={resource_name.authHome}
              options={{ label: "Auth Home" }}
              // render the map as a 'list' to allow viewing the entire view
              // else nothing appears. setting it to 'show' doesn't work as RA
              // would think that we're accessing the detail view of a particular record
              list={AuthHome}
            />,

          ]}
        </Admin>
      )}
      {showLoader && (
        <div style={{ marginTop: "25%", marginLeft: "40%" }}>
          <CircularProgress variant="indeterminate" size="10%" />
        </div>
      )}
      {showSignin && !showDashboard && !showLoader && <SignIn />}
    </div>
  );
}

export default TwAdmin;
