import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { CreateButton, useListContext } from "react-admin";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import noOrder from "../assets/noOrder.svg";

const useStyles = makeStyles({
  root: {
    color: "grey",
    margin: "1rem",
  },
  icon: {
    width: "9rem",
    height: "9rem",
    marginTop: "3rem",
  },
});

function setResource(resource) {
  // function to get resource from collection url string
  // by turning string into array they getting last element
  let resourceName = resource.split("/").pop();

  // Capitalize the first letter
  resourceName = resourceName[0].toUpperCase() + resourceName.substring(1);
  return resourceName;
}

const EmptyListPage = (props) => {
  const { basePath, resource } = useListContext();
  const classes = useStyles();

  //no list available image
  const NoData = () => {
    return <img alt="logo" src={noOrder} className={classes.icon} />;
  };

  return (
    <Box textAlign="center" m={1}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <NoData />
          <Typography variant="h4" paragraph className={classes.root}>
            No {setResource(resource)} yet.
          </Typography>
          <Typography variant="body1" className={classes.root}>
            Do you want to add one?
          </Typography>
          {/* <CreateButton
            basePath={basePath}
            label=""
            variant="contained"
            color="primary"
            size="large"
          /> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmptyListPage;
