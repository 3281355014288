import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { db } from "../../../../../config/firebaseConfig";
import { navigate } from "@reach/router";
import { syncCustomClaims, syncTeamData } from "../../../syncGlobalProfile";
import { ProfileContext } from "../../../../../context/ProfileContext";
import CreateCompany from "../CreateCompany";

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const CreateServiceProvider = ({ authUser, userId, accountType }) => {
  const { updateProfile } = useContext(ProfileContext)
  const [showDialog, setShowDialog] = React.useState(false);
  const [state, setState] = useState({
    name: "",
  });

  const { name } = state;
 const getSpName = (coName) => {
   setState({
     name: coName
   });
 }

  const createSp = async (payload) => {
    let spId;
    try {
      const spRef = await db.collection("companies").add(payload);
      spId = spRef.id;
      console.log("**** success: sp was created *****", spId);
    } catch (err) {
      console.error("*** err: creating company *****", err);
    }

    return spId;
  };

  const createAdmin = async (payload, spId) => {
    let adminId;
    try {
      const adminRef = await db
        .collection(`companies/${spId}/admins`)
        .add(payload);
      adminId = adminRef.id;
      console.log("**** success: admin was created *****", adminId);
    } catch (err) {
      console.error("**** err: creating admin ***", err);
    }
    return adminId;
  };

  const getSp = async (spId) => {
    try {
      const sp = await db.collection("companies").doc(`${spId}`).get();
      return {
        id: sp.id,
        ...sp.data(),
      };
    } catch (err) {
      console.error("*** err: fetching service provider *****", err);
    }
  };

  const getAdmin = async (spId, adminId) => {
    try {
      const admin = await db.doc(`companies/${spId}/admins/${adminId}`).get();
      return {
        id: admin.id,
        ...admin.data(),
      };
    } catch (err) {
      console.error("*** err: fetching admin *****", err);
    }
  };

  // const getFormattedPermissions = (rawPermissions, companyId) =>
  //   formatPermissions(rawPermissions, companyId);

  // const getTeam = async (spId, teamId) => {
  //   try {
  //     const team = await db.doc(`companies/${spId}/teams/${teamId}`).get();
  //     return {
  //       id: team.id,
  //       name: team.data().name,
  //       permissions: getFormattedPermissions(team.data().permissions, spId),
  //     };
  //   } catch (err) {
  //     console.error(
  //       "******* auth error: an error occurred while fetching admin's team ******",
  //       err
  //     );
  //   }
  //};

  const handleSubmit = async (e) => {
    const timerStart = Date.now();
    console.log(">>> handleSubmit starts at ", timerStart)
    e.preventDefault();
    // display Loading dialog
  setShowDialog(true);

    // create service provider
    const spId = await createSp({
      name: name,
    });

    let timeNow = Date.now();
    let timeDiff = timeNow - timerStart;
    console.log(`>>> company creation done at ${timeNow}. ${timeDiff}ms so far...`)

    let ownerAdminCreated = false;
    let ownerAdminTeamCreated = false;
    // listen for changes in sp
    db.collection("companies")
      .doc(spId)
      .onSnapshot(
        async (spDocSnapshot) => {
          // only create an admin if there's an `adminsCount` field and there's only one
          // such admin, else we'd end up with multiple admin records for the same SP
          timeNow = Date.now();
          timeDiff = timeNow - timerStart;
          console.log(`>>> onSnapshot called at ${timeNow}. ${timeDiff}ms so far...`)

          const spData = spDocSnapshot.data();

          if (
            !ownerAdminCreated &&
            spData && spData.adminsCount < 1
          ) {
            // create admin
            const ownerAdminId = await createAdmin({
              userId: userId,
              companyType: accountType,
              companyId: spId,
              emailAddress: authUser.email, // adding this as it is required while setting custome calims in the onCreateAdmin function
            },
            spId);

            timeNow = Date.now();
            timeDiff = timeNow - timerStart;
            console.log(`>>> owner admin creation done at ${timeNow}. ${timeDiff}ms so far...`)

            ownerAdminCreated = true;
            // sync custom claims
            await syncCustomClaims(authUser);

            timeNow = Date.now();
            timeDiff = timeNow - timerStart;
            console.log(`>>> custom claims sync done at ${timeNow}. ${timeDiff}ms so far...`)

            do {
              timeNow = Date.now();
              timeDiff = timeNow - timerStart;
              console.log(`>>> inside do..while loop; ${timeDiff}ms elapsed`);
              // get the owner admin
              const ownerAdmin = await getAdmin(spId, ownerAdminId);

              if(ownerAdmin.team && ownerAdmin.team.id){
                ownerAdminTeamCreated = true;

                // get the team
                const team = await syncTeamData(spId, ownerAdmin.team.id, "companies")

                const syncedProfile = {
                  authUser,
                  company: await getSp(spId),
                  admin: ownerAdmin,
                  team
                }

                // update the profile context
                updateProfile(syncedProfile)

                // add the profile object as it'll be used to replenish the profile context across page refreshes
                localStorage.setItem(
                  "cachedProfile",
                  JSON.stringify(syncedProfile)
                );

                timeNow = Date.now();
                timeDiff = timeNow - timerStart;
                console.log(`>>> team data sync + profile caching done at ${timeNow}. ${timeDiff}ms so far...`)

                // redirect to the dashboard
                navigate("/home")
              } else {
                timeNow = Date.now();
                timeDiff = timeNow - timerStart;
                console.log(`>>> no team yet for admin ${ownerAdmin.id};, ${timeDiff}ms elapsed`);
              }
            } while (!ownerAdminTeamCreated);
          } else {
            console.log(`>>> skipping admin/sCC/do..while loop`)
          }
        },
        (err) => console.error("*** err: listening to sp snapshot ******", err)
      );
  };

  return (
    <CreateCompany
      showDialog={showDialog}
      handleSubmit={handleSubmit}
      useStyles={useStyles}
      sendCoName={getSpName}
    />
  );
};

CreateServiceProvider.propTypes = {
  authUser: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  accountType: PropTypes.string.isRequired,
};

export default CreateServiceProvider;
