import React from "react";
import {
  Grid,
  Card,
  Divider,
  Typography,
  CardContent,
  Avatar,
  Chip
} from "@material-ui/core";
import { TextField, FunctionField,ReferenceField } from "react-admin";
import {useStyles} from "../../pages/admin/Agents/views/AgentsShow";
import PropTypes from "prop-types";
import moment from "moment";

const CustomerDetailsCard = ({ record }) => {
  const classes = useStyles();
  return record.type === "individual" ? (
    <Grid container spacing={1}>
      {record !== undefined && (
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Card className={classes.card}>
            <div className={classes.wrapper}>
              <Avatar alt={record.name} className={classes.image} />
              <FunctionField
                className={classes.text}
                render={(record) => record.firstName ? `${record.firstName} ${record.lastName}`:`${record.name} `}
              />
               <React.Fragment>
                <div className={classes.text}>
                 <Chip label={record.type}/>
                </div>
              </React.Fragment>
            </div>
            <Divider className={classes.divider} />
            <CardContent>
              <Grid container spacing={2} className={classes.main}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    component="p"
                    className={classes.main}
                  >
                    Contact:{" "}
                  </Typography>
                  <div>
                    <TextField className={classes.text} source="phoneNumber" />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    component="p"
                    className={classes.main}
                  >
                    Address:{" "}
                  </Typography>
                  <div className={classes.text}>
                    {record.address}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    component="p"
                    className={classes.main}
                  >
                    Email:{" "}
                  </Typography>
                  <div className={classes.text}>
                    {record.emailAddress || "N/A"}
                  </div>
                </Grid> 
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    component="p"
                    className={classes.main}
                  >
                    Added on:{" "}
                  </Typography>
                  <div className={classes.text}>{moment(record.createdAt * 1).format("DD-MM-YYYY")}</div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  ) : (
    <Grid container spacing={1}>
      {record !== undefined && (
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Card className={classes.card}>
            <div className={classes.wrapper}>
              <Avatar alt={record.businessName} className={classes.image} />
              <FunctionField
                className={classes.text}
                render={(record) => `${record.name} `}
              />
              <React.Fragment>
                <div className={classes.text}>
                 <Chip label={record.type}/>
                </div>
              </React.Fragment>
            </div>
            <Divider className={classes.divider} />
            <CardContent>
              <Grid container spacing={2} className={classes.main}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    component="p"
                    className={classes.main}
                  >
                    Contact:{" "}
                  </Typography>
                  <ReferenceField  source="businessId" reference="businesses" link={false}>
                  <FunctionField
                    className={classes.text}
                    render={(record) => record.phoneNumber}
                  />
                  </ReferenceField>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    component="p"
                    className={classes.main}
                  >
                    Address:{" "}
                  </Typography>
                  <div className={classes.text}>
                    {record.address}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    component="p"
                    className={classes.main}
                  >
                    Email:{" "}
                  </Typography>
                  <ReferenceField  source="businessId" reference="businesses" link={false}>
                  <FunctionField
                    className={classes.text}
                    render={(record) => record.emailAddress}
                  />
                  </ReferenceField>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    component="p"
                    className={classes.main}
                  >
                    Invited on:{" "}
                  </Typography>
                  <div className={classes.text}>{moment(record.createdAt * 1).format("DD-MM-YYYY")}</div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};
CustomerDetailsCard.propTypes = {
  record: PropTypes.object.isRequired,
};
export default CustomerDetailsCard;
