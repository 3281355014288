import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import pure from "recompose/pure";
import { CardHeader, Avatar, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: "-0.8rem",
		marginBottom: "-1.2rem",
	},
	image: {
		width: "3.3rem",
		height: "3.3rem",
	},
	text: {
		fontStyle: "italics",
	},
}));

const AgentBioField = ({ record }) => {
	const classes = useStyles();
	return record ? (
		<div className={classes.root}>
			<CardHeader
				avatar={<Avatar alt={record.firstName} className={classes.image} />}
				title={
					record.name ? record.name : `${record.firstName} ${record.lastName}`
				}
				subheader={
					<React.Fragment>
						<div>
							<Typography
								variant="caption"
								display="block"
								className={classes.text}
							>
								{record.emailAddress}
							</Typography>
						</div>
						<div>
							<Typography
								variant="caption"
								display="block"
								className={classes.text}
							>
								{record.phoneNumber}
							</Typography>
						</div>
					</React.Fragment>
				}
			/>
		</div>
	) : null;
};

const PureFullNameField = pure(AgentBioField);

PureFullNameField.defaultProps = {
	source: "lastName",
	label: "Name",
};

export default PureFullNameField;
