/* eslint-disable */
import React from "react";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import CompanyIcon from '@material-ui/icons/AccountBalanceOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { functions } from "../../config/firebaseConfig";
import IndeterminateLoading from "../../components/IndeterminateLoading";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ValidateInvite = () => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleSubmit = async () => {
    // display Loading dialog
    setShowDialog(true);

    // extract invited admin & company ID from params. sample URL:
    // <hostname>/accept-invite?adminId=U2FsdGVkX18XzcvV4xu0HiLMUvca3i4Rrv8XLR4oxCK9uEu3ZrJJzpvBEVOSvQoC&companyId=U2FsdGVkX1%2B%2BCzrEaH9QxdBYlZgkWWLCkMHGpBAIpcT3gEaNdOPvoDaQKi2hUbn2&inviteDateTime=U2FsdGVkX19BzjkHm87AfNr84oFRJdsz1wj2z9f6yp8%3D
    // based off https://stackoverflow.com/a/58611779/1145905
    const qString = window.location.search;
    const urlParams = new URLSearchParams(qString);
    const inviteData = {
      // re-encode params to preserve URL encoding on the invite link (without which, URL chars like %2B in the original query
      // are converted into into '+'), which leads to "TypeError: o.hasOwnProperty is not a function" observed when triggering the callable
      adminId: encodeURIComponent(urlParams.get('adminId')),
      companyId: encodeURIComponent(urlParams.get('companyId')),
      inviteDateTime: encodeURIComponent(urlParams.get('inviteDateTime')),
    };

    console.log("inviteData === ", inviteData);

    let validateEmail = functions.httpsCallable('verifyEmail');
    let verifyBusiness = functions.httpsCallable('verifyBusinessInvite');

    const accountType = encodeURIComponent(urlParams.get('accountType'));
    //if accountTYpe exists then this is a business invite
    if (accountType !== "null") {
      // accountType is not undefined
      console.log('validating the business invite email');
      const businessInviteData = {
          spAdminDocId: encodeURIComponent(urlParams.get('spAdminDocId')),
          accountType: encodeURIComponent(urlParams.get('accountType')),
          spInviterCompanyId: encodeURIComponent(urlParams.get('companyId')),
          inviteId: encodeURIComponent(urlParams.get('inviteId')),
          inviteDateTime: encodeURIComponent(urlParams.get('inviteDateTime')),
      };
      console.log("businessInviteData === ", businessInviteData);

      //call cloud function
      await verifyBusiness(businessInviteData)
      .then(function (result) {
          console.log("business invite verification successful: ", result);
          setShowDialog(false); // hide Loading dialog
          /**
           * save decrypted result data in localstorage so that its readily
           * available throughout the auth process where we:
           * 1. set flag  to dynamically set skipping/showing of signup screens based on accountType
           * 2. Add the inviter companySpId to the business created customer object during auth
           * 2. update the invite record once the new business finishes the last step in auth process.
           *  */ 
          localStorage.setItem('spAdminDocId', result.data.spAdminDocId);
          localStorage.setItem('accountType', result.data.accountType);
          localStorage.setItem('spInviterCompanyId', result.data.spInviterCompanyId);
          localStorage.setItem('inviteId', result.data.inviteId);
          localStorage.setItem('inviteDateTime', result.data.inviteDateTime);
          location.assign("/"); // redirect to login
      }).catch(function (error) {
          setShowDialog(false); // hide Loading dialog
          const { message } = error;
          console.log("invite validation error: ", message);
          setError(message);
      });

  } else {
    //this is an admin invite
      await validateEmail(inviteData)
      .then(function(result) {
          console.log("email verification: ", result);
          setShowDialog(false); // hide Loading dialog
          location.assign("/signin/true"); // redirect to login
      }).catch(function(error) {
          setShowDialog(false); // hide Loading dialog
          console.log("invite validation error: ", error);
          alert("Something went wrong while confirming your invitation: ", error);
      });
  }

  };

  // perform the email validation by default
  React.useEffect(() => {
    console.log("validating the invite");
    handleSubmit();
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <IndeterminateLoading showDialog={showDialog} message="We are validating your account. Please wait for a moment to complete setting up your account"/>
      <CssBaseline />
      <div className={classes.root}>
        <Avatar className={classes.avatar}>
          <CompanyIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Twift Admin
        </Typography>
        {error !== null ? 
        <Alert severity="error">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs="auto">
              {error}
            </Grid>
          </Grid>
        </Alert> :
        <>
          <Typography component="h6" variant="h6">
            Please click below to accept your invitation
          </Typography>
          <form
            onSubmit={handleSubmit}
            className={classes.form} noValidate>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              className={classes.submit}
            >
              Accept
            </Button>
          </form>
        </>
        }
      </div>
    </Container>
  );
};

export default ValidateInvite;
