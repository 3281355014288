import React from "react";
import Typography from "@material-ui/core/Typography";

import styled from "styled-components";

const Wrapper = styled.div`
  margin-top: 3rem;
  width: 100%;
`;

export default function ContactInfo() {
  const message =
    "Send us a message by pressing the button on the bottom right of your screen in case your question was not answered. We will get back To you as soon as possible.";
  return (
    <Wrapper>
      <Typography variant="body2" color="textSecondary" component="p">
        {message}
      </Typography>
    </Wrapper>
  );
}
