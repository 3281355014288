import React, { useContext } from "react";
import { ProfileContext } from "../context/ProfileContext";
import { Toolbar, SaveButton } from "react-admin";

// the saveButton is held in a toolbar
const SaveCompanyOnSaveToolbar = (props) => {
  const { profile } = useContext(ProfileContext);
  const serviceProviderId = profile.company.id;

  return serviceProviderId ? (
    <Toolbar {...props}>
      <SaveButton
        transform={(data) => ({ ...data, serviceProviderId: serviceProviderId })}
        submitOnEnter={true}
      />
    </Toolbar>
  ) : null;
};
export default SaveCompanyOnSaveToolbar;
