import React from "react";
import styled from "styled-components";

const StickyAnnouncementBanner = () => {

  return (
    <Wrapper>
      <h3>Version 3 🚀</h3>
      <p>
       You can now use the next version of Twift.
        {/* <CTA> */}
        <a href={`${process.env.REACT_APP_V3_LINK}/auth/signup`}>
          <u><b>Register NOW!</b></u>
        </a>
        {/* </CTA> */}
      </p>
    </Wrapper>
  );
};

export default StickyAnnouncementBanner;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.1);
  padding: 0 1rem;
  position: fixed;
  width: 60%;
  margin: auto;
  background: rgb(131,58,180);
  background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
  left: 0;
  right: 0;
  top: 1rem;
  border-radius: 5rem;
  overflow: visible;
  z-index: 5;
  * {
    background-color: transparent;
  }
  h3, p, a {
    color: #fff;
    font-size: 1.2rem;
    text-decoration: none;
  }
  p,
  a {
    font-weight: 800;
    font-family: "Fira Sans", sans-serif;
  }
  h3 {
    font-family: "Ubuntu", sans-serif;
    margin-right: 0.5rem;
  }
  u {
    margin-left: 0.5rem;
    transition: 0.3s;
    :hover {
        font-size: 1rem;
        transition: 0.3s;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    top: 0;
    border-radius: 0;
    padding: 0 1rem;
    h3, p, a {
      font-size: 0.8rem;
    }
  }
`;
