import React, { useContext, useState, useEffect, useRef } from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateTimeInput } from "react-admin-date-inputs2";
import parseDateTime from "../../utils/parseDateTime";
import { validateRequired, validatePlaceName } from "../../utils/validators";
import DateUtils from "@date-io/moment";
import PageHeader from "../PageHeader";
import styles from "./styles";
//material ui imports
import {
	Paper,
	Button,
	Grid,
	StepLabel,
	Step,
	Stepper,
	StepConnector,
} from "@material-ui/core";
//RA imports
import {
	TextInput,
	SelectInput,
	Create,
	useRedirect,
	useNotify,
	SaveButton,
	SimpleForm,
	Toolbar,
} from "react-admin";
import { ProfileContext } from "../../context/ProfileContext";
import createRecord from "../../utils/createRecord";
import { db } from "../../config/firebaseConfig";
import firebase from "firebase";
import { useStyles } from "./OrderStyles";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import RateReviewIcon from "@material-ui/icons/RateReview";
import clsx from "clsx";
import PropTypes from "prop-types";
import { HiddenTextInput } from "../hiddenTextInput";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { createCustomer } from "../../utils/createCustomer";
import Review from "./Reviews";
import PackageDetailsAccordion from "./PackageDetails";
import RecipientForm from "./RecipientForm";
import IndeterminateLoading from "../../components/IndeterminateLoading";
import { useHistory } from "react-router-dom";

const useColorlibStepIconStyles = makeStyles({
	root: {
		backgroundColor: "#ccc",
		zIndex: 1,
		color: "#fff",
		width: 50,
		height: 50,
		display: "flex",
		borderRadius: "50%",
		justifyContent: "center",
		alignItems: "center",
	},
	active: {
		backgroundImage:
			"linear-gradient( 136deg, #C2F8FF 0%, #00acc1 50%, #00acc1 100%)",
		boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
	},
	completed: {
		backgroundImage:
			"linear-gradient( 136deg, #C2F8FF 0%, #00acc1 50%, #00acc1 100%)",
	},
});
function ColorlibStepIcon(props) {
	const classes = useColorlibStepIconStyles();
	const { active, completed } = props;

	const icons = {
		1: <GroupAddIcon />,
		2: <LocalShippingIcon />,
		3: <RateReviewIcon />,
	};

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active,
				[classes.completed]: completed,
			})}
		>
			{icons[String(props.icon)]}
		</div>
	);
}

ColorlibStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 */
	active: PropTypes.bool,
	/**
	 * Mark the step as completed. Is passed to child components.
	 */
	completed: PropTypes.bool,
	/**
	 * The label displayed in the step icon.
	 */
	icon: PropTypes.node,
};
const ColorlibConnector = withStyles({
	alternativeLabel: {
		top: 22,
		left: "calc(-50% + 16px)",
		right: "calc(50% + 16px)",
	},
	active: {
		"& $line": {
			backgroundImage:
				"linear-gradient( 95deg,#C2F8FF 0%,#00acc1 50%,#00acc1 100%)",
		},
	},
	completed: {
		"& $line": {
			backgroundImage:
				"linear-gradient( 95deg,#C2F8FF 0%,#00acc1 50%,#00acc1 100%)",
		},
	},
	line: {
		height: 3,
		border: 0,
		backgroundColor: "#eaeaf0",
		borderTopWidth: 3,
		borderRadius: 1,
	},
})(StepConnector);

const OrdersCreate = (props) => {
	const { profile } = useContext(ProfileContext);
	const businessId = profile.company.id;
	const businessName = profile.company.name;
	const businessPhoneNumber = profile.company.phoneNumber;
	const businessCustomerId = profile.company.customerId;
	const [serviceProvider, setServiceProviders] = useState([]);
	const classes = useStyles();
	const serviceProviderId = profile.company.id;
	const redirect = useRedirect();
	const notify = useNotify();
	const deliveryDate = new Date(
		firebase.firestore.Timestamp.now().seconds * 1000,
	);
	const [activeStep, setActiveStep] = React.useState(0);
	const [recipientCustomerId, setRecipientCustomerId] = useState("");
	const [recipientInputValues, setRecipientInputValues] = useState({
		name: "",
		address: "",
		phoneNumber: "",
		role: "recipient",
	});
	const [showLoader, setShowLoader] = useState(false);

	const onRecipientChangeHandler = (event) => {
		const { name, value } = event.currentTarget;
		// dynamically update Recipient form inputs
		setRecipientInputValues((recipientInputValues) => ({
			...recipientInputValues,
			[name]: value,
		}));
	};

	const updateAutocompletedCustomerData = ({
		id,
		name,
		address,
		phoneNumber,
	}) => {
		setRecipientInputValues((recipientInputValues) => ({
			...recipientInputValues,
			name,
			address,
			phoneNumber,
			id,
		}));
	};

	const validateForm = React.useRef(null);
	//renders from recipient to dispatch step
	const onRecipientStep = async () => {
		if (!validateForm.current.checkValidity()) {
			notify(
				"Please fill in the detail before proceeding to the next step ",
				"warning",
				{ duration: 2 },
			);
			return;
		}
		const recipientId = await SaveCustomer(recipientInputValues);
		//update customer id of role recipient
		setRecipientCustomerId(recipientId);
		setActiveStep(activeStep + 1);
	};

	const handlePhoneNumberChange = (value) => {
		setRecipientInputValues((recipientInputValues) => ({
			...recipientInputValues,
			phoneNumber: value,
		}));
	};

	//initial consignments route
	const formRootPathname = "/consignments/create";
	const history = useHistory();
	const initialLocation = useRef(formRootPathname || history.location.pathname);

	useEffect(() => {
		const release = history.block((location) => {
			const isInsideForm = location.pathname.startsWith(
				initialLocation.current,
			);

			if (!isInsideForm && !activeStep - 1) {
				return alert(
					"You are about to exit the page. Are you sure you want to do this",
				);
			}

			return undefined;
		});

		return () => {
			if (release) {
				release();
			}
		};
	}, [history]);

	//show alert before page gets reloaded, contains 2 options (cancel & reload)
	useEffect(() => {
		const checkUrlPath = () => {
			if (formRootPathname === history.location.pathname) {
				return true;
			} else {
				return false;
			}
		};
		//trigger the reload prompt if the current page is equal to the location
		window.onbeforeunload = function () {
			return checkUrlPath()
				? "If you leave this page you will lose your unsaved changes."
				: null;
		};

		return () => {
			window.onbeforeunload = function () {
				return null;
			};
		};
	}, [history]);

	const updateGeoAddress = (address) => {
		if (activeStep === 0) {
			setRecipientInputValues((recipientInputValues) => ({
				...recipientInputValues,
				address: address,
			}));
		}
	};

	//show step label
	const steps = ["Recipient Details", "Courier Details", "Review Order"];

	const CourierForm = () => {
		return (
			<>
				<Grid container spacing={2}>
					<Grid item lg={12}>
						<Grid container justifyContent="center" spacing={2}>
							<Grid item lg={7} sm={10} xs={12}>
								<>
									<PageHeader title="Assign Service Provider" />
									{/* sps */}
									<SelectInput
										style={{ marginTop: "2rem" }}
										source="serviceProviderId"
										label="Service Provider"
										choices={serviceProvider}
										optionText={(record) =>
											record.name ? record.name : record.phoneNumber
										}
										validate={validateRequired}
										variant="outlined"
										optionValue="id"
										fullWidth
									/>
									<TextInput
										source="sender.address"
										label="Address"
										validate={validatePlaceName}
										variant="outlined"
										placeholder="Enter pick-up location"
										helperText="*Your desired pick up location for this order"
										fullWidth
									/>
									<MuiPickersUtilsProvider utils={DateUtils}>
										<DateTimeInput
											source="date"
											variant="outlined"
											label="Delivery Date"
											parse={parseDateTime}
											validate={validateRequired}
											initialValue={deliveryDate}
											fullWidth
											options={{
												format: "DD/MM/YYYY, HH:mm:ss",
												ampm: false,
												clearable: true,
												showTodayButton: true,
											}}
										/>
									</MuiPickersUtilsProvider>
								</>
							</Grid>
							<Grid item lg={4}>
								<PackageDetailsAccordion />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{/* business(sender) details */}
				<HiddenTextInput source="sender.id" initialValue={businessCustomerId} />
				<HiddenTextInput
					source="sender.phoneNumber"
					initialValue={businessPhoneNumber}
				/>
				<HiddenTextInput source="sender.type" initialValue="business" />
				<HiddenTextInput source="sender.name" initialValue={businessName} />
				<HiddenTextInput source="agent.id" initialValue="" />
				{/* status === created */}
				<HiddenTextInput source="status.value" initialValue="created" />
			</>
		);
	};

	//function to get the current step
	function getStepContent(step) {
		switch (step) {
			case 0:
				return (
					<RecipientForm
						formName="recipient"
						values={recipientInputValues}
						onChangeHandler={onRecipientChangeHandler}
						updateAutocompletedCustomerData={updateAutocompletedCustomerData}
						validateForm={validateForm}
						handlePhoneNumberChange={handlePhoneNumberChange}
						updateGeoAddress={updateGeoAddress}
					/>
				);
			case 1:
				return <CourierForm />;
			case 2:
				return (
					<>
						{showLoader && (
							<IndeterminateLoading
								showDialog={true}
								message="Requesting order. Do not close this tab nor exit your browser."
							/>
						)}
						<Review recipientValues={recipientInputValues} />
					</>
				);
			default:
				throw new Error("Unknown step");
		}
	}

	//renders the next step
	const handleNext = () => {
		setActiveStep(activeStep + 1);
	};

	//renders the previous step
	const handleBack = () => {
		setActiveStep(activeStep - 1);
	};

	async function fetchData(collectionUrl) {
		// DRY function to fetch data from a collection url
		let querySnapshot = await db.collection(collectionUrl).get();
		const data = querySnapshot.docs.map((doc) => {
			// create object using doc data and id
			let obj = doc.data();
			obj.id = doc.id;
			return obj;
		});
		return data;
	}

	useEffect(() => {
		// set service providers
		const collectionUrl = "companies";
		fetchData(collectionUrl).then((data) => {
			setServiceProviders(data);
		});
	}, [serviceProviderId]);

	const onSuccess = () => {
		notify("The order was created successfully", "success");
		redirect("list", props.basePath);
	};

	const onFailure = () => {
		notify("The Order was not created!", "warning");
	};

	/*
uses a custom save button component that creates and saves address docs whenever a consignment is saved
*/

	// handles customer creation during orders creation in step 1
	const SaveCustomer = async (values) => {
		const { name, address, phoneNumber, id } = values;
		const customerUserData = {
			name,
			address,
			phoneNumber,
		};
		//save data on customers record
		const customerData = {
			serviceProviders: serviceProviderId,
			type: "individual",
			name: `${name}`,
			phoneNumber: phoneNumber,
			address: address,
		};

		const resource = "customers";

		const customers = await db
			.collection("customers")
			.where("phoneNumber", "==", phoneNumber)
			.get();
		const isCustomer = customers.size === 1;

		// // Save customer data to the users collection and the rest in customers
		if (id === undefined && isCustomer === false) {
			try {
				// // save Customer's user data

				const clientId = await createCustomer(
					customerUserData,
					customerData,
					resource,
					onCustomerSuccess,
					onCustomerFailure,
				);
				//return customer id
				return clientId;
			} catch (error) {
				onFailure(error);
				console.log(error);
			}
		} else if (isCustomer === true) {
			// return the customerId of this customer
			const clientId = customers.docs[0].data().id;
			return clientId;
		}
		//after saving customer ,return customer id(sender.id) and set customer.type===senderer
	};

	const onCustomerSuccess = () => {};

	const onCustomerFailure = () => {};

	let isSaving = false;

	const handleSaveOrder = async (formData, recipientInputValues) => {
		isSaving = true;
		setShowLoader(true);
		async function saveAddress() {
			const createAddress = async (address) => {
				// a function to create an address doc given an object
				try {
					let queryDocSnap = await db.collection("addresses").add(address);
					return queryDocSnap.id;
				} catch (error) {
					alert(
						"An error occurred, please try again. Ensure you did not leave any of the fields empty while entering the recipient's or courier details.",
					);
					isSaving = false;
					setShowLoader(false);
				}
			};

			// define origin from submitted data
			let origin = {
				name: formData.sender?.address || "",
				country: "Kenya",
				city: formData.sender?.city || "",
				nearestLandmark: formData.sender?.nearestLandmark || "",
				type: "home",
				serviceProviderId: businessId,
			};

			// define destination from submitted data
			let destination = {
				name: recipientInputValues?.address || "",
				country: "Kenya",
				city: recipientInputValues?.city || "",
				nearestLandmark: recipientInputValues?.nearestLandmark || "",
				type: "home",
				serviceProviderId: businessId,
			};

			// create origin and destination docs saving their ids
			const originId = await createAddress(origin);
			const destinationId = await createAddress(destination);
			return [
				{ id: originId, name: formData.sender.address },
				{ id: destinationId, name: recipientInputValues.address },
			];
		}
		const [origin, destination] = await saveAddress();
		const resource = "consignments";
		/*
      create a payload to responsible for manipulating the created orders data
      to match the security rules specs
      */
		let recipient = {
			name: `${recipientInputValues.name}`,
			phoneNumber: recipientInputValues.phoneNumber,
			id:
				recipientInputValues.id === undefined
					? recipientCustomerId
					: recipientInputValues.id,
		};

		let orderPayload = {
			origin,
			destination,
			sender: {
				name: formData.sender.name,
				phoneNumber: formData.sender.phoneNumber,
				id: formData.sender.id,
				type: formData.sender.type,
			},
			recipient,
			agent: {
				id: formData.agent.id,
			},
			serviceProviderId: formData.serviceProviderId,
			status: {
				value: formData.status.value,
			},
			date: formData.date,
			size: formData.size,
			description: formData.description,
			numberOfPackages: formData.numberOfPackages,
		};

		// add common fields to the payload
		const currentTime = firebase.firestore.FieldValue.serverTimestamp();
		orderPayload = {
			...orderPayload,
			createdBy: profile.authUser.email,
			updatedBy: profile.authUser.email,
			createdAt: currentTime,
			updatedAt: currentTime,
		};

		//create a consignment record to the db
		createRecord(orderPayload, resource, onSuccess, onFailure);
	};

	const StyledToolbar = (props) => (
		<Toolbar {...props}>
			<React.Fragment>
				<div className={classes.buttons}>
					{activeStep !== 0 && (
						<Button onClick={handleBack} variant="outlined" color="primary">
							PREVIOUS
						</Button>
					)}
					{activeStep === steps.length - 1 ? (
						<SaveButton
							saving={isSaving}
							label="REQUEST ORDER"
							handleSubmitWithRedirect={props.handleSubmitWithRedirect}
							submitOnEnter={true}
							onSave={(data) => handleSaveOrder(data, recipientInputValues)}
						/>
					) : (
						<Button
							variant="contained"
							color="primary"
							onClick={async () =>
								activeStep === steps.length - 3
									? await onRecipientStep()
									: activeStep === steps.length - 2
									? await handleNext()
									: activeStep === steps.length - 1
									? await handleNext()
									: null
							}
							disabled={props.invalid}
						>
							CONTINUE
						</Button>
					)}
				</div>
			</React.Fragment>
		</Toolbar>
	);

	return (
		<Create
			{...props}
			onSuccess={onSuccess}
			undoable={false}
			onFailure={onFailure}
			title="Create order"
		>
			<SimpleForm
				ref={initialLocation}
				warnWhenUnsavedChanges
				toolbar={<StyledToolbar alwaysEnableSaveButton />}
			>
				<main className={classes.layout}>
					<Stepper
						alternativeLabel
						activeStep={activeStep}
						connector={<ColorlibConnector />}
					>
						{steps.map((label) => (
							<Step key={label}>
								<StepLabel StepIconComponent={ColorlibStepIcon}>
									{label}
								</StepLabel>
							</Step>
						))}
					</Stepper>
					{getStepContent(activeStep)}
				</main>
			</SimpleForm>
		</Create>
	);
};
OrdersCreate.propTypes = {
	basePath: PropTypes.string.isRequired,
	invalid: PropTypes.func.isRequired,
	handleSubmitWithRedirect: PropTypes.func.isRequired,
};
export default OrdersCreate;
