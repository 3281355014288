import * as React from "react";
import NumberOfCustomers from "../../components/dashboardComponents/numberOfCustomers";
import TotalConsignments from "../../components/dashboardComponents/totalConsignments";
import RevenueStatus from "../../components/dashboardComponents/revenueStatus";
import LineGraph from "../../components/dashboardComponents/LineGraph";
import DoughnutChart from "../../components/dashboardComponents/Doughnut";
import Messages from "../../components/dashboardComponents/Messages";
import PendingOrders from "../../components/dashboardComponents/PendingOrders";
import AgentsCard from "../../components/dashboardComponents/AgentsCard";
import styled from "styled-components";
import { db } from "../../config/firebaseConfig";
import { ProfileContext } from "../../context/ProfileContext";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import { useContext, useEffect, useState } from "react";
import { filterCollection } from "../../utils/filterCollection";

const DashboardCards = styled.div`
  margin: 0.5rem 1rem 1rem;
  display: grid;
  grid-template-columns: repeat(1, 2fr);
  gap: 1rem;

  h1 {
    font-size: 1.2rem !important;
    font-family: "Ubuntu", sans-serif;
  }

  h4 {
    font-size: 1rem !important;
    font-family: "Fira Sans", sans-serif;
    color: #707070;
  }

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 2fr);
  }

  @media (min-width: 1000px) {
    grid-template-columns: repeat(4, 2fr);
  }
`;

const IntroWrapper = styled.div`
  padding-top: 1rem;
  margin: 0 2rem 0;
  display: flex;
  justify-content: space-between;
`;

const GridWrapper = styled(Grid)`
  margin: 0.5rem 1rem 1rem;
`;

const IntroText = styled(Typography)`
  text-transform: capitalize;
`;
const IntroTextWrapper = styled.div`
  justify-content: flex-start;
`;
const Wrapper = styled.div`
  width: 100%;
`;
const FilterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;
const FilterButton = styled(InputLabel)`
  width: 50px;
  text-transform: capitalize;
  font-weight: bold;

  &:hover {
    //off white
    background: #f5f5f5;
  }
  &:focus {
    background: #f5f5f5;
    color: #00acc1;
  }
`;
const Dashboard = () => {
  const { profile } = useContext(ProfileContext);
  let [customersNo, setCustomersNo] = useState(0);
  const [consignmentsNo, setConsignmentsNo] = useState(0);
  const [revenue, setRevenue] = useState([]);
  const todayDate = moment().format("MMMM Do YYYY");
  const [pending, setPending] = useState(0);
  const [returned, setReturned] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [allOrders, setAllOrders] = useState([]);
  const [orders, setOrders] = useState([]);
  const companyId = profile.companyId;
  const [filter, setFilter] = useState("all");
  const [scheduledOrders, setScheduledOrders] = useState([]);
  const [title, setTitle] = useState("Total");
  const [pendingOrders, setPendingOrders] = useState([]);

  const handleChange = (event) => setFilter(event.target.value);

  //filter allOrders weekly, daily, monthly, yearly based filter state set orders state
  useEffect(() => {
    if (filter === "all") {
      setOrders(allOrders);
      setPendingOrders(scheduledOrders);
      setTitle("Total");
    }
    if (filter === "weekly") {
      const weeksOrders = filterCollection(allOrders, "week");
      const weeksPendingOrders = filterCollection(scheduledOrders, "week");

      if (weeksOrders.length > 0) {
        setOrders(weeksOrders);
        setPendingOrders(weeksPendingOrders);
        setTitle("Week's");
      } else {
        setOrders([]);
        setPendingOrders([]);
      }
    }

    if (filter === "daily") {
      const todaysOrders = filterCollection(allOrders, "today");
      const todaysPendingOrders = filterCollection(scheduledOrders, "today");

      if (todaysOrders.length > 0) {
        setOrders(todaysOrders);
        setPendingOrders(todaysPendingOrders);
        setTitle("Today's");
      } else {
        setOrders([]);
        setPendingOrders([]);
      }
    }
    if (filter === "monthly") {
      const monthlyOrders = filterCollection(allOrders, "monthly");
      const monthlyPendingOrders = filterCollection(scheduledOrders, "monthly");

      if (monthlyOrders.length > 0) {
        setOrders(monthlyOrders);
        setPendingOrders(monthlyPendingOrders);
        setTitle("Month's");
      } else {
        setOrders([]);
        setPendingOrders([]);
      }
    }
    if (filter === "yearly") {
      const yearlyOrders = filterCollection(allOrders, "year");
      const yearlyPendingOrders = filterCollection(scheduledOrders, "year");

      if (yearlyOrders.length > 0) {
        setOrders(yearlyOrders);
        setPendingOrders(yearlyPendingOrders);
        setTitle("Year's");
      } else {
        setOrders([]);
        setPendingOrders([]);
      }
    }
  }, [filter, allOrders]);

  /**
   * Obtain the firstName which we'll use in the hello greeting in the dashboard
   * - we obtain it from profile.admin instead of profile.authUser because profile.authUser.displayName is null
   * for some auth accounts
   */
  const firstName = profile.admin.firstName;

  useEffect(() => {
    const docRef = db.doc(`companies/${profile.company.id}`);
    // fetch the consignment, customer counts summary for this company and
    // listen for any changes in the customer record and reload the UI to
    // reflect the latest changes on the company totals
    const unSubscribe = docRef.onSnapshot((doc) => {
      if (doc.exists) {
        setCustomersNo(doc.data().customersCount);
        setConsignmentsNo(doc.data().consignmentsCount);
        setRevenue(doc.data().weeklyRevenue);

        // get the length of the collection and set that to the customersNo variable and then update the state
        db.collection("customers")
          .where("serviceProviders", "==", profile.company.id)
          .get()
          .then((snap) => {
            customersNo = snap.size;
            setCustomersNo(customersNo);
          });
      }
    });

    db.collection("consignments")
      .where("serviceProviderId", "==", profile.company.id)
      .onSnapshot((snapshot) => {
        let i;
        const ordersArray = [];
        const orders = [];
        for (i = 0; i < snapshot.size; i++) {
          // filter values by only those that arent deleted
          if (snapshot.docs[i].data().deleted === undefined) {
            setTotalOrders(snapshot.size);
            // push order statuses to array
            ordersArray.push(snapshot.docs[i].data().status.value);
            // push order to array
            orders.push(snapshot.docs[i].data());
          }
        }
        setAllOrders(orders);
        //create pending Array from orders that contains "created","accepted", "dispatched", "dispatched-assigned", "dispatched-awaiting-delivery", "dispatched-in-transit"
        const pendingOrders = orders.filter(
          (order) =>
            order.status.value === "created" ||
            order.status.value === "accepted" ||
            order.status.value === "dispatched" ||
            order.status.value === "dispatched-assigned" ||
            order.status.value === "dispatched-awaiting-delivery" ||
            order.status.value === "dispatched-in-transit"
        );

        setScheduledOrders(pendingOrders);

        // custom function to count number of occurences of a value in an array
        const countOccurrences = (arr, val) =>
          arr.reduce((a, v) => (v === val ? a + 1 : a), 0);
        snapshot.docChanges().forEach((changes) => {
          // now we set the different order statuses to their respective groups
          // adopted from https://docs.google.com/spreadsheets/d/1IHn-Iwp0yqG-b1Xp7pnhaqAZNtxlWX3Hr4nMM0N02q0/edit#gid=1411939321,
          // the Twift Model actions excel file
          if (ordersArray.includes("returned")) {
            // set number of returned statuses
            setReturned(countOccurrences(ordersArray, "returned"));
          }
          if (
            ordersArray.includes("declined") ||
            ordersArray.includes("delivered") ||
            ordersArray.includes("delivered-pickup") ||
            ordersArray.includes("delivered-collected")
          ) {
            // create completedArray from OrdersArray that contains declined, delivered, delivered-pickup and delivered-collected
            const completedArray = ordersArray.filter(
              (order) =>
                order === "declined" ||
                order === "delivered" ||
                order === "delivered-pickup" ||
                order === "delivered-collected"
            );
            console.log(
              "completedArray" + completedArray.length,
              completedArray
            );

            // set completed orders count
            const declinedCount = countOccurrences(ordersArray, "declined");
            const deliveredCount = countOccurrences(ordersArray, "delivered");
            const deliveredPickupCount = countOccurrences(
              ordersArray,
              "delivered-pickup"
            );
            const deliveredCollectedCount = countOccurrences(
              ordersArray,
              "delivered-collected"
            );
            const deliveredTotalCount =
              declinedCount +
              deliveredCount +
              deliveredPickupCount +
              deliveredCollectedCount;
            setCompleted(deliveredTotalCount);
          }
          if (
            ordersArray.includes("created") ||
            ordersArray.includes("accepted") ||
            ordersArray.includes("dispatched") ||
            ordersArray.includes("dispatched-assigned") ||
            ordersArray.includes("dispatched-awaiting-delivery") ||
            ordersArray.includes("dispatched-in-transit")
          ) {
            // set pending orders count
            const created = countOccurrences(ordersArray, "created");
            const accepted = countOccurrences(ordersArray, "accepted");
            const dispatched = countOccurrences(ordersArray, "dispatched");
            const dispatched_assigned = countOccurrences(
              ordersArray,
              "dispatched-assigned"
            );
            const dispatched_awaiting_delivery = countOccurrences(
              ordersArray,
              "dispatched-awaiting-delivery"
            );
            const dispatched_in_transit = countOccurrences(
              ordersArray,
              "dispatched-in-transit"
            );
            const pendingCount =
              created +
              accepted +
              dispatched +
              dispatched_assigned +
              dispatched_awaiting_delivery +
              dispatched_in_transit;
            setPending(pendingCount);
          }
        });
      });

    return unSubscribe; // same as unSubscribe()?
  }, [profile.company.id, profile.admin.userId]);

  return (
    <Wrapper>
      <IntroWrapper>
        <IntroTextWrapper>
          <Typography variant="body2" color="textSecondary" component="p">
            {todayDate}
          </Typography>
          <IntroText variant="h6" color="textPrimary">
            Hello, {firstName ? firstName : "Admin"}
          </IntroText>
        </IntroTextWrapper>

        <FilterWrapper>
          <FormControl>
            <FilterButton id="filterDash">Filter</FilterButton>
            <Select
              labelId="filterDash"
              id="filter"
              value={filter}
              onChange={handleChange}
            >
              {/*    mentuItems to filter daily, weekly, annualy  */}
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"daily"}>Daily</MenuItem>
              <MenuItem value={"weekly"}>Weekly</MenuItem>
              <MenuItem value={"monthly"}>Monthly</MenuItem>
              <MenuItem value={"yearly"}>yearly</MenuItem>
            </Select>
          </FormControl>
        </FilterWrapper>
      </IntroWrapper>

      <DashboardCards>
        <TotalConsignments
          title={title}
          consignmentsNo={orders.length > 0 ? orders.length : 0}
        />
        <NumberOfCustomers
          customersNo={
            customersNo > 0 || customersNo !== undefined ? customersNo : 0
          }
        />
        <RevenueStatus />
        <AgentsCard />
      </DashboardCards>

      <GridWrapper>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <LineGraph revenue={revenue} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <DoughnutChart
              totalOrders={totalOrders}
              completedCount={completed}
              returnedCount={returned}
              pendingCount={pending}
            />
          </Grid>
          <Grid item xs={12} lg={8}>
            <PendingOrders
              orders={
                pendingOrders.length > 0 ? pendingOrders : scheduledOrders
              }
              title={title}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Messages />
          </Grid>
        </Grid>
      </GridWrapper>
    </Wrapper>
  );
};

export default Dashboard;
