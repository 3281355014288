
import  { firebaseApp } from "./firebaseConfig";

// set config options for the RAF data provider:
// https://github.com/benwinding/react-admin-firebase#options
const rafOptions = {
    // add 'deleted' meta field for non-destructive deleting functionality
    // NOTE: hides 'deleted' records from list views unless overridden by filtering for {deleted: true}
    softDelete: true,
    // Enable logging of react-admin-firebase
    logging: true,
    //previously initalized  firebase app instance
    app: firebaseApp,
    //allow local persistence
    persistence: "local",
    // allow a document to set the ID field as a property
    dontAddIdFieldToDoc: false,
    // Casing for meta fields like 'createdby' and 'updatedby', defaults to 'lower', options are 'lower' | 'camel' | 'snake' | 'pascal' | 'kebab'
    metaFieldCasing: 'camel',
    // Have custom metadata field names instead of: 'createdate', 'lastupdate', 'createdby', 'updatedby'
    renameMetaFields: {
        created_at: 'createdAt', // default: 'createdate'
        created_by: 'createdBy', // default: 'createdby'
        updated_at: 'updatedAt', // default: 'lastupdate'
        updated_by: 'updatedBy', // default: 'updatedby'
    },
};

export { rafOptions };
