import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import twiftImage from "../../assets/twift.png";

const AuthSidebar = () => {
  return (
    <Wrapper>
      <Typography component="p">
        A few clicks away from being able to better manage your operations.
      </Typography>
      <img src={twiftImage} alt="logo" width="100%" />
    </Wrapper>
  );
};

export default AuthSidebar;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 3rem;
  width: 30%;
  background-color: #00acc1;
  p {
    color: #fff;
    font-size: 1.5rem;
    font-weight: 600;
    font-family: "Ubuntu", sans-serif;
    margin: 3rem 0;
    text-align: center;
  }
  @media (max-width: 1024px) {
    padding: 0;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    img {
      width: 80%;
      margin: auto auto;
    }
    p {
      display: none;
    }
  }
`;
