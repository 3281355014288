import React from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { updateCustomer } from "../../utils/createCustomer";

const CustomerExistsModal = ({
	isOpen,
	isClosed,
	duplicateCustomerCreation,
	updateExistingCustomerData,
	customerData,
	values,
	customerId,
}) => {
	const [open, setOpen] = React.useState(isOpen);
	const handleClose = () => {
		setOpen(isClosed);
	};

	const handleUpdatedCustomer = async () => {
		const onSuccess = () => {
			console.log(`customer updated succesfully`);
		};

		const onFailure = () => {
			console.log(`customer failed to update `);
		};
		//function to update customer data
		await updateCustomer("customers", customerId, values, onSuccess, onFailure);

		duplicateCustomerCreation("updateExisting");
	};

	const handleExistingCustomer = () => {
		duplicateCustomerCreation("useExisting");
		const { id, name, address, phoneNumber } = customerData;
		updateExistingCustomerData({ id, name, address, phoneNumber });
	};

	return (
		<div>
			<Dialog open={open} onClose={handleClose} onBackdropClick="false">
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						A customer <b>{customerData.name}</b> already exists with the number{" "}
						<b>{customerData.phoneNumber}</b>
						<br />
						You can use the existing record or update it with the new details.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleUpdatedCustomer}
						color="primary"
						variant="outlined"
					>
						Update Customer
					</Button>
					<Button
						onClick={handleExistingCustomer}
						color="primary"
						autoFocus
						variant="contained"
					>
						Use Existing
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
CustomerExistsModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	isClosed: PropTypes.bool.isRequired,
	duplicateCustomerCreation: PropTypes.string.isRequired,
	customerData: PropTypes.object.isRequired,
	values: PropTypes.object.isRequired,
	updateExistingCustomerData: PropTypes.func.isRequired,
	customerId: PropTypes.string.isRequired,
};
export default CustomerExistsModal;
