import "react-app-polyfill/stable";
import "core-js";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import App from "./routes/App";
import { debugContextDevtool } from "react-context-devtool";
import * as serviceWorker from "./serviceWorker";

const container = document.getElementById("root");

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  container
);

/**
 * Attach root container in debugContextDevtool method
 * @see - https://github.com/twende-app/twende-web-react/pulls
 */
debugContextDevtool(container, {
  disable: process.env.NODE_ENV === "production",
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
