import React,{ useEffect, useState } from "react";
import { ShowButton } from "react-admin";
import getResourceLink from "../../routes/resource-names";
import { db } from "../../config/firebaseConfig";


const AgentDetails = (props) => {
  const { serviceProviderId, agentId } = props
  const resourceName = getResourceLink(serviceProviderId);
  const [agent, setAgent] = useState({})
  const agentsRef = db.collection(`companies/${serviceProviderId}/agents`).doc(`${agentId}`)

  async function fetchData() {
    const data = await agentsRef.get().then((doc) => {
      if (doc.exists) {
        const agentDoc = doc.data()
        return agentDoc
      } else {
        console.log("No agent found!");
        return
      }
    }).catch((error) => {
      console.log("Error getting document:", error);
      return error
    });
    return data
  }

  useEffect(() => {
    const getData = () => {
      fetchData().then((agentData) => {
        setAgent(agentData);
      });
    }
    getData()
  }, []);
  
  return <>
  {agent ? 
    <>
      {` ${agent.firstName} ${agent.lastName} `}
      {agent?.deleted === true ? null :
      <ShowButton basePath={"/"+resourceName.agents} label="Details" record={agent} />
      }
    </>
   : <>
      <span>N/A</ span>
    </>}
    </>
};

export default AgentDetails;
