import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import useAuthUser from "../useAuthUser";
import {
  WorkspaceSwitcherAvatar,
  WorkspaceSwitcherContentWrapper,
  WorkspaceSwitcherName,
  WorkspaceSwitcherWrapper,
} from "./styles";
import WorkspaceSwitcherContent from "./WorkspaceSwitcherContent";
import { navigate } from "@reach/router";
import { getUser } from "../authHelpers";

const WorkspaceSwitcher = ({ workspaces }) => {
  const [userId, setUserId] = useState("");
  const authUser = useAuthUser();
  const photoURL = authUser && authUser.photoURL;

  useEffect(() => {
    const fetchTwiftUser = async () => {
      if (authUser) {
        const twiftUser = await getUser(authUser.email);
        setUserId(twiftUser.id);
      }
    };

    fetchTwiftUser();
  }, [authUser]);

  const handleCreateWorkspace = () =>
    navigate("/company-picker", { state: { userId } });
  return (
    <WorkspaceSwitcherWrapper>
      {workspaces.length
        ? workspaces.map((workspace) => (
            <WorkspaceSwitcherContent
              workspace={workspace}
              photoURL={photoURL}
            />
          ))
        : null}
      {/* <WorkspaceSwitcherContentWrapper>
        <WorkspaceSwitcherAvatar
          createWorkspace={true}
          onClick={handleCreateWorkspace}
        >
          <AddCircleOutlineIcon />
        </WorkspaceSwitcherAvatar>
        <WorkspaceSwitcherName>CREATE</WorkspaceSwitcherName>
      </WorkspaceSwitcherContentWrapper> */}
    </WorkspaceSwitcherWrapper>
  );
};

WorkspaceSwitcher.propTypes = {
  workspaces: PropTypes.array.isRequired,
};

export default WorkspaceSwitcher;
