import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import Typography from "@material-ui/core/Typography";
import { blue } from "@material-ui/core/colors";
import { db } from "../../../config/firebaseConfig";
import Chip from '@material-ui/core/Chip';

let serviceProviders = [];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;
  const [serviceProvider, setServiceProviders] = useState([]);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  //fetches for a list of all available SPs
  const fetchServiceProviders = async () => {
    const providersList = (await db.collection("companies").get()).docs;

    providersList.forEach((provider) => {
      const data = {
        id: provider.id,
        ...provider.data(),
      };
      return !data.deleted || data.deleted != true
        ? (serviceProviders = [...serviceProviders, data])
        : null;
    });

    setServiceProviders(serviceProviders);
  };

  useEffect(() => {
    fetchServiceProviders();
  }, []);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Select a service provider
      </DialogTitle>
      <List>
        {serviceProviders.map((serviceProviders) => (
          <ListItem
            button
            onClick={() => handleListItemClick(serviceProviders.name)}
            key={serviceProviders.id}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={serviceProviders.name} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogDemo() {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };
  
  const handleDelete = () => {
    console.info('You clicked the delete icon.');
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Select a service provider
      </Button>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
      <br /><br />
     {!selectedValue.length  ? (
      <h5>no default service provider selected</h5>
      ):(
     <Chip
        variant="outlined"
        avatar={<Avatar alt={selectedValue} src={selectedValue} />}
        label={selectedValue}
        onDelete={handleDelete}
      />
      )}
    </div>
  );
}
