import React, { useState, useEffect } from "react";
import { auth } from "../../../config/firebaseConfig";
import CreateBusiness from "./components/CreateBusiness";
import CreateServiceProvider from "./components/CreateServiceProvider";
import PersonalInfo from "./components/PersonalInfo";
import Register from "./components/Register";
import SelectAccountType from "./components/SelectAccountType";
import styled from "styled-components";
import {
  Typography,
  InputAdornment,
  IconButton,
  Grid,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import { StyledTextField, AuthLink, useStyles } from "../AuthStyles";
import AuthSidebar from "../../../components/Auth/Sidebar";
import AuthContentWrapper from "../../../components/Auth/ContentWrapper";
import AuthPageWrapper from "../../../components/Auth/PageWrapper";
import { EmailAndPswdBtnWrapper } from "../../../components/Auth/Buttons";
import { navigate } from "@reach/router";
import TwSnackBar from "../../../layout/TwSnackBar";

const SignUp = () => {
  const classes = useStyles();
  const [state, setState] = useState({
    authUser: {},
    accountType: "",
    userId: "",
    showAuthProviders: true,
    showPersonalInfo: false,
    showSelectAccountType: false,
    firstName: "",
    lastName: "",
    displayName: "",
    email: "",
    phoneNumber: "",
    password: "",
    formErrors: {
      email: null,
      password: null,
    },
    showPassword: false,
    showSubsequentSteps: false,
    completeSignup: false,
    googleAuthUserPreview: {},
  });
  const [notification, setNotification] = useState({
    severity: "error",
    msg: null,
  });
  const [showSnackBar, setShowSnackBar] = useState({
    isOpen: false,
    message: "",
    info: ""
  })

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    password,
    formErrors,
    showPassword,
    googleAuthUserPreview,
    showAuthProviders,
    showPersonalInfo,
    authUser,
    showSelectAccountType,
    accountType,
    userId,
  } = state;

  const getAuthUserFromChild = () => {
    navigate("/about-you");
  };

  const toggleShowPersonalInfo = ({
    userId,
    showPersonalInfo,
    showSelectAcType,
  }) =>
    setState((state) => ({
      ...state,
      userId: userId,
      showPersonalInfo: showPersonalInfo,
      showSelectAccountType: showSelectAcType,
      //if showSelectAcType is false, then the current user is trying to complete a business invite
      accountType: showSelectAcType ? accountType : "business",
    }));

  const toggleShowSelectAccountType = ({ acType, showSelectAcType }) =>
    setState((state) => ({
      ...state,
      accountType: acType,
      showSelectAccountType: showSelectAcType,
    }));

  useEffect(() => {
    if (localStorage.getItem("authUserInfoFromSignIn")) {
      console.log(localStorage.getItem("authUserInfoFromSignIn"));
      /**
       * redirect user back to sign-in if auth.currentUser is null.
       * This can happen, when the auth session expires.
       */
      if (!auth.currentUser) {
        console.log("Redirect to signin");
        window.location.assign("/");
      }
      setState((state) => ({
        ...state,
        authUser: auth.currentUser,
        showAuthProviders: false,
        showPersonalInfo: true,
      }));
    }
    /**
     * retrieve state sent from the sign-in page if a user
     * attempted to sign-in with an accout that doesn't a company
     */
    if (
      localStorage.getItem("completeSignUp") &&
      localStorage.getItem("googleAuthUserPreview")
    ) {
      setState((state) => ({
        ...state,
        completeSignup: JSON.parse(localStorage.getItem("completeSignUp")),
        googleAuthUserPreview: JSON.parse(
          localStorage.getItem("googleAuthUserPreview")
        ),
      }));
    } else if (localStorage.getItem("completeSignInWithEmail")) {
      setNotification({
        severity: "info",
        msg: "We couldn't find an account with the details you provided, Sign Up instead?",
      });

      // retrieve cached object
      const completeSignInWithEmailObj = JSON.parse(
        localStorage.getItem("completeSignInWithEmail")
      );
      const { email, password } = completeSignInWithEmailObj;

      // set state.email and state.password which automatically prefills the form
      setState((state) => ({
        ...state,
        email: email,
        password: password,
      }));
    }
  }, []);

  useEffect(() => {
    const completeCompanySignup = JSON.parse(
      localStorage.getItem("completeCompanySignup")
    );
    if (completeCompanySignup) {
      // complete company signup from the otp page
      // show the company type chooser screen
      setState((state) => ({
        ...state,
        showAuthProviders: false,
        showSelectAccountType: true,
        authUser: auth.currentUser,
        userId: completeCompanySignup.userId,
      }));
    }
  }, []);

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    // dynamically update form inputs
    setState((state) => ({
      ...state,
      [name]: value,
      displayName: `${firstName} ${lastName}`,
    }));
  };

  // const handlePhoneNumberChange = (value) =>
  //   setState((state) => ({
  //     ...state,
  //     phoneNumber: value,
  //   }));

  const handleEmailAndPasswordSignUp = async (e) => {
    e.preventDefault();

    // close any notification that was being shown so that it's not persisted through out the different signup screens
    handleCloseNotification(e);

    const formFieldIsRequiredText = "* This field is required";
    if (!email && !password) {
      setState((state) => ({
        ...state,
        email: formFieldIsRequiredText,
        password: formFieldIsRequiredText,
      }));
    } else if (!email) {
      setState((state) => ({
        ...state,
        email: formFieldIsRequiredText,
      }));
    } else if (!password) {
      setState((state) => ({
        ...state,
        password: formFieldIsRequiredText,
      }));
    } else {
      try {
        const authResult = await auth.createUserWithEmailAndPassword(
          email,
          password
        );
        console.log(
          "***** success: user created with e+p ****",
          authResult.user
        );
        const createdUser = authResult.user;

        // update the created user with a display name matching the first + last name
        createdUser
          .updateProfile({
            displayName: `${firstName} ${lastName}`,
          })
          .then(() => {
            console.log(
              "*** success: the created user's displayName has been updated to ****",
              `${firstName} ${lastName}`
            );
          })
          .catch((err) =>
            console.error(
              "**** an error occurred while updating the created user's displayName ****",
              err
            )
          );

        // add phone number to localStorage -> will be used in companySignup
        localStorage.setItem("adminPhoneNumber", phoneNumber);
        localStorage.setItem(
          "adminDisplayName",
          googleAuthUserPreview.displayName
        );

        getAuthUserFromChild(createdUser);
      } catch (err) {
        // display the error to the user
        console.log(
          "***** an error occurred while creating user with emai + psdw ******",
          err
        );
        const { code, message } = err;
        if (code === "auth/email-already-in-use") {
          /**
           * handle 'email-already-in-use'
           * @todo - in the future, we should present the user with the twift account that we've fetched,
           * and inform them that they already have an account with the given details and ask them whether they'd like to continue
           * with that or create another one
           */
          localStorage.setItem(
            "signInUserFromSignUp",
            JSON.stringify({
              message,
              email,
              password,
            })
          );
          // redirect to '/'
          navigate("/");
        } else {
          // show snackbar with a generic error
          setShowSnackBar({
            isOpen: true,
            message: "Something went wrong during signup, please contact us if this persists",
            info: message
          })
        }
      }
    }
  };

  const handleClickShowPassword = () =>
    setState((state) => ({
      ...state,
      showPassword: !showPassword,
    }));

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const toggleAuthSteps = (e) => {
  //   e.preventDefault();
  //   setState((state) => ({
  //     ...state,
  //     showSubsequentSteps: !showSubsequentSteps,
  //   }));
  // };

  const handleCloseNotification = (e) => {
    e.preventDefault();

    // reset the notification object
    setNotification({
      severity: "error",
      msg: null,
    });

    // clear any info that had been set in localStorage from sign-in
    localStorage.removeItem("completeSignUp");
    localStorage.removeItem("googleAuthUserPreview");
    localStorage.removeItem("completeSignInWithEmail");
  };

  const closeSnackBar = () => setShowSnackBar({
    isOpen: false,
    message: "",
    info: ""
  })

  const getAlertInfoFromChild = (payload) => setShowSnackBar({
    isOpen: payload.isOpen,
    message: payload.message,
    info: payload.info
  })

  const getGoogleAuthErr = (payload) => setNotification({
    severity: payload.severity,
    msg: payload.msg
  })

  return (
    <AuthPageWrapper>
      <AuthSidebar />
      <AuthContentWrapper>
        {notification.msg !== null && (
          <Alert
            onClose={handleCloseNotification}
            severity={notification.severity}
          >
            {notification.msg}
          </Alert>
        )}
        {showSnackBar.isOpen && <TwSnackBar isOpen={showSnackBar.isOpen} handleClose={closeSnackBar} message={showSnackBar.message} errContext={showSnackBar.info} />}
        {showAuthProviders && (
          <div>
            <Typography component="h1">REGISTER</Typography>
            <Typography component="p" id="setup">
              Let’s get you all set up. Register your company by filling in the
              Details below or set up your account using one of the Two methods.
            </Typography>

            <Register getAuthUser={getAuthUserFromChild} setNotification={getGoogleAuthErr} sendAlertInfoToParent={getAlertInfoFromChild} />
            <Row>
              <RegisterTextFields
                variant="outlined"
                margin="normal"
                label="Email address"
                name="email"
                value={email}
                placeholder="E.g: abcd@example.com"
                id="email"
                onChange={(event) => onChangeHandler(event)}
                autoComplete="email"
                required
                fullWidth
                error={formErrors.email}
                helperText={formErrors?.email}
              />
              <RegisterTextFields
                variant="outlined"
                margin="normal"
                label="Password"
                name="password"
                value={password}
                id="password"
                onChange={(event) => onChangeHandler(event)}
                required
                fullWidth
                error={formErrors.password}
                helperText={formErrors?.password}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Row>
            <hr />
            <EmailAndPswdBtnWrapper
              fullWidth
              variant="contained"
              className={classes.submit}
              onClick={handleEmailAndPasswordSignUp}
            >
              GET STARTED
            </EmailAndPswdBtnWrapper>
            <Grid
              className={classes.helperText}
              container
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Grid item style={{ display: "flex", flexDirection: "column" }}>
                <AuthLink to="/">
                  <span style={{ color: "#707070" }}>
                    Already have an account?
                  </span>
                  <span className={classes.link}>Sign in here</span>
                </AuthLink>
              </Grid>
            </Grid>
          </div>
        )}
        {showPersonalInfo && (
          <PersonalInfo
            user={authUser}
            toggleShowPersonalInfo={toggleShowPersonalInfo}
            sendAlertInfoToParent={getAlertInfoFromChild}
          />
        )}
        {showSelectAccountType && (
          <SelectAccountType
            toggleShowSelectAccountType={toggleShowSelectAccountType}
          />
        )}
        {accountType === "serviceProvider" && (
          <CreateServiceProvider
            authUser={authUser}
            userId={userId}
            accountType={accountType}
          />
        )}
        {accountType === "business" && (
          <CreateBusiness
            userId={userId}
            accountType={accountType}
            authUser={authUser}
          />
        )}
      </AuthContentWrapper>
    </AuthPageWrapper>
  );
};;

export default SignUp;

const Row = styled.div`
  margin: 2rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span {
    color: #707070;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

const RegisterTextFields = styled(StyledTextField)`
  width: 80%;
  margin: 1rem;
`;
