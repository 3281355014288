import * as React from "react";
import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  yellow: {
    color: "#C17600",
    backgroundColor: "#fff7df",
    borderColor: "#C17600",
  },
  blue: {
    color: "#00acc1",
    backgroundColor: "#e5fdff",
    borderColor: "#00acc1",
  },
  red: {
    color: "#e55425",
    backgroundColor: "#fcede8",
    borderColor: "#e55425",
  },
});

const AgentVehicleType = ({ vehicleType }) => {
  const classes = useStyles();
  //loops through the vehicle category in search of the selected  agents vehicleType
  switch (vehicleType) {
    case "Pick-up":
    case "Trailer":
    case "Lorry":
    case "Mini-Lorry":
      return (
        <Chip label={vehicleType} variant="outlined" className={classes.yellow} />
      );
    case "Motorbike":
    case "Car":
    case "Van":
      return (
        <Chip
          label={vehicleType}
          variant="outlined"
          className={classes.blue}
        />
      );
    default:
      return (
        <Chip variant="outlined" className={classes.red} label={vehicleType} />
      );
  }
};

export default AgentVehicleType;
