import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

const ProfileContext = createContext();

const ProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState({});
  // persists the firebase auth user object to globally avail it to other components
  const [authUser, setAuthUser] = useState({});

  // actions
  const createProfile = (payload) => setProfile(payload);

  const updateProfile = (payload) => setProfile(payload);

  const updateAuthUser = (authUser) => setAuthUser(authUser);

  const clearProfile = () =>
    setProfile({
      profile: {
        authUser: {},
        admin: {},
        company: {},
        team: {},
      },
    });

  useEffect(() => {
    if (localStorage.getItem("cachedProfile")) {
      setProfile(JSON.parse(localStorage.getItem("cachedProfile")));
    }
  }, []);

  const contextVal = { authUser, profile, createProfile, updateProfile, clearProfile, updateAuthUser };

  return (
    <ProfileContext.Provider value={contextVal} displayName="profileContext">
      {children}
    </ProfileContext.Provider>
  );
};

ProfileProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ProfileContext, ProfileProvider };
