import moment from "moment";

// filter through object and for each item in object, check if its date matches the filter

export const filterCollection = (data, interval) => {
	switch (interval) {
		case "today":
			let results = data.filter((item) => {
				let convertedDate = item.date.seconds * 1000;
				// use moment to check if the item.date is the same as the current date
				console.log(moment(convertedDate).isSame(moment(), "day"));

				return moment(convertedDate).isSame(moment(), "day");
			});

			return results;
		case "month":
			let monthResults = data.filter((item) => {
				let convertedDate = item.date.seconds * 1000;
				return moment(convertedDate).isSame(moment(), "month");
			});

			return monthResults;
		case "year":
			let annualResults = data.filter((item) => {
				let convertedDate = item.date.seconds * 1000;
				return moment(convertedDate).isSame(moment(), "year");
			});

			return annualResults;
		case "week":
			let thisWeeks = data.filter((item) => {
				let convertedDate = item.date.seconds * 1000;
				// let itemDate = item.date.toDate();
				// console.log("convertedDate", moment(convertedDate).format("LLLL"));
				// console.log("itemDate", itemDate);
				return moment(convertedDate).isSame(moment(), "week");
			});

			return thisWeeks;

		default:
			return data;
	}
};
