import React, { useEffect, useState} from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import {
  useShowController,
  DateField,
  TextField,
  NumberField,
} from "react-admin";
import Transaction from "./PaymentTransaction";
import { db } from "../../config/firebaseConfig";

const PaymentShow = (props) => {
  const { record } = useShowController(props);
  const classes = useStyles();

  const [state, setState] = useState({
    transactionId: "",
    transactionDesc: "",
    transactions: [],
  });

  const { transactions } = state;

  useEffect(() => {
    const getPaymentTransaction = async () => {
      const transRef = db.collection(
        `payments/${props.id}/transactions`
      );
      try {
        const transactions = await transRef.get();
        setState((state) => ({
          ...state,
          transactions: transactions.docs,
        }));
        transactions.forEach((trans) => {
          setState((state) => ({
            ...state,
            transactionId: trans.id,
          }));
        });
      } catch (error) {
        console.error(
          "********** error fetching payment transaction ********",
          error
        );
      }
    };

    getPaymentTransaction();
  }, [props.id]);

  if (!record) return null;
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              Payment Information
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography gutterBottom align="right">
              Id: <TextField className={classes.detail} source="id" />
            </Typography>
            <Typography gutterBottom align="right">
            Date: <DateField className={classes.detail} label="Date" source="createdAt" showTime />
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} container alignContent="flex-end">
            <Typography>
              Account Name: <TextField className={classes.detail} source="accountName" />
              <br />
              Account Number: <TextField className={classes.detail} source="accountNumber" />
              <br />
              Type: <TextField className={classes.detail} source="type" />
              <br />
              Mode: <TextField className={classes.detail} source="mode" />
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.spacer}>&nbsp;</div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography gutterBottom align="center">
              Amount{" "}
            </Typography>
            <Typography  className={classes.detail} gutterBottom align="center">
              <NumberField  className={classes.detail} source="amount" />
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography gutterBottom align="center">
              Status
            </Typography>
            <Typography className={classes.detail} gutterBottom align="center">
              {record.status}
            </Typography>
          </Grid>
        </Grid>
        <div>
          <Grid
            container
            spacing={1}
            item
            xs={12}
          >
            <h3>Transactions</h3>
            <Transaction transactions={transactions} />
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
};

export default PaymentShow;

const useStyles = makeStyles(() => ({
  root: {
    width: 600,
    margin: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#e0e0e3",
    boxShadow: "none"
  },
  spacer: { height: 20 },
  transactions: { margin: "10px 0" },
  detail: {
    fontWeight: "500",
    fontSize: "1rem"
  }
}));
