import React, { useContext } from "react";
import { ProfileContext } from "../context/ProfileContext";
import { db } from "../config/firebaseConfig";
import { Toolbar, SaveButton } from "react-admin";

// the saveButton is held in a toolbar
const SaveAddressOnConsignmentSaveToolbar = (props) => {
  /*
    A save button component that creates and saves address docs whenever a consignment is saved
*/
  const { profile } = useContext(ProfileContext);
  const companyId = profile.company.id;

  async function saveAddress(consignmentData) {
    /* 
        A function that creates origin and destination address docs in db from consignments input
        Returns the created docs ids as an array
    */
    const createAddress = async (address) => {
      // a function to create an address doc given an object
      try {
        let queryDocSnap = await db.collection("addresses").add(address);
        return queryDocSnap.id;
      } catch (error) {
        console.log("Address creation Error", error);
        alert("Address was not successfully created. Please try again");
      }
    };

    // define origin from submitted data
    let origin = {
      name: consignmentData.sender?.address || "",
      country: "Kenya",
      city: consignmentData.sender?.city || "",
      nearestLandmark: consignmentData.sender?.nearestLandmark || "",
      type: "home",
      companyId: companyId,
    };

    // define destination from submitted data
    let destination = {
      name: consignmentData.recipient?.address || "",
      country: "Kenya",
      city: consignmentData.recipient?.city || "",
      nearestLandmark: consignmentData.recipient?.nearestLandmark || "",
      type: "home",
      companyId: companyId,
    };

    // create origin and destination docs saving their ids
    const originId = await createAddress(origin);
    const destinationId = await createAddress(destination);

    return [originId, destinationId];
  }

  async function transformConsignment(consignmentData) {
    /* 
        A function that creates origin/destination fields as maps and also includes companyId, to consignments data before saving
        Returns the transformed/updated consignments data object
    */

    const [originId, destinationId] = await saveAddress(consignmentData);

    // transform the data by creating origin and destination map fields which store address details
    if (originId && destinationId) {
      // create a map of origin with id & name
      consignmentData.origin = {
        id: originId,
        name: consignmentData.sender?.address,
      };

      // create a map of destination with id & name
      consignmentData.destination = {
        id: destinationId,
        name: consignmentData.recipient?.address,
      };

      // add companyId to consignments
      consignmentData.companyId = companyId;

      return consignmentData;
    }
  }

  return companyId ? (
    <Toolbar {...props}>
      <SaveButton
        transform={(data) => transformConsignment(data)}
        submitOnEnter={true}
      />
    </Toolbar>
  ) : null;
};
export default SaveAddressOnConsignmentSaveToolbar;
