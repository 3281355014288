import LogRocket from "logrocket";
import formatPermissions from "../../components/formatPermissions";
import { db, functions } from "../../config/firebaseConfig";

const adminsRef = db.collectionGroup("admins");
const agentsRef = db.collectionGroup("agents");

/**
 * verifies a firebase auth user object is a valid one
 * @param {firebase.User} authUser an object representing a firebase user account
 * @returns true if the user object is a valid account
 */
const validateFirebaseUser = (authUser) => {
  if (!authUser.uid) {
    alert("***** the provider auth user is not a valid firebase auth user");
    throw Error("*** the given authUser is not a valid firebase auth user ****");
  }

  return true;
};

const checkIsExistingUser = async (email) => {
  try {
    const users = await db
      .collection("users")
      .where("emailAddress", "==", email)
      .get();
    /**
     * - we want to return true if we have just one user record matching the given email
     * - and false if:
     *  - there's no user matching the given email
     *  - there's more than one user record matching the given email
     *    - it's important that we do this so that we know to notify the user
     *    that there was a problem with their account
     */
    return users.size === 1;
  } catch (err) {
    console.error("**** err: fetching User by email *****", err);
  }
};

const getUser = async (email) => {
  let res;
  try {
    const users = await db
      .collection("users")
      .where("emailAddress", "==", email)
      .get();

    users.forEach((user) => {
      res = {
        id: user.id,
        ...user.data(),
      };
    });
  } catch (err) {
    //
    console.error("**** err: fetching User by email *****", err);
  }

  return res;
};

/**
 * Calls a callable (cloud function) to trigger custom claims update on the auth user.
 * This will make sure that the admin user's firestore record is synchronized
 * with its corresponding firebase auth user object and thus avail the most current tokens
 * for the user to have the access permissions required for firestore security rules to evaluate properly
 * @param {Map} data - a series of key-value pairs containing any parameters that the callable function may need
 * to customize its behavior, e.g. companyDocId, emailAddress, etc. (see the definition of the callable in the backend module)
 */
export const syncCustomClaims = async (authUser, data) => {
  const updateAdminClaims = functions.httpsCallable("customClaims-setAuthCustomClaims");

  try {
    // do the actual update
    const result = await updateAdminClaims(data);
    // force-refresh token getting to ensure we have the latest changes take effect
    await refreshFirebaseUser(authUser);
    return result;
  } catch (err) {
    console.error("****** an error occurred while updating admin claims *******", err);
  }
}

/**
 * force refresh tokens to retrieve the latest custom claims. this makes sure
 * that subsequent steps (e.g. fetching/setting an admin's team) to have the right
 * permissions, otherwise we end up with errors like "...FirebaseError: false for 'get' @ L229..."
 * @param {firebase.User} authUser a firebase user account
 * @returns the same auth user, in case callers want to reference it
 */
const refreshFirebaseUser = async (authUser) => {

  await authUser
  .getIdToken(true)
  .then((token) => console.log(`fetched token ${token} for user ${authUser.uid}`))
  .catch((err) =>
    console.error(
      "*** an error occurred while getting user idToken *****",
      err
    )
  );

  return authUser;
};

const syncAgentData = async (email) => {
  const user = await getUser(email);
  let syncedAgentData;

  try {
    const agentsSnap = await agentsRef.where("userId", "==", user.id).get();

    // if there exist at least one agent
    if (!agentsSnap.empty) {
      syncedAgentData = {};
      // if there exists more than one agent
      if (agentsSnap.size > 1) {
        // handle multiple agents
        syncedAgentData.hasMultipleAgents = true;
        syncedAgentData.agents = [];
        agentsSnap.forEach((agentDoc) => {
          syncedAgentData.agents.push({
            ...agentDoc.data(),
            id: agentDoc.id,
          });
        });
      } else {
        // there's only one agent
        agentsSnap.forEach((agentDoc) => {
          syncedAgentData = {
            ...agentDoc.data(),
            id: agentDoc.id,
          };
        });
      }
    }
  } catch (err) {
    console.error("@@@@@@ err: fetching agents @@@@@@@@@@", err);
  }

  return syncedAgentData;
};

const syncAdminData = async (authUser) => {
  /**
   * get admins where emailAdress === authUser.email
   *  - check if the admin is registered with more than one company. If so, and assumming we allow that, do what? - ask them to choose which one they'd like to be associated with? If we don't allow that, show a warning alert? - then what?
   *
   * what if we can't find an admin with the given email? shouldn't happen on sign-in? when can this happen.
   *
   */
  const user = await getUser(authUser.email);
  let syncedAdmin;
  const admins = await adminsRef.where("userId", "==", user.id).get();
  if (!admins.empty) {
    syncedAdmin = {};
    if (admins.size > 1) {
      /**
       * admin is registered with more than one company
       * ask user to choose the company that they'd like to continue with
       */
      syncedAdmin.hasMultipleAdmins = true;
      syncedAdmin.admins = [];
      admins.forEach((adminDoc) => {
        syncedAdmin.admins.push({
          id: adminDoc.id,
          ...adminDoc.data(),
        });
      });
    } else {
      // admin is registered with just one company
      admins.forEach(async (admin) => {
        // get admin data
        syncedAdmin = {
          ...admin.data(),
          id: admin.id,
        };
      });

      //block on the result of the custom claims setting to avoid any permissions errors
      // on subsequent steps from occuring, e.g. "...an error occurred while fetching admin's team..."
      await syncCustomClaims(authUser, { companyDocId: syncedAdmin.companyId });
    }
  }

  return syncedAdmin;
};

export const syncCompanyData = async (companyId, companyType) => {
  // fetch company
  let collection;
  companyType === "serviceProvider"
    ? (collection = "companies")
    : (collection = "businesses");
  try {
    const company = await db.collection(`${collection}`).doc(companyId).get();
    return {
      ...company.data(),
      id: company.id,
    };
  } catch (err) {
    console.error(
      "***** an error occurred while fetching admin company ******",
      err
    );
  }
};

const getFormattedPermissions = (rawPermissions, companyId) =>
  formatPermissions(rawPermissions, companyId);

export const syncTeamData = async (companyId, teamId, resourceName) => {
  try {
    const team = await db
      .doc(`${resourceName}/${companyId}/teams/${teamId}`)
      .get();
    return {
      id: team.id,
      name: team.data().name,
      permissions: getFormattedPermissions(team.data().permissions, companyId),
    };
  } catch (err) {
    console.error(
      "******* auth error: an error occurred while fetching admin's team ******",
      err
    );
  }
};

const syncGlobalProfile = async (authUser, updateProfileContext) => {
  let syncedProfile = {};

  // verify if authUser is a valid firebase auth user
  validateFirebaseUser(authUser);

  const isExistingUser = await checkIsExistingUser(authUser.email);
  if (isExistingUser) {
    syncedProfile.isTeamCreated = false;
    // update syncedProfile.authUser
    syncedProfile.authUser = authUser;

    // update syncedProfile.agent
    const agent = await syncAgentData(authUser.email);
    syncedProfile.agent = agent;

    // update syncedProfile.admin
    const admin = await syncAdminData(authUser);

    /**
     * the following will be run only if:
     *  1. admin is a truthy value, i.e, it's not null or undefined
     *  2. admin.hasMultipleAdmins === false, i.e, we don't have multiple admin accounts
     *    - if admin.hasMultipleAdmins === true, the setting of the company and team data
     *      will be done in <MultipleAccountsChooser /> after a user selects from one of the
     *      available accounts
     */
    if (admin && !admin.hasMultipleAdmins) {
      /**
       * 1. set syncedProfile.company
       * 1. set syncedProfile.admin
       * 1. set syncedProfile.team
       */

      // this is a sign-in thus we know that a team has already been created
      syncedProfile.isTeamCreated = true;

      // fetch the company that the signed-in user belongs to
      const company = await syncCompanyData(admin.companyId, admin.companyType);

      // determine the company collection from which to fetch the admin user's team data
      let teamResourceName;
      switch (admin.companyType) {
        case "serviceProvider":
          teamResourceName = "companies";
          break;
        case "business":
          teamResourceName = "businesses";
          break;
        default:
          // no valid company type, raise error
          throw Error(`Invalid admin company type: ${admin.companyType}. Sorry, it must be an issue on our side, please let us know so we can fix it`);
      }

      const adminTeam = await syncTeamData(company.id, admin.team.id, teamResourceName);

      // update syncedProfile.company && syncedProfile.team
      syncedProfile = {
        ...syncedProfile,
        admin,
        team: adminTeam,
        company,
      };


      //update profile context
      updateProfileContext(syncedProfile);

      // add the profile object as it'll be used to replenish the profile context across page refreshes
      localStorage.setItem(
        "cachedProfile",
        JSON.stringify(syncedProfile)
      );
    } else {
      // admin.hasMultipleAdmins === true thus we're not waiting for the team to be created
      // update syncedProfile
      syncedProfile = {
        ...syncedProfile,
        admin,
        isTeamCreated: true,
      };
    }
  } else {
    /**
     * This means one of the following:
     *    - there's no user record matching the given email address
     *    - there's more than one user record matching the given email address
     *
     * Therefore we don't set the isTeamCreated flag which we use in the login component
     * (together with whether there exists an admin/agent) determine whether to redirect the user
     * to the signup page.
     */

    const usersCount = (await db.collection("users").get()).size;
    if (usersCount > 1) {
      // there are more than one user in the database
      console.error(
        `*** Auth Err: We found ${usersCount} users with email: ${authUser.email} ***`
      );

      // use logRocket to log the error. @see https://docs.logrocket.com/reference/capture-message
      LogRocket.captureMessage(
        `Err: We found duplicate user records for ${authUser.email} in the database.`,
        {
          tags: {
            scope: "auth",
            type: "error",
          },
          extra: {
            userEmail: `${authUser.email}`,
            details: `We found ${usersCount} users for ${authUser.email}.`,
          },
        }
      );
    }

    syncedProfile = {
      authUser: authUser,
      usersCount,
    };
  }

  return syncedProfile;
};

export default syncGlobalProfile;
